import React from "react";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

export const ZoneAutoselect = (props) => {
  const { options, onAutoCompleteChange, value } = props;

  return (
    <Autocomplete
      value={value}
      onChange={onAutoCompleteChange}
      id="zones-autoselect"
      options={[{ id: false, name: "Not In Zone" }, ...options]}
      getOptionLabel={(option) => {
        return option.name;
      }}
      renderInput={(params) => (
        <TextField {...params} label="Zone" variant="filled" margin="dense" />
      )}
    />
  );
};
