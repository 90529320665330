import React, { useState, useEffect } from "react";
import { Snackbar } from "@material-ui/core";
import { GLOBAL_REDUCER_TYPES } from "../../reducers";
import MuiAlert from "@material-ui/lab/Alert";
import { useSelector, useDispatch } from "react-redux";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const ErrorToast = () => {
  const dispatch = useDispatch();
  const error = useSelector((state) => state.globalState.error);
  const [open, setOpen] = useState(!!error);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch({ type: GLOBAL_REDUCER_TYPES.SET_API_REQUEST_ERROR, payload: null });
  };

  useEffect(() => {
    if (error) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [error]);

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Alert onClose={handleClose} severity="error">
        {error && error.message}
        <div>
          {error &&
            error.serializeErrors &&
            error.serializeErrors().map((erroObj, index) => {
              return (
                <div key={index}>
                  {erroObj.field ? `${erroObj.field} :` : ""} {erroObj.message}
                </div>
              );
            })}
        </div>
      </Alert>
    </Snackbar>
  );
};
