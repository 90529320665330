import { loadModules } from "esri-loader";
import { store } from "../../../../store";
import { MapUtilsV2, MAP_ICONS } from "../../../../commons/map";
import { parseUtcToLocal } from "../../../../utils/date-fns";

const MAP_CONFIG = {
  SKETCH_TOOLS: {
    CIRCLE: "circle",
    RECTANGLE: "rectangle",
    POLYGON: "polygon",
  },
  DEFAULT_ZOOM: 16,
};

export const GRAPHICS_ACTIONS = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  CREATE_FROM_TEMPLATE: "CREATE_FROM_TPL",
  NONE: "NONE",
};
export const ZONE_REDUCER_TYPES = {
  FETCH_ALL_ZONES_SUCCESS: "FETCH_ALL_ZONES_SUCCESS",
  FETCH_ALL_ZONE_CATEGORIES_SUCCESS: "FETCH_ALL_ZONE_CATEGORIES_SUCCESS",
  SET_MAP_VIEW: "SET_MAP_VIEW",
  // ** Selected Zone Name  Types **
  SET_SELECTED_ZONE: "SET_SELECTED_ZONE",
  SET_SELECTED_ZONE_NAME: "SET_SELECTED_ZONE_NAME",
  SET_SELECTED_ZONE_HEAD_COUNT: "SET_SELECTED_ZONE_HEAD_COUNT",
  SET_SELECTED_ZONE_ENTRY_MESSAGE: "SET_SELECTED_ZONE_ENTRY_MESSAGE",
  SET_SELECTED_ZONE_EXIT_MESSAGE: "SET_SELECTED_ZONE_EXIT_MESSAGE",
  SET_SELECTED_ZONE_CATEGORIES: "SET_SELECTED_ZONE_CATEGORIES",
  SET_SELECTED_ZONE_COLOUR: "SET_SELECTED_ZONE_COLOUR",
  SET_SELECTED_ZONE_IS_TEMPORARY: "SET_SELECTED_ZONE_IS_TEMPORARY",
  SET_SELECTED_ZONE_SAVE_AS_TEMPLATE: "SET_SELECTED_ZONE_SAVE_AS_TEMPLATE",
  SET_SELECTED_MAP_GRAPHIC: "SET_SELECTED_MAP_GRAPHIC",
  SET_SELECTED_ZONE_BY_GRAPHIC_ID: "SET_SELECTED_ZONE_BY_GRAPHIC_ID",
  SET_SELECTED_ZONE_END_TIME: "SET_SELECTED_ZONE_END_TIME",
  SET_SELECTED_ZONE_START_TIME: "SET_SELECTED_ZONE_START_TIME",
  SET_SELECTED_ZONE_REACTIVATE_START_END_TIME: "SET_SELECTED_ZONE_REACTIVATE_START_END_TIME",
  // selected Category
  SET_SELECTED_CATEGORY: "SET_SELECTED_CATEGORY",
  SET_SELECTED_CATEGORY_NAME: "SET_SELECTED_CATEGORY_NAME",
  SET_SELECTED_CATEGORY_START_TIME: "SET_SELECTED_CATEGORY_START_TIME",
  SET_SELECTED_CATEGORY_END_TIME: "SET_SELECTED_CATEGORY_END_TIME",
  // ** Dialogs and Forms **
  SET_CREATE_ZONE_FORM_DISPLAY: "SET_CREATE_ZONE_FORM_DISPLAY",
  SET_CREATE_NEW_CATEGORY_DIALOG_DISPLAY: "SET_CREATE_NEW_CATEGORY_DIALOG_DISPLAY",
  SET_ADD_ZONE_DIALOG_DISPLAY: "SET_ADD_ZONE_DIALOG_DISPLAY",
  SET_REMOVE_ZONE_DIALOG: "SET_REMOVE_ZONE_DIALOG",
  SET_REMOVE_ZONE_CATEGORY_DIALOG: "SET_REMOVE_ZONE_CATEGORY_DIALOG",
  SET_SELECT_ZONE_FROM_TPL_DISPLAY: "SET_SELECT_ZONE_FROM_TPL_DISPLAY",
  SET_SHOW_ZONES_TPL_TABLE: "SET_SHOW_ZONES_TPL_TABLE",
  SET_ZONE_REACTIVATE_DIALOG_DISPLAY: "SET_ZONE_REACTIVATE_DIALOG_DISPLAY",
  SET_EDIT_ZONE_FROM_TPL_FORM: "SET_EDIT_ZONE_FROM_TPL_FORM",
  SET_EDIT_ZONE_DIALOG_DISPLAY: "SET_EDIT_ZONE_DIALOG",
  // Filter Panel + Filter Map Users
  SET_SHOW_FILTER_PANEL: "SET_SHOW_FILTER_PANEL",
  SET_FILTER_PANEL_ZONE_TYPE: "SET_FILTER_PANEL_ZONE_TYPE",
  SET_FILTER_PANEL_ZONE_CATEGORIES: "SET_FILTER_PANEL_ZONE_CATEGORIES",
  REMOVE_FILTER_PANEL_ZONE_CATEGORIES: "REMOVE_FILTER_PANEL_ZONE_CATEGORIES",
  CLEAR_FILTER_PANEL: "CLEAR_FILTER_PANEL",
  // Search
  SET_ZONES_SEARCH_VALUE: "SET_ZONES_SEARCH_VALUE",
  // MAP
  SET_MAP_EDIT_SHAPE_IN_PROGRESS: "SET_MAP_EDIT_SHAPE_IN_PROGRESS",
  // Filters
  SET_ZONES_VIEW_PAGE_FILTER: "SET_ZONES_VIEW_PAGE_FILTER",
  // Soft ALerts
  SET_DASHBOARD_MESSAGE_SOFT_ALERT: "SET_DASHBOARD_MESSAGE_SOFT_ALERT",
  SET_SITE_DEVICES_SUMMARY: "SET_SITE_DEVICES_SUMMARY",
  // Edit Zone Form State
  SET_EDIT_FORM: "SET_EDIT_FORM",
  SET_USER_HISTORY: "SET_USER_HISTORY",
};
const GRAPHICS_LAYERS_ID = {
  ZONES: "ZONES_GRAPHICS_LAYER",
  LABELS: "ZONES_LABEL_LAYER",
  HEADCOUNT: "ZONES_HEADCOUNT_LAYER",
  USER_DEVICES: "USER_DEVICES_LAYER",
  MAP_VECTOR_TILE: "MAP_VECTOR_TILE",
  USERS: "USERS",
  USER_PATH: "USER_PATH"
};

const colors = ["#6D717A", "#878C95", "#A2A6B1", "#BCC1CC", "#D5CBCB", "#EDD5CA", "#E2B49A", "#DB9167", "#CC7441"];

let ArcGISMap,
  MapView,
  GraphicsLayer,
  Sketch,
  SketchViewModel,
  Graphic,
  Polygon,
  Point,
  geometryEngine,
  webMercatorUtils,
  VectorTileLayer,
  EsriConfig,
  Legend,
  Expand,
  FeatureLayer,
  UniqueValueRenderer,
  SimpleLineSymbol,
  Color,
  PictuureMakerSymbol,
  MapImageLayer,
  TileLayer;

export class DeviceHistoryReportMap {
  constructor(props) {
    this.modulesLoaded = false;
    this.map = null;
    this.mapView = null;
    this.sketchVM = null;
    this.selectedGraphic = null;
    this.currentGraphic = null;
    this.graphicSketchAction = GRAPHICS_ACTIONS.NONE;
    this.store = store;
    this.mapUtils = null;
    this.site = props.site;
    this.filterData = props.filterData;
    this.deviceDetails = props.deviceDetails;
    this.state = {
      showGraphicLabel: false,
      showSketchTools: false,
      formOpen: false,
      interval: null
    };

    this.loadModulesAndInitMap();
  }

  async loadModulesAndInitMap() {
    [
      ArcGISMap,
      Color,
      MapView,
      EsriConfig,
      GraphicsLayer,
      Graphic,
      Polygon,
      Sketch,
      SketchViewModel,
      Point,
      geometryEngine,
      webMercatorUtils,
      VectorTileLayer,
      Legend,
      Expand,
      FeatureLayer,
      UniqueValueRenderer,
      SimpleLineSymbol,
      PictuureMakerSymbol,
      MapImageLayer,
      TileLayer
    ] = await loadModules(
      [
        "esri/Map",
        "esri/Color",
        "esri/views/MapView",
        "esri/config",
        "esri/layers/GraphicsLayer",
        "esri/Graphic",
        "esri/geometry/Polygon",
        "esri/widgets/Sketch",
        "esri/widgets/Sketch/SketchViewModel",
        "esri/geometry/Point",
        "esri/geometry/geometryEngine",
        "esri/geometry/support/webMercatorUtils",
        "esri/layers/VectorTileLayer",
        "esri/widgets/Legend",
        "esri/widgets/Expand",
        "esri/layers/FeatureLayer",
        "esri/renderers/UniqueValueRenderer",
        "esri/symbols/SimpleLineSymbol",
        "esri/symbols/PictureMarkerSymbol",
        "esri/layers/MapImageLayer",
        "esri/layers/TileLayer"
      ],
      {
        css: true,
      }
    );

    this.modulesLoaded = true;
    if (process.env.REACT_APP_SITEWISE_ARCGIS_MAP_KEY) {
      EsriConfig.apiKey = process.env.REACT_APP_SITEWISE_ARCGIS_MAP_KEY;
    }
    this.map = new ArcGISMap({
      basemap: "satellite" // Basemap layer
    });
    var mark = [72.6218139, 21.0899323];
    if (this.deviceDetails && this.deviceDetails.status && this.deviceDetails.status.location ) {
      if(this.deviceDetails.status.location.longitude && this.deviceDetails.status.location.latitude){
      mark = [this.deviceDetails.status.location.longitude, this.deviceDetails.status.location.latitude]
      }
    }
    if (this.filterData && this.filterData.userLocationHistory && this.filterData.userLocationHistory[0]) {
      mark = [this.filterData.userLocationHistory[0].longitude, this.filterData.userLocationHistory[0].latitude]
    }

    this.mapView = new MapView({
      map: this.map,
      //...MapUtilsV2.getSubSiteCenter(site),
      // zoom: site.subSites && site.subSites[0] ? site.subSites[0].zoom : 3,
      // constraints: {
      //   maxZoom: site.maxZoom ? site.maxZoom : 3,
      //   minZoom: site.minZoom ? site.minZoom : 20,
      // },
      center: mark,
      zoom: 13,
      container: "map-container",
      constraints: {
        snapToZoom: false
      }
    });
    const userPathGraphicLayer = new GraphicsLayer({
      id: GRAPHICS_LAYERS_ID.USER_PATH,
    });
    const usersGraphicLayer = new GraphicsLayer({
      id: GRAPHICS_LAYERS_ID.USERS,
    });
    this.map.addMany([
      userPathGraphicLayer,
      usersGraphicLayer
    ]);
    if (this.filterData) {
      this.triggerHistory(this.filterData);
      this.mapView.when(() => {
        this.mapView.on("click", this.clickEvents);
      });
    }

  }

  clickEvents = async (event) => {
    event.stopPropagation();
    this.onUserDeviceClick(event);
  };
  onUserDeviceClick = async (event) => {
    const response = await this.mapView.hitTest(event);
    if (response.results.length) {
      const graphics = response.results.filter(
        (result) => result.graphic.layer.id === GRAPHICS_LAYERS_ID.USERS
      );
      if (graphics.length) {
        this.mapView.popup.open({});
        if (this.mapView.popup.visible === false) {
          this.mapView.popup.visible = true;
        }
        this.mapView.popup.location = graphics[0].mapPoint;
        this.mapView.popup.title = parseUtcToLocal(graphics[0].graphic.attributes.zone.timeStamp);
      }
    }
  };
  async triggerHistory(zones) {
    this.resetUserHistoryMapLayer();
    await this.renderLines(zones);
    await this.rendersUserPoint(zones);
  }
  async renderLines(zones) {
    if (zones != null && zones.userLocationHistory != null && zones.userLocationHistory.length > 0) {
      const zonesLabelsGraphicLayer = MapUtilsV2.getLayerById(this.map, GRAPHICS_LAYERS_ID.USER_PATH); 
        for (var row = zones.userLocationHistory.length - 1; row >= 0; row--) {
        var paths = [];
        var firstElement = zones.userLocationHistory[row];
        var secondElement = zones.userLocationHistory[row + 1];
        if (secondElement == null) {
          secondElement = firstElement;
        }

        paths.push([firstElement.longitude, firstElement.latitude]);
        paths.push([secondElement.longitude, secondElement.latitude]);
        const polyline = {
          type: "polyline",
          paths: paths
        };
        const simpleLineSymbol = {
          type: "simple-line",
          color: colors[colors.length - row - 1] != null ? colors[colors.length - row - 1] : colors[0], // Orange
          width: 2
        };
        const polylineGraphic = new Graphic({
          geometry: polyline,
          symbol: simpleLineSymbol
        });
        zonesLabelsGraphicLayer.add(polylineGraphic);
      }
      await this.map.add(zonesLabelsGraphicLayer);

    }
  }
  async rendersUserPoint(zones) {
    const zonesGraphicsLayerZones = MapUtilsV2.getLayerById(this.map, GRAPHICS_LAYERS_ID.USERS);
    if (zones.userLocationHistory != null && zones.userLocationHistory.length > 0) {
      // zones.userLocationHistory.forEach(async (zone, index) => {
      for (var row = zones.userLocationHistory.length - 1; row >= 0; row--) {
        //zones.userLocationHistory.forEach(async (zone, index) => {
        var zone = zones.userLocationHistory[row];
        var index = row;
        const point = { //Create a point
          type: "point",
          longitude: zone.longitude,
          latitude: zone.latitude
        };
        const simpleMarkerSymbol = {
          type: "simple-marker",
          color: colors[colors.length - index - 1] != null ? colors[colors.length - index - 1] : colors[0],//index==0?[226, 119, 40]:[199, 200, 201],  // Orange
          outline: {
            color: colors[colors.length - index - 1] != null ? colors[colors.length - index - 1] : colors[0],//index==0?[226, 119, 40]:[226, 119, 40], // [255, 255, 255], // White
            width: 2
          }
        };
        const pointGraphic = new Graphic({
          geometry: point,
          symbol: simpleMarkerSymbol,
          popupTemplate: {
            title: zone.timeStamp,
            content: [{
              type: "fields",
              fieldInfos: [{
                fieldName: zone.timeStamp,
                label: zone.timeStamp,
                format: {
                  digitSeparator: true
                }
              }, {
                fieldName: "expression/per-asian"
              }]
            }]
          },
          attributes: {
            zone
          }
        });
        zonesGraphicsLayerZones.add(pointGraphic);
      }
      await this.map.add(zonesGraphicsLayerZones);
    }
  }
  resetUserHistoryMapLayer = async () => {
    const toClearLayers = [GRAPHICS_LAYERS_ID.USERS, GRAPHICS_LAYERS_ID.USER_PATH];
    for (const layerID of toClearLayers) {
      const layer = MapUtilsV2.getLayerById(this.map, layerID);
      layer && layer.graphics.removeAll();
    }
  };
}
