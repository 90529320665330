import { makeStyles } from "@material-ui/core";
import { FONTS } from "../../utils";
import { fade } from "@material-ui/core/styles/colorManipulator";

const spaceBetween = "space-between";
export const useCommonStyles = makeStyles((theme) => ({
  page_wrapper: {
    flex: 1,
    display: "flex",
    paddingTop: "43px",
    paddingLeft: "39px",
    paddingRight: "39px",
    flexDirection: "column",
  },
  page_header: {
    marginBottom: "64px",
    display: "flex",
    justifyContent: spaceBetween,
  },
  page_breadcrumps_header: {
    marginBottom: "33px",
    display: "flex",
    justifyContent: spaceBetween,
  },
  page_breadcrumps: {
    marginBottom: "55px",
    display: "flex",
    gap: "26px",
    alignItems: "center",
    ...FONTS.B1_Reg_18px,
    '& svg': {
      ...FONTS.B1_Reg_18px,
    },
  },
  page_title: {
    ...FONTS.H4_ShellBold_24,
  },
  page_subtitle: {
    ...FONTS.Label1_ShellBook_24,
  },
  formPaper: {
    width: "100%",
    height: "100%",
    background: theme.palette.base.elevation_1,
    padding: "24px",
    overflow: "scroll",
    [theme.breakpoints.down("sm")]: {
      // tablets
      height: "40vh",
      marginTop: "10px",
    },
  },
  //TABS COMPONENT
  tabs_wrapper: {
    display: "flex",
    justifyContent: spaceBetween,
    alignItems: "center",
    background: theme.palette.background.default,
  },
  tabs_indicator: {
    background: "transparent",
  },
  tabs_root: {
    background: fade(theme.palette.base.elevation_1, 0.5),
  },
  tab_root: {
    textTransform: "none",
    ...FONTS.B2_Reg_16,
  },
  tab_selected: {
    background: theme.palette.base.elevation_1,
    ...FONTS.H7_BOLD_16,
  },
  ellipses: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));
