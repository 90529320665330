import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Paper,
  useTheme,
  makeStyles,
  useMediaQuery,
  Table,
  TableBody,
  TableFooter,
  TableContainer,
  TablePagination,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { Row } from "./row";
import {
  useCustomTableHook,
  useCustomTableStyles,
  EnhancedTableToolbar,
  EnhancedTableHead,
  UserPermissionContext,
  ICONS,
  useDebounce,
} from "../../../../../commons";
import { parseUtcToLocal } from "../../../../../utils";
import { ZONE_REDUCER_TYPES, ZonesReducerActions } from "../../../../../reducers";

const headCells = [
  {
    id: "name",
    sortBy: "name",
    label: "Category Name",
    content: (row) => row.name,
    width: "20%",
  },
  {
    id: "isAdHoc",
    sortBy: "isAdHoc",
    label: "Status",
    content: (row) => (row.isAdHoc ? "Temporary" : "Permanent"),
    width: "15%",
  },
  {
    id: "startTime",
    sortBy: "startTime",
    label: "Starts on",
    content: (row) => parseUtcToLocal(row.startTime),
    width: "20%",
  },
  {
    id: "endTime",
    sortBy: "endTime",
    label: "Ends on",
    content: (row) => parseUtcToLocal(row.endTime),
    width: "20%",
  },
  {
    id: "activeZones",
    sortBy: "activeZones",
    label: "Zones",
    content: (row) => row.activeZones,
    width: "6%",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  buttonRoot: {
    border: "1px solid white",
  },
}));

export const CategoriesTable = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const tableStyles = useCustomTableStyles();
  const dispatch = useDispatch();
  const selectedZone = useSelector((state) => state.zonesScreen.selectedZone);
  const [tableSearchStr, setTableSearchStr] = useState("");
  const [tableData, setTableData] = useState([]);
  const { Permissions } = useContext(UserPermissionContext);
  const isTablet = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    setPage,
    getComparator,
    stableSort,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useCustomTableHook({ defaultSortBy: headCells[0].sortBy, rowsPerPage: isTablet ? 3 : 10 });

  const { isLargeMapView, data } = props;

  const checkIfRowSelected = (id) => selectedZone && selectedZone.id === id;

  const tableSearchDebounced = useDebounce((_nextVal) => {
    setPage(0);
    setTableSearchStr(_nextVal);
  }, 300);

  useEffect(() => {
    dispatch(ZonesReducerActions.getAllZoneCategories());
  }, [dispatch]);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  useEffect(() => {
    let arr = [];

    if (tableSearchStr) {
      arr = data.filter(
        (tData) => tData.name && tData.name.toLowerCase().includes(tableSearchStr.toLowerCase())
      );
    } else {
      arr = data;
    }

    setTableData(arr);
    setPage(0);
  }, [tableSearchStr, setTableData, data, setPage]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          toShow={isLargeMapView}
          headCells={headCells}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />

        <TableContainer style={{ maxHeight: isTablet ? "24vh" : "60vh" }}>
          <Table
            className={tableStyles.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
            classes={{
              root: tableStyles.tableRoot,
            }}
            stickyHeader
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
              toShow={!isLargeMapView}
              tableWithRowActions={true}
              onChangeSearch={(event) => {
                tableSearchDebounced(event.target.value);
              }}
              searchLabel={"Category Name"}
            />
            <TableBody>
              {stableSort(tableData, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((rowData) => {
                  const isItemSelected = checkIfRowSelected(rowData.id);
                  return (
                    <Row
                      key={rowData.id}
                      rowData={rowData}
                      headCells={headCells}
                      isItemSelected={isItemSelected}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TableFooter className={tableStyles.tabelFooterRoot} component="div">
          {Permissions.Manage_Zones ? (
            <Button
              startIcon={<ICONS.Add />}
              color="primary"
              onClick={() =>
                dispatch({
                  type: ZONE_REDUCER_TYPES.SET_CREATE_NEW_CATEGORY_DIALOG_DISPLAY,
                  payload: true,
                })
              }
            >
              Add new Category
            </Button>
          ) : (
            <div></div>
          )}

          <TablePagination
            classes={{
              root: tableStyles.tablePaginationRoot,
            }}
            rowsPerPageOptions={[]}
            labelRowsPerPage=""
            count={tableData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            component="div"
          />
        </TableFooter>
      </Paper>
    </div>
  );
};
