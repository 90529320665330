import React from "react";
import { Select, MenuItem, InputLabel, makeStyles } from "@material-ui/core";

const styles = makeStyles((theme) => ({
  select: {
    color: theme.palette.primary.main,
    "&:before": {
      borderBottom: "none !important",
    },
  },
}));

const recepientTypes = [
  {
    label: "By User Type",
    key: "USER_TYPE",
  },
  {
    label: "By User Role",
    key: "USER_ROLE",
  },
  {
    label: "By User Category",
    key: "USER_CATEGORY",
  },
  {
    label: "By Zone",
    key: "USER_ZONE",
  },
  {
    label: "By Zone Category",
    key: "ZONE_CATEGORIES",
  },
  {
    label: "All users",
    key: "ALL_USERS",
  },
  {
    label: "By User Device ID",
    key: "BY_USER_DEVICE_ID",
  }
];

export const MessageRecepientTypeDropdown = (props) => {
  const classes = styles();
  const { value, onChange } = props;
  return (
    <>
      <InputLabel id="message-recepient-type-dropdown-label">Select Recipient</InputLabel>
      <Select
        className={classes.select}
        labelId="message-recepient-type-dropdown-label"
        id="message-recepient-type-dropdown"
        onChange={onChange}
        value={value ? recepientTypes.find((type) => type.key === value) : ""}
        data-testid="message-recepient-type-dropdown"
        inputProps={{
          id: "message-recepient-type-dropdown-input",
          "data-testid": "message-recepient-type-dropdown-input",
        }}
      >
        <MenuItem value="" disabled>
          Select Recipient
        </MenuItem>

        {recepientTypes.map((recepientObj) => (
          <MenuItem key={recepientObj.key} value={recepientObj}>
            {recepientObj.label}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};
