import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Paper, makeStyles } from "@material-ui/core";
import { FONTS } from "../../../utils";
import { ZonesReducerActions, ZONE_REDUCER_TYPES } from "../../../reducers";
import { ZonesColorCodes } from "../../../config";
import { Datepicker, ColorPallete, useCommonStyles } from "../../../commons";
import { parseUtcToLocal } from "../../../utils";
import { ZoneCategoriesAutocomplete } from "../components";

import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

const MenuProps = {
  PaperProps: {
    style: {
      width: 250,
    },
  },
};

const useStyles = makeStyles(() => ({
  formControl: {
    width: "100%",
    marginTop: "24px",
  },
  formTitle: {
    ...FONTS.H5_Bold_22,
    marginBottom: "12px",
  },
}));

export const EditZoneFromTpl = (props) => {
  const classes = useStyles();
  const commonStyles = useCommonStyles();
  const dispatch = useDispatch();
  const { onCancel, onConfirm } = props;
  const selectedZone = useSelector((state) => state.zonesScreen.selectedZone);
  const [name, setName] = useState("");
  const entryMessage = selectedZone?.entryMessage;
  const exitMessage = selectedZone?.exitMessage;
  const colour = selectedZone?.colour;
  const categories = selectedZone?.categories;
  const isTemporary = selectedZone?.isTemporary;
  const endTime = selectedZone?.endTime;
  const startTime = selectedZone?.startTime;
  const selectedMapGraphic = useSelector((state) => state.zonesScreen.selectedMapGraphic);
  const [saveAsTemplate, setSaveAsTemplate] = useState(false);

  const zoneCategories = useSelector((state) => state.zonesScreen.zoneCategories?.active);
  const [defaultCategory] = useState(
    zoneCategories.filter((cat) => cat.name.toLowerCase() === "common area")
  );

  const getRenderValue = (colorObj) => {
    return (
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <ColorPallete colour={colorObj.colorCode} />
        <span style={{ marginLeft: "5px" }}> {colorObj.label}</span>
      </div>
    );
  };

  function handleFormSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    if (name) {
      dispatch({
        type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE_SAVE_AS_TEMPLATE,
        payload: saveAsTemplate,
      });
      dispatch(ZonesReducerActions.createZoneFromTpl());
    }
    onConfirm && onConfirm();
  }

  return (
    <Paper className={commonStyles.formPaper}>
      <div className={classes.formTitle}>Edit Zone From Template</div>
      <form onSubmit={handleFormSubmit} autocomplete="false">
        <Grid container spacing={2}>
          <Grid item md={12} xs={6}>
            <FormControl variant="filled" className={classes.formControl}>
              <TextField
                autoComplete="off"
                required
                id="filled-basic"
                label="Zone Name"
                variant="filled"
                fullWidth={true}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  dispatch({
                    type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE_NAME,
                    payload: e.target.value,
                  });
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={12} xs={6}>
            <FormControl variant="filled" className={classes.formControl}>
              <ZoneCategoriesAutocomplete
                value={categories}
                options={zoneCategories}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValues) => {
                  const newCategories = [
                    ...defaultCategory,
                    ...newValues.filter((option) => option.id !== defaultCategory[0].id),
                  ];

                  dispatch({
                    type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE_CATEGORIES,
                    payload: newCategories,
                  });
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={12} xs={6}>
            <FormControl variant="filled" className={classes.formControl}>
              <TextField
                helperText={`(${entryMessage ? entryMessage.length : 0}/120)`}
                id="filled-basic"
                label="Entry Message"
                variant="filled"
                multiline
                rows={3}
                rowsMax={3}
                fullWidth={true}
                value={entryMessage.slice(0, 120)}
                onChange={(event) =>
                  dispatch({
                    type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE_ENTRY_MESSAGE,
                    payload: event.target.value.slice(0, 120),
                  })
                }
              />
            </FormControl>
          </Grid>
          <Grid item md={12} xs={6}>
            <FormControl variant="filled" className={classes.formControl}>
              <TextField
                id="filled-basic"
                label="Exit Message"
                variant="filled"
                multiline
                helperText={`(${exitMessage ? exitMessage.length : 0}/120)`}
                rows={3}
                rowsMax={3}
                fullWidth={true}
                onChange={(event) =>
                  dispatch({
                    type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE_EXIT_MESSAGE,
                    payload: event.target.value.slice(0, 120),
                  })
                }
                value={exitMessage.slice(0, 120)}
              />
            </FormControl>
          </Grid>
          <Grid item md={12} xs={6}>
            <FormControl variant="filled" className={classes.formControl}>
              <InputLabel id="simple-select-filled-label">Color Code</InputLabel>
              <Select
                MenuProps={MenuProps}
                labelId="simple-select-filled-label"
                id="simple-select-filled"
                onChange={(event) => {
                  const colorCode = event.target.value.colorCode;
                  const colourObj = { red: colorCode[0], green: colorCode[1], blue: colorCode[2] };
                  dispatch({
                    type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE_COLOUR,
                    payload: colourObj,
                  });
                }}
                renderValue={getRenderValue}
                defaultValue={{ colorCode: colour, label: "" }}
              >
                {ZonesColorCodes.map((colorObj) => {
                  return (
                    <MenuItem value={colorObj} style={{ display: "inline-block" }}>
                      <ColorPallete colour={colorObj.colorCode} />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={12} xs={6}>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={saveAsTemplate}
                    onChange={(event) => {
                      setSaveAsTemplate(event.target.checked);
                    }}
                    name="save_as_template"
                    color="primary"
                  />
                }
                label="Save as Template"
              />
            </FormControl>
          </Grid>
          <Grid item md={12} xs={12}>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isTemporary}
                    onChange={(event) => {
                      dispatch({
                        type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE_IS_TEMPORARY,
                        payload: event.target.checked,
                      });
                    }}
                    name="temp_zone_checkbox"
                    color="primary"
                  />
                }
                label="Make a Temporary Zone"
              />
            </FormControl>
          </Grid>
        </Grid>

        {isTemporary ? (
          <Grid item md={12} xs={6}>
            <FormControl component="fieldset" style={{ width: "100%" }}>
              <Datepicker
                handleDateChange={(dateTime) => {
                  dispatch({
                    type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE_START_TIME,
                    payload: dateTime,
                  });
                }}
                selectedDate={startTime}
                label="Select Start Date"
              />
              <Datepicker
                handleDateChange={(dateTime) => {
                  dispatch({
                    type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE_END_TIME,
                    payload: dateTime,
                  });
                }}
                selectedDate={endTime}
                label="Select End Date"
                {...(startTime && {
                  minDate: startTime,
                })}
                {...(startTime && {
                  minDateMessage: `Date and Time should not be less than ${parseUtcToLocal(
                    startTime,
                    "MM-DD-YY HH:mm A"
                  )}`,
                })}
                strictCompareDates={true}
              />
            </FormControl>
          </Grid>
        ) : null}
        <Grid item md={12} xs={12}>
          <FormControl
            className={classes.formControl}
            style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}
          >
            <Button
              variant="outlined"
              color="primary"
              style={{ ...FONTS.B4_BOLD_14 }}
              onClick={(event) => {
                dispatch({
                  type: ZONE_REDUCER_TYPES.SET_EDIT_ZONE_FROM_TPL_FORM,
                  payload: {
                    show: false,
                  },
                });
                dispatch({ type: ZONE_REDUCER_TYPES.SET_SHOW_ZONES_TPL_TABLE, payload: false });
                onCancel && onCancel(event);
              }}
            >
              Cancel
            </Button>

            <Button
              disabled={!selectedMapGraphic}
              type="submit"
              style={{ marginLeft: "10px", ...FONTS.B4_BOLD_14 }}
              variant="contained"
              color="primary"
            >
              Create
            </Button>
          </FormControl>
        </Grid>
      </form>
    </Paper>
  );
};
