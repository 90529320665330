import React, { useContext } from "react";
import { Grid, Box, Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { DocumentTitle, UserPermissionContext, useCommonStyles } from "../../commons";
import { MessagesContainer } from "./messages-container";
import { MESSAGES_REDUCER_TYPES } from "../../reducers";

const MessagesScreen = () => {
  const dispatch = useDispatch();
  const commonStyles = useCommonStyles();
  const { Permissions } = useContext(UserPermissionContext);

  return (
    <>
      <DocumentTitle title="Sitewise | Messages" />
      <Grid id="j-sitewise-messages" className={commonStyles.page_wrapper}>
        <Box className={commonStyles.page_header}>
          <span className={commonStyles.page_title} data-testid="page-title">
            Messages
          </span>
          {Permissions.Manage_Bulk_Messages ? (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                dispatch({
                  type: MESSAGES_REDUCER_TYPES.SET_SEND_MESSAGE_DIALOG_DISPLAY,
                  payload: true,
                });
              }}
              data-testid="send-message-bttn"
            >
              SEND NEW MESSAGE
            </Button>
          ) : null}
        </Box>
        <MessagesContainer />
      </Grid>
    </>
  );
};

export default MessagesScreen;
