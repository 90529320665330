import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableFooter,
  TableRow,
  TableContainer,
  TablePagination,
  Paper,
  IconButton,
  useTheme,
  Button,
} from "@material-ui/core";
import {
  EnhancedTableToolbar,
  EnhancedTableHead,
  useCustomTableHook,
  ICONS,
} from "../../../../../commons";
import { Row } from "./row";
import { useSelector, useDispatch } from "react-redux";
import { ZONE_REDUCER_TYPES } from "../../../../../reducers";

const headCells = [
  {
    id: "name",
    sortBy: "name",
    label: "Zone Name",
    isVisibleInRow: true,
    content: (row) => row.name,
  },
  {
    id: "status",
    sortBy: "isTemporary",
    isVisibleInRow: false,
    label: "Status",
    content: (row) => (row.isTemporary ? "Temporary" : "Permanent"),
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {},
  buttonRoot: {
    border: "1px solid white",
  },
}));

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    count,
    page,
    rowsPerPage,
    onChangePage,
    disableTableFooterActionBttn,
    selectZoneAndEditFromTpl,
  } = props;

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleFooterActionBttnClick = () => {
    dispatch({
      type: ZONE_REDUCER_TYPES.SET_EDIT_ZONE_FROM_TPL_FORM,
      payload: {
        show: true,
      },
    });

    selectZoneAndEditFromTpl && selectZoneAndEditFromTpl();
  };

  return (
    <div className={classes.root}>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === "rtl" ? <ICONS.KeyboardArrowRight /> : <ICONS.KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? <ICONS.KeyboardArrowLeft /> : <ICONS.KeyboardArrowRight />}
      </IconButton>
      <Button disabled={disableTableFooterActionBttn} onClick={handleFooterActionBttnClick}>
        Add
      </Button>
    </div>
  );
}

export const ZonesTemplateTable = (props) => {
  const classes = useStyles();

  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    getComparator,
    stableSort,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useCustomTableHook({ rowsPerPage: 5 });

  const selectedZone = useSelector((state) => state.zonesScreen.selectedZone);

  const { isLargeMapView, data, onTableRowSelectedCb, selectZoneAndEditFromTpl } = props;

  const checkIfRowSelected = (id) => selectedZone && selectedZone.id === id;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          toShow={isLargeMapView}
          headCells={headCells}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />

        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="Template TableTitle"
            aria-label="Zones Template Table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
              toShow={!isLargeMapView}
            />
            <TableBody>
              {stableSort(data, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((rowData, index) => {
                  const isItemSelected = checkIfRowSelected(rowData.id);
                  return (
                    <Row
                      key={index}
                      rowData={rowData}
                      headCells={headCells}
                      isItemSelected={isItemSelected}
                      onTableRowSelectedCb={onTableRowSelectedCb}
                    />
                  );
                })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[]}
                  labelRowsPerPage=""
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={(subProps) => (
                    <TablePaginationActions
                      {...subProps}
                      selectZoneAndEditFromTpl={selectZoneAndEditFromTpl}
                      disableTableFooterActionBttn={!selectedZone}
                    />
                  )}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};
