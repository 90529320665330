import React from "react";
import PropTypes from "prop-types";
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  InputAdornment,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { ICONS } from "../../commons";
import { useSelector } from "react-redux";

const useStyles = (isOverlay, isminWidth) =>
  makeStyles((theme) => ({
    tableHeadRoot: {
      background: isOverlay
        ? theme.palette.base.elevation_overlay_1
        : theme.palette.base.elevation_1,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    tableCell: {
      background: isOverlay
        ? theme.palette.base.elevation_overlay_1
        : theme.palette.base.elevation_1,
      padding: "10px",
    },
    searchField: {
      minWidth: isminWidth ? 205 : 0,
    },
  }));

function RenderSearchField(props) {
  const { classes, tableWithRowActions, searchLabel, onChangeSearch, searchVal, editing } = props;
  const searchFieldInputRef = React.useRef();
  function handleClick(e) {
    e.stopPropagation();
    searchFieldInputRef.current.focus();
  }

  if (tableWithRowActions) {
    return (
      <TableCell
        classes={{
          root: classes.tableCell,
        }}
        style={{ textAlign: "right" }}
      >
        <TextField
          classes={{ root: classes.searchField }}
          inputRef={searchFieldInputRef}
          id="table-search-textfield"
          placeholder={searchLabel || "Search..."}
          onChange={onChangeSearch}
          autoComplete="off"
          onClick={handleClick}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ICONS.Search />
              </InputAdornment>
            ),
          }}
          {...(typeof searchVal !== "undefined" ? { value: searchVal } : {})}
          disabled={editing}
        />
      </TableCell>
    );
  } else {
    return null;
  }
}

export const EnhancedTableHead = (props) => {
  const {
    order,
    orderBy,
    onRequestSort,
    headCells,
    toShow = true,
    tableWithRowActions,
    onChangeSearch,
    searchLabel,
    searchVal,
    isOverlay,
    isminWidth,
  } = props;
  const classes = useStyles(isOverlay, isminWidth)();
  const editing = useSelector((state) => state.zonesScreen.editingForm);

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  if (toShow) {
    const sortedOrder = (order) => {
      return order === "desc" ? "sorted descending" : "sorted ascending";
    };
    return (
      <TableHead className={`${classes.tableHeadRoot}`}>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              classes={{
                root: classes.tableCell,
              }}
              key={headCell.id}
              align={"left"}
              padding={"default"}
              sortDirection={orderBy === headCell.sortBy ? order : false}
              {...(headCell.width && {
                style: {
                  width: headCell.width,
                },
              })}
            >
              <TableSortLabel
                active={orderBy === headCell.sortBy}
                direction={orderBy === headCell.sortBy ? order : "asc"}
                onClick={createSortHandler(headCell.sortBy)}
              >
                {headCell.label}
                {orderBy === headCell.sortBy ? (
                  <span className={classes.visuallyHidden}>{sortedOrder(order)}</span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
          {RenderSearchField({
            tableWithRowActions,
            onChangeSearch,
            searchLabel,
            searchVal,
            classes,
            editing,
          })}
        </TableRow>
      </TableHead>
    );
  }

  return null;
};

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};
