import React, { lazy, Suspense, useEffect, useContext, useMemo } from "react";
import { MuiThemeProvider, createMuiTheme, CssBaseline } from "@material-ui/core";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useUserPermission, USER_PERMISSIONS, UserPermissionContext, ScopeContext } from "./commons";
import { lightThemeConfig, themeConfig } from "./theme";
import { MainScreen, ZonesScreen, LoginScreen, PrivateRoute } from "./routes";
import { SignalRConn } from "./utils/signalR";
import { GLOBAL_REDUCER_TYPES } from "./reducers";
import "./App.css";
import { APP_CONSTANTS } from "./config";

const DevicesScreen = lazy(() => import("./routes/devices/devices-screen"));
const MessagesScreen = lazy(() => import("./routes/messages/messages-screen"));
const AlertsScreen = lazy(() => import("./routes/alerts/alerts-screen"));
const UsersScreen = lazy(() => import("./routes/users/users-screen"));
const PrivacyScreen = lazy(() => import("./routes/privacy/privacy-screen"));
const SitesScreen = lazy(() => import("./routes/sites/screens/sites-screen/sites-screen"));
const SiteEditScreen = lazy(() =>
  import("./routes/sites/screens/site-edit-screen/site-edit-screen")
);
const PrivacyConsentScreen = lazy(() => import("./routes/privacy/privacy-consent-screen"));
const ReportsScreen = lazy(() => import("./routes/reports/reports-screen"));

const EVENTS = {
  alerts: "alerts",
  bulkdevicemessages: "bulkdevicemessages",
  dashboardmessages: "dashboardmessages",
  devices: "devices",
  zones: "zones",
  zonecategories: "zonecategories",
  sitelocations: "sitelocations",
  roles: "roles",
  users: "users",
};

function isOneConditionTrue(condition1, condition2) {
  const response = condition1 || condition2;
  return response;
}

function isRouteEnabled(routeAuthorization, route) {
  let response = null;
  if (routeAuthorization) {
    response = route;
  }
  return response;
}

export const App = () => {
  const { namedRoutes} = useContext(ScopeContext);

  const auth = useSelector((state) => state.auth);
  const activeAlerts = useSelector((state) => state.globalState.alerts.activeAlerts);
  const currentTheme = useSelector((state) => state.globalState.theme);

  const createdTheme = useMemo(() => {
    const selectedTheme = currentTheme === APP_CONSTANTS.THEME_DARK 
      ? themeConfig 
      : lightThemeConfig;
    return createMuiTheme(selectedTheme);
  }, [currentTheme]);
  
  const dispatch = useDispatch();
  const isZonesRouteEnabled = useUserPermission(USER_PERMISSIONS.View_Zones);
  const isDevicesRouteEnabled = useUserPermission(USER_PERMISSIONS.View_Devices);
  const isUsersTabEnabled = useUserPermission(USER_PERMISSIONS.View_Users);
  const isRolesTabEnabled = useUserPermission(USER_PERMISSIONS.View_Roles);
  const isPeopleRouteEnabled = isOneConditionTrue(isUsersTabEnabled, isRolesTabEnabled);
  const isAlertsRouteEnabled = useUserPermission(USER_PERMISSIONS.View_Alerts);
  const areDashboardMessagesEnabled = useUserPermission(USER_PERMISSIONS.View_Dashboard_messages);
  const areBulkMessagesEnabled = useUserPermission(USER_PERMISSIONS.View_Bulk_Messages);
  const isMessagesRouteEnabled = isOneConditionTrue(areDashboardMessagesEnabled, areBulkMessagesEnabled);
  const usersSitesQty = auth?.sitewiseUser?.SiteLocationIds?.length > 0;
  const isManageSitesRouteEnabled = isOneConditionTrue(useUserPermission(USER_PERMISSIONS.Manage_Sites), usersSitesQty);
  const RoutesJSON = [
    isRouteEnabled(isZonesRouteEnabled,
      {
        label: "Zones",
        href: namedRoutes.zones,
        component: ZonesScreen,
        supportedSignalREventKeys: [
          EVENTS.alerts,
          EVENTS.bulkdevicemessages,
          EVENTS.dashboardmessages,
          EVENTS.devices,
          EVENTS.zones,
          EVENTS.zonecategories,
        ],
      }),
    isRouteEnabled(isAlertsRouteEnabled,
      {
        label: "Alerts",
        href: namedRoutes.alerts,
        component: AlertsScreen,
        supportedSignalREventKeys: [EVENTS.alerts, EVENTS.devices, EVENTS.zones],
      }
    ),
    isRouteEnabled(isPeopleRouteEnabled,
      {
        label: "Users",
        href: namedRoutes.users,
        component: UsersScreen,
        supportedSignalREventKeys: [EVENTS.roles, EVENTS.users, EVENTS.alerts],
      }
    ),
    isRouteEnabled(isDevicesRouteEnabled,
      {
        label: "Devices",
        href: namedRoutes.devices,
        component: DevicesScreen,
        supportedSignalREventKeys: [EVENTS.devices, EVENTS.alerts],
      }
    ),
    isRouteEnabled(isMessagesRouteEnabled,
      {
        label: "Messages",
        href: namedRoutes.messages,
        component: MessagesScreen,
        supportedSignalREventKeys: [
          EVENTS.bulkdevicemessages,
          EVENTS.dashboardmessages,
          EVENTS.alerts,
        ],
      }
    ),
    {
      label: "Reports",
      href: namedRoutes.reports,
      component: ReportsScreen
    },
    isRouteEnabled(isManageSitesRouteEnabled,
      {
        label: "Manage sites",
        href: namedRoutes.sites,
        component: SitesScreen,
        supportedSignalREventKeys: [EVENTS.sitelocations],
      })
  ].filter(Boolean);

  const PERMISSITON_STATE = {
    Permissions: {
      Manage_Sites: useUserPermission(USER_PERMISSIONS.Manage_Sites),
      Manage_Admins: useUserPermission(USER_PERMISSIONS.Manage_Admins),
      Manage_Users: useUserPermission(USER_PERMISSIONS.Manage_Users),
      View_Users: useUserPermission(USER_PERMISSIONS.View_Users),
      Manage_Zones: useUserPermission(USER_PERMISSIONS.Manage_Zones),
      View_Zones: useUserPermission(USER_PERMISSIONS.View_Zones),
      Delete_Users: useUserPermission(USER_PERMISSIONS.Delete_Users),
      Manage_Roles: useUserPermission(USER_PERMISSIONS.Manage_Roles),
      View_Roles: useUserPermission(USER_PERMISSIONS.View_Roles),
      View_Devices: useUserPermission(USER_PERMISSIONS.View_Devices),
      Manage_Devices: useUserPermission(USER_PERMISSIONS.Manage_Devices),
      Manage_Alerts: useUserPermission(USER_PERMISSIONS.Manage_Alerts),
      View_Alerts: useUserPermission(USER_PERMISSIONS.View_Alerts),
      Manage_Dashoboard_Messages: useUserPermission(USER_PERMISSIONS.Manage_Dashoboard_Messages),
      Manage_Device_Messages: useUserPermission(USER_PERMISSIONS.Manage_Device_Messages),
      Manage_Bulk_Messages: useUserPermission(USER_PERMISSIONS.Manage_Bulk_Messages),
      View_Dashboard_messages: useUserPermission(USER_PERMISSIONS.View_Dashboard_messages),
      View_Device_Messages: useUserPermission(USER_PERMISSIONS.View_Device_Messages),
      View_Bulk_Messages: useUserPermission(USER_PERMISSIONS.View_Bulk_Messages),
      Manage_User_Categories: useUserPermission(USER_PERMISSIONS.Manage_User_Categories),
      View_User_Categories: useUserPermission(USER_PERMISSIONS.View_User_Categories),
    },
  };
  const signalRConn = new SignalRConn(dispatch, RoutesJSON);
  useEffect(() => {
    if (activeAlerts.length && !PERMISSITON_STATE.Permissions.View_Alerts) {
      activeAlerts.forEach((alert) => {
        if (!alert.muted) {
          dispatch({
            type: GLOBAL_REDUCER_TYPES.SET_MUTED_ALERTS_IDS,
            payload: alert.id,
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAlerts]);

  return (
    <MuiThemeProvider theme={createdTheme}>
      <CssBaseline />
      <UserPermissionContext.Provider value={PERMISSITON_STATE}>
        <BrowserRouter>
          <MainScreen routesJSON={RoutesJSON} signalRConn={signalRConn}>
            <Suspense fallback={<div></div>}>
              <Switch>
                {RoutesJSON.map((route) => (
                  <PrivateRoute
                    exact
                    path={route.href}
                    component={route.component}
                    auth={auth}
                    key={route.label}
                  />
                ))}
                <PrivateRoute exact path={`${namedRoutes.sites}/:siteId`} component={SiteEditScreen} auth={auth} />
                <Route exact path={namedRoutes.privacy} component={PrivacyScreen}></Route>
                <Route exact path={`${namedRoutes.privacy}/:userId`} component={PrivacyConsentScreen} />
                <Route exact path={namedRoutes.root} component={LoginScreen} />
                <Route render={() => <Redirect to={namedRoutes.root} />} />
              </Switch>
            </Suspense>
          </MainScreen>
        </BrowserRouter>
      </UserPermissionContext.Provider>
    </MuiThemeProvider>
  );
};
