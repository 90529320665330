import { makeStyles } from "@material-ui/core";
import { FONTS } from "../../../../utils";

export const useSubSiteDialogStyles = makeStyles((theme) => ({
    hr: {
      border: "0",
      height: "1px",
      background: "rgba(0, 0, 0, 0.1)",
      margin: "30px 0px 30px 0px",
    },
    inputMargin: {
      marginTop: "30px",
    },
    actionDescription: {
      ...FONTS.ShellBold_12,
    },
    dialog: {
      position: "absolute",
      top: 60,
    },
  }));