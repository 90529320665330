import React, { useState, useEffect } from "react";
import { makeStyles, Dialog, DialogTitle, FormControl, Button, Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { DevicesSearch } from "../../devices-search";
import { DevicesReducerActions } from "../../../../../reducers";
import { useDialogStyles } from "../../../../../commons";
import { UsersSearch } from "../../users-search";

const useStyles = makeStyles(() => ({
  formControl: {
    width: "100%",
    marginBottom: "32px",
  },
  formControlActions: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
}));

const cssProperties = {
  variant: "filled",
  primaryColor: "primary",
};

export const AssignUnassignedDeviceDialog = ({ show, onCancel, onConfirm, siteID }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dialogStyles = useDialogStyles();
  const selectedDevice = useSelector((state) => state.devicesScreen.selected.device);
  const [deviceToAssign, setDeviceToAssign] = useState(null);
  const [userToAssign, setUserToAssign] = useState(null);

  const [assignBttnDisabled, setAssignBttnDisabled] = useState(true);

  function handleCancelBttnClick() {
    onCancel && onCancel();
  }

  function resetForm() {
    setDeviceToAssign(null);
    setUserToAssign(null);

    setAssignBttnDisabled(true);
  }

  function handleFormSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    dispatch(DevicesReducerActions.assignDevice(deviceToAssign, userToAssign));
    onConfirm && onConfirm(event);
  }

  useEffect(() => {
    resetForm();
  }, [show]);

  useEffect(() => {
    setDeviceToAssign(selectedDevice);
  }, [selectedDevice]);

  useEffect(() => {
    if (deviceToAssign && userToAssign) {
      setAssignBttnDisabled(false);
    } else {
      setAssignBttnDisabled(true);
    }
  }, [deviceToAssign, userToAssign]);

  if (!show) {
    return null;
  }

  return (
    <Dialog open={show} onClose={onCancel} PaperProps={{ className: dialogStyles.dialogPaper }}>
      <Box className={dialogStyles.dialogContentWrapper}>
        <DialogTitle
          className={dialogStyles.dialogTitle}
          disableTypography
          id="responsive-dialog-title"
          onClose={onCancel}
        >
          <span data-testid="dialog-title">Assign Device</span>
        </DialogTitle>
        <form className={classes.form} autoComplete="off" onSubmit={handleFormSubmit}>
          <FormControl variant={cssProperties.variant} className={classes.formControl}>
            <DevicesSearch
              value={selectedDevice}
              label="Device ID"
              onAutoCompleteChange={(event, newVal) => {
                event.stopPropagation();
                setDeviceToAssign(newVal);
              }}
              siteID={siteID}
              queryParams={{
                ct: 10,
                a: false,
              }}
            />
          </FormControl>

          <FormControl variant={cssProperties.variant} className={classes.formControl}>
            <UsersSearch
              label="Search for User"
              onAutoCompleteChange={(event, newVal) => {
                event.stopPropagation();
                setUserToAssign(newVal);
              }}
              siteID={siteID}
              queryParams={{
                ct: 10
              }}
            />
          </FormControl>

          <FormControl variant={cssProperties.variant} className={classes.formControlActions}>
            <Button
              color={cssProperties.primaryColor}
              variant="outlined"
              onClick={handleCancelBttnClick}
              className={dialogStyles.dialogActionBttn}
              data-testid="cancel-bttn"
            >
              Cancel
            </Button>

            <Button
              disabled={assignBttnDisabled}
              type="submit"
              variant="contained"
              color={cssProperties.primaryColor}
              style={{ marginLeft: "10px" }}
              className={dialogStyles.dialogActionBttn}
              data-testid="confirm-bttn"
            >
              Assign Device
            </Button>
          </FormControl>
        </form>
      </Box>
    </Dialog>
  );
};
