import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";

export const YellowCheckbox = withStyles({
  root: {
    "&$checked": {
      color: "#EFBF04",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export const DisabledYellowCheckbox = withStyles({
  root: {
    "&$checked": {
      color: "#EFBF04",
      opacity: 0.38,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
