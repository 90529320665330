import React from "react";
import { Chip, TextField } from "@material-ui/core";
import { useChipStyles } from "../useChipStyles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ICONS } from "../../../../../commons";

export const UserCategoryAutoselectWithChips = (props) => {
  const { userDevices, onChange, onChipDelete, options, value, showCount } = props;
  const chipClasses = useChipStyles();

  function handleChipDelete(data) {
    const arr = [...value];
    const i = arr.indexOf(data);
    arr.splice(i, 1);
    onChipDelete(arr);
  }

  function renderChips() {
    return value.map((selected) => {
      let label = "";
      if (showCount) {
        let count = 0;
        userDevices.forEach((device) => {
          const { currentAssignedUser } = device;
          if (currentAssignedUser?.userCategory?.name === selected.name) {
            count += 1;
          }
        });
        label = `${selected.name} (${count})`;
      } else {
        label = selected.name;
      }
      return (
        <Chip
          key={selected.id}
          className={chipClasses.chip}
          label={label}
          onDelete={() => {
            handleChipDelete(selected);
          }}
          onClick={() => {
            handleChipDelete(selected);
          }}
        />
      );
    });
  }

  return (
    <div>
      <Autocomplete
        multiple
        filterSelectedOptions
        id="zone-categories-autocomplete"
        value={value}
        options={options}
        getOptionLabel={(option) => option.name}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label={"User Category"}
            margin="dense"
            variant="filled"
            onChange={() => {}}
          />
        )}
        renderTags={() => null}
        popupIcon={<ICONS.AddCircle />}
      />
      <div className={chipClasses.chipsWrapper}>{renderChips()}</div>
    </div>
  );
};
