import React from "react";

export const USER_PERMISSIONS = {
  Manage_Sites: "sitewise.Permissions.Manage.Sites",
  Manage_Admins: "sitewise.Permissions.Manage.Admins",
  Manage_Users: "sitewise.Permissions.Manage.Users",
  View_Users: "sitewise.Permissions.View.Users",
  Manage_Zones: "sitewise.Permissions.Manage.Zones",
  View_Zones: "sitewise.Permissions.View.Zones",
  Delete_Users: "sitewise.Permissions.Delete.Users",
  Manage_Roles: "sitewise.Permissions.Manage.Roles",
  View_Roles: "sitewise.Permissions.View.Roles",
  View_Devices: "sitewise.Permissions.View.Devices",
  Manage_Devices: "sitewise.Permissions.Manage.Devices",
  Manage_Alerts: "sitewise.Permissions.Manage.Alerts",
  View_Alerts: "sitewise.Permissions.View.Alerts",
  Manage_Dashoboard_Messages: "sitewise.Permissions.Manage.DashboardMessages",
  Manage_Device_Messages: "sitewise.Permissions.Manage.DeviceMessages",
  Manage_Bulk_Messages: "sitewise.Permissions.Manage.BulkMessages",
  View_Dashboard_messages: "sitewise.Permissions.View.DashboardMessages",
  View_Device_Messages: "sitewise.Permissions.View.DeviceMessages",
  View_Bulk_Messages: "sitewise.Permissions.View.BulkMessages",
  Manage_User_Categories: "sitewise.Permissions.Manage.UserCategories",
  View_User_Categories: "sitewise.Permissions.View.UserCategories"
};

export const useUserPermission = (permission) => {
  let enabled = false;
  const sitewiseUser = localStorage.getItem("sitewise_user");
  const sitewiseUserObj = JSON.parse(sitewiseUser);

  if (
    sitewiseUserObj &&
    sitewiseUserObj.Permissions &&
    sitewiseUserObj.Permissions.includes(permission)
  ) {
    enabled = true;
  }

  return enabled;
};

export const UserPermissionContext = React.createContext({});
