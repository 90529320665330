import React, { useState } from "react";
import {
  makeStyles,
  Dialog,
  DialogTitle,
  FormControl,
  TextField,
  InputAdornment,
  FormHelperText,
  Button,
  Box,
} from "@material-ui/core";
import { FONTS } from "../../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { GlobalReducerActions, UsersReducerActions } from "../../../../reducers";
import { ICONS, useDialogStyles } from "../../../../commons";
import { Link } from "react-router-dom";
import { SiteLocationsAPI } from "../../../../api";

const useStyles = makeStyles(() => ({
  dialogTitle: {
    textAlign: "center",
    paddingTop: "45px",
    paddingBottom: "81px",
    ...FONTS.H3_DB_28,
  },
  formControl: {
    width: "100%",
    marginTop: "24px",
  },
}));

export const BatchUploadDialog = ({ show, onCancel, onConfirm }) => {
  const dialogStyles = useDialogStyles();
  const classes = useStyles();
  const [uploadFile, setUploadFile] = useState(null);
  const siteID = useSelector((state) => state.globalState.siteID);

  const dispatch = useDispatch();

  function saveStreamCSV(filename, text) {
    let blobObject = null;
    if (window.navigator.msSaveBlob) {
      // IE 10 and later, and Edge.
      blobObject = new Blob([text], { type: "text/csv" });
      window.navigator.msSaveBlob(blobObject, filename);
    } else {
      // Everthing else (except old IE).
      // Create a dummy anchor (with a download attribute) to click.
      var anchor = document.createElement("a");
      anchor.download = filename;
      if (window.URL.createObjectURL) {
        // Everything else new.
        blobObject = new Blob([text], { type: "text/csv" });
        anchor.href = window.URL.createObjectURL(blobObject);
      } else {
        // Load up the data into the URI for "download."
        anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(text);
      }
      // Now, click it.
      if (document.createEvent) {
        var event = document.createEvent("MouseEvents");
        event.initEvent("click", true, true);
        anchor.dispatchEvent(event);
      } else {
        anchor.click();
      }
    }
  }

  function handleCancelBttnClick() {
    setUploadFile(null);
    onCancel && onCancel();
  }

  function handleFormSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    const formData = new FormData();
    formData.append("csv", uploadFile);

    dispatch(UsersReducerActions.uploadUsersCsvfile(formData)).then(() => {
      setUploadFile(null);
    });

    onConfirm && onConfirm(event);
  }

  function onFileInputChange(event) {
    setUploadFile(event.target.files[0]);
  }

  if (!show) {
    return null;
  }

  return (
    <Dialog open={show} onClose={onCancel} PaperProps={{ className: dialogStyles.dialogPaper }}>
      <Box className={dialogStyles.dialogContentWrapper}>
        <DialogTitle
          className={dialogStyles.dialogTitle}
          disableTypography
          id="responsive-dialog-title"
          onClose={onCancel}
        >
          <span>Upload CSV to add multiple users</span>
        </DialogTitle>
        <form autoComplete="off" onSubmit={handleFormSubmit}>
          <FormControl required variant="filled" className={classes.formControl}>
            <label htmlFor="upload-photo">
              <TextField
                className={classes.formControl}
                required
                value={uploadFile && uploadFile.name}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <ICONS.Publish />
                    </InputAdornment>
                  ),
                }}
              />

              <input
                style={{ display: "none" }}
                id="upload-photo"
                name="upload-photo"
                type="file"
                onChange={onFileInputChange}
                accept=".csv"
              />
            </label>
            <FormHelperText id="my-helper-text">Maximum file size: 2MB</FormHelperText>
          </FormControl>

          <FormControl
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "32px",
              display: "flex",
              alignItems: "baseline",
            }}
          >
            <Link
              style={{ color: "white", textDecoration: "none" }}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                dispatch(GlobalReducerActions.showPageLoader());
                SiteLocationsAPI.getBulkUploadUserTemplate(siteID).then((res) => {
                  saveStreamCSV("bulk_upload_template.csv", res);
                  dispatch(GlobalReducerActions.hidePageLoader());
                });
              }}
            >
              Download Template CSV
            </Link>

            <div>
              <Button onClick={handleCancelBttnClick} variant="outlined" color="primary">
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ marginLeft: "10px" }}
              >
                Create
              </Button>
            </div>
          </FormControl>
        </form>
      </Box>
    </Dialog>
  );
};
