import React from "react";
import { Button, Tooltip } from "@material-ui/core";
import { ICONS } from "../../../../commons";

export const pdfConsentContent = ({
  privacyPdfUrl,
  enabledPrivacySettings,
  classes,
  handlePrivacyDialog,
  setPrivacy,
  privacy,
  manageSites
}) => {
  return !privacyPdfUrl ? (
    <Button
      size="medium"
      variant="outlined"
      disabled={!enabledPrivacySettings}
      className={classes.uploadButton}
      color="primary"
      onClick={() => handlePrivacyDialog(true)}
    >
      Upload Consent Form PDF
    </Button>
  ) : (
    <div className={classes.pdfContainer}>
      <span className={classes.pdfText}>Consent Form</span>
      {manageSites ? (
      <span className={classes.iconContainer}>
        <Tooltip title="Upload File">
          <ICONS.ArrowUpwardIcon
            onClick={() => handlePrivacyDialog(true)}
            className={classes.icons}
          />
        </Tooltip>
        <Tooltip title="Remove File">
          <ICONS.Delete
            onClick={() =>
              setPrivacy({ ...privacy, consent: null, privacyPdfFile: null, privacyPdfUrl: "" })
            }
            className={classes.icons}
          />
        </Tooltip>
      </span>) :<span className={classes.iconContainer}></span> }
    </div>
  );
};
