import React from "react";
import { makeStyles, IconButton, Tooltip } from "@material-ui/core";
import { ICONS } from "../icon-manager";

const useStyles = makeStyles((theme) => ({
  plotPlanWrapper: {
    position: "absolute",
    bottom: "124px",
    left: "15px",
    background: theme.palette.common.black,
    display: "flex",
  },
}));

export const HomeMapWidget = (props) => {
  const { homeLocation, onLocateSiteCb } = props;
  const classes = useStyles();

  return (
    <div className={classes.plotPlanWrapper}>
      <Tooltip title={"Go to Home"}>
        <IconButton
          aria-label="Home"
          style={{
            padding: "5px 4px",
          }}
          color="contained"
          onClick={() => {
            onLocateSiteCb(homeLocation)
          }}
        >
          <ICONS.HomeIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};
