import React, { useState, useContext } from "react";
import { DrawerNavBar } from "./drawer-nav-bar";
import { TopNavBar } from "./top-nav-bar";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ZONE_REDUCER_TYPES } from "../../reducers";
import { ScopeContext } from "../../commons";

export const Navbar = (props) => {
  const { routesJSON } = props;
  const history = useHistory();
  const location = useLocation();
  // Drawer Menu
  const [showDrawerMenu, setShowDrawerMenu] = useState(false);

  const activeAlerts = useSelector((state) => state.globalState.alerts.activeAlerts);
  const siteID = useSelector((state) => state.globalState.siteID);
  const editing = useSelector((state) => state.zonesScreen.editingForm);
  const editingShape = useSelector((state) => state.zonesScreen.mapEditShapeInProgress);
  const dispatch = useDispatch();
  const {namedRoutes} = useContext(ScopeContext);


  const menuItemClick = (link) => (event) => {
    if (editing || editingShape) {
      dispatch({ type: ZONE_REDUCER_TYPES.SET_EDIT_ZONE_DIALOG_DISPLAY, payload: true });
    } else {
      history.push(link);
    }
  };
  if (location.pathname === namedRoutes.root || location.pathname.includes(namedRoutes.privacy)) {
    return null;
  }

  return (
    <>
      <TopNavBar
        siteID={siteID}
        MainMenuListJSON={routesJSON}
        setShowDrawerMenu={setShowDrawerMenu}
        menuItemClick={menuItemClick}
        activeAlertsCount={activeAlerts.length}
      />
      <DrawerNavBar
        MainMenuListJSON={routesJSON}
        showDrawerMenu={showDrawerMenu}
        setShowDrawerMenu={setShowDrawerMenu}
        menuItemClick={menuItemClick}
        activeAlertsCount={activeAlerts.length}
      />
    </>
  );
};
