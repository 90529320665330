import React from "react";
import {
  FormControl,
  TextField,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
} from "@material-ui/core";
import { TabPanel } from "../../../../commons/tabs";

export const FirstTabPanel = (props) => {
  const {
    tabValue,
    handleNextSubmit,
    classes,
    isContractor,
    handleRadioGroupChange,
    company,
    setCompany,
    contractorOrEmployeeId,
    setContractorOrEmployeeId,
    setName,
    name,
    email,
    setEmail,
    setErrorText,
    errorText,
    handleCancelBttnClick,
    site,
    setRadioChannel,
    setDepartment,
    setLocations,
    locations,
    department,
    radioChannel,
  } = props;
  return (
    <TabPanel value={tabValue} index={0}>
      <form autoComplete="off" onSubmit={handleNextSubmit}>
        <FormControl component="fieldset" className={classes.formControl}>
          <RadioGroup
            value={isContractor}
            onChange={handleRadioGroupChange}
            style={{ display: "flex", flexDirection: "row" }}
          >
            <FormControlLabel value={"false"} control={<Radio />} label="Shell Employee" />
            <FormControlLabel value={"true"} control={<Radio />} label="Contractor" />
          </RadioGroup>
        </FormControl>

        <FormControl required variant="filled" className={classes.formControl}>
          <TextField
            required
            id="filled-basic"
            label="Name"
            variant="filled"
            fullWidth={true}
            value={name}
            onChange={(event) => setName(event.target.value)}
            inputProps={{
              maxLength: 30,
            }}
          />
          <FormHelperText>{`${name.length}/30`}</FormHelperText>
        </FormControl>

        <FormControl required variant="filled" className={classes.formControl}>
          <TextField
            required
            type="email"
            id="filled-basic"
            label="Email"
            variant="filled"
            fullWidth={true}
            value={email}
            onChange={(event) => {
              if (errorText) {
                setErrorText("");
              }
              setEmail(event.target.value);
            }}
            helperText={errorText}
            error={errorText}
          />

          {isContractor === "false" ? null : (
            <>
              {site?.userAttributes?.company && (
                <FormControl required variant="filled" className={classes.formControl}>
                  <TextField
                    required
                    id="filled-basic"
                    label="Contracting Company Name"
                    variant="filled"
                    fullWidth={true}
                    value={company}
                    onChange={(event) => setCompany(event.target.value)}
                  />
                </FormControl>
              )}
            </>
          )}

          {site?.userAttributes?.contractorOrEmployeeId && (
            <FormControl required variant="filled" className={classes.formControl}>
              <TextField
                id="filled-basic"
                label="Pass # or Contractor ID #"
                variant="filled"
                fullWidth={true}
                value={contractorOrEmployeeId}
                onChange={(event) => setContractorOrEmployeeId(event.target.value)}
              />
            </FormControl>
          )}

          {site?.userAttributes?.department && (
            <FormControl required variant="filled" className={classes.formControl}>
              <TextField
                id="filled-basic"
                label="Department"
                variant="filled"
                fullWidth={true}
                value={department}
                onChange={(event) => setDepartment(event.target.value)}
              />
            </FormControl>
          )}

          {site?.userAttributes?.locations && (
            <FormControl required variant="filled" className={classes.formControl}>
              <TextField
                id="filled-basic"
                label="Locations"
                variant="filled"
                fullWidth={true}
                value={locations}
                onChange={(event) => setLocations(event.target.value)}
              />
            </FormControl>
          )}

          {site?.userAttributes?.radioChannel && (
            <FormControl required variant="filled" className={classes.formControl}>
              <TextField
                id="filled-basic"
                label="Radio Channel"
                variant="filled"
                fullWidth={true}
                value={radioChannel}
                onChange={(event) => setRadioChannel(event.target.value)}
              />
            </FormControl>
          )}

          <FormControl variant="filled" className={classes.formControlActions}>
            <Button color="primary" variant="outlined" onClick={handleCancelBttnClick}>
              Cancel
            </Button>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginLeft: "10px" }}
            >
              Next
            </Button>
          </FormControl>
        </FormControl>
      </form>
    </TabPanel>
  );
};
