import { GlobalReducerActions, GLOBAL_REDUCER_TYPES } from "./global-reducer";
import { SiteLocationsAPI, AlertsAPI, DevicesAPI } from "../api";

function filterZones(data, zonesViewPageFilter) {
  const activeZones = data.filter((zone) => zone.isActive);

  let zonesToRender = [];

  if (zonesViewPageFilter === "active") {
    zonesToRender = activeZones;
  }

  return {
    allZones: [...data],
    activeZones,
    zonesToRender: zonesToRender,
  };
}

export const ALERTS_REDUCER_TYPES = {
  // selected
  SET_SELECTED_CURRENT_ALERT: "SET_SELECTED_CURRENT_ALERT",
  SET_SELECTED_ALERT_LOG: "SET_SELECTED_ALERT_LOG",
  // ZONES
  SET_ZONES: "SET_ZONES",
  SET_USER_DEVICES: "SET_USER_DEVICES",
  // dialogs
  SET_CLEAR_ALERT_DIALOG_DISPLAY: "SET_CLEAR_ALERT_DIALOG_DISPLAY",
  SET_ALERT_NOTE_DIALOG_DISPLAY: "SET_ALERT_NOTE_DIALOG_DISPLAY",
  // Alerts
  SET_CLEARED_ALERTS: "SET_CLEARED_ALERTS",
  // RESET
  RESET_ALERTS_SCREEN_STATE: "RESET_ALERTS_SCREEN_STATE",
  SET_ALERTS_PAGE_MAP_VIEW: "SET_ALERTS_PAGE_MAP_VIEW",
};

export const ALERTS_INITIAL_STATE = {
  zones: [],
  mapView: "LARGE_MAP",
  allZonesData: {
    allZones: [],
    activeZones: [],
  },
  userDevices: [],
  clearedAlerts: [],
  dialogs: {
    showClearAlertDialog: false,
    showAlertNoteDialog: false,
  },
  selected: {
    alertLog: null,
    currentAlert: null,
  },
};

export const alertsReducer = (_state, action) => {
  const state = _state || ALERTS_INITIAL_STATE;
  const { type, payload } = action;
  switch (type) {
    //Selected
    case ALERTS_REDUCER_TYPES.SET_SELECTED_CURRENT_ALERT:
      return {
        ...state,
        selected: {
          ...state.selected,
          currentAlert: payload,
        },
      };

    case ALERTS_REDUCER_TYPES.SET_SELECTED_ALERT_LOG:
      return {
        ...state,
        selected: {
          ...state.selected,
          alertLog: payload,
        },
      };

    // Alerts

    case ALERTS_REDUCER_TYPES.SET_CLEARED_ALERTS:
      return {
        ...state,
        clearedAlerts: payload,
      };

    //ZONES
    case ALERTS_REDUCER_TYPES.SET_ZONES:
      const { allZones, activeZones, zonesToRender } = filterZones(payload, "active");

      return {
        ...state,
        allZonesData: {
          allZones: allZones,
          activeZones: activeZones,
        },
        zones: zonesToRender,
      };

    //Devices
    case ALERTS_REDUCER_TYPES.SET_USER_DEVICES:
      return {
        ...state,
        userDevices: action.payload,
      };

    // Dialogs
    case ALERTS_REDUCER_TYPES.SET_CLEAR_ALERT_DIALOG_DISPLAY:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          showClearAlertDialog: payload,
        },
      };

    case ALERTS_REDUCER_TYPES.SET_ALERT_NOTE_DIALOG_DISPLAY:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          showSendMessageDialog: false,
          showAlertNoteDialog: payload,
        },
      };

    case ALERTS_REDUCER_TYPES.SET_ALERTS_PAGE_MAP_VIEW:
      return {
        ...state,
        mapView: payload,
      };

    case ALERTS_REDUCER_TYPES.RESET_ALERTS_SCREEN_STATE:
      return ALERTS_INITIAL_STATE;
    default:
      return state;
  }
};

// ACTIONS //
const getUserDevices = (queryString, showPageLoader = true) => {
  return async (dispatch, getState) => {
    try {
      showPageLoader && dispatch(GlobalReducerActions.showPageLoader());
      const siteID = getState().globalState.siteID;
      const response = await DevicesAPI.getSitelocationDevicesByQueryString(siteID, queryString);
      dispatch({ type: ALERTS_REDUCER_TYPES.SET_USER_DEVICES, payload: response });
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const getAllZones = (showPageLoader = true) => {
  return async (dispatch, getState) => {
    try {
      showPageLoader && dispatch(GlobalReducerActions.showPageLoader());
      const siteID = getState().globalState.siteID;
      const response = await SiteLocationsAPI.getAllZonesBySitelocation(siteID);
      dispatch({ type: ALERTS_REDUCER_TYPES.SET_ZONES, payload: response });
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const updateAlert = (alertData) => {
  return async (dispatch) => {
    try {
      dispatch(GlobalReducerActions.showPageLoader());
      await AlertsAPI.updateAlertById(alertData);
      await Promise.all([
        dispatch(GlobalReducerActions.getAllAlerts(false)),
        dispatch(AlertsReducerActions.getAllClearedAlerts(false)),
      ]);
      dispatch({ type: ALERTS_REDUCER_TYPES.SET_ALERT_NOTE_DIALOG_DISPLAY, payload: false });
      if (alertData.isCleared) {
        dispatch({
          type: GLOBAL_REDUCER_TYPES.SET_MUTED_ALERTS_IDS,
          payload: alertData.id,
        });
      }
      dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const plotClear = (zoneId) => {
  return async (dispatch, getState) => {
    try {
      dispatch(GlobalReducerActions.showPageLoader());
      const siteId = getState().globalState.siteID;
      await AlertsAPI.plotClear(zoneId, siteId);
      dispatch(GlobalReducerActions.getAllAlerts());
      dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const getAllClearedAlerts = (showPageLoader = true) => {
  return async (dispatch, getState) => {
    try {
      showPageLoader && dispatch(GlobalReducerActions.showPageLoader());
      const siteId = getState().globalState.siteID;
      const response = await AlertsAPI.getAllClearedAlertsBySiteLocation(siteId);
      dispatch({ type: ALERTS_REDUCER_TYPES.SET_CLEARED_ALERTS, payload: response });
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const init = (siteID) => {
  return async (dispatch) => {
    try {
      dispatch(GlobalReducerActions.showPageLoader());
      await dispatch(GlobalReducerActions.getSiteById(siteID, false));

      const params = new URLSearchParams({
        o: true,
        a: true,
      });
      const queryString = params.toString();

      dispatch(AlertsReducerActions.getAllZones(false));
      dispatch(AlertsReducerActions.getAllClearedAlerts(false));
      dispatch(GlobalReducerActions.getAllAlerts(false));
      dispatch(AlertsReducerActions.getUserDevices(queryString, false));
      dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

export const AlertsReducerActions = {
  updateAlert,
  getAllZones,
  getUserDevices,
  plotClear,
  getAllClearedAlerts,
  init,
};
