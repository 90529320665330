import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Table,
  TableBody,
  TableFooter,
  TableContainer,
  TablePagination,
  Button,
  Paper,
} from "@material-ui/core";
import { Row } from "./row";
import {
  useCustomTableHook,
  useCustomTableStyles,
  EnhancedTableHead,
  UserPermissionContext,
  ICONS,
  useDebounce,
} from "../../../../commons";
import { parseUtcToLocal } from "../../../../utils";
import { USERS_REDUCER_TYPES } from "../../../../reducers";

const headCells = [
  {
    id: "role",
    sortBy: "role",
    label: "Role Name",
    width: "15%",
    content: (row) => row.role,
  },
  {
    id: "activeUsersCount",
    sortBy: "activeUsersCount",
    label: "Users assigned to active Device",
    width: "15%",
    content: (row) => row.activeUsersCount,
  },
  {
    id: "deviceUsersCount",
    sortBy: "deviceUsersCount",
    label: "Users assigned to Device",
    width: "15%",
    content: (row) => row.deviceUsersCount,
  },
  {
    id: "totalUsersCount",
    sortBy: "totalUsersCount",
    label: "Total Users",
    width: "15%",
    content: (row) => row.totalUsersCount,
  },
  {
    id: "createdTimestamp",
    sortBy: "createdTimestamp",
    label: "Role Created On",
    width: "15%",
    content: (row) => parseUtcToLocal(row.createdTimestamp),
  },
];

export const RolesTable = (props) => {
  const tableClasses = useCustomTableStyles();
  const dispatch = useDispatch();
  const selectedRole = useSelector((state) => state.usersScreen.selected.role);
  const [tableSearchStr, setTableSearchStr] = useState("");

  // Permissions
  const { Permissions } = useContext(UserPermissionContext);

  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    setPage,
    getComparator,
    stableSort,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useCustomTableHook({ defaultSortBy: "role", rowsPerPage: 10 });

  const { data } = props;
  const [tableData, setTableData] = useState([]);

  const checkIfRowSelected = (id) => selectedRole && selectedRole.id === id;

  const tableSearchDebounced = useDebounce((_nextVal) => {
    setPage(0);
    setTableSearchStr(_nextVal);
  }, 300);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  useEffect(() => {
    let arr = [];

    if (tableSearchStr) {
      arr = data.filter(
        (tData) => tData.role && tData.role.toLowerCase().includes(tableSearchStr.toLowerCase())
      );
    } else {
      arr = data;
    }

    setTableData(arr);
  }, [tableSearchStr, setTableData, data]);

  return (
    <Paper>
      <TableContainer className={tableClasses.tableContainer}>
        <Table
          className={tableClasses.table}
          aria-labelledby="tableTitle"
          aria-label="roles table"
          stickyHeader
          data-testid="roles-table"
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={headCells}
            toShow={true}
            tableWithRowActions={true}
            onChangeSearch={(event) => {
              tableSearchDebounced(event.target.value);
            }}
            searchLabel={"Role Name"}
            searchTestId="search-role-name"
          />
          <TableBody data-testid="roles-table-body">
            {stableSort(tableData, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((rowData) => {
                const isItemSelected = checkIfRowSelected(rowData.id);
                return (
                  <Row
                    key={rowData.id}
                    rowData={rowData}
                    headCells={headCells}
                    isItemSelected={isItemSelected}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TableFooter className={tableClasses.tabelFooterRoot} component="div" data-testid="footer">
        {Permissions.Manage_Roles ? (
          <Button
            startIcon={<ICONS.Add />}
            color="primary"
            onClick={() =>
              dispatch({ type: USERS_REDUCER_TYPES.SET_NEW_ROLE_DIALOG_DISPLAY, payload: true })
            }
            data-testid="footer-action-bttn"
            id="footer-action-bttn"
          >
            Add new role
          </Button>
        ) : null}

        <TablePagination
          classes={{
            root: tableClasses.tablePaginationRoot,
          }}
          component="div"
          rowsPerPageOptions={[]}
          labelRowsPerPage=""
          count={tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableFooter>
    </Paper>
  );
};
