import React, { useState, useEffect, useContext } from "react";
import { makeStyles, Dialog, DialogTitle, FormControl, Button, Box } from "@material-ui/core";
import { useDialogStyles, UserPermissionContext } from "../../../../commons";
import { Grid } from "@material-ui/core";

import { DeviceHistoryReportsScreen } from './device-history-report-screen';
const useStyles = makeStyles((theme) => ({
    formControl: {
        width: "100%",
        marginBottom: "32px",
    },
    formControlActions: {
        flexDirection: "row",
        justifyContent: "flex-end",
    },
    form: {
        display: "flex",
        flexDirection: "column",
    },
    assignWarning: {
        color: theme.palette.warning.main,
    },
    dialogPaper: {
        width: "100%",
        maxWidth: "100% !important",
        backgroundColor: theme.palette.base.elevation_1,
    },
    dialogContentWrapper: {
        padding: "20px",
    },
    dialogTitle: {
        paddingTop: "0px",
        paddingLeft: "0px",
        paddingRight: "0px",
    },
}));
export const ViewZoneHistoryDialog = ({ show, deviceDetails, triggerClosePopup }) => {
    const classes = useStyles();
    return (
        <Dialog
            open={show}
            //onClose={onCancel}
            PaperProps={{ className: classes.dialogPaper }}
            data-testid="assign-new-device-dialog"
        >
            <Box className={classes.dialogContentWrapper}>
                <DialogTitle
                    className={classes.dialogTitle}
                    disableTypography
                    id="responsive-dialog-title"
                >
                    <Grid container spacing={5}>
                        <Grid item xs={6}>
                            <span>View History Device</span>
                        </Grid>
                        <Grid item xs={6} style={{textAlign:"right"}}>
                            <a onClick={() => triggerClosePopup()}>X</a>
                        </Grid>
                    </Grid>
                </DialogTitle>
            </Box>
            <FormControl>
                <DeviceHistoryReportsScreen deviceDetails={deviceDetails}></DeviceHistoryReportsScreen>
            </FormControl>
        </Dialog>);

};