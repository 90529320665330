import { useCallback, useRef, useEffect } from "react";
import _debounce from "lodash.debounce";

export const useDebounce = (callback, delay = 300) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedCallback = useCallback(callback, []);
  const debouncedFn = useRef(_debounce(memoizedCallback, delay));

  useEffect(() => {
    debouncedFn.current = _debounce(memoizedCallback, delay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoizedCallback, debouncedFn, delay]);

  return debouncedFn.current;
};
