import React from "react";
import { TableCell, TableRow, Box } from "@material-ui/core";
import { ColorPallete } from "../../../../../commons/color-pallete";

export const Row = (props) => {
  const { rowData, headCells, isItemSelected, onTableRowSelectedCb } = props;

  const handleTableRowClicked = (event, zoneData) => {
    event.stopPropagation();
    onTableRowSelectedCb && onTableRowSelectedCb(zoneData);
  };

  return headCells.map((cell, index) => {
    if (cell.isVisibleInRow) {
      return (
        <TableRow
          hover
          onClick={(event) => handleTableRowClicked(event, rowData)}
          role="checkbox"
          tabIndex={-1}
          key={rowData.name}
          selected={isItemSelected}
        >
          {index === 0 ? (
            <TableCell align="left">
              <Box display="flex" alignItems="center">
                <Box pl={1.5} pr={3}>
                  <ColorPallete colour={rowData.displayColour} data={rowData} />
                </Box>
                <span>{cell.content(rowData)}</span>
              </Box>
            </TableCell>
          ) : (
            <TableCell key={`${cell.id}-${index}`}>
              <span>{cell.content(rowData)}</span>
            </TableCell>
          )}
        </TableRow>
      );
    } else {
      return null;
    }
  });
};
