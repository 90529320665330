import React from "react";
import { makeStyles } from "@material-ui/core";
import { getIconByKey } from "../icon-manager";

const useStyles = makeStyles(() => ({
  colorPallete: {
    width: "24px",
    height: "24px",
  },
}));

export const CategoryPallete = (props) => {
  const classes = useStyles();
  const { data } = props;
  const { colour, icon, isAdHoc } = data;
  const styleObj = {};
  let ChipIcon;

  function getColorValue(colour) {
    try {
      let colorVal = "";
      if (colour && Array.isArray(colour)) {
        colorVal = `rgba(${colour[0]},${colour[1]},${colour[2]})`;
      } else if (typeof colour === "object") {
        colorVal = `rgba(${colour["red"]},${colour["green"]},${colour["blue"]})`;
      } else if (typeof color === "string") {
        colorVal = colour;
      }

      return colorVal;
    } catch (error) {
      return error;
    }
  }

  if (isAdHoc) {
    styleObj.border = `2px solid #F7F7F7`;
  } else {
    styleObj.backgroundColor = "#F7F7F7";
  }

  if (colour) {
    if (isAdHoc) {
      styleObj.border = `2px solid ${getColorValue(colour)}`;
    } else {
      styleObj.backgroundColor = getColorValue(colour);
    }
  }

  if (icon) {
    ChipIcon = getIconByKey(icon);
  }

  return (
    <div
      className={`${classes.colorPallete}`}
      style={{
        ...styleObj,
      }}
    >
      {ChipIcon && <ChipIcon style={{ color: "#373746", padding: "2px" }} />}
    </div>
  );
};
