import React, { useState, useContext } from "react";
import { TableCell, TableRow, Button, Box, TextField } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { USERS_REDUCER_TYPES, UsersReducerActions } from "../../../../reducers";
import {
  RowActionBttn,
  UserPermissionContext,
  ICONS,
  useCustomTableStyles,
} from "../../../../commons";
import { parseUtcToLocal } from "../../../../utils";

export const Row = (props) => {
  const tableStyles = useCustomTableStyles();
  const { rowData, headCells } = props;

  const { Permissions } = useContext(UserPermissionContext);

  const [currentRole, setcurrentRole] = useState({
    role: rowData.role,
    id: rowData.id,
  });

  const [isEditRowActive, setisEditRowActive] = useState(false);
  const [saveBttnDisabled, setSaveBttnDisabled] = useState(true);

  const dispatch = useDispatch();

  const handleTableRowClicked = (event) => {
    event.stopPropagation();
  };

  function resetRowEditData() {
    setcurrentRole({
      role: rowData.role,
      id: rowData.id,
      createdTimestamp: rowData.createdTimestamp,
    });
  }

  const editRowContent = (isEditRowActive) => {
    return isEditRowActive ? (
      <>
        <Button
          className={tableStyles.rowActionBttn}
          disabled={saveBttnDisabled}
          variant="contained"
          color="primary"
          data-id={rowData.id}
          size="small"
          title="Save"
          onClick={(event) => {
            event.stopPropagation();
            setisEditRowActive(false);
            dispatch(UsersReducerActions.updateRole(currentRole));
          }}
        >
          SAVE
        </Button>

        <RowActionBttn
          title={"Cancel"}
          onClick={(event) => {
            event.stopPropagation();
            setisEditRowActive(false);
            resetRowEditData();
          }}
          Icon={ICONS.Cancel}
        />
      </>
    ) : (
      <>
        <RowActionBttn
          title={"Edit"}
          color="primary"
          onClick={(event) => {
            event.stopPropagation();
            setisEditRowActive(true);
            dispatch({ type: USERS_REDUCER_TYPES.SET_SELECTED_ROLE, payload: rowData });
          }}
          Icon={ICONS.Edit}
        />

        <RowActionBttn
          title={"Remove"}
          color="primary"
          onClick={(event) => {
            event.stopPropagation();
            dispatch({
              type: USERS_REDUCER_TYPES.SET_SELECTED_ROLE,
              payload: rowData,
            });
            dispatch({
              type: USERS_REDUCER_TYPES.SET_REMOVE_ROLE_DIALOG_DISPLAY,
              payload: true,
            });
          }}
          Icon={ICONS.Delete}
        />
      </>
    );
  };
  return (
    <TableRow hover onClick={(event) => handleTableRowClicked(event)} key={rowData.id}>
      {isEditRowActive ? (
        <>
          <TableCell className={tableStyles.cell} key={`${rowData.id}-name`}>
            <TextField
              label="Role Name"
              variant="filled"
              value={currentRole.role}
              onChange={(event) => {
                setcurrentRole({ ...currentRole, role: event.target.value });
                setSaveBttnDisabled(false);
              }}
              inputProps={{
                "data-testid": "role-input",
              }}
            />
          </TableCell>
          <TableCell className={tableStyles.cell} key={`${rowData.id}-count`}>
            {rowData.activeUsersCount}
          </TableCell>
          <TableCell className={tableStyles.cell} key={`${rowData.id}-devicecount`}>
            {rowData.deviceUsersCount}
          </TableCell>
          <TableCell className={tableStyles.cell} key={`${rowData.id}-useercount`}>
            {rowData.totalUsersCount}
          </TableCell>
          <TableCell className={tableStyles.cell} key={`${rowData.id}-time`}>
            {parseUtcToLocal(rowData.createdTimestamp)}
          </TableCell>
        </>
      ) : (
        headCells.map((cell, index) => {
          return (
            <TableCell key={`${cell.id}-${index}`} className={tableStyles.cell}>
              <span>{cell.content(rowData)}</span>
            </TableCell>
          );
        })
      )}

      <TableCell
        className={tableStyles.cell}
        key={`${rowData.id}-actions`}
        data-testid="actions-cell"
      >
        {Permissions.Manage_Roles ? (
          <Box className={tableStyles.rowActionBttnsWrapper}>{editRowContent(isEditRowActive)}</Box>
        ) : null}
      </TableCell>
    </TableRow>
  );
};
