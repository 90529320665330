import React from "react";
import { Tooltip, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  colorPallete: {
    fontSize: "26px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

function getColorValue(colour) {
  let colorVal = "";
  if (colour && Array.isArray(colour)) {
    colorVal = `rgba(${colour[0]},${colour[1]},${colour[2]})`;
  } else if (colour && typeof colour === "object") {
    colorVal = `rgba(${colour["red"]},${colour["green"]},${colour["blue"]})`;
  } else if (typeof colour === "string") {
    colorVal = colour;
  }

  return colorVal;
}

export const ColorPallete = (props) => {
  const classes = useStyles();
  const { data, title, colour } = props;
  const styleObj = {
    color: null,
  };

  styleObj.color = getColorValue(colour);

  let FaIcon = "";

  if (data && data.alerts && data.alerts.length) {
    FaIcon = (
      <i
        data-testid={`alert-zone-${data.id}`}
        className="fa fa-exclamation-circle"
        aria-hidden="true"
        style={{ color: "#DB4052" }}
      ></i>
    );
  } else if (data && data.isTemporary) {
    FaIcon = (
      <i
        data-testid={`temp-zone-${data.id}`}
        className="fa fa-circle-thin"
        aria-hidden="true"
        style={{
          ...styleObj,
        }}
      ></i>
    );
  } else {
    FaIcon = (
      <i
        data-testid={`zone-colour-pallete`}
        className="fa fa-circle"
        aria-hidden="true"
        style={{
          ...styleObj,
        }}
      ></i>
    );
  }

  if (title) {
    return (
      <Tooltip title={title ? title : ""}>
        <div className={classes.colorPallete}>{FaIcon}</div>
      </Tooltip>
    );
  } else {
    return <div className={classes.colorPallete}>{FaIcon}</div>;
  }
};

ColorPallete.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object,
  colour: PropTypes.any,
};
