import React, { useContext, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import {
  DocumentTitle,
  BackBttn,
  useCommonStyles,
  RemoveDialog,
  ScopeContext,
} from "../../../../commons";
import { SiteConfigForms, EditSiteName } from "./components";
import { SitesReducerActions, SITES_REDUCER_TYPES } from "../../../../reducers";
import SiteCoordFormPage from "./site-cords-form-page";

const SiteEditScreen = () => {
  const commonStyles = useCommonStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const currentSite = useSelector((state) => state.sitesScreen.currentSite);
  const removeSubsiteDialog = useSelector((state) => state.sitesScreen.dialogs.removeSubsiteDialog);
  const pageIndex = useSelector((state) => state.sitesScreen.pageIndex);
  const { namedRoutes } = useContext(ScopeContext);

  const backToSites = useCallback(() => {
    return (
      <BackBttn
        show={true}
        label={"Back to Full List"}
        onClick={() => {
          history.push(namedRoutes.sites);
          dispatch({ type: SITES_REDUCER_TYPES.SET_CURRENT_SITE, payload: null });
          dispatch({ type: SITES_REDUCER_TYPES.RESET_CURRENT_SUBSITE, payload: null });
          dispatch({ type: SITES_REDUCER_TYPES.SET_PAGE_INDEX, payload: 0 });
          dispatch({ type: SITES_REDUCER_TYPES.SET_MAP_SELECTED_SUBSITE, payload: null });
        }}
      />
    );
  }, [dispatch, history, namedRoutes.sites]);

  useEffect(() => {
    return () => {
      dispatch({ type: SITES_REDUCER_TYPES.SET_PAGE_INDEX, payload: 0 });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DocumentTitle title="Sitewise | Site Config Edit" />
      {pageIndex === 0 ? (
        <SiteCoordFormPage backToSites={backToSites} />
      ) : (
        <Grid id="j-sitewise-siteEdit" className={commonStyles.page_wrapper}>
          {backToSites(history, dispatch, namedRoutes)}
          <EditSiteName currentSite={currentSite} />
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ flex: 2 }}>
              <SiteConfigForms currentSite={currentSite} />
            </div>
          </div>
        </Grid>
      )}

      <RemoveDialog
        title="Are you sure you want to remove sub site area?"
        show={removeSubsiteDialog.show}
        fullMapView={true}
        onConfirm={() => {
          dispatch(SitesReducerActions.deleteSubSite());
        }}
        onCancel={(event) => {
          event.stopPropagation();
          dispatch({
            type: SITES_REDUCER_TYPES.SET_REMOVE_SUBSITE_DIALOG_DISPLAY,
            payload: {
              show: false,
              id: null,
            },
          });
        }}
      />
    </>
  );
};

export default SiteEditScreen;
