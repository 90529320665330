import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import { MapFilter } from "../map-filter";
import {
  MultiSiteSelector,
  AlertSnackbar,
  FullMapWidget,
  HomeMapWidget,
  UserPermissionContext,
  WeatherWidget
} from "../../../../commons";
import { PlotPlan } from "../../../../commons/map/widgets/plot-plan/plot-plan";
import { useDispatch, useSelector } from "react-redux";
import { ZONE_REDUCER_TYPES, plotPlanImageLayerJson } from "../../../../reducers";
import { FONTS } from "../../../../utils";

const useStyles = makeStyles(() => ({
  mapContainer: {
    width: "100%",
    height: "100%",
  },
  tickerMessage: {
    ...FONTS.B5_Reg_12,
    textAlign: "center",
  }
}));

export const ZoneMapContainer = (props) => {
  const classes = useStyles();
  const {
    showMapFilters = true,
    showAlertSnackbars = true,
    onLocateSiteCb,
    showHidePlotPlan,
    showHideMapImageLayer,
    mapView,
  } = props;
  const dispatch = useDispatch();
  const snackbarAlerts = useSelector((state) => state.globalState.snackbarAlerts.list);
  const site = useSelector((state) => state.globalState.site);
  const siteName = site?.name.toLowerCase();
  const mainSite = site?.subSites.length ? site?.subSites[0] : "";
  const mapFilters = useSelector((state) => state.globalState.mapUserDevices.filters);
  const [open, setOpen] = useState(false);
  const { Permissions } = useContext(UserPermissionContext);
  const showPlotPlan = plotPlanImageLayerJson[siteName]?.show;
  const tickerMessage = useSelector((state) => state.globalState.site?.disclaimerTickerMessage ?? "");

  return (
    <>
      <div id="map-container" className={classes.mapContainer} />
      <div className={classes.tickerMessage} dangerouslySetInnerHTML={{ __html: tickerMessage }}>
      </div>
      {showAlertSnackbars && Permissions.View_Alerts ? (
        <AlertSnackbar activeAlerts={snackbarAlerts} onActionBttnClick={props.onActionBttnClick} />
      ) : null}
      {showMapFilters && Permissions.View_Devices ? (
        <MapFilter open={open} filters={mapFilters} setOpen={setOpen} {...props} />
      ) : null}
      <MultiSiteSelector site={site} onLocateSiteCb={onLocateSiteCb} />
      {mainSite ? (<HomeMapWidget
        homeLocation={mainSite}
        onLocateSiteCb={
          onLocateSiteCb
        }
      />):null}
      {mainSite && site.id === process.env.REACT_APP_SITEWISE_WEATHER_SITEID ?(<WeatherWidget site={site} />):null}
      {showPlotPlan && (
        <PlotPlan
          showHidePlotPlan={showHidePlotPlan}
          showHideMapImageLayer={showHideMapImageLayer}
        />
      )}
      <FullMapWidget
        isSelected={mapView === "ONLY_MAP"}
        mapView={mapView}
        onClickCb={(_mapView) => {
          dispatch({
            type: ZONE_REDUCER_TYPES.SET_MAP_VIEW,
            payload: _mapView !== "ONLY_MAP" ? "ONLY_MAP" : "LARGE_MAP",
          });
        }}
      />
    </>
  );
};
