import { makeStyles } from "@material-ui/core";
import { FONTS } from "../../utils";

export const useDialogStyles = makeStyles((theme) => ({
  dialogPaper: {
    width: "464px",
    backgroundColor: theme.palette.base.elevation_1,
    boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.25)",
    borderRadius: 0,
  },
  closeIconWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    position: "absolute",
    right: "0px",
  },
  dialogContentWrapper: {
    padding: "36px 36px 36px 36px",
  },
  dialogTitle: {
    paddingTop: "0px",
    paddingLeft: "0px",
    paddingRight: "0px",
    marginBottom: "57px",
    ...FONTS.H5_ShellBold_20,
  },
  dialogTitleCentered: {
    textAlign: "center",
    paddingBottom: "40px",
    ...FONTS.H5_ShellBold_18,
  },
  actionsBttnWrapper: {
    flexDirection: "row",
    justifyContent: "flex-end",
    display: "flex",
    gap: "10px"
  },
  dialogActionBttn: {
    ...FONTS.B4_BOLD_14,
  },
}));
