import React from "react";
import { makeStyles, IconButton, Tooltip } from "@material-ui/core";
import { ICONS } from "../icon-manager";

const useStyles = makeStyles((theme) => ({
  plotPlanWrapper: {
    position: "absolute",
    bottom: "78px",
    right: "15px",
    background: theme.palette.common.black,
    display: "flex",
  },
}));

export const FullMapWidget = (props) => {
  const { mapView, isSelected, onClickCb } = props;
  const classes = useStyles();

  return (
    <div className={classes.plotPlanWrapper}>
      <Tooltip title={"Fullscreen Map"}>
        <IconButton
          aria-label="Fullscreen"
          style={{
            padding: "5px 4px",
          }}
          color={isSelected ? "primary" : ""}
          onClick={() => {
            onClickCb(mapView);
          }}
        >
          <ICONS.ZoomOutMapIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};
