import React, { useState, useCallback, useEffect } from "react";
import { TextField, InputAdornment } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ICONS, useDebounce } from "../../../../../commons";
import { DevicesAPI } from "../../../../../api";

export const OnlineDevicesSearch = (props) => {
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchString, setSearchString] = useState("");

  const { onAutoCompleteChange, value, siteID } = props;
  const onTextFieldCb = useDebounce((nextValue) => setSearchString(nextValue), 300);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const params = new URLSearchParams({
      q: searchString,
      o: true,
      a: true,
      ct: 10,
    });

    const queryStr = params.toString();
    const response = await DevicesAPI.getSitelocationDevicesByQueryString(siteID, queryStr);
    setOptions(response);
    setLoading(false);
  }, [siteID, searchString]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
      setSearchString("");
    }
  }, [open]);

  useEffect(() => {
    if (searchString) {
      fetchData();
    }
  }, [searchString, fetchData]);

  return (
    <Autocomplete
      id="Search Device"
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={value}
      onChange={(event, newVal) => {
        event.stopPropagation();
        onAutoCompleteChange(newVal);
      }}
      options={options}
      getOptionLabel={(option) => option.manufacturerId}
      loading={loading}
      renderInput={(params) => (
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ICONS.Search />
              </InputAdornment>
            ),
          }}
          label="Device ID"
          margin="dense"
          variant="filled"
          onChange={(event) => {
            onTextFieldCb(event.target.value);
          }}
          {...params}
        />
      )}
    />
  );
};
