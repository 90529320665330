import React, { useEffect, useRef } from "react";
import { makeStyles, Box } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Navbar, PageLoader, ErrorToast, InfoToast } from "../commons";
import { AuthReducerActions, } from "../reducers";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      height: "100%",
      width: "100%",
    },
    wrapper: {
      flex: 1,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  };
});

export const MainScreen = ({ children, routesJSON, signalRConn }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const isSignalRConnected = useSelector((state) => state.globalState.isSignalRConnected);
  const navigation = useSelector((state) => state.auth.navigation);
  const goToNewRoute = useSelector((state) => state.auth.goToNewRoute);
  const location = useLocation();
  const currentTabRef = useRef();

  useEffect(() => {
    const manageSignalR = async () => {
      let isRootOrSameTab = false;
      if (currentTabRef.current === location.pathname) {
        isRootOrSameTab = true;
      }
      currentTabRef.current = location.pathname;
      if (!auth.isLoggedIn && isSignalRConnected) {
        await signalRConn.stopConnection();
      } else if (auth.isLoggedIn && !isRootOrSameTab && !isSignalRConnected) {
        await signalRConn.startConnection(currentTabRef);
      } else {
        signalRConn.updateCurrentTabRef(currentTabRef);
      }
    };
    manageSignalR();
  }, [auth.isLoggedIn, location, isSignalRConnected, signalRConn]);

  useEffect(() => {
    if (routesJSON.length && goToNewRoute) {
      dispatch(AuthReducerActions.setAuthorizedRoute(routesJSON[0].href));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigation]);

  return (
    <>
      <Box id="SitewiseApp" className={classes.container}>
        <Navbar routesJSON={routesJSON} />
        <Box className={classes.wrapper}>{children}</Box>
      </Box>
      <PageLoader />
      <ErrorToast />
      <InfoToast />
    </>
  );
};
