import { UserAPI, SiteLocationsAPI } from "../api";
import { GlobalReducerActions } from "../reducers";

const USERS_INITIAL_STATE = {
  users: [],
  selected: {
    user: null,
    role: null,
  },
  batchUploadResponse: null,
  userCategories: [],
  userRoles: [],
  searchedDevices: [],
  dialogs: {
    showNewUserDialog: false,
    showNewRoleDialog: false,
    showRemoveRoleDialog: false,
    showBatchUploadDialog: false,
    showNewUserCreateDialog: false,
    showRemoveUserDialog: false,
  }
};

export const USERS_REDUCER_TYPES = {
  SET_ALL_USERS: "SET_ALL_USERS",
  // ** dialogs
  SET_NEW_USER_DIALOG_DISPLAY: "SET_NEW_USER_DIALOG_DISPLAY",
  SET_NEW_ROLE_DIALOG_DISPLAY: "SET_NEW_ROLE_DIALOG_DISPLAY",
  SET_NEW_USER_CREATE_DIALOG: "SET_NEW_USER_CREATE_DIALOG",
  SET_REMOVE_ROLE_DIALOG_DISPLAY: "SET_REMOVE_ROLE_DIALOG_DISPLAY",
  SET_REMOVE_USER_DIALOG_DISPLAY: "SET_REMOVE_USER_DIALOG_DISPLAY",
  SET_BATCH_UPLOAD_DIALOG_DISPLAY: "SET_BATCH_UPLOAD_DIALOG_DISPLAY",
  SET_USER_CATEGORIES: "SET_USER_CATEGORIES",
  SET_USER_ROLES: "SET_USER_ROLES",
  // selected
  SET_SELECTED_ROLE: "SET_SELECTED_ROLE",
  SET_SELECTED_ROLE_NAME: "SET_SELECTED_ROLE_NAME",
  SET_SELECTED_USER: "SET_SELECTED_USER",
  // Search
  // Batch Upload
  SET_BATCH_UPLOAD_RESPONSE: "SET_BATCH_UPLOAD_RESPONSE",
};

export const usersReducer = (_state, action) => {
  const state = _state || USERS_INITIAL_STATE;
  const { type, payload } = action;
  switch (type) {
    case USERS_REDUCER_TYPES.SET_ALL_USERS:
      return {
        ...state,
        users: payload,
      };

    case USERS_REDUCER_TYPES.SET_NEW_USER_DIALOG_DISPLAY:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          showNewUserDialog: payload,
        },
      };

    case USERS_REDUCER_TYPES.SET_NEW_ROLE_DIALOG_DISPLAY:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          showNewRoleDialog: payload,
        },
      };

    case USERS_REDUCER_TYPES.SET_REMOVE_ROLE_DIALOG_DISPLAY:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          showRemoveRoleDialog: payload,
        },
      };

    case USERS_REDUCER_TYPES.SET_BATCH_UPLOAD_DIALOG_DISPLAY:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          showBatchUploadDialog: payload,
        },
      };

    case USERS_REDUCER_TYPES.SET_NEW_USER_CREATE_DIALOG:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          showNewUserCreateDialog: payload,
        },
      };

    case USERS_REDUCER_TYPES.SET_REMOVE_USER_DIALOG_DISPLAY:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          showRemoveUserDialog: payload,
        },
      };

    case USERS_REDUCER_TYPES.SET_SELECTED_USER:
      return {
        ...state,
        selected: {
          ...state.selected,
          user: payload,
        },
      };

    case USERS_REDUCER_TYPES.SET_SELECTED_ROLE:
      return {
        ...state,
        selected: {
          ...state.selected,
          role: payload,
        },
      };

    case USERS_REDUCER_TYPES.SET_USER_CATEGORIES:
      return {
        ...state,
        userCategories: payload,
      };

    case USERS_REDUCER_TYPES.SET_USER_ROLES:
      return {
        ...state,
        userRoles: payload,
      };

    case USERS_REDUCER_TYPES.SET_SELECTED_ROLE_NAME:
      return {
        ...state,
        selected: {
          ...state.selected,
          role: {
            ...state.selected.role,
            role: payload,
          },
        },
      };

    case USERS_REDUCER_TYPES.SET_BATCH_UPLOAD_RESPONSE:
      return {
        ...state,
        batchUploadResponse: payload,
      };
    default:
      return state;
  }
};

const getAllUsers = (showPageLoader = true) => {
  return async (dispatch, getState) => {
    try {
      showPageLoader && dispatch(GlobalReducerActions.showPageLoader());
      const siteId = getState().globalState.siteID;
      const response = await SiteLocationsAPI.getAllUsersBySiteLocation(siteId);
      dispatch({ type: USERS_REDUCER_TYPES.SET_ALL_USERS, payload: response });
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const getAllUsersFromJarvis = (showPageLoader = true) => {
  return async (dispatch, getState) => {
    try {
      showPageLoader && dispatch(GlobalReducerActions.showPageLoader());
      const siteId = getState().globalState.siteID;
      const body = {
        id:siteId
      }
      await SiteLocationsAPI.syncAllUsersFromJarvis(body);
      const response = await SiteLocationsAPI.getAllUsersBySiteLocation(siteId);
      dispatch({ type: USERS_REDUCER_TYPES.SET_ALL_USERS, payload: response });
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const deleteUser = () => {
  return async (dispatch, getState) => {
    try {
      const selectedUser = getState().usersScreen.selected.user;
      const siteId = getState().globalState.siteID;
      dispatch(GlobalReducerActions.showPageLoader());
      await UserAPI.deleteUser(selectedUser.id, siteId);
      dispatch({ type: USERS_REDUCER_TYPES.SET_REMOVE_USER_DIALOG_DISPLAY, payload: false });
      dispatch(UsersReducerActions.getAllUsers());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
      dispatch({ type: USERS_REDUCER_TYPES.SET_REMOVE_USER_DIALOG_DISPLAY, payload: false });
    }
  };
};

const createUser = (user) => {
  return async (dispatch, getState) => {
    try {
      dispatch(GlobalReducerActions.showPageLoader());
      const accessToken = getState().auth.accessToken;
      const siteLocationId = getState().globalState.siteID;
      await UserAPI.createUser(
        { ...user, siteLocationId: siteLocationId },
        accessToken
      );
      dispatch({ type: USERS_REDUCER_TYPES.SET_NEW_USER_CREATE_DIALOG, payload: false });
      dispatch(UsersReducerActions.getAllUsers());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
      dispatch({ type: USERS_REDUCER_TYPES.SET_REMOVE_USER_DIALOG_DISPLAY, payload: false });
    }
  };
};

const getUserCategoriesSummary = () => {
  return async (dispatch, getState) => {
    try {
      const siteID = getState().globalState.siteID;
      const response = await UserAPI.getUserCategoriesSummary(siteID);
      dispatch({ type: USERS_REDUCER_TYPES.SET_USER_CATEGORIES, payload: response });
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const getUserRoles = (showPageLoader = true) => {
  return async (dispatch, getState) => {
    try {
      showPageLoader && dispatch(GlobalReducerActions.showPageLoader());
      const siteLocationId = getState().globalState.siteID;
      const response = await SiteLocationsAPI.getAllUserRolesBySiteLocation(siteLocationId);
      dispatch({ type: USERS_REDUCER_TYPES.SET_USER_ROLES, payload: response });
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const createRole = (roleName) => {
  return async (dispatch, getState) => {
    try {
      dispatch(GlobalReducerActions.showPageLoader());
      const siteLocationId = getState().globalState.siteID;
      await UserAPI.createRole({ role: roleName, siteLocationId: siteLocationId });
      dispatch({ type: USERS_REDUCER_TYPES.SET_NEW_ROLE_DIALOG_DISPLAY, payload: false });
      dispatch(UsersReducerActions.getUserRoles());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
      dispatch({ type: USERS_REDUCER_TYPES.SET_NEW_ROLE_DIALOG_DISPLAY, payload: false });
    }
  };
};

const deleteRole = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(GlobalReducerActions.showPageLoader());
      const selectedRole = getState().usersScreen.selected.role;
      await UserAPI.deleteRole(selectedRole.id);
      dispatch({ type: USERS_REDUCER_TYPES.SET_REMOVE_ROLE_DIALOG_DISPLAY, payload: false });
      dispatch(UsersReducerActions.getUserRoles());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
      dispatch({ type: USERS_REDUCER_TYPES.SET_REMOVE_ROLE_DIALOG_DISPLAY, payload: false });
    }
  };
};

const updateRole = (roleData) => {
  return async (dispatch, getState) => {
    try {
      const siteLocationId = getState().globalState.siteID;
      dispatch(GlobalReducerActions.showPageLoader());
      await UserAPI.updateRole({ ...roleData, siteLocationId });
      dispatch(UsersReducerActions.getUserRoles());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const updateUser = (userData) => {
  return async (dispatch, getState) => {
    try {
      dispatch(GlobalReducerActions.showPageLoader());
      const siteLocationId = getState().globalState.siteID;
      await UserAPI.updateUser({ ...userData, siteLocationId: siteLocationId });
      dispatch(UsersReducerActions.getAllUsers());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const uploadUsersCsvfile = (formData) => {
  return async (dispatch, getState) => {
    try {
      dispatch(GlobalReducerActions.showPageLoader());
      const siteLocationId = getState().globalState.siteID;
      const response = await SiteLocationsAPI.bulkUploadUsersBySiteID(formData, siteLocationId);

      dispatch({
        type: USERS_REDUCER_TYPES.SET_BATCH_UPLOAD_RESPONSE,
        payload: response,
      });

      dispatch({
        type: USERS_REDUCER_TYPES.SET_BATCH_UPLOAD_DIALOG_DISPLAY,
        payload: false,
      });
      dispatch(UsersReducerActions.getAllUsers());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const init = () => {
  return async (dispatch) => {
    try {
      await dispatch(GlobalReducerActions.getSiteById());
      await dispatch(UsersReducerActions.getAllUsers());
      dispatch(UsersReducerActions.getUserRoles());
      dispatch(UsersReducerActions.getUserCategoriesSummary());
      dispatch(GlobalReducerActions.getAllAlerts(false));
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};
const getUserLocationHistory = (manufactureId) => {
  return UserAPI.getUserLocationHistory(manufactureId);
};
const videoChat = async (deviceId, action) => {
 
   return  await UserAPI.videoChat(deviceId, action);
};
export const UsersReducerActions = {
  init,
  getAllUsers,
  getUserCategoriesSummary,
  getUserRoles,
  deleteUser,
  createUser,
  createRole,
  deleteRole,
  updateRole,
  updateUser,
  uploadUsersCsvfile,
  getUserLocationHistory,
  videoChat,
  getAllUsersFromJarvis
};
