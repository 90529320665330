import React, { useState, useEffect } from "react";
import { makeStyles, ButtonGroup, Button } from "@material-ui/core";
import { FONTS } from "../../utils";
import { ICONS } from "../icon-manager";

const useStyles = makeStyles((theme) => ({
  multi_site_selector: {
    position: "absolute",
    bottom: "1%",
    left: "13px",
    background: theme.palette.background.default,
    transition: "all 3s ease-in-out",
    display: "flex",
  },
  site_label: {
    ...FONTS.B4_BOLD_14,
    padding: "10px",
  },
  bttn: {
    ...FONTS.B4_BOLD_14,
    borderRadius: 0,
    border: "none",
    color: "white",
    borderRight: "1px solid",
    borderColor: "white",
    background: "#1D1D25",
  },
}));

export const MultiSiteSelector = (props) => {
  const { onLocateSiteCb, site } = props;
  const classes = useStyles();
  const [expand, setExpand] = useState(false);
  const [selectedSite, setSelectedSite] = useState(null);

  function onBttnClick(site) {
    setSelectedSite(site);
    setExpand(false);
    onLocateSiteCb && onLocateSiteCb(site);
  }

  useEffect(() => {
    if (site) {
      const item = site.subSites[0];
      setSelectedSite(item);
    }
  }, [site]);

  if (site && site.subSites.length > 1) {
    return (
      <div className={classes.multi_site_selector}>
        {expand ? (
          <ButtonGroup disableElevation variant="contained" color="primary">
            {site?.subSites.map((site, index) => {
              return (
                <Button key={index} onClick={() => onBttnClick(site)} className={classes.bttn}>
                  {site.name}
                </Button>
              );
            })}
          </ButtonGroup>
        ) : (
          <span className={classes.site_label}>{selectedSite?.name}</span>
        )}
        <Button
          size="small"
          aria-label="select merge strategy"
          onClick={() => {
            setExpand(!expand);
          }}
        >
          {expand ? <ICONS.ChevronLeft /> : <ICONS.ChevronRight />}
        </Button>
      </div>
    );
  } else {
    return null;
  }
};
