import React, { useState, useEffect, useCallback } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { Grid, makeStyles, Tooltip, MenuItem } from "@material-ui/core";
import { TextField, FormControl, Button } from "@material-ui/core";
import { getUserLocationHistoryByMultiIDandTimeFrame } from "../../reducers";
import { DeviceHistoryReportMap } from './device-history-reports-map';
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { ICONS } from "../../commons/icon-manager";
import { IconButton, InputAdornment } from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
var filterDevices = [];
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const fieldStyle = {
    wrapper: {
        height: "600px",
    },
    formControl: {
        width: "100%",
        paddingBottom: "10px",
        height: "450px",
        overflow: "scroll"
    },
    dateControl: {
        width: "30%",
        paddingLeft: "5px"
    },
    buttonControl: {
        paddingTop: "25px",
        paddingLeft: "10px"
    }
}

export const DeviceHistoryReportsScreen = () => {

    const site = useSelector((state) => state.globalState.site);
    const [devices, setDevices] = useState([]);
    const [selectedFromDate, setSelectedFromDate] = useState(null);
    const [selectedToDate, setSelectedToDate] = useState(null);
    const [colorsAssigned, setcolorsAssigned] = useState(false);
    const [requestfilter, setrequestfilter] = useState(true);
    const [deviesData, setdeviesData] = useState(useSelector((state) => state.devicesScreen.devices));
    const [centerSeleted, setcenterSeleted] = useState([]);
    const [map,setMap] = useState(null);

    useEffect(() => {
        if (requestfilter === true) {
            setMap(new DeviceHistoryReportMap({ site: site, filtereddevices: devices, center: centerSeleted }));
            setrequestfilter(false);
        }
        if (colorsAssigned === false) {
            let deviceData = deviesData;
            for (var row = 0; row < deviceData.length; row++) {
                if (deviceData[row].color == null) {
                    deviceData[row].color = getRandomColor();
                }
                setdeviesData(deviceData);
                setcolorsAssigned(true);
            }
        }
    });
    const handleFilter = async (event) => {
        var selectedDevice = deviesData.map(function (item) {
            if (item.selected) {
                return item.manufacturerId;
            }
            else
            {
                return null;
            }
        });
        if(selectedDevice)
        {
            selectedDevice=selectedDevice.filter(f=>f!=null);
        }
        const validatedSelectedFromDate = selectedFromDate ? selectedFromDate._d.toJSON() : "";
        const validatedSelectedToDate=selectedToDate ? selectedToDate._d.toJSON() : "";
        var zones = await getUserLocationHistoryByMultiIDandTimeFrame(selectedDevice, validatedSelectedFromDate,validatedSelectedToDate);
        SetZoneColorAndCenter(zones, deviesData, setcenterSeleted, setDevices, setrequestfilter);
    }
    const getRandomColor = () => {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }
    const renderSelectDevice = (event, index) => {
        var arrdevices = deviesData;
        arrdevices[index].selected = event.target.checked;
        setdeviesData(arrdevices);
        ReactDOM.render(renederDeviceSelect(), document.getElementById("divCheckboxDiv"));
    }
    const linkGotToMap = async (row, zone) => {
        // var link={latitude: 52.17664237,longitude:4.45444225};
        // map.gotoLocation(link);
        // await map.mapView.goTo({ center: [link.longitude, link.latitude],zoom:20 });
        //var zone = filterDevices.filter(f => f.manufacturerId == row.manufacturerId)[0];
        if (row.selected === true && zone && zone.userLocationHistory) {
            var link = { latitude: zone.userLocationHistory[0].latitude, longitude: zone.userLocationHistory[0].longitude };
            console.log(link);
            setMap(new DeviceHistoryReportMap({site:site,filtereddevices:filterDevices,center:[zone.userLocationHistory[0].longitude,zone.userLocationHistory[0].latitude]}));
            console.log({ center: [link.longitude, link.latitude], zoom: 20 });
        }
    }
    const renederDeviceSelect = () => {
        return (<FormControl variant="filled" style={fieldStyle.formControl}>
            {deviesData.map((row, index) => (
                <div>

                    <MenuItem >
                        <Checkbox
                            checked={row.selected === true ? true : false}
                            onClick={(event) => {
                                renderSelectDevice(event, index);
                            }}
                            defaultChecked={false}
                            name={row.manufacturerId}
                            style={{ color: "#EFBF04" }}
                        />
                        <a onClick={async () => { await linkGotToMap(row, filterDevices.filter(f => f.manufacturerId === row.manufacturerId)[0]) }}>
                            <ICONS.FiberManualRecordIcon style={{ fill: row.color }} />
                            {row.manufacturerId}{" - "} {row.currentAssignedUser != null ? row.currentAssignedUser.name : "Un Assigned"}
                        </a>
                    </MenuItem>
                    {/* <a onClick={async () => { await linkGotToMap(row, filterDevices.filter(f => f.manufacturerId == row.manufacturerId)[0]) }}>
                        <ICONS.FiberManualRecordIcon style={{ fill: row.color }} />
                        {row.manufacturerId}{" - "} {row.currentAssignedUser != null ? row.currentAssignedUser.name : "Un Assigned"}
                    </a> */}
                </div>
            ))}
        </FormControl>);
    }
    return (<div>
        <Grid container spacing={5}>
            <Grid item xs={0.3}>
            </Grid>
            <Grid item xs={3}>
                <FormControl variant="filled" >
                    Devices:
                </FormControl><br />
                <div id="divCheckboxDiv">{renederDeviceSelect()}</div>
            </Grid>
            <Grid item xs={8} style={fieldStyle.wrapper} >
                <div id="map-container" style={{ height: "430px" }} />
                <FormControl variant="filled" style={fieldStyle.dateControl}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DateTimePicker
                            inputVariant="filled"
                            fullWidth={true}
                            margin="normal"
                            value={selectedFromDate}
                            onChange={(value) => { setSelectedFromDate(value) }}
                            label="Select From Date"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton>
                                            <ICONS.CalendarToday />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }} />
                    </MuiPickersUtilsProvider>
                </FormControl>
                <FormControl variant="filled" style={fieldStyle.dateControl}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DateTimePicker
                            inputVariant="filled"
                            fullWidth={true}
                            margin="normal"
                            value={selectedToDate}
                            onChange={(value) => { setSelectedToDate(value) }}
                            label="Select To Date"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton>
                                            <ICONS.CalendarToday />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }} />
                    </MuiPickersUtilsProvider>
                </FormControl>
                <FormControl style={fieldStyle.buttonControl}>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                            var selectedDevice = deviesData.map(function (item) {
                                item.selected = false;
                                return item;
                            });
                            setDevices(selectedDevice);
                            setrequestfilter(true);
                            setSelectedFromDate(null);
                            setSelectedToDate(null);
                            ReactDOM.render(renederDeviceSelect(), document.getElementById("divCheckboxDiv"));
                        }}
                    >
                        Clear All
                    </Button>
                </FormControl>
                <FormControl style={fieldStyle.buttonControl}>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => { handleFilter(); }}
                    >
                        Filter
                    </Button>
                </FormControl>
            </Grid>
        </Grid>
    </div >);

}
function SetZoneColorAndCenter(zones, deviesData, setcenterSeleted, setDevices, setrequestfilter) {
    if (zones) {
        zones = zones.filter(f => f != null && f.manufacturerId != null);
        for (var row = 0; row < zones.length; row++) {
            if (zones[row] != null && zones[row].manufacturerId != null && deviesData.filter(f => f.manufacturerId === zones[row].manufacturerId)[0].color) {
                zones[row].color = deviesData.filter(f => f.manufacturerId === zones[row].manufacturerId)[0].color;
            }
        }
        //deviesData
        if (zones && zones[0].userLocationHistory[0]) {
            setcenterSeleted([zones[0].userLocationHistory[0].longitude, zones[0].userLocationHistory[0].latitude]);
        }
        setDevices(zones);
        filterDevices = zones;
        setrequestfilter(true);
    }
    return zones;
}

