import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Dialog,
  DialogTitle,
  FormControl,
  TextField,
  Button,
  Box,
} from "@material-ui/core";
import { useDialogStyles } from "../../../../commons/dialogs/use-dialog-styles";
import { useDispatch } from "react-redux";
import { UsersReducerActions } from "../../../../reducers";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    marginBottom: "32px",
  },
  formControlActions: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
}));

export const NewRoleDialog = ({ title, show, onCancel, onConfirm }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dialogStyles = useDialogStyles();
  const [roleName, setRoleName] = useState("");

  function handleCancelBttnClick() {
    onCancel && onCancel();
  }

  function handleFormSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    dispatch(UsersReducerActions.createRole(roleName));
    onConfirm && onConfirm(event);
  }

  useEffect(() => {
    setRoleName("");
  }, [show]);

  if (!show) {
    return null;
  }

  return (
    <Dialog open={show} onClose={onCancel} PaperProps={{ className: dialogStyles.dialogPaper }}>
      <Box className={dialogStyles.dialogContentWrapper}>
        <DialogTitle
          className={dialogStyles.dialogTitle}
          disableTypography
          id="responsive-dialog-title"
          onClose={onCancel}
        >
          <span>{title}</span>
        </DialogTitle>
        <form className={classes.form} autoComplete="off" onSubmit={handleFormSubmit}>
          <FormControl required variant="filled" className={classes.formControl}>
            <TextField
              required
              id="filled-basic"
              label="Role Name"
              variant="filled"
              fullWidth={true}
              value={roleName}
              onChange={(event) => setRoleName(event.target.value)}
              inputProps={{
                "data-testid": "role-input",
              }}
            />
          </FormControl>
          <FormControl required variant="filled" className={classes.formControlActions}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleCancelBttnClick}
              className={dialogStyles.dialogActionBttn}
              data-testid="cancel-bttn"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginLeft: "10px" }}
              className={dialogStyles.dialogActionBttn}
              data-testid="create-bttn"
            >
              Create
            </Button>
          </FormControl>
        </form>
      </Box>
    </Dialog>
  );
};
