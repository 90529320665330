const IDS = {
  SKETCH_HELPER_TEXT: "sketch-tool-helper-text",
  GRAPHIC_LABEL_ACTION_BTTN: "graphic-label-action-bttn",
};

const getSketchHelperWidget = () => {
  let sketchHelperDiv = document.getElementById(IDS.SKETCH_HELPER_TEXT);

  if (!sketchHelperDiv) {
    sketchHelperDiv = document.createElement("div");
  }

  sketchHelperDiv.innerHTML = `<span class="sketch-tool-helper-label"></span>`;
  sketchHelperDiv.id = IDS.SKETCH_HELPER_TEXT;
  sketchHelperDiv.className = IDS.SKETCH_HELPER_TEXT;
  sketchHelperDiv.style.display = "none";
  return sketchHelperDiv;
};

const getGraphicLabelActionBttnToMapView = (callback) => {
  const graphicLabel = document.createElement("div");
  graphicLabel.innerHTML = `<span class="graphic-label-action-text"></span><span class="graphic-label-action"><i class="fa fa-times map-cross-icon" aria-hidden="true"></i></span>`;
  graphicLabel.id = IDS.GRAPHIC_LABEL_ACTION_BTTN;
  graphicLabel.className = IDS.GRAPHIC_LABEL_ACTION_BTTN;
  graphicLabel.style.top = "15%";
  graphicLabel.style.left = "50%";
  graphicLabel.style.display = "none";
  graphicLabel.querySelector(".graphic-label-action").onclick = callback;
  return graphicLabel;
};

const getGraphicSaveBttn = () => {
  // using
  const bttnId = "graphic-save-bttn";
  let zoneSaveBttn = document.getElementById(bttnId);
  if (zoneSaveBttn) {
    return zoneSaveBttn;
  }
  zoneSaveBttn = document.createElement("button");
  zoneSaveBttn.id = bttnId;
  zoneSaveBttn.className = `map-action-button ${bttnId}`;
  zoneSaveBttn.style.display = "none";
  zoneSaveBttn.innerHTML = `<span>Done</span>`;
  return zoneSaveBttn;
};
const getGraphicCancelBttn = (callback) => {
  // using
  const bttnId = "graphic-cancel-bttn";
  let cancelBttn = document.getElementById(bttnId);
  if (cancelBttn) {
    return cancelBttn;
  }
  cancelBttn = document.createElement("button");
  cancelBttn.id = bttnId;
  cancelBttn.className = `map-action-button ${bttnId}`;
  cancelBttn.style.display = "none";
  cancelBttn.innerHTML = `<span>Cancel</span>`;
  cancelBttn.onclick = callback;
  return cancelBttn;
};

const addGraphicUpdateBttn = () => {
  const bttnId = "graphic-update-bttn";
  let zoneUpdateBttn = document.getElementById(bttnId);

  if (zoneUpdateBttn) {
    return zoneUpdateBttn;
  }
  zoneUpdateBttn = document.createElement("button");
  zoneUpdateBttn.id = bttnId;
  zoneUpdateBttn.className = `map-action-button ${bttnId}`;
  zoneUpdateBttn.style.display = "none";
  zoneUpdateBttn.innerHTML = `<span>Done</span>`;
  return zoneUpdateBttn;
};

export const MapWidgetTmpl = {
  getGraphicSaveBttn,
  addGraphicUpdateBttn,
  getGraphicLabelActionBttnToMapView,
  getSketchHelperWidget,
  getGraphicCancelBttn,
};
