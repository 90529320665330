import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Tabs, Tab, Box } from "@material-ui/core";
import { TabPanel, a11yProps } from "../../commons/tabs";
import { SendMessageDialog, AlertsSentTable, NotificationsTable } from "./components";
import { MESSAGES_REDUCER_TYPES, MessagesReducerActions } from "../../reducers";
import { UserPermissionContext, useCommonStyles } from "../../commons";

export const MessagesContainer = () => {
  const dispatch = useDispatch();
  const commonStyles = useCommonStyles();
  const { Permissions } = useContext(UserPermissionContext);

  const [tabValue, setTabValue] = useState(0);
  const siteID = useSelector((state) => state.globalState.siteID);
  const dashboardMessages = useSelector((state) => state.messagesScreen.dashboardMessages);
  const bulkDeviceMessages = useSelector((state) => state.messagesScreen.bulkDeviceMessages);

  const showSendMessageDialog = useSelector(
    (state) => state.messagesScreen.dialogs.showSendMessageDialog
  );
  const handleTabClick = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (siteID) {
      if (tabValue === 0) {
        dispatch(MessagesReducerActions.getAllDashboardMessages());
      } else if (tabValue === 1) {
        dispatch(MessagesReducerActions.getAllBulkDeviceMessages());
      }
    }
  }, [siteID, tabValue, dispatch]);

  useEffect(() => {
    if (siteID) {
      dispatch(MessagesReducerActions.init());
    }
  }, [siteID, dispatch]);

  useEffect(() => {
    if (siteID) {
      if (!Permissions.View_Dashboard_messages && Permissions.View_Bulk_Messages) {
        setTabValue(1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Paper>
      <Box className={commonStyles.tabs_wrapper}>
        <Tabs
          value={tabValue}
          onChange={handleTabClick}
          aria-label="tabs"
          style={{ display: "flex", justifyContent: "space-between" }}
          classes={{
            root: commonStyles.tabs_root,
            indicator: commonStyles.tabs_indicator,
          }}
        >
          {Permissions.View_Dashboard_messages ? (
            <Tab
              label={`Notifications`}
              data-testid="notifications-tab"
              {...a11yProps(0)}
              classes={{
                root: commonStyles.tab_root,
                selected: commonStyles.tab_selected,
              }}
            />
          ) : null}

          {Permissions.View_Bulk_Messages ? (
            <Tab
              label={`Sent Messages`}
              data-testid="sent-messages-tab"
              {...a11yProps(1)}
              classes={{
                root: commonStyles.tab_root,
                selected: commonStyles.tab_selected,
              }}
            />
          ) : null}
        </Tabs>
      </Box>
      {Permissions.View_Dashboard_messages &&
        <TabPanel TabPanel value={tabValue} index={0}>
          <NotificationsTable data={dashboardMessages} />
        </TabPanel>
      }
      {Permissions.View_Bulk_Messages &&
        <TabPanel value={tabValue} index={1}>
          <AlertsSentTable data={bulkDeviceMessages} />
        </TabPanel>
      }
      <SendMessageDialog
        show={showSendMessageDialog}
        onCancel={() => {
          dispatch({
            type: MESSAGES_REDUCER_TYPES.SET_SEND_MESSAGE_DIALOG_DISPLAY,
            payload: false,
          });
        }}
      />
    </Paper>
  );
};
