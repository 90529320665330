import { MapUtilsV2 } from "../../../../../commons/map";

const responseFilter = (response, GRAPHICS_LAYERS_ID) => {
  return response.results.filter(
    (result) => result.graphic.layer.id === GRAPHICS_LAYERS_ID.USER_DEVICES
  );
};

export const pointerMoveEventHandler = (mapView, store, GRAPHICS_LAYERS_ID, viewAlerts = true) => {
  mapView.on("pointer-move", (event) => {
    event.stopPropagation();
    mapView.hitTest(event).then((response) => {
      if (response.results.length) {
        const graphics = responseFilter(response, GRAPHICS_LAYERS_ID);
        if (graphics.length) {
          mapView.popup.open({});
          if (mapView.popup.visible === false) {
            mapView.popup.visible = true;
          }

          MapUtilsV2.renderMapTooltipMarkup(graphics[0], store, mapView, viewAlerts);
        }
        //  else {
        //   mapView.popup.close();
        // }
      }
      //  else {
      //   mapView.popup.close();
      // }
    });
  });
};

export const onUserDeviceClick = async (event, mapView, store, GRAPHICS_LAYERS_ID, viewAlerts = true) => {
  const response = await mapView.hitTest(event);
  let isOnUserClick = false;
  if (response.results.length) {
    const graphics = responseFilter(response, GRAPHICS_LAYERS_ID);

    if (graphics.length) {
      isOnUserClick = true;
      mapView.popup.open({});

      if (mapView.popup.visible === false) {
        mapView.popup.visible = true;
      }
      MapUtilsV2.renderMapTooltipMarkup(graphics[0], store, mapView, viewAlerts);
    } else {
      mapView.popup.close();
    }
  } else {
    mapView.popup.close();
  }

  return isOnUserClick;
  };
