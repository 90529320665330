import React, { useState } from "react";
import {
  makeStyles,
  Dialog,
  DialogTitle,
  FormControl,
  Button,
  Box,
  ButtonBase,
  TextField,
  Link,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { ICONS, useDialogStyles } from "../../../../../commons";
import { SitesReducerActions } from "../../../../../reducers";
import { FONTS } from "../../../../../utils";
import { APP_CONSTANTS } from "../../../../../config";

const useStyles = makeStyles(() => ({
  formControl: {
    width: "100%",
    marginBottom: "32px",
  },
  formControlActions: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  uploadButton: {
    width: "100%",
    borderBottom: "1px solid",
    backgroundColor: "rgba(255, 255, 255, 0.09)",
    marginBottom: "24px",
  },
  uploadButtonContents: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
    ...FONTS.B2_Reg_16,
  },
  labelText: {
    padding: "11px 11px 0 11px",
    ...FONTS.B5_Bold_12,
  },
  userInputs: {
    marginBottom: "24px",
  },
}));

function handleFormDoneBttn({ event, error, onConfirm, onCancel, setEnableDialog }) {
  event.preventDefault();
  event.stopPropagation();
  if (!error) {
    onConfirm();
    onCancel();
    setEnableDialog(false);
  }
}

function handleDialogChange({ event, currentDeviceInfo, dispatch, setEnableDialog, enabledPlotClearReminder, deviceConfiguration }) {
  event.preventDefault();
  const device = deviceConfiguration;
  device.enablePlotClearReminder = enabledPlotClearReminder
  if (currentDeviceInfo.device === "Blackline") {
    device.Blackline = {
      OrgName: currentDeviceInfo.OrgName,
    }
  } else if (currentDeviceInfo.device === "ISC") {
      device.ISC = {
        OrgName: currentDeviceInfo.OrgName,
      }
  } else if (currentDeviceInfo.device === "Mobile") {
    device.Mobile = true;
  };

  dispatch(
    SitesReducerActions.updateDeviceConfigById(device)
  ).then((res) => {
    if (res === 204) {
      setEnableDialog(true);
    }
  });
};

export const DeviceDialog = ({
  show,
  onCancel,
  onConfirm,
  currentDeviceInfo,
  setCurrentDeviceInfo,
}) => {
  const classes = useStyles();
  const dialogStyles = useDialogStyles();
  const [enableDialog, setEnableDialog] = useState(false);
  const enabledPlotClearReminder = useSelector((state) => state.sitesScreen.currentSite.deviceConfiguration.enablePlotClearReminder);
  const deviceConfiguration = useSelector((state) => state.sitesScreen.currentSite.deviceConfiguration);

  const error = useSelector((state) => state.globalState.error);
  const dispatch = useDispatch();

  function handleFileChange(event) {
    setCurrentDeviceInfo({ ...currentDeviceInfo, file: event.target.files[0] });
  }

  function handleChange(event) {
    setCurrentDeviceInfo({ ...currentDeviceInfo, [event.target.name]: event.target.value });
  }

  function setDeviceName(device) {
    let response;
    if (device === "Mobile") {
      response = "Mobile Device";
    } else {
      response = device;
    }
    return response;
  }

  function renderDeviceContent(device) {
    switch (device) {
      case "Blackline":
        return (
          <>
            <TextField
              variant="filled"
              label="Organization Name"
              onChange={handleChange}
              className={classes.userInputs}
              name="OrgName"
              value={currentDeviceInfo.OrgName || ""}
            />
            <Link
              href={APP_CONSTANTS[device]}
              target="_blank"
              rel="noopener"
              style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}
            >
              <ICONS.ArrowForwardIcon />
              <span style={{ marginLeft: "10px" }}>Go to {currentDeviceInfo.device} portal</span>
            </Link>
          </>
        );
        case "ISC":
          return (
            <>
              <TextField
                variant="filled"
                label="Organization Name"
                onChange={handleChange}
                className={classes.userInputs}
                name="OrgName"
                value={currentDeviceInfo.OrgName || ""}
              />
              <Link
                href={APP_CONSTANTS[device]}
                target="_blank"
                rel="noopener"
                style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}
              >
                <ICONS.ArrowForwardIcon />
                <span style={{ marginLeft: "10px" }}>Go to {currentDeviceInfo.device} portal</span>
              </Link>
            </>
          );
      case "TWTG":
        return (
          <>
            <TextField
              variant="filled"
              label="MQTT Broker End Point"
              onChange={handleChange}
              className={classes.userInputs}
              name="endPoint"
              value={currentDeviceInfo.endPoint}
            />
            <FormControl required variant="filled" className={classes.formControl}>
              <input
                color="primary"
                type="file"
                onChange={handleFileChange}
                id="icon-button-file"
                style={{ display: "none" }}
              />
              <label htmlFor="icon-button-file">
                <ButtonBase
                  size="large"
                  component="span"
                  className={classes.uploadButton}
                  style={{ marginBottom: 0 }}
                >
                  <div className={classes.uploadButtonContents}>
                    <span>
                      {currentDeviceInfo.file
                        ? currentDeviceInfo.file.name
                        : "Private CA Certificate"}
                    </span>
                    <ICONS.ArrowUpwardIcon />
                  </div>
                </ButtonBase>
              </label>
            </FormControl>
          </>
        );
      default:
        return null;
    }
  }

  return (
    <Dialog open={show} onClose={onCancel} PaperProps={{ className: dialogStyles.dialogPaper }}>
      <Box className={dialogStyles.dialogContentWrapper}>
        <DialogTitle className={dialogStyles.dialogTitle} disableTypography onClose={onCancel}>
          <span>Enable New Device</span>
        </DialogTitle>
        {!enableDialog ? (
          <>
            <h3>{setDeviceName(currentDeviceInfo.device)}</h3>
            <form
              className={classes.form}
              autoComplete="off"
              onSubmit={(event) => {
                handleDialogChange({ event, currentDeviceInfo, dispatch, setEnableDialog, enabledPlotClearReminder, deviceConfiguration });
              }}
            >
              {renderDeviceContent(currentDeviceInfo.device)}
              <FormControl required variant="filled" className={classes.formControlActions}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    onCancel();
                  }}
                  className={dialogStyles.dialogActionBttn}
                  data-testid="cancel-bttn"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ marginLeft: "10px" }}
                  className={dialogStyles.dialogActionBttn}
                  data-testid="create-bttn"
                  disabled={!currentDeviceInfo}
                >
                  Enable
                </Button>
              </FormControl>
            </form>
          </>
        ) : (
          <>
            <div style={{ marginBottom: "45px" }}>
              {setDeviceName(currentDeviceInfo.device)} has been successfully enabled.
            </div>
            <form
              className={classes.form}
              autoComplete="off"
              onSubmit={(event) => {
                handleFormDoneBttn({
                  event,
                  error,
                  onConfirm,
                  onCancel,
                  setEnableDialog,
                });
              }}
            >
              <FormControl required variant="filled" className={classes.formControlActions}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setEnableDialog(false)}
                  className={dialogStyles.dialogActionBttn}
                  data-testid="cancel-bttn"
                >
                  Back
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ marginLeft: "10px" }}
                  className={dialogStyles.dialogActionBttn}
                  data-testid="create-bttn"
                  disabled={!currentDeviceInfo}
                >
                  Done
                </Button>
              </FormControl>
            </form>
          </>
        )}
      </Box>
    </Dialog>
  );
};