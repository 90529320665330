import React from 'react';

export const ScopeContext = React.createContext({
    jarvisScope: false,
    routePrefix: "",
    namedRoutes:{}
});

export const ScopeContextProvider = props =>{
   const jarvisScope = window?.jarvis;
   const routePrefix = jarvisScope ? "/sitewise" : "";
   const namedRoutes = {
       alerts:`${routePrefix}/alerts`,
       zones:`${routePrefix}/zones`,
       devices:`${routePrefix}/devices`,
       users:`${routePrefix}/users`,
       sites:`${routePrefix}/sites`,
       messages:`${routePrefix}/messages`,
       reports:`${routePrefix}/reports`,
       privacy:`${routePrefix}/privacy`,
       root:`${routePrefix}/`,
   }
   return <ScopeContext.Provider { ...props } value={{ jarvisScope, routePrefix, namedRoutes }}   />;
}  
