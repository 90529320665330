import React from "react";
import { connect } from "react-redux";
import { SITES_REDUCER_TYPES } from "../../../../../../reducers";
import { SiteCordsMap } from "./site-cords-map";

class SiteCordsMapContainer extends React.Component {
  constructor(props) {
    super(props);
    this.mapContainerRef = React.createRef();
  }

  onMapLoadCb = (currentSite) => {
    if (currentSite?.subSites[0]) {
      this.props.setMapSelectedSubsite(currentSite.subSites[0]);
    }
  };

  goToSubsite = (map, mapSelectedSubSite) => {
    if (map?.renderMap && mapSelectedSubSite) {
      map.goToSubSite(mapSelectedSubSite);
    }
  };

  componentDidMount() {
    this._map = new SiteCordsMap({
      onMapLoadCb: this.onMapLoadCb,
      container: this.mapContainerRef.current,
    });
  }

  componentWillUnmount() {
    if (this._map) {
      this._map.cleanUp();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.mapSelectedSubSite !== prevProps.mapSelectedSubSite) {
      this.goToSubsite(this._map, this.props.mapSelectedSubSite);
    }

    if (this._map?.renderMap) {
      if (
        this.props.currentSite !== prevProps.currentSite ||
        this.props.currentSubSite !== prevProps.currentSubSite ||
        this.props.showMapInfoWidget !== prevProps.showMapInfoWidget
      ) {
        this._map.renderMap(
          this.props.currentSite,
          this.props.currentSubSite,
          this.props.showMapInfoWidget
        );
      }
    }
  }

  render() {
    return (
      <div
        ref={this.mapContainerRef}
        style={{
          width: "100%",
          height: "100%",
        }}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setMapSelectedSubsite: (subSite) =>
    dispatch({
      type: SITES_REDUCER_TYPES.SET_MAP_SELECTED_SUBSITE,
      payload: subSite,
    }),
});

export default connect(null, mapDispatchToProps)(SiteCordsMapContainer);
