import React from "react";

export const PrivacyOptOutContent = () => {
  return (
    <div>
      <h4>By opting out, you will not be able to use a Blackline, ISC device and therefore:</h4>
      <ul>
        <li>We will be unable to protect your Safety at the highest level.</li>
        <li>Your permissions on site might be restricted.</li>
        <li>You will not be able receive Emergency Alerts as quickly.</li>
        <li>You will not be able to immediately know about the presence of hazardous gases.</li>
        <li>
          You will not be able to seek help as easily (SOS). This might be critical, especially for
          higher risks jobs such as Lone worker, work at height, confined space work.
        </li>
        <li>You may not be aware of PPE that may be required for your job.</li>
        <li>In the event of an incident, the response time of Emergency team may be longer.</li>
        <li>You may not be aware of zone restrictions.</li>
        <li>You will not be able to easily contact your team or a supervisor.</li>
      </ul>
    </div>
  );
};
