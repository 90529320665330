import React from "react";
import { Dialog, DialogTitle, Button, Box, Grid, FormHelperText } from "@material-ui/core";
import { useDialogStyles } from "../../../../../commons/dialogs/use-dialog-styles";
import { useDispatch } from "react-redux";
import { SITES_REDUCER_TYPES, SitesReducerActions } from "../../../../../reducers";
import { useSubSiteDialogStyles } from "../use-subsite-dialog-styles";
import { CustomTextInput, CustomInputLabel } from "../../custom-form-components";


const closeDialog = (dispatch, isDefaultSiteForm) => {
  dispatch({
    type: SITES_REDUCER_TYPES.SET_SHOW_MAP_INFO_WIDGET,
    payload: false,
  });

  dispatch({
    type: SITES_REDUCER_TYPES.RESET_CURRENT_SUBSITE,
    payload: null,
  });

  dispatch({
    type: SITES_REDUCER_TYPES.NEW_SITE_FORM_VISIBLE,
    payload: false,
  });

  dispatch({
    type: SITES_REDUCER_TYPES.SET_SHOW_ADD_NEW_SITE_BTTN,
    payload: true,
  });

  if (!isDefaultSiteForm) {
    dispatch({
      type: SITES_REDUCER_TYPES.SET_MAP_ENABLE_DOUBLE_CLICK,
      payload: false,
    });
  }
};



export const CreateSubSiteDialog = ({
  show,
  rootContainer,
  currentSubSite,
  currentSite,
  id,
  isDefaultSiteForm,
}) => {
  const dispatch = useDispatch();
  const dialogStyles = useDialogStyles();
  const classes = useSubSiteDialogStyles();

  const createSite = (event) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(SitesReducerActions.saveNewSubsite());
  };



  let isSaveBttnDisabled = true;
  let errorNameVisible = false;

  if (
    currentSubSite &&
    currentSubSite.name &&
    currentSubSite.latitude &&
    currentSubSite.longitude &&
    currentSubSite.zoom > 2
  ) {
    isSaveBttnDisabled = false;
  }

  if (
    currentSite &&
    currentSite.subSites &&
    currentSite.subSites.length &&
    currentSubSite &&
    currentSubSite.name
  ) {
    currentSite.subSites.forEach((site) => {
      if (site.name.toLowerCase() === currentSubSite.name.toLowerCase()) {
        errorNameVisible = true;
        isSaveBttnDisabled = true;
      }
    });
  }

  const mapInputesDisabled =
    currentSubSite === null || !currentSubSite.latitude || !currentSubSite.longitude;

  return (
    <Dialog
      open={show}
      PaperProps={{
        className: `${dialogStyles.dialogPaper} ${classes.dialog}`,
        style: {
          width: "100%",
          maxWidth: "fit-content",
        },
      }}
      container={rootContainer}
      data-testid="edit-sub-site-dialog"
    >
      <Box className={dialogStyles.dialogContentWrapper}>
        <DialogTitle className={dialogStyles.dialogTitle} disableTypography>
          <span>Add additional site markers</span>
        </DialogTitle>
        <form autoComplete="off" onSubmit={createSite}>
          <Grid container>
            <Grid item xs={12}>
              <CustomInputLabel>
                {isDefaultSiteForm ? "Default Site Area Name" : "Name of Site Area"}
              </CustomInputLabel>
              <CustomTextInput
                fullWidth
                type="text"
                value={currentSubSite?.name || ""}
                onChange={(event) => {
                  dispatch({
                    type: SITES_REDUCER_TYPES.SET_CURRENT_SUBSITE,
                    payload: {
                      ...currentSubSite,
                      id,
                      name: event.target.value,
                    },
                  });
                }}
                inputProps={{
                  "data-testid": "name-input",
                }}
              />
              {errorNameVisible && (
                <FormHelperText data-testid="error-message">
                  Site Name Should Be Unique
                </FormHelperText>
              )}
            </Grid>
          </Grid>
          <hr className={classes.hr} />
          <Grid container spacing={4}>
            <Grid item sm={12} className={classes.actionDescription}>
              Please double click on the map to find the location
            </Grid>
            <Grid item sm={12} md={6}>
              <CustomInputLabel>Lat.</CustomInputLabel>
              <CustomTextInput
                disabled={mapInputesDisabled}
                onChange={(event) => {
                  dispatch({
                    type: SITES_REDUCER_TYPES.SET_CURRENT_SUBSITE,
                    payload: {
                      ...currentSubSite,
                      id,
                      latitude: parseFloat(event.target.value, 10),
                    },
                  });
                }}
                fullWidth
                type="number"
                value={currentSubSite?.latitude || ""}
                key="latitude_text_field"
                inputProps={{
                  "data-testid": "latitude-input",
                }}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <CustomInputLabel>Long.</CustomInputLabel>
              <CustomTextInput
                disabled={mapInputesDisabled}
                onChange={(event) => {
                  dispatch({
                    type: SITES_REDUCER_TYPES.SET_CURRENT_SUBSITE,
                    payload: {
                      ...currentSubSite,
                      id,
                      longitude: parseFloat(event.target.value, 10),
                    },
                  });
                }}
                fullWidth
                type="number"
                value={currentSubSite?.longitude || ""}
                key="longitude_text_field"
                inputProps={{
                  "data-testid": "longitude-input",
                }}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <CustomInputLabel>Zoom Level</CustomInputLabel>
              <CustomTextInput
                type="number"
                disabled={mapInputesDisabled}
                value={currentSubSite?.zoom || ""}
                onChange={(event) => {
                  dispatch({
                    type: SITES_REDUCER_TYPES.SET_CURRENT_SUBSITE,
                    payload: {
                      ...currentSubSite,
                      id,
                      zoom: parseInt(event.target.value, 10),
                    },
                  });
                }}
                fullWidth
                key="zoom_text_field"
                inputProps={{
                  "data-testid": "zoom-input",
                }}
              />
            </Grid>
          </Grid>

          <Grid container className={classes.inputMargin}>
            <Grid item xs={12} className={dialogStyles.actionsBttnWrapper}>
              <Button
                variant="outlined"
                disabled={mapInputesDisabled && isDefaultSiteForm}
                onClick={()=>{closeDialog(dispatch,isDefaultSiteForm)}}
                data-testid="cancel-bttn"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={isSaveBttnDisabled}
                variant="contained"
                color="primary"
                disableElevation={true}
                data-testid="save-bttn"
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Dialog>
  );
};
