import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Paper,
  Table,
  TableFooter,
  TableBody,
  TableContainer,
  TablePagination,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import {
  useCustomTableHook,
  useCustomTableStyles,
  EnhancedTableToolbar,
} from "../../../../../commons";
import { ZONE_REDUCER_TYPES } from "../../../../../reducers";
import { Row } from "./row";

const headCells = [
  {
    id: "name",
    sortBy: "name",
    label: "Zone Name",
    width: "15%",
    content: (row) => row.name,
  },
  {
    id: "activeUsers",
    sortBy: "headcount",
    label: "Active Users",
    width: "5%",
    content: (row) => row.headcount,
  },
  {
    id: "alerts",
    sortBy: "alerts",
    label: "Alerts",
    width: "5%",
    content: (row) => row.alerts.length,
  },
  {
    id: "categories",
    sortBy: "categories",
    label: "Categories",
    width: "25%",
    content: (row) => row.categories,
  },
  {
    id: "createdTimestamp",
    sortBy: "createdTimestamp",
    label: "Created on",
    width: "10%",
    content: (row) => row.createdTimestamp,
  },
];

export const ActiveZonesSmallTable = (props) => {
  const theme = useTheme();
  const tableStyles = useCustomTableStyles();
  const dispatch = useDispatch();
  const selectedZone = useSelector((state) => state.zonesScreen.selectedZone);
  const searchVal = useSelector((state) => state.zonesScreen.search);
  const isTablet = useMediaQuery(theme.breakpoints.down("sm"));
  const { data, isLargeMapView, onTableRowClickCb, onTableExpandBttnCb, showFilterPanel, selectZoneAndEdit, onDeleteZoneCb } = props;

  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    setPage,
    getComparator,
    stableSortZone,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useCustomTableHook({
    data: data,
    defaultSortBy: "name",
    selectedZone: selectedZone,
    rowsPerPage: isTablet ? 3 : 10,
    isZoneRoute: true,
  });

  const checkIfRowSelected = (id) => selectedZone && selectedZone.id === id;

  if (isLargeMapView) {
    headCells.forEach(cellItem => {
      if (cellItem.sortBy === "name" || cellItem.sortBy === orderBy) {
        cellItem.isVisibleInRow = true;
      } else {
        cellItem.isVisibleInRow = false;
      }
      return cellItem;
    });
  }

  return (
    <Paper>
      <EnhancedTableToolbar
        toShow={true}
        toFade={showFilterPanel}
        headCells={headCells}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        onChangeSearch={(event) => {
          setPage(0);
          dispatch({
            type: ZONE_REDUCER_TYPES.SET_ZONES_SEARCH_VALUE,
            payload: event.target.value,
          });
        }}
        searchLabel={"Zone Name"}
        searchVal={searchVal}
      />

      <TableContainer style={{ maxHeight: isTablet ? "24vh" : "59.2vh" }}>
        <Table aria-labelledby="tableTitle" aria-label="Current Zones Table Small" stickyHeader>
          <TableBody>
            {stableSortZone(data, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((rowData) => {
                const isItemSelected = checkIfRowSelected(rowData.id);
                return (
                  <Row
                    key={rowData.id}
                    orderBy={orderBy}
                    isLargeMapView={isLargeMapView}
                    rowData={rowData}
                    onTableRowClickCb={onTableRowClickCb}
                    onTableExpandBttnCb={onTableExpandBttnCb}
                    isItemSelected={isItemSelected}
                    selectZoneAndEdit={selectZoneAndEdit}
                    onDeleteZoneCb={onDeleteZoneCb}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TableFooter className={tableStyles.tabelFooterRoot} component="div">
        <div></div>
        <TablePagination
          component="div"
          classes={{
            root: tableStyles.tablePaginationRoot,
          }}
          rowsPerPageOptions={[]}
          labelRowsPerPage=""
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableFooter>
    </Paper>
  );
};
