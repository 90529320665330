import React, {useContext} from "react";
import { Route, Redirect } from "react-router-dom";
import { ScopeContext } from "../../commons";

export const PrivateRoute = (props) => {
  const { component, isLoggedIn, auth, ...otherProps } = props;
  const {namedRoutes} = useContext(ScopeContext);

  return auth.isLoggedIn ? (
    <Route exact component={component} render={undefined} {...otherProps} />
  ) : (
    <Redirect to={{ pathname: namedRoutes.root }} />
  );
};
