import React, { useState, useContext } from "react";
import { Paper, makeStyles, MenuItem } from "@material-ui/core";
import {
  PersonalInfoForm,
  SiteDeviceForm,
  UserCategoryPermissionsForm,
  UserDataManagementForm,
} from "../../../forms";
import { FONTS } from "../../../../../utils";
import { TabPanel, ScopeContext } from "../../../../../commons";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SITES_REDUCER_TYPES } from "../../../../../reducers";

const useStyles = makeStyles((theme) => ({
  menuItem: {
    width: "100%",
    justifyContent: "center",
    borderBottom: "3px solid #464659",
    ...FONTS.Button1_Bold_14,
    height: "50px",
  },
  menuItemActive: {
    width: "100%",
    justifyContent: "center",
    borderBottom: `3px solid ${theme.palette.primary.main}`,
    ...FONTS.Button1_Bold_14,
    height: "50px",
  },
  label: {
    display: "flex",
    ...FONTS.Button1_Bold_14,
  },
  contentContainer: {
    padding: "24px",
  },
  paper: {
    background: theme.palette.base.elevation_1,
  },
}));

export const SiteConfigForms = (props) => {
  const classes = useStyles();
  const { currentSite } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [active, setActive] = useState(0);
  const {namedRoutes} = useContext(ScopeContext);

  const navigateToSites = () => {
    history.push(namedRoutes.sites);
    dispatch({ type: SITES_REDUCER_TYPES.SET_PAGE_INDEX, payload: 0 });
  };

  return (
    <Paper className={classes.paper} style={{ height: "100%" }}>
      <div className={classes.label}>
        <MenuItem
          disableRipple
          onClick={() => setActive(0)}
          className={active === 0 ? classes.menuItemActive : classes.menuItem}
        >
          DATA/PRIVACY
        </MenuItem>

        <MenuItem
          disableRipple
          onClick={() => setActive(1)}
          className={active === 1 ? classes.menuItemActive : classes.menuItem}
        >
          USER DATA MGMT
        </MenuItem>

        <MenuItem
          disableRipple
          onClick={() => {
            setActive(2);
          }}
          className={active === 2 ? classes.menuItemActive : classes.menuItem}
        >
          USER CATEGORY / PERMISSIONS
        </MenuItem>

        <MenuItem
          disableRipple
          onClick={() => {
            setActive(3);
          }}
          className={active === 3 ? classes.menuItemActive : classes.menuItem}
        >
          DEVICES / ALERTS
        </MenuItem>
      </div>
      <div className={classes.contentContainer}>
        <TabPanel value={active} index={0}>
          <PersonalInfoForm
            currentSite={currentSite}
            navigateToSites={navigateToSites}
            setActive={setActive}
          />
        </TabPanel>

        <TabPanel value={active} index={1}>
          <UserDataManagementForm
            currentSite={currentSite}
            navigateToSites={navigateToSites}
            setActive={setActive}
          />
        </TabPanel>

        <TabPanel value={active} index={2}>
          <UserCategoryPermissionsForm
            currentSite={currentSite}
            navigateToSites={navigateToSites}
            setActive={setActive}
          />
        </TabPanel>

        <TabPanel value={active} index={3}>
          <SiteDeviceForm
            currentSite={currentSite}
            navigateToSites={navigateToSites}
            setActive={setActive}
          />
        </TabPanel>
      </div>
    </Paper>
  );
};
