import React from "react";
import { PropTypes } from "prop-types";
import { DeviceBatteryIcon } from "../device-battery-icon/index";

export const DeviceIdWithBatteryIcon = (props) => {
  const { manufacturerId, level, isOnline } = props;
  if (manufacturerId && level >= 0 && level <= 100) {
    return (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <DeviceBatteryIcon
            data-testid={`device-battery-icon-${manufacturerId}`}
            manufacturerId={manufacturerId}
            level={level}
            isOnline={isOnline}
          />
          <span
            style={{
              paddingLeft: "10px",
            }}
          >
            {manufacturerId}
          </span>
        </div>
      </>
    );
  }
  return null;
};

DeviceIdWithBatteryIcon.propTypes = {
  manufacturerId: PropTypes.string,
  level: PropTypes.number,
  isOnline: PropTypes.bool,
};
