import React from "react";
import {
  FormControl,
  FormControlLabel,
  Checkbox,
  Paper,
  Button,
  Box,
  makeStyles,
} from "@material-ui/core";
import { CategoryChip } from "../../../commons/category-chip";
import { useDispatch, useSelector } from "react-redux";
import { ZONE_REDUCER_TYPES } from "../../../reducers";
import { FONTS } from "../../../utils";

const filtersCheckBox = [
  {
    label: "Zones With Active Alerts",
    filterBy: "alerts",
    value: false,
    comparatorFn: (item) => item.alerts.length > 0,
  },
  {
    label: "Zones With Active Users",
    filterBy: "headcount",
    value: false,
    comparatorFn: (item) => item.headcount > 0,
  },
  {
    label: "Temporary Zones",
    filterBy: "isTemporary",
    values: false,
    comparatorFn: (item) => item.isTemporary === true,
  },
];

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    position: "absolute",
    top: "52px",
    padding: "15px",
    background: "#2B2B36",
    zIndex: theme.zIndex.drawer + 1,
  },
  filterToolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
  },
  filterToolbarTitle: {
    ...FONTS.Label1_Med_12,
  },
  bttnLabel: {
    ...FONTS.Label1_Med_12,
  },
  checkboxWrapperTitle: {
    ...FONTS.Label1_Med_12,
  },
  checkboxLabel: {
    ...FONTS.Label2_Bold_12,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  chipsWrapper: {
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  checkboxWrapper: {
    marginTop: "68px",
    display: "flex",
    flexDirection: "column",
  },
}));

export const FilterPanel = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const activeAndAppliedToZone = useSelector(
    (state) => state.zonesScreen.zoneCategories.activeAndAppliedToZone
  );

  // Panel filter values
  const selectedZoneCategories = useSelector(
    (state) => state.zonesScreen.filterPanel.selectedZoneCategories
  );
  const selectedZoneTypes = useSelector((state) => state.zonesScreen.filterPanel.selectedZoneTypes);

  const showFilterPanel = useSelector((state) => state.zonesScreen.showFilterPanel);

  function upsert(array, item, key) {
    const i = array.findIndex((_item) => _item[key] === item[key]);
    if (i > -1) {
      array.splice(i, 1);
    } else {
      array.push(item);
    }
    return array;
  }

  function handleChipClick(id) {
    const existingCategories = [...selectedZoneCategories];
    const i = existingCategories.findIndex((_id) => _id === id);

    if (i > -1) {
      existingCategories.splice(i, 1);
    } else {
      existingCategories.push(id);
    }

    dispatch({
      type: ZONE_REDUCER_TYPES.SET_FILTER_PANEL_ZONE_CATEGORIES,
      payload: {
        selectedZoneCategories: existingCategories,
        selectedZoneTypes: selectedZoneTypes,
      },
    });
  }

  function handleCheckboxClick(key, comparatorFn, value) {
    let arr = [...selectedZoneTypes];
    arr = upsert(arr, { type: "checkbox", key, value, comparatorFn }, "key");
    dispatch({
      type: ZONE_REDUCER_TYPES.SET_FILTER_PANEL_ZONE_TYPE,
      payload: {
        selectedZoneCategories: selectedZoneCategories,
        selectedZoneTypes: arr,
      },
    });
  }

  function renderChips(allZoneCategories) {
    return allZoneCategories.map((category) => {
      return (
        <CategoryChip
          data={category}
          isSelected={selectedZoneCategories.includes(category.id)}
          handleClick={handleChipClick}
          isFilterChip={true}
        />
      );
    });
  }

  function renderBox() {
    return filtersCheckBox.map((filterObj) => {
      let isSelected = false;
      selectedZoneTypes.forEach((obj) => {
        if (obj.key === filterObj.filterBy) {
          isSelected = true;
        }
      });
      return (
        <FormControl component="fieldset" className={classes.formControl}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isSelected}
                onChange={(event) =>
                  handleCheckboxClick(
                    filterObj.filterBy,
                    filterObj.comparatorFn,
                    event.target.checked
                  )
                }
                color="primary"
              />
            }
            label={filterObj.label}
            classes={{
              label: classes.checkboxLabel,
            }}
          />
        </FormControl>
      );
    });
  }

  if (showFilterPanel) {
    return (
      <Paper className={classes.paper}>
        <Box className={classes.filterToolbar}>
          <span className={classes.filterToolbarTitle}>Filter Form By: </span>
          <Button
            color="primary"
            onClick={() => {
              dispatch({
                type: ZONE_REDUCER_TYPES.SET_FILTER_PANEL_ZONE_CATEGORIES,
                payload: {
                  selectedZoneCategories: [],
                  selectedZoneTypes: [],
                },
              });
            }}
          >
            <span className={classes.bttnLabel}>Clear All</span>
          </Button>
        </Box>
        <Box className={classes.chipsWrapper}>{renderChips(activeAndAppliedToZone)}</Box>
        <Box className={classes.checkboxWrapper}>
          <Box className={classes.checkboxWrapperTitle}>Show</Box>
          {renderBox()}
        </Box>
      </Paper>
    );
  }

  return null;
};
