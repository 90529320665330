import { MapUtilsV2 } from "../../../../../commons/map";

export const pointerMoveEventHandler = (
  mapView,
  sketchVM,
  sketch,
  state,
  currentGraphic,
  store,
  GRAPHICS_LAYERS_ID,
  viewAlerts = true
) => {
  mapView.on("pointer-move", (event) => {
    event.stopPropagation();
    if (!sketchVM || sketchVM.state === "active") {
      return;
    }

    if (state.shapeDrawInProgress) {
      sketch.update(currentGraphic);
      return;
    }
    mapView.hitTest(event).then((response) => {
      if (response.results.length) {
        /**User Devices Popup */
        const graphics = response.results.filter(
          (result) => (result.graphic.layer!=null && result.graphic.layer.id === GRAPHICS_LAYERS_ID.USER_DEVICES)
        );
        /***User Devices History popup */
        const graphicsUserHistory = response.results.filter(
          (result) => (result.graphic.layer!=null && result.graphic.layer.id === GRAPHICS_LAYERS_ID.USERS)
        );
        /***Media Assets popup */
        const graphicsMediaAssets = response.results.filter(
          (result) => (result.graphic.layer!=null && result.graphic.layer.id === GRAPHICS_LAYERS_ID.MEDIA_ASSETS)
        );
        /**User History */
        MapViewPopUp(graphics, mapView, store, viewAlerts, graphicsUserHistory, graphicsMediaAssets);
      } 
    });
  });
};
function MapViewPopUp(graphics, mapView, store, viewAlerts, graphicsUserHistory, graphicsMediaAssets) {
  if (graphics.length) {
    mapView.popup.open({});
    if (mapView.popup.visible === false) {
      mapView.popup.visible = true;
    }
      MapUtilsV2.renderMapTooltipMarkup(graphics[0], store, mapView, viewAlerts);
  } else if (graphicsUserHistory.length) {
    mapView.popup.open({});
    if (mapView.popup.visible === false) {
      mapView.popup.visible = true;
    }
    MapUtilsV2.renderMapTooltipMarkupUserHistory(graphicsUserHistory[0], store, mapView, viewAlerts);

  } else if (graphicsMediaAssets.length) {
    mapView.popup.open({});
    if (mapView.popup.visible === false) {
      mapView.popup.visible = true;
    }
    MapUtilsV2.renderMapTooltipMarkupMediaAssets(graphicsMediaAssets[0], store, mapView);

  }
}

