import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  MenuItem,
  Select,
  Toolbar,
  Divider,
  TextField,
  InputAdornment,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import { FilterPanelBttn } from "../../routes/zones/filter-panel";
import { FONTS } from "../../utils";
import { ICONS } from "../../commons";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    background: theme.palette.base.elevation_1,
  },
  toolbarTitle: {
    marginRight: "10px",
    ...FONTS.Label2_Bold_12,
  },
  menuItemRoot: {
    ...FONTS.Label2_Bold_12,
  },
  formControl: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  select: {
    ...FONTS.Label2_Bold_12,
    color: theme.palette.primary.main,
    "&:before": {
      borderBottom: "none !important",
    },
  },
  fade: {
    opacity: 0.5,
  },
}));

function RenderSearchField(props) {
  const searchInputRef = React.useRef();
  const { isSearchOpen, searchVal, setIsSearchOpen, searchLabel, onChangeSearch, classes } = props;

  function handleClick(e) {
    e.stopPropagation();
    searchInputRef.current.focus();
  }

  if (isSearchOpen || (typeof searchVal !== "undefined" && searchVal.length > 0)) {
    return (
      <TextField
        onClick={handleClick}
        autoFocus={true}
        inputRef={searchInputRef}
        id="input-with-icon-textfield"
        placeholder={searchLabel || "Search..."}
        onChange={onChangeSearch}
        autoComplete="off"
        {...(typeof searchVal !== "undefined" ? { value: searchVal } : {})}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <ICONS.Search />
            </InputAdornment>
          ),
        }}
      />
    );
  } else {
    return (
      <IconButton
        aria-label="delete"
        className={classes.margin}
        size="medium"
        onClick={() => {
          setIsSearchOpen(true);
        }}
      >
        <ICONS.Search fontSize="inherit" />
      </IconButton>
    );
  }
}

export const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const {
    toShow,
    headCells,
    orderBy,
    onRequestSort,
    searchLabel,
    onChangeSearch,
    searchVal,
  } = props;

  const handleChange = (event) => {
    if (event.target.value) {
      onRequestSort(event, event.target.value, event.target.value === "name" ? "asc" : "desc");
    }
  };

  if (toShow) {
    return (
      <>
        <Toolbar className={`${classes.root} ${props.toFade ? classes.fade : ""}`}>
          <FormControl className={classes.formControl}>
            <span className={classes.toolbarTitle}>Sort By:</span>
            <Select
              className={classes.select}
              value={orderBy}
              onChange={handleChange}
              inputProps={{
                "data-testid": "enhanced-toolbar-select-sort",
              }}
            >
              {headCells.map((cell, index) => (
                <MenuItem className={classes.menuItemRoot} key={index} value={cell.sortBy}>
                  {cell.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div style={{ display: "flex", alignItems: "center" }}>
            <FilterPanelBttn />
            {RenderSearchField({
              isSearchOpen,
              searchVal,
              setIsSearchOpen,
              searchLabel,
              onChangeSearch,
              classes,
            })}
          </div>
        </Toolbar>
        <Divider />
      </>
    );
  }

  return null;
};

EnhancedTableToolbar.propTypes = {
  headCells: PropTypes.array.isRequired,
  orderBy: PropTypes.string.isRequired,
};
