import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  CardMedia,
  makeStyles,
  IconButton,
  Tooltip,
  CardActionArea
} from "@material-ui/core";

import { ICONS, UserPermissionContext, useCommonStyles, ScopeContext } from "../../../../commons";
import {
  GLOBAL_REDUCER_TYPES,
  SITES_REDUCER_TYPES,
  AuthReducerActions
} from "../../../../reducers";
import { FONTS } from "../../../../utils";
import { useStaticMapApi } from "../../../../hooks";
import worldMapPlaceholder from "../../screens/site-edit-screen/components/worldMap.png";

const useStyles = makeStyles((theme) => ({
  card: {
    flexBasis: "328px",
    background: "transparent",
    [theme.breakpoints.down("sm")]: {
      flexBasis: "35%",
    },
  },
  media: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '@media (max-height: 765px)': {
      height: "180px",
    },
    '@media (max-height: 535px)': {
      height: "100px",
    }
  },
  previewImage: {
    color: "#2b2b36",
    opacity: "0.8",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  siteName: {
    ...FONTS.ShellBold_16,
    whiteSpace: "nowrap",
    overflow: "hidden",
    lineHeight: 1,
    textOverflow: "ellipsis",
    '@media (max-width: 1300px)': {
      fontSize: "14px",
    },
  },
  icon: {
    ...FONTS.H5_ShellBold_18,
    '@media (max-width: 1300px)': {
      fontSize: "16px",
    }
  },
  mediaContainer: {
    position: "relative",
    textAlign: "center",
    boxShadow: "0px 1px 10px rgb(0 0 0 / 25%)",
    borderRadius: "6px",
    background: "white",
    overflow: "hidden",
    width: "100%",
    height: "218px",
    '@media (max-height: 765px)': {
      height: "180px",
    },
    '@media (max-height: 535px)': {
      height: "100px",
    },
    "& .MuiButton-label": {
      display: "block",
      width: "100%",
      height: "100%"
    },
  },
}));

function renderSiteCardActionArea({
  site,
  handleSiteEditBttn,
  renderDeleteIcon,
  classes,
  Permissions,
  dispatch,
}) {
  return (
    <div style={{ 
      display: "grid", 
      gridTemplateColumns: "1fr min-content", 
      paddingTop: "5px",
      alignItems: "center"
    }}>
      <Tooltip title={site.name} placement="bottom-start">
        <div className={classes.siteName}>
          {site.name} {site.active ? null : "(Deactivated)"}
        </div>
      </Tooltip>
      {site.active ? (
        <div style={{ display: "flex", gap: "1.5rem" }}>
          {Permissions.Manage_Sites ? (
            <Tooltip title="Edit">
              <IconButton
                size="small"
                variant="outlined"
                onClick={handleSiteEditBttn}
              >
                <ICONS.EditCustomIcon 
                  color="primary"
                  className={classes.icon}
                />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="View site settings">
              <IconButton
                size="small"
                variant="outlined"
                onClick={handleSiteEditBttn}
              >
                <ICONS.EyeCustomIcon
                  color="primary"
                  className={classes.icon}
                />
              </IconButton>
            </Tooltip>
          )

          }

          {Permissions.Manage_Sites && renderDeleteIcon({ site, dispatch, classes })}
        </div>
      ) : (
        <div>{Permissions.Manage_Sites && renderDeleteIcon({ site, dispatch, classes })}</div>
      )}
    </div>
  );
}

function renderDeleteIcon({ site, dispatch, classes }) {
  if (site.active) {
    return (
      <Tooltip title="Deactivate">
        <IconButton
          size="small"
          variant="outlined"
          onClick={() =>
            dispatch({
              type: SITES_REDUCER_TYPES.SET_DEACTIVATE_SITE_DIALOG,
              payload: {
                showInfoDialog: true,
                site: site,
                showConfirmDialog: false,
              },
            })
          }
        >
          <ICONS.DeleteCustomIcon 
            color="primary"
            className={classes.icon}
          />
        </IconButton>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title="Activate">
        <IconButton
          size="small"
          variant="outlined"
          onClick={() =>
            dispatch({
              type: SITES_REDUCER_TYPES.SET_REACTIVATE_SITE_DIALOG,
              payload: {
                showInfoDialog: true,
                site: site,
                showConfirmDialog: false,
              },
            })
          }
        >
          <ICONS.Restore 
            color="primary"
          />
        </IconButton>
      </Tooltip>
    );
  }
}

export const SiteCard = (props) => {
  const classes = useStyles();
  const commonStyles = useCommonStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [goToZone, setGoToZone] = useState(false);
  const { site } = props;
  const authorizedRoute = useSelector((state) => state.auth?.authorizedRoute);
  const { namedRoutes, jarvisScope } = useContext(ScopeContext);
  const jarvisWorldMap = "/worldMap.png";
  const { Permissions } = useContext(UserPermissionContext);
  const [img] = useStaticMapApi(site.subSites[0] ? site.subSites[0].extent : null, [
    site.subSites.length && JSON.stringify(site.subSites[0].extent),
  ]);
  const worldMap = () => {
    let image;
    if(img){
      image = img;
    }else{
      image = !jarvisScope ? worldMapPlaceholder : jarvisWorldMap;
    }
    return image;
  }

  function updateSiteInfo() {
    dispatch({ type: GLOBAL_REDUCER_TYPES.SET_SITE_ID, payload: site.id });
    dispatch(AuthReducerActions.updateSiteUserInfo());
  }

  async function handleGoToSiteBttn() {
    if(!window.jarvis?.tenant.id) {
      updateSiteInfo();
      setGoToZone(true);
    }
  }

  async function handleSiteEditBttn() {
    history.push(`${namedRoutes.sites}/${site.id}`);
  }

  useEffect(() => {
    if (goToZone) {
      history.push(authorizedRoute);
    }

    return () => {
      setGoToZone(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorizedRoute])

  return (
    <div className={classes.card}>
      <CardActionArea
        className={classes.mediaContainer}
        style={{ pointerEvents: site.active ? "pointer" : "none" }}
        onClick={handleGoToSiteBttn}
        disableRipple
      >
        <CardMedia
          style={{ opacity: site.active ? 1 : 0.3 }}
          className={classes.media}
          image={worldMap()}
          title={site.name}
          component="img"
        />
        {img ? null : <span className={classes.previewImage}>Loading Map Preview Image</span>}
      </CardActionArea>
      {renderSiteCardActionArea({
        site,
        commonStyles,
        handleSiteEditBttn,
        renderDeleteIcon,
        classes,
        Permissions,
        dispatch,
      })}
    </div>
  );
};
