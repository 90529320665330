import { MapUtilsV2 } from "../../../../commons/map";

const addCustomWidgets = (graphicLabelClickCb, _mapView) => {
  const graphicLabel = MapUtilsV2.MapWidgetTmpl.getGraphicLabelActionBttnToMapView(
    graphicLabelClickCb
  );
  _mapView.ui.add(graphicLabel, "manual");
};

const hideAllActionsBttns = () => {
  const zoneUpdateBttn = document.getElementById("graphic-update-bttn");
  const zoneSaveBttn = document.getElementById("graphic-save-bttn");
  const zoneCancelBttn = document.getElementById("graphic-cancel-bttn");

  if (zoneUpdateBttn) {
    zoneUpdateBttn.style.display = "none";
  }

  if (zoneSaveBttn) {
    zoneSaveBttn.style.display = "none";
  }

  if (zoneCancelBttn) {
    zoneCancelBttn.style.display = "none";
  }
};

export const AlertsMapUtils = {
  addCustomWidgets,
  hideAllActionsBttns,
};
