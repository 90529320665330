import React, { useEffect, useState } from "react";
import { Box, Dialog, makeStyles, Button } from "@material-ui/core";
import { FONTS } from "../../../utils";
import { useSelector } from "react-redux";
import { ParagraphJSON } from "../../paragraphs";

const useStyles = makeStyles((theme) => ({
    dialogContainer: {
        width: "607px",
        backgroundColor: theme.palette.base.elevation_1,
    },
    title: {
        textAlign: "center",
        paddingBottom: "20px",
        ...FONTS.H5_ShellBold_18,
    },
    body: {
        textAlign: "center",
        ...FONTS.H5_ShellBold_16,
        marginBottom: "32px"
    },
    buttonContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignIitems: "center",
    },
    customizeButton: {
        padding: "10px 16px",
        width: "103px",
        heigh: "44px",
        backgroundColor: "Transparent",
        backgroundRepeat: "no-repeat",
        border: "none",
        cursor: "pointer",
        overflow: "hidden",
        outline: "none",
        color: "white",
        letterSpacing: "0.02em",
        fontWeight: "bold",
        fontSize: "12px",
        lineHeight: "120%"
    },
    cookiePolicy: {
        fontWeight: "bold",
        color: "#FDE26A",
        textDecoration: "none"
    }
}));

export const CookiesDialog = () => {
    const cookiesInfo = localStorage.getItem("users_with_accepted_cookies")
    const currentUserId = useSelector((state) => state.auth.sitewiseUser?.sub);
    const [show, setShow] = useState(true);

    function handleAcceptCookies() {
        let newCookiesInfo;

        if (cookiesInfo === null) {
            newCookiesInfo = currentUserId;
        } else {
            newCookiesInfo = cookiesInfo + "," + currentUserId;
        }
        localStorage.setItem("users_with_accepted_cookies", newCookiesInfo);
        setShow(false);
    }

    const classes = useStyles();

    useEffect(() => {
        if (cookiesInfo && cookiesInfo.length) {
            const ids = cookiesInfo.split(",")
            if (ids.includes(currentUserId)) {
                setShow(false);
            }
        } else {
            setShow(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show])

    if (!show) {
        return null;
    }

    return (
        <Dialog open={show} PaperProps={{ className: classes.dialogContainer }}>
            <Box style={{ padding: "24px 24px 24px 24px" }}>
                <div className={classes.title}>About Cookies on this site</div>
                <div className={classes.body} dangerouslySetInnerHTML={{ __html: ParagraphJSON.cookiesDialogText }}>
                </div>
                <div className={classes.buttonContainer} onClick={handleAcceptCookies}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleAcceptCookies}
                    >
                        Accept
                    </Button>
                </div>
            </Box>
        </Dialog>
    );
};
