import React, { useContext } from "react";
import { IconButton, Tooltip, makeStyles } from "@material-ui/core";
import { ICONS, UserPermissionContext, useCommonStyles } from "../../../../../../commons";
import { SITES_REDUCER_TYPES, SitesReducerActions } from "../../../../../../reducers";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  rowIcon: {
    fontSize: "1.2rem",
  },
  cuttedColumn: {
    minWidth: "70px",
  },
  nameColumn: {
    fontWeight: "700",
  },
}));

export const SiteDetail = (props) => {
  const { id, subSite, goToSubSite, mapSelectedSubSite, showMapInfoWidget } = props;

  const dispatch = useDispatch();
  const { Permissions } = useContext(UserPermissionContext);
  const classes = useStyles();
  const commonStyles = useCommonStyles();

  const editButtonDisable = showMapInfoWidget || !Permissions.Manage_Sites;

  const getEyeIcon = () => {
    if (mapSelectedSubSite && mapSelectedSubSite.name === subSite.name) {
      return <ICONS.EyeCustomIcon className={classes.rowIcon} />;
    } else {
      return <ICONS.EyeCustomRegularIcon className={classes.rowIcon} />;
    }
  };

  return (
    <>
      <div className={`${commonStyles.ellipses} ${classes.cuttedColumn} ${classes.nameColumn}`}>
        <Tooltip title={subSite.name}>
          <span>{subSite.name}</span>
        </Tooltip>
      </div>
      <div className={`${commonStyles.ellipses} ${classes.cuttedColumn}`}>
        <Tooltip title={`${subSite.latitude}, ${subSite.longitude}`}>
          <span>
            {subSite.latitude}, {subSite.longitude}
          </span>
        </Tooltip>
      </div>
      <div>
        <Tooltip title="View on Map">
          <IconButton
            aria-label="View On Map"
            size="medium"
            color="primary"
            onClick={() =>
              dispatch({
                type: SITES_REDUCER_TYPES.SET_MAP_SELECTED_SUBSITE,
                payload: subSite,
              })
            }
          >
            {getEyeIcon()}
          </IconButton>
        </Tooltip>
        <Tooltip title="Edit">
          <span>
            <IconButton
              disabled={editButtonDisable}
              {...(!editButtonDisable && {
                color: "primary",
              })}
              aria-label="Edit"
              size="medium"
              onClick={() => {
                dispatch(SitesReducerActions.showEditSubSiteDialog(true));
                goToSubSite({ ...subSite, id: id });
                dispatch({
                  type: SITES_REDUCER_TYPES.SET_MAP_ENABLE_DOUBLE_CLICK,
                  payload: true,
                });
                dispatch({
                  type: SITES_REDUCER_TYPES.SET_SHOW_ADD_NEW_SITE_BTTN,
                  payload: false,
                });
                dispatch({
                  type: SITES_REDUCER_TYPES.SET_SHOW_MAP_INFO_WIDGET,
                  payload: true,
                });
              }}
            >
              <ICONS.EditCustomIcon className={classes.rowIcon} />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Remove">
          <span>
            <IconButton
              disabled={!Permissions.Manage_Sites}
              {...(Permissions.Manage_Sites && {
                color: "primary",
              })}
              aria-label="Remove"
              size="medium"
              variant="outlined"
              onClick={() =>
                dispatch({
                  type: SITES_REDUCER_TYPES.SET_REMOVE_SUBSITE_DIALOG_DISPLAY,
                  payload: {
                    show: true,
                    id,
                  },
                })
              }
            >
              <ICONS.DeleteCustomRegularIcon className={classes.rowIcon} />
            </IconButton>
          </span>
        </Tooltip>
      </div>
    </>
  );
};
