import React from "react";
import { PropTypes } from "prop-types";
import { DeviceBatteryIcon } from "../device-battery-icon/index";

export const DeviceIdWithBatteryIconTooltip = (props) => {
  const { manufacturerId, level, isOnline } = props;
  if (manufacturerId && level >= 0 && level <= 100) {
    return (
      <>
        <div>#{manufacturerId}</div>
        <div style={{ display: "flex", marginTop: "5px", alignItems: "center" }}>
          <DeviceBatteryIcon manufacturerId={manufacturerId} level={level} isOnline={isOnline} />
          <span
            style={{
              paddingTop: "2%",
              paddingLeft: "10px",
            }}
          >
            {level}%
          </span>
        </div>
      </>
    );
  }
  return null;
};

DeviceIdWithBatteryIconTooltip.propTypes = {
  manufacturerId: PropTypes.string,
  level: PropTypes.number,
  isOnline: PropTypes.bool,
};
