import { CustomError } from "./custom-error";

export class ServerError extends CustomError {
  statusCode = 500;

  constructor() {
    super("Something went wrong at server.");
    Object.setPrototypeOf(this, ServerError.prototype);
  }

  serializeErrors() {
    return [];
  }
}
