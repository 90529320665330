import React from "react";
import { TableCell, TableRow, Tooltip } from "@material-ui/core";
import { useCustomTableStyles } from "../../../../commons";
import { useDispatch } from "react-redux";
import { DEVICES_REDUCER_TYPES } from "../../../../reducers";


function displayDevicesList(event, cell, rowData, dispatch) {
  event.stopPropagation();
  if (cell.dialogDevicesFn) {
    const content = typeof cell.dialogDevicesFn === "function" && cell.dialogDevicesFn(rowData);
  dispatch({type: DEVICES_REDUCER_TYPES.DEVICES_DIALOG_DISPLAY, payload: {allDevicesList:content,show:true}})

  }
}


export const Row = (props) => {
  const tableStyles = useCustomTableStyles();
  const { rowData, headCells, isItemSelected } = props;
  const dispatch = useDispatch();

  const handleTableRowClicked = (event) => {
    event.stopPropagation();
  };

  return (
    <TableRow
      hover
      onClick={(event) => handleTableRowClicked(event)}
      role="checkbox"
      tabIndex={-1}
      selected={isItemSelected}
    >
      {headCells.map((cell, index) => {
                const { showEllipses } = cell;
                const content = cell.content(rowData);
                const toolTipContent = cell.toolTipContent ? cell.toolTipContent(rowData) : null;

                const tableCellCmp = (
          <TableCell
          onClick={(event) => {
            displayDevicesList(event, cell, rowData, dispatch);
          }}
          key={`${cell.id}-${index}`} className={tableStyles.cell}>
            <span>{cell.content(rowData)}</span>
          </TableCell>
                );

                if (showEllipses && content) {
                  return (
                    <Tooltip
                      classes={{
                        tooltip: tableStyles.tooltip,
                      }}
                      title={
                        <div className={tableStyles.tooltipContentWrapper}>
                          <span className={tableStyles.tooltipContent}>{toolTipContent ?? content}</span>
                        </div>
                      }
                      arrow
                      placement="top-start"
                      key={`${rowData.id}-${cell.id}-${index}-tooltip`}
                    >
                      {tableCellCmp}
                    </Tooltip>
                  );
                }
        return tableCellCmp;

      })}

      <TableCell className={tableStyles.cell}></TableCell>
    </TableRow>
  );
};
