import React from "react";
import {
  FormControl,
  TextField,
  Button,
  DialogTitle
} from "@material-ui/core";
import { TabPanel } from "../../../../../commons/tabs";
import { useDialogStyles } from "../../../../../commons";

export const FirstTab = (props) => {
  
  const dialogStyles = useDialogStyles();
  const {
    tabValue,
    handleNextSubmit,
    classes,
    name,
    email,
    setEmail,
    setName,
    setErrorText,
    errorText,
    handleCancelBttnClick
  } = props;
  return (
    <TabPanel value={tabValue} index={0}>
      <form autoComplete="off" onSubmit={handleNextSubmit}>
      <DialogTitle className={dialogStyles.dialogTitle} disableTypography >
    <span>Add New Super Admin</span>
  </DialogTitle>
        <FormControl required variant="filled" className={classes.formControl}>
          <TextField
            required
            id="filled-basic"
            label="Name"
            variant="filled"
            fullWidth={true}
            value={name}
            onChange={(event) => setName(event.target.value)}
            inputProps={{
              maxLength: 30,
            }}           
          />         
        </FormControl>

        <FormControl required variant="filled" className={classes.formControl}>
          <TextField
            required
            type="email"
            id="filled-basic"
            label="Email"
            variant="filled"
            fullWidth={true}
            value={email}
            onChange={(event) => {
              if (errorText) {
                setErrorText("");
              }
              setEmail(event.target.value);
            }}
            helperText={errorText}
            error={errorText}
          />
        
          <FormControl variant="filled" className={classes.formControlActions}>
            <Button color="primary" variant="outlined" onClick={handleCancelBttnClick}>
              Cancel
            </Button>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginLeft: "10px" }}
            >
              Next
            </Button>
          </FormControl>
        </FormControl>
      </form>
    </TabPanel>
  );
};
