import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableFooter,
  TableContainer,
  TablePagination,
  Paper,
} from "@material-ui/core";
import {
  EnhancedTableHead,
  useCustomTableHook,
  useCustomTableStyles,
  useDebounce,
} from "../../../../../commons";
import { Row } from "../row";
import { parseUtcToLocal } from "../../../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { DevicesDialog } from "../../../../../commons/dialogs/devices-dialog";
import { DEVICES_REDUCER_TYPES } from "../../../../../reducers";


const headCellMap = {
  message: "message",
  recipientType: "recipientType",
  displayZone: "displayZone",
  createdTimestamp: "createdTimestamp",
  sentCount: "sentCount",
  width15: "15%",
  width10: "10%",
};

const styles = {
    cursor:'pointer',
    color:'#13bc86',
    fontWeight:'bold',
    fontSize:'22px',
    textDecoration:'underline',
}

const headCells = [
  {
    id: headCellMap.message,
    sortBy: headCellMap.message,
    label: "Message",
    width: "24%",
    content: (row) => row.message,
  },
  {
    id: headCellMap.recipientType,
    sortBy: headCellMap.recipientType,
    label: "Recipients",
    width: headCellMap.width15,
    content: (row) => {
      return row.recipientType;
    },
  },
  {
    id: headCellMap.displayZone,
    sortBy: headCellMap.displayZone,
    label: "Zone",
    width: headCellMap.width10,
    content: (row) => {
      return row.displayZone;
    },
  },
  {
    id: headCellMap.createdTimestamp,
    sortBy: headCellMap.createdTimestamp,
    label: "Date Sent",
    width: headCellMap.width15,
    content: (row) => parseUtcToLocal(row.createdTimestamp),
  },
  {
    id: headCellMap.sentCount,
    sortBy: headCellMap.sentCount,
    label: "Sent to Devices",
    width: headCellMap.width10,
    content: (row) => {
      return(
        <div style={styles}>
          {row.sentCount}
          </div>
      ) 
    },
    toolTipContent: () => "see acknowledge device info",
    showEllipses: true, 
    dialogDevicesFn: (row) => row,
  },
];

export const AlertsSentTable = (props) => {
  const tableClasses = useCustomTableStyles();
  const [tableSearchStr, setTableSearchStr] = useState("");

  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    setPage,
    getComparator,
    stableSort,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useCustomTableHook({
    defaultSortBy: headCells[3].sortBy,
    defaultSortOrder: "desc",
    rowsPerPage: 10,
  });

  const { data } = props;
  const [tableData, setTableData] = useState([]);
  const dispatch = useDispatch();
  const showDialog = useSelector((state) => state.devicesScreen?.showListofDevicesDialog ?? false);

  const alertSentTableSearchDebounced = useDebounce((_nextVal) => {
    setPage(0);
    setTableSearchStr(_nextVal);
  }, 300);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  useEffect(() => {
    let arr = [];

    if (tableSearchStr) {
      arr = data.filter(
        (tData) =>
          tData.message && tData.message.toLowerCase().includes(tableSearchStr.toLowerCase())
      );
    } else {
      arr = data;
    }
    setTableData(arr);
  }, [tableSearchStr, setTableData, data]);

  if (tableData === null) {
    return null;
  }

  return (
    <Paper>
      <TableContainer className={tableClasses.tableContainer}>
        <Table
          aria-labelledby="tableTitle"
          aria-label="Sent Messages Table"
          stickyHeader
          data-testid="sent-messages-table"
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={headCells}
            toShow={true}
            tableWithRowActions={true}
            onChangeSearch={(event) => {
              alertSentTableSearchDebounced(event.target.value);
            }}
            searchLabel={"Message"}
          />
          <TableBody data-testid="sent-messages-table-body">
            {stableSort(tableData, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((rowData) => {
                return <Row key={rowData.id} rowData={rowData} headCells={headCells} />;
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TableFooter className={tableClasses.tabelFooterRoot} component="div">
        <div></div>
        <TablePagination
          classes={{
            root: tableClasses.tablePaginationRoot,
          }}
          component="div"
          rowsPerPageOptions={[]}
          labelRowsPerPage=""
          count={tableData && tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableFooter>
      <DevicesDialog
          show={showDialog}
          onCancel={() => {
            dispatch({type: DEVICES_REDUCER_TYPES.DEVICES_DIALOG_DISPLAY, payload: {allDevicesList:[],show:false}});
          }}
        />
    </Paper>
  );
};
