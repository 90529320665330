import { loadModules } from "esri-loader";
import { MapWidgetTmpl } from "./map-widget-tmpl";
import { store } from "../../../../store";
import { MapUtils } from "./map-utils";
import { pointerMoveEventHandler } from "./util-fns/pointer-move-event-handler";
import {
  ZONE_REDUCER_TYPES,
  ZonesReducerActions,
  GlobalReducerActions,
  UsersReducerActions,
  DevicesReducerActions,
  plotPlanImageLayerJson,
  MESSAGES_REDUCER_TYPES,
} from "../../../../reducers";
import { MapUtilsV2, MAP_ICONS } from "../../../../commons/map";
import { onUserDeviceClick } from "../../../alerts/components/alerts-map/util-fns/pointer-move-event-handler";

const MAP_CONFIG = {
  SKETCH_TOOLS: {
    CIRCLE: "circle",
    RECTANGLE: "rectangle",
    POLYGON: "polygon",
  },
  DEFAULT_ZOOM: 16,
};

export const GRAPHICS_ACTIONS = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  CREATE_FROM_TEMPLATE: "CREATE_FROM_TPL",
  NONE: "NONE",
};

const GRAPHICS_LAYERS_ID = {
  ZONES: "ZONES_GRAPHICS_LAYER",
  LABELS: "ZONES_LABEL_LAYER",
  HEADCOUNT: "ZONES_HEADCOUNT_LAYER",
  USER_DEVICES: "USER_DEVICES_LAYER",
  MAP_VECTOR_TILE: "MAP_VECTOR_TILE",
  USERS: "USERS",
  USER_PATH: "USER_PATH",
  USER_DEVICES_HEAT_MAP: "USER_DEVICES_HEAT_MAP",
  MAP_IMAGE_LAYER: "MAP_IMAGE_LAYER",
  MEDIA_ASSETS:"MEDIA_ASSETS"
};

const simpleMarker = "simple-marker";
const colors = [
  "#6D717A",
  "#878C95",
  "#A2A6B1",
  "#BCC1CC",
  "#D5CBCB",
  "#EDD5CA",
  "#E2B49A",
  "#DB9167",
  "#CC7441",
];

let ArcGISMap,
  MapView,
  GraphicsLayer,
  Sketch,
  SketchViewModel,
  Graphic,
  Polygon,
  Point,
  geometryEngine,
  webMercatorUtils,
  VectorTileLayer,
  EsriConfig,
  Legend,
  Expand,
  FeatureLayer,
  UniqueValueRenderer,
  SimpleLineSymbol,
  Color,
  PictuureMakerSymbol,
  MapImageLayer,
  TileLayer,
  ImageryLayer;

const plotTestIdFirst = "fb1d41dc9f684c219ccdb74020ddd3f4";
const plotTestIdSecond = "1bb488ce8421405ca9950f1acff1060c";
const PlotPlanJSON = [
  { id: plotTestIdFirst, name: "Plot Test 1", isVisible: true },
  { id: plotTestIdSecond, name: "Plot Test 2", isVisible: true },
];

export class ZoneMap {
  constructor({ onMapLoadCb, viewDevicesEnabled, viewAlertsEnabled }) {
    this.modulesLoaded = false;
    this.map = null;
    this.mapView = null;
    this.sketchVM = null;
    this.onMapLoadCb = onMapLoadCb;
    this.selectedGraphic = null;
    this.currentGraphic = null;
    this.graphicSketchAction = GRAPHICS_ACTIONS.NONE;
    this.store = store;
    this.mapUtils = null;
    this.viewDevicesEnabled = viewDevicesEnabled;
    this.viewAlertsEnabled = viewAlertsEnabled;
    this.state = {
      showGraphicLabel: false,
      showSketchTools: false,
      formOpen: false,
      plotPlan: PlotPlanJSON,
      interval: null,
    };

    this.loadModulesAndInitMap();
  }

  async loadModulesAndInitMap() {
    [
      ArcGISMap,
      Color,
      MapView,
      EsriConfig,
      GraphicsLayer,
      Graphic,
      Polygon,
      Sketch,
      SketchViewModel,
      Point,
      geometryEngine,
      webMercatorUtils,
      VectorTileLayer,
      Legend,
      Expand,
      FeatureLayer,
      UniqueValueRenderer,
      SimpleLineSymbol,
      PictuureMakerSymbol,
      MapImageLayer,
      TileLayer,
      ImageryLayer,
    ] = await loadModules(
      [
        "esri/Map",
        "esri/Color",
        "esri/views/MapView",
        "esri/config",
        "esri/layers/GraphicsLayer",
        "esri/Graphic",
        "esri/geometry/Polygon",
        "esri/widgets/Sketch",
        "esri/widgets/Sketch/SketchViewModel",
        "esri/geometry/Point",
        "esri/geometry/geometryEngine",
        "esri/geometry/support/webMercatorUtils",
        "esri/layers/VectorTileLayer",
        "esri/widgets/Legend",
        "esri/widgets/Expand",
        "esri/layers/FeatureLayer",
        "esri/renderers/UniqueValueRenderer",
        "esri/symbols/SimpleLineSymbol",
        "esri/symbols/PictureMarkerSymbol",
        //"esri/Graphic",
        //"esri/layers/GraphicsLayer",
        "esri/layers/MapImageLayer",
        "esri/layers/TileLayer",
        "esri/layers/ImageryLayer",
      ],
      {
        css: true,
      }
    );

    this.modulesLoaded = true;
    if (process.env.REACT_APP_SITEWISE_ARCGIS_MAP_KEY) {
      EsriConfig.apiKey = process.env.REACT_APP_SITEWISE_ARCGIS_MAP_KEY;
    }
    this.map = new ArcGISMap({
      basemap: "satellite",
    });

    const site = this.store.getState().globalState.site;
    const siteName = site?.name.toLowerCase();
    const currentSiteLayer = plotPlanImageLayerJson[siteName];

    if (currentSiteLayer?.show) {
      if (currentSiteLayer?.trustedServers) {
        EsriConfig.request.trustedServers.push(currentSiteLayer.trustedServers);
      }
      currentSiteLayer.layers.forEach((index) => {
        const layerType = index.layer;
        this.validateLayerType(layerType, this.map, index.layerProperties);
      });
    }

    this.mapView = new MapView({
      container: "map-container",
      map: this.map,
      highlightOptions: {
        color: [255, 255, 0, 1],
        haloOpacity: 0.9,
        fillOpacity: 0.2,
      },
      popup: {
        collapseEnabled: true,
        actions: [],
        visibleElements: {
          closeButton: true,
        },
        dockOptions: {
          buttonEnabled: true,
          breakpoint: true,
        },
      },
      ...MapUtilsV2.getSubSiteCenter(site),
      zoom: site.subSites && site.subSites[0] ? site.subSites[0].zoom : 3,
      constraints: {
        maxZoom: site.maxZoom ? site.maxZoom : 3,
        minZoom: site.minZoom ? site.minZoom : 20,
      },
    });

    if (site.subSites[0]) {
      this.mapView.rotation = site.subSites[0].rotation;
    }
    this.mapUtils = new MapUtils(this.map, this.mapView);
    this.mapView.ui.components = [];
    MapUtilsV2.addDefaultWidgets(this.mapView);
    this.mapView.when(() => {
      this.onMapLoadCb &&
        this.onMapLoadCb(
          this.renderMap,
          this.store.getState().zonesScreen.zones,
          this.store.getState().globalState.mapUserDevices.list,
          this.store.getState().zonesScreen.uploadedMediaData
        );
      this.mapUtils.addCustomWidgets(this.labelClickCb, this.cancelCreateGraphic);
      const renderer = {
        type: "unique-value", // autocasts as new UniqueValueRenderer()
        field: "REGION", // autocasts as new SimpleFillSymbol()
        uniqueValueInfos: [
          {
            // All features with value of "North" will be blue
            value: "RealWear",
            symbol: {
              type: "picture-marker",
              url: "data:image/png;base64," + MAP_ICONS.REALWARE_ICON,
              contentType: "image/png",
              width: "32px",
              height: "32px",
            },
          },
          {
            // All features with value of "East" will be green
            value: "APPLE",
            symbol: {
              type: simpleMarker,
              style: "path",
              size: "28px",
              outline: {
                color: [0, 0, 0],
                width: 0,
              },
              path: MAP_ICONS.IPHONE_ICON,
              color: "white",
            },
          },
          {
            // All features with value of "South" will be red
            value: "BLACKLINE",
            symbol: {
              type: simpleMarker,
              style: "path",
              size: "28px",
              outline: {
                color: [0, 0, 0],
                width: 0,
              },
              path: MAP_ICONS.PERSON_ICON,
              color: "white",
            },
          },
          {
            // All features with value of "South" will be red
            value: "ISC",
            symbol: {
              type: simpleMarker,
              style: "path",
              size: "28px",
              outline: {
                color: [0, 0, 0],
                width: 0,
              },
              path: MAP_ICONS.PERSON_ICON,
              color: "brown",
            },
          },
          {
            // All features with value of "West" will be yellow
            value: "OTHERS",
            symbol: {
              type: simpleMarker,
              style: "path",
              size: "28px",
              outline: {
                color: [0, 0, 0],
                width: 0,
              },
              path: MAP_ICONS.ANDROID_ICON,
              color: "white",
            },
          },
        ],
      };
      const povLayer = new FeatureLayer({
        portalItem: {
          id: "eb54b44c65b846cca12914b87b315169",
        },
        renderer: renderer,
        title: "Device Legend",
      });
      this.map.layers.add(povLayer);
      const legend = new Expand({
        content: new Legend({
          view: this.mapView, // other styles include 'classic'
          style: "classic",
        }),
        view: this.mapView,
      });

      this.mapView.ui.add(legend, "top-left");
      this.addCheckBoxPlotPlan();
      this.renderUserDevicesHeatMap();
      this.mapView.on("click", this.clickEvents);

      if ("ontouchstart" in window) {
        //not use
      } else {
        pointerMoveEventHandler(
          this.mapView,
          this.sketchVM,
          this.sketch,
          this.state,
          this.currentGraphic,
          this.store,
          GRAPHICS_LAYERS_ID,
          this.viewAlertsEnabled
        );

        this.mapView.on("key-down", this.keyDownEvent);
        const mapView = this;
        this.mapView.popup.on("trigger-action", function (event) {
      if (event.action.id === "send-message") { 
        mapView.store.dispatch({
          type: ZONE_REDUCER_TYPES.SET_SEND_MESSAGE_DIALOG_ZONES_ALERTS,
          payload: {
            manufacturerId: event.action.manufacturerId,
            id: event.action.deviceID,
            isAlerts: event.action.isAlerts
        },
        });
        mapView.store.dispatch({
          type: MESSAGES_REDUCER_TYPES.SET_SEND_MESSAGE_ZONES_ALERTS_DIALOG_DISPLAY,
          payload: true,
        });
      }   
  });
      }
    });
    const context = this;
    this.mapView.popup.on("trigger-action", async function (event) {
      context.TriggerActions(event, context);
    });
  }

  validateLayerType(layerType, mapContext, layerContext) {
    let Layer;
    if (layerType === "ImageryLayer") {
      Layer = new ImageryLayer(layerContext);
    } else if (layerType === "MapImageLayer") {
      Layer = new MapImageLayer(layerContext);
    } else if (layerType === "VectorTileLayer") {
      Layer = new VectorTileLayer(layerContext);
    } else {
      Layer = new TileLayer(layerContext);
    }
    mapContext.add(Layer);
  }

  identifyWithinZoneAndNotWithinZone(unique) {
    if (unique?.length > 1) {
      const onlyWithinZoneDevices = unique.filter((item) => {
        return item?.zone;
      });
      this.goToSiteLocation(onlyWithinZoneDevices[0]?.device?.status?.location);
    } else if (unique?.length > 0) {
      this.goToSiteLocation(unique[0].device?.status?.location);
    }
  }

  filterRecentAlertDevice(activeAlerts) {
    const unique = [
      ...new Map(
        activeAlerts.map((item) => [item.device?.id ? item["device"]["id"] : 0, item])
      ).values(),
    ];
    this.identifyWithinZoneAndNotWithinZone(unique);
  }
  goToRecentAlertDevice() {
    const store = this.store.getState();
    const { activeAlerts } = store.globalState?.alerts || [];
    if (activeAlerts.length) {
      this.filterRecentAlertDevice(activeAlerts);
    }
  }

  addCheckBoxPlotPlan() {
    var innerHTML = '<div class="div-plot" id="div-plot">';
    innerHTML += `<form>Plot Id:<input type="text" id="txtPlotId" class=\"ersi-textBox\" required/>&nbsp;
          Layer Type: <select id="layerType">
          <option value="VectorTileLayer">VectorTileLayer</option>
          <option value="MapImageLayer">MapImageLayer</option>
          <option value="TileLayer">TileLayer</option>
        </select>
      <input type="submit" id="btnEnterPlot" class="ersi-button" value="+" /><br /><br /></form>`;
    this.map.allLayers.items.map((item) => {
      innerHTML += `<input type="checkbox" id="${item.id}" checked="${item.visible}" />
      <label for="${item.id}">${item.id} - ${item.type}</label><br>`;
    });
    innerHTML += "</div>";
    var div = document.createElement("div");
    div.innerHTML = innerHTML.trim();
    var bgExpand = new Expand({
      view: this.mapView,
      content: div,
      accessKey: "expandPlot",
    });
    var context = this;
    bgExpand.watch("expanded", function () {
      if (bgExpand.expanded) {
        var checkboxes = document.querySelectorAll("input[type='checkbox']");
        context.PLotPlanEventListener(checkboxes, context);
        var btnEnterPlot = document.getElementById("btnEnterPlot");
        btnEnterPlot.addEventListener("click", function () {
          context.addNewPlot(context);
        });
      }
    });
    this.mapView.ui.add(bgExpand, "bottom-left");
  }
  PLotPlanEventListener(checkboxes, context) {
    checkboxes.forEach(function (checkbox) {
      checkbox.addEventListener("change", function () {
        context.showHidePlotPlans(this.id, this.checked);
      });
    });
  }

  addNewPlot(context) {
    var txtPlotId = document.getElementById("txtPlotId").value;
    var layerType = document.getElementById("layerType").value;
    var plotPlan = { id: txtPlotId, name: layerType };
    if (txtPlotId !== "" && layerType !== "") {
      context.state.plotPlan.push(plotPlan);
      var tileLayer = null;
      if (layerType === "VectorTileLayer") {
        tileLayer = new VectorTileLayer({
          portalItem: {
            id: txtPlotId,
          },
          id: GRAPHICS_LAYERS_ID.MAP_VECTOR_TILE + txtPlotId,
        });
      } else if (layerType === "MapImageLayer") {
        tileLayer = new MapImageLayer({
          url: txtPlotId,
        });
      } else if (layerType === "TileLayer") {
        tileLayer = new TileLayer({
          portalItem: {
            id: txtPlotId,
          },
        });
      }
      context.map.add(tileLayer);
      {
        var innerHTML = "";
        innerHTML += `<form>Id/URL:<input type="text" id="txtPlotId" class=\"ersi-textBox\" required/>&nbsp;
              Layer Type: <select id="layerType">
              <option value="VectorTileLayer">VectorTileLayer</option>
              <option value="MapImageLayer">MapImageLayer</option>
              <option value="TileLayer">TileLayer</option>
            </select>
          <input type="submit" id="btnEnterPlot" class="ersi-button" value="+" /><br /><br /></form>`;
        this.map.allLayers.items.map((item) => {
          innerHTML += `<input type="checkbox" id="${item.id}" checked="${item.visible}" />
         <label for="${item.id}">${item.id} - ${item.type}</label><br>`;
        });
        document.getElementById("div-plot").innerHTML = innerHTML;
        var checkboxes = document.querySelectorAll("input[type='checkbox']");
        this.PLotPlanEventListener(checkboxes, context);
        var btnEnterPlot = document.getElementById("btnEnterPlot");
        btnEnterPlot.addEventListener("click", function () {
          context.addNewPlot(context);
        });
      }
    }
  }
  clickEvents = async (event) => {
    event.stopPropagation();
    if (this.sketchVM.state === "active") {
      return;
    }
    if (this.store.getState().zonesScreen.mapEditShapeInProgress) {
      this.sketch.update(this.currentGraphic);
      return;
    }
    const isOnUserClick = await onUserDeviceClick(
      event,
      this.mapView,
      this.store,
      GRAPHICS_LAYERS_ID,
      this.viewAlertsEnabled
    );
    // Adds the action to the view's default popup.
    if (isOnUserClick) {
      const context = this;
      this.mapView.popup.on("trigger-action", async function (event) {
        await context.TriggerActions(event, context);
      });
      return;
    } else {
      this.resetUserHistoryMapLayer();
    }

    const selectedGraphics = [];
    const layer = MapUtilsV2.getLayerById(this.map, GRAPHICS_LAYERS_ID.ZONES);
    layer.graphics.forEach((graphic) => {
      if (graphic.geometry.contains(event.mapPoint)) {
        var area = geometryEngine.geodesicArea(
          geometryEngine.simplify(graphic.geometry),
          "square-meters"
        );
        graphic.attributes.area = area;
        selectedGraphics.push(graphic);
      }
    });

    selectedGraphics.sort((graphicA, graphicB) =>
      graphicA.attributes.area > graphicB.attributes.area ? 1 : -1
    );

    const graphic = selectedGraphics[0];

    if (graphic) {
      this.selectedGraphic = graphic;
      MapUtilsV2.MapWidgetTmpl.updateGraphicLabelActionBttn(graphic, true);
      this.gotToGraphic(graphic);
      this.store.dispatch({
        type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE_BY_GRAPHIC_ID,
        payload: graphic.attributes.id,
      });
    }
  };

  keyDownEvent = (event) => {
    if (event.key === "Escape") {
      this.cancelCreateGraphic();
    }
  };

  labelClickCb = () => {
    const editing = this.store.getState().zonesScreen.editingForm;
    if (editing) {
      this.store.dispatch({ type: ZONE_REDUCER_TYPES.SET_EDIT_ZONE_DIALOG_DISPLAY, payload: true });
    } else {
      this.mapView.goTo({
        zoom: MAP_CONFIG.DEFAULT_ZOOM,
      });
      MapUtilsV2.MapWidgetTmpl.updateGraphicLabelActionBttn(null, false);
      this.store.dispatch({ type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE, payload: null });
      this.selectedGraphic = null;
      const filters = this.store.getState().globalState.mapUserDevices.filters;
      this.store.dispatch(GlobalReducerActions.getUserDevicesWithAppliedFilters(filters, false));
      this.rerenderUserDevices();
    }
  };

  renderMap = (zones, userDevices, mediaAssets) => {
    if (!this.modulesLoaded) {
      return;
    }

    const zonesGraphicsLayer = new GraphicsLayer({
      id: GRAPHICS_LAYERS_ID.ZONES,
    });

    const zonesHeadcountLayer = new GraphicsLayer({
      id: GRAPHICS_LAYERS_ID.HEADCOUNT,
    });

    const zonesLabelsGraphicLayer = new GraphicsLayer({
      id: GRAPHICS_LAYERS_ID.LABELS,
    });

    const userDevicesGraphicLayer = new GraphicsLayer({
      id: GRAPHICS_LAYERS_ID.USER_DEVICES,
    });

    const userPathGraphicLayer = new GraphicsLayer({
      id: GRAPHICS_LAYERS_ID.USER_PATH,
    });
    const usersGraphicLayer = new GraphicsLayer({
      id: GRAPHICS_LAYERS_ID.USERS,
    });
    const userGraphicHeatMapLayer = new GraphicsLayer({
      id: GRAPHICS_LAYERS_ID.USER_DEVICES_HEAT_MAP,
      visible: false,
    });
    const mediaGraphicMapLayer = new GraphicsLayer({
      id: GRAPHICS_LAYERS_ID.MEDIA_ASSETS,
      // visible: false,
    });
    this.map.addMany([
      userGraphicHeatMapLayer,
      zonesGraphicsLayer,
      zonesHeadcountLayer,
      zonesLabelsGraphicLayer,
      userDevicesGraphicLayer,
      userPathGraphicLayer,
      usersGraphicLayer,
      mediaGraphicMapLayer
    ]);

    if (zones.length) {
      this.renderZones(zones);
    }

    if (userDevices.length) {
      this.renderUserDevices(userDevices);
    }
    if (mediaAssets.length) {
      this.renderMediaAssets(mediaAssets);
    }
  };

  resetZonesMapLayer = () => {
    const toClear = [
      GRAPHICS_LAYERS_ID.ZONES,
      GRAPHICS_LAYERS_ID.HEADCOUNT,
      GRAPHICS_LAYERS_ID.LABELS,
    ];

    for (const layerID of toClear) {
      const layer = MapUtilsV2.getLayerById(this.map, layerID);
      layer && layer.graphics.removeAll();
    }

    this.mapUtils.hideAllActionsBttns(false);
  };

  resetUserDevicesMapLayer = async () => {
    const toClearLayers = [GRAPHICS_LAYERS_ID.USER_DEVICES];
    for (const layerID of toClearLayers) {
      const layer = MapUtilsV2.getLayerById(this.map, layerID);
      layer && layer.graphics.removeAll();
    }

    this.mapUtils.hideAllActionsBttns(false);
  };

  async TriggerActions(event, context) {
    if (event.action.id === "Video") {
      await UsersReducerActions.videoChat(event.action.manufacturerId, "start");
    } else if (event.action.id === "History") {
      await context.triggerHistory(event.action.manufacturerId);
      const interval = setInterval(async () => {
        await context.triggerHistory(event.action.manufacturerId);
      }, 20000);
      context.state.interval = interval;
    } else if (event.action.id === "TeamsChat") {
      var currentUserEmail = context.store.getState().auth.sitewiseUser.email;
      var teamcallURL = `https://teams.microsoft.com/l/chat/0/0?users=
      ${event.action.userEmail},${currentUserEmail}&topicName=SitewiseChat`;
      window.open(teamcallURL);
    }
  }

  async rerenderUserDevices() {
    const layer = MapUtilsV2.getLayerById(this.map, GRAPHICS_LAYERS_ID.USER_DEVICES);
    const allUserDevices = layer.graphics;
    allUserDevices.forEach((userDeviceGraphic) => {
      const { alerts } = userDeviceGraphic.attributes;
      if (alerts.length === 0) {
        userDeviceGraphic.visible =
          this.store.getState().globalState.mapUserDevices.filters.filtersApplied ||
          !!this.store.getState().zonesScreen.selectedZone;
      } else {
        userDeviceGraphic.visible = this.viewAlertsEnabled;
      }
    });
  }

  async renderZones(zones) {
    if (!this.modulesLoaded) {
      return;
    }

    if (this.store.getState().zonesScreen.mapEditShapeInProgress || this.state.formOpen) {
      return;
    }

    const zonesGraphicsLayer = MapUtilsV2.getLayerById(this.map, GRAPHICS_LAYERS_ID.ZONES);
    const zonesHeadcountLayer = MapUtilsV2.getLayerById(this.map, GRAPHICS_LAYERS_ID.HEADCOUNT);
    const zonesLabelsGraphicLayer = MapUtilsV2.getLayerById(this.map, GRAPHICS_LAYERS_ID.LABELS);

    if (!zonesGraphicsLayer && !zonesHeadcountLayer && !zonesLabelsGraphicLayer) {
      return;
    }

    zonesLabelsGraphicLayer.visible = this.viewDevicesEnabled;
    zonesHeadcountLayer.visible = this.viewDevicesEnabled;

    this.resetZonesMapLayer();

    zones.forEach(async (zone) => {
      const rings = zone.polygonPoints.map((p) => {
        return [p.longitude, p.latitude];
      });

      const polygon = new Polygon({
        rings: rings,
      });

      let zoneColourWithoutAlpha;
      if (zone.displayColour) {
        zoneColourWithoutAlpha = [
          zone.displayColour["red"],
          zone.displayColour["green"],
          zone.displayColour["blue"],
        ];
      } else {
        zoneColourWithoutAlpha = [255, 0, 0];
      }

      const textSymbol = {
        type: "text",
        color: "black",
        xoffset: 0,
        yoffset: -4,
        text: zone.headcount,
        font: {
          size: 12,
          weight: "bold",
        },
      };

      const polygonFillSymbol = {
        type: "simple-fill",
        color: [...zoneColourWithoutAlpha, 0.3],
        style: "solid",
        outline: {
          color: {
            r: zoneColourWithoutAlpha[0],
            g: zoneColourWithoutAlpha[1],
            b: zoneColourWithoutAlpha[2],
            a: 1,
          },
          width: 2,
        },
      };

      var markerSymbol = {
        style: "circle",
        size: 20,
        type: simpleMarker,
        color: [...zoneColourWithoutAlpha, 1],
        outline: {
          width: 0,
        },
      };

      var polygonGraphic = new Graphic({
        geometry: webMercatorUtils.geographicToWebMercator(polygon),
        symbol: polygonFillSymbol,
        attributes: {
          ...zone,
          type: "ZONE",
        },
      });

      var point = new Point(polygonGraphic.geometry.centroid);

      polygonGraphic.attributes = {
        ...zone,
        type: "ZONE",
      };

      var pointGraphic = new Graphic({
        geometry: point,
        symbol: markerSymbol,
      });

      var textGraphic = new Graphic({
        geometry: point,
        symbol: textSymbol,
      });

      zonesHeadcountLayer.add(pointGraphic);
      zonesLabelsGraphicLayer.add(textGraphic);
      zonesGraphicsLayer.add(polygonGraphic);
    });

    await this.addSketchToGraphicLayer(zonesGraphicsLayer);
  }

  async renderUserDevices(userDevices) {
    if (!this.modulesLoaded) {
      return;
    }

    if (this.store.getState().zonesScreen.mapEditShapeInProgress || this.state.formOpen) {
      return;
    }

    const userDevicesGraphicLayer = MapUtilsV2.getLayerById(
      this.map,
      GRAPHICS_LAYERS_ID.USER_DEVICES
    );

    if (!userDevicesGraphicLayer) {
      return;
    }

    this.resetUserDevicesMapLayer();

    MapUtilsV2.renderUserDevicesOnMap({ userDevices, Point, Graphic, userDevicesGraphicLayer });

    this.rerenderUserDevices();
  }

   renderMediaAssets(mediaAssets) {
    // if (!this.modulesLoaded) {
    //   return;
    // }

    if (this.store.getState().zonesScreen.mapEditShapeInProgress || this.state.formOpen) {
      return;
    }

    const mediaAssetsGraphicLayer = MapUtilsV2.getLayerById(
      this.map,
      GRAPHICS_LAYERS_ID.MEDIA_ASSETS
    );

    if (!mediaAssetsGraphicLayer) {
      return;
    }

    // this.resetUserDevicesMapLayer();
    MapUtilsV2.renderMediaAssetsOnMap({ mediaAssets, Point, Graphic, mediaAssetsGraphicLayer });

  }

  addSketchToGraphicLayer = async (layer) => {
    // SKETCH
    this.sketchVM = new SketchViewModel({
      updateOnGraphicClick: false,
      layer: layer,
      view: this.mapView,
    });

    this.sketch = new Sketch({
      creationMode: "update",
      availableCreateTools: ["polygon", "rectangle", "circle", "move", "transform", "reshape"],
      viewModel: this.sketchVM,
      visibleElements: {
        selectionTools: {
          "lasso-selection": false,
          "rectangle-selection": false,
        },
      },
    });

    // SKETCH EVENTS
    this.sketch.on("create", async (event) => {
      if (event.state === "start") {
        if (this.graphicSketchAction === GRAPHICS_ACTIONS.NONE) {
          this.graphicSketchAction = GRAPHICS_ACTIONS.CREATE;
        }
      }

      if (event.state === "complete") {
        this.currentGraphic = event.graphic;
        if (event.tool === MAP_CONFIG.SKETCH_TOOLS.CIRCLE) {
          this.currentGraphic.attributes = {
            shape: "Circle",
          };
        } else if (
          event.tool === MAP_CONFIG.SKETCH_TOOLS.RECTANGLE ||
          event.tool === MAP_CONFIG.SKETCH_TOOLS.POLYGON
        ) {
          this.currentGraphic.attributes = {
            shape: "Polygon",
          };
        }
      }
    });

    this.sketch.on("update", (event) => {
      if (event.state === "start") {
        this.store.dispatch({
          type: ZONE_REDUCER_TYPES.SET_MAP_EDIT_SHAPE_IN_PROGRESS,
          payload: true,
        });
        this.mapUtils.updateSketchHelperTxt(
          true,
          "Edit by dragging the dots and click 'Done' when finished."
        );
        this.mapUtils.disableSketchOptions(true);
        const labelsPointsLayer = MapUtilsV2.getLayerById(this.map, GRAPHICS_LAYERS_ID.LABELS);
        const headcountLayer = MapUtilsV2.getLayerById(this.map, GRAPHICS_LAYERS_ID.HEADCOUNT);

        if (this.graphicSketchAction === GRAPHICS_ACTIONS.CREATE_FROM_TEMPLATE) {
          // hide del tool panel in CREATE_FROM_TEMPLATE
          this.mapUtils.setShowSketchInfoPanel(false);
          labelsPointsLayer.visible = false;
          headcountLayer.visible = false;
        } else if (this.graphicSketchAction === GRAPHICS_ACTIONS.UPDATE) {
          labelsPointsLayer.visible = false;
          headcountLayer.visible = false;
        }

        if (
          this.graphicSketchAction === GRAPHICS_ACTIONS.CREATE ||
          this.graphicSketchAction === GRAPHICS_ACTIONS.CREATE_FROM_TEMPLATE
        ) {
          const saveBttn = MapWidgetTmpl.getGraphicSaveBttn();
          this.mapView.ui.add(saveBttn, "manual");
          saveBttn.style.display = "block";
          saveBttn.onclick = () => {
            this.sketchVM.complete();

            if (this.graphicSketchAction === GRAPHICS_ACTIONS.CREATE) {
              this.store.dispatch({
                type: ZONE_REDUCER_TYPES.SET_SELECTED_MAP_GRAPHIC,
                payload: this.currentGraphic,
              });
              this.store.dispatch({
                type: ZONE_REDUCER_TYPES.SET_CREATE_ZONE_FORM_DISPLAY,
                payload: true,
              });
            } else {
              this.store.dispatch({
                type: ZONE_REDUCER_TYPES.SET_SELECTED_MAP_GRAPHIC,
                payload: this.currentGraphic,
              });
            }

            this.state.formOpen = true;
            // Hide Tools
            this.graphicSketchAction = GRAPHICS_ACTIONS.NONE;
            this.setShowSketchTools(false);
          };
        } else if (this.graphicSketchAction === GRAPHICS_ACTIONS.UPDATE) {
          const updateBttn = MapWidgetTmpl.addGraphicUpdateBttn();
          const cancelBttn = MapWidgetTmpl.getGraphicCancelBttn(this.cancelCreateGraphic);
          this.mapView.ui.add(updateBttn, "manual");
          this.mapView.ui.add(cancelBttn, "manual");
          updateBttn.style.display = "block";
          cancelBttn.style.display = "block";
          updateBttn.onclick = this.updateGraphic;
        }
      }

      if (event.state === "active") {
        this.store.dispatch({
          type: ZONE_REDUCER_TYPES.SET_MAP_EDIT_SHAPE_IN_PROGRESS,
          payload: true,
        });
        this.currentGraphic = event.graphics[0];
      }

      if (event.state === "complete") {
        this.currentGraphic = event.graphics[0];
        MapUtilsV2.MapWidgetTmpl.updateGraphicLabelActionBttn(null, false);
        this.mapUtils.disableSketchOptions(false);
      }
    });

    this.sketch.on("delete", () => {
      this.mapUtils.updateSketchHelperTxt(true);
      this.mapUtils.disableSketchOptions(false);
      this.mapUtils.hideAllActionsBttns();
    });
  };

  selectZoneAndZoom(zoneData, toUpdateSelectedZone = true) {
    this.map.layers.forEach(async (layer) => {
      const graphics = layer.graphics;
      graphics &&
        graphics.forEach(async (graphic) => {
          if (graphic.attributes && graphic.attributes.id === zoneData.id) {
            this.selectedGraphic = graphic;
            if (toUpdateSelectedZone) {
              this.store.dispatch({
                type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE_BY_GRAPHIC_ID,
                payload: graphic.attributes.id,
              });
            }

            MapUtilsV2.MapWidgetTmpl.updateGraphicLabelActionBttn(graphic, true);
            await this.gotToGraphic(graphic);
          }
        });
    });
  }

  selectZone(zoneData) {
    this.map.layers.forEach(async (layer) => {
      const graphics = layer.graphics;
      graphics.forEach(async (graphic) => {
        if (graphic.attributes && graphic.attributes.id === zoneData.id) {
          this.selectedGraphic = graphic;
          this.store.dispatch({
            type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE_BY_GRAPHIC_ID,
            payload: graphic.attributes.id,
          });
        }
      });
    });
  }

  unselectZone() {
    this.selectedGraphic = null;
    this.mapView.goTo({
      zoom: MAP_CONFIG.DEFAULT_ZOOM,
    });
    MapUtilsV2.MapWidgetTmpl.updateGraphicLabelActionBttn(null, false);
    this.rerenderUserDevices();
  }

  setFormOpen(val) {
    this.state.formOpen = val;
  }

  async goToSiteLocation(site) {
    if (site?.latitude && site?.longitude) {
      const { latitude, longitude } = site;
      var opts = {
        duration: 1000,
        easing: "ease-in-out",
      };
      if (this.mapView) {
        try {
          await this.mapView.goTo(
            {
              center: [longitude, latitude],
              zoom: site.zoom || 16,
              rotation: site.rotation || 0,
            },
            opts
          );
        } catch (error) {
          console.log(error);
        }
      }
    }
  }

  async goToSiteLocationWithAllParameters() {}

  async gotToGraphic(graphic) {
    try {
      var opts = {
        duration: 1500,
        easing: "ease-out",
      };
      await this.mapView.goTo(
        {
          target: graphic,
        },
        opts
      );
      this.rerenderUserDevices();
    } catch (error) {
      console.log(error);
    }
  }

  showHideAllUserDevices = (toShow) => {
    this.rerenderUserDevices();
  };

  setShowSketchTools = (toShow) => {
    if (toShow) {
      this.mapUtils.updateSketchHelperTxt(true);
      this.mapUtils.updateCancelBttnWidget(true);
      this.mapView.ui.add(this.sketch, "top-left");

      this.mapView.focus();
    } else {
      this.mapUtils.updateSketchHelperTxt(false);
      this.mapView.ui.remove(this.sketch);
      this.mapUtils.hideAllActionsBttns();
      this.mapUtils.updateCancelBttnWidget(false);
    }

    this.store.dispatch({
      type: ZONE_REDUCER_TYPES.SET_MAP_EDIT_SHAPE_IN_PROGRESS,
      payload: toShow,
    });
  };

  cancelCreateGraphic = (selectedZoneNull = true) => {
    const headCount = MapUtilsV2.getLayerById(this.map, GRAPHICS_LAYERS_ID.HEADCOUNT);
    const labelsPoints = MapUtilsV2.getLayerById(this.map, GRAPHICS_LAYERS_ID.LABELS);
    if (this.graphicSketchAction === "UPDATE") {
      this.selectedGraphic.visible = true;
      if (this.currentGraphic) {
        this.sketchVM.layer.remove(this.currentGraphic);
        this.currentGraphic = null;
      }
      this.sketchVM.layer.remove(this.sketchVM.updateGraphics.items[0]);
      headCount.visible = true;
      labelsPoints.visible = true;
    } else if (this.graphicSketchAction === "CREATE_FROM_TPL") {
      if (this.currentGraphic) {
        this.sketchVM.layer.remove(this.currentGraphic);
        this.currentGraphic = null;
      }
      this.sketchVM.layer.remove(this.sketchVM.updateGraphics.items[0]);
      this.store.dispatch({ type: ZONE_REDUCER_TYPES.SET_EDIT_ZONE_FROM_TPL_FORM, payload: false });
      this.store.dispatch({ type: ZONE_REDUCER_TYPES.SET_SHOW_ZONES_TPL_TABLE, payload: false });
      this.store.dispatch({
        type: ZONE_REDUCER_TYPES.SET_ZONES_VIEW_PAGE_FILTER,
        payload: "active",
      });

      this.graphicSketchAction = GRAPHICS_ACTIONS.NONE;
    } else {
      if (this.currentGraphic) {
        this.sketchVM.layer.remove(this.currentGraphic);
        this.currentGraphic = null;
      }
    }
    this.store.dispatch({
      type: ZONE_REDUCER_TYPES.SET_EDIT_FORM,
      payload: false,
    });
    this.mapUtils.disableSketchOptions(false);
    this.setShowSketchTools(false);
    this.mapUtils.hideAllActionsBttns();
    this.sketchVM.cancel();
    if (selectedZoneNull) {
      this.store.dispatch({ type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE, payload: null });
    }
  };

  editGraphic = async (zoneData, actionType = GRAPHICS_ACTIONS.CREATE) => {
    this.cancelCreateGraphic(false);
    this.graphicSketchAction = actionType;
    this.selectZoneAndZoom(zoneData, false);
    this.setShowSketchTools(true);
    MapUtilsV2.MapWidgetTmpl.updateGraphicLabelActionBttn(null, false);
    if (actionType === GRAPHICS_ACTIONS.CREATE_FROM_TEMPLATE) {
      this.sketch.update(this.selectedGraphic.clone());
    } else {
      this.sketch.update(this.selectedGraphic.clone());
      this.selectedGraphic.visible = false;
    }
  };

  updateGraphic = () => {
    this.store.dispatch({
      type: ZONE_REDUCER_TYPES.SET_SELECTED_MAP_GRAPHIC,
      payload: this.currentGraphic,
    });
    this.sketch.complete();
    const graphic = this.currentGraphic;
    this.store.dispatch(ZonesReducerActions.updateZone(graphic));
    this.graphicSketchAction = GRAPHICS_ACTIONS.NONE;
    MapUtilsV2.MapWidgetTmpl.updateGraphicLabelActionBttn(null, false);
    this.setShowSketchTools(false);
  };

  showHidePlotPlans(Id, IsVisible) {
    this.showHideLayerLogic(Id, IsVisible);
  }

  showHideLayerLogic(graphicID, IsVisible = false) {
    const layer = MapUtilsV2.getLayerById(this.map, graphicID);
    if (layer) {
      layer.visible = IsVisible ? IsVisible : !layer.visible;
    }
  }

  showHidePlotPlan(graphicID) {
    this.showHideLayerLogic(graphicID);
  }

  showHideMapImageLayer(graphicID) {
    this.showHideLayerLogic(graphicID);
  }
  async triggerHistory(manufacturerId) {
    this.resetUserHistoryMapLayer();
    var zones = await UsersReducerActions.getUserLocationHistory(manufacturerId);
    this.store.dispatch({
      type: ZONE_REDUCER_TYPES.SET_USER_HISTORY,
      payload: zones,
    });
    await this.renderLines(zones);
    await this.rendersUserPoint(zones);
  }
  async renderLines(zones) {
    if (
      zones != null &&
      zones.userLocationHistory != null &&
      zones.userLocationHistory.length > 0
    ) {
      const zonesLabelsGraphicLayer = MapUtilsV2.getLayerById(
        this.map,
        GRAPHICS_LAYERS_ID.USER_PATH
      );
      for (var row = 0; row < zones.userLocationHistory.length; row++) {
        var paths = [];
        var firstElement = zones.userLocationHistory[row];
        var secondElement = zones.userLocationHistory[row + 1];
        if (secondElement == null) {
          secondElement = firstElement;
        }
        paths.push([firstElement.longitude, firstElement.latitude]);
        paths.push([secondElement.longitude, secondElement.latitude]);
        const polyline = {
          type: "polyline",
          paths: paths,
        };
        const simpleLineSymbol = {
          type: "simple-line",
          color:
            colors[colors.length - row - 1] != null ? colors[colors.length - row - 1] : colors[0], // Orange
          width: 2,
        };
        const polylineGraphic = new Graphic({
          geometry: polyline,
          symbol: simpleLineSymbol,
        });
        zonesLabelsGraphicLayer.add(polylineGraphic);
      }
      await this.addSketchToGraphicLayer(zonesLabelsGraphicLayer);
    }
  }
  async rendersUserPoint(zones) {
    const zonesGraphicsLayerZones = MapUtilsV2.getLayerById(this.map, GRAPHICS_LAYERS_ID.USERS);
    zones.userLocationHistory.forEach(async (zone, index) => {
      const point = {
        //Create a point
        type: "point",
        longitude: zone.longitude,
        latitude: zone.latitude,
      };
      const simpleMarkerSymbol = {
        type: simpleMarker,
        color:
          colors[colors.length - index - 1] != null ? colors[colors.length - index - 1] : colors[0], //index==0?[226, 119, 40]:[199, 200, 201],  // Orange
        outline: {
          color:
            colors[colors.length - index - 1] != null
              ? colors[colors.length - index - 1]
              : colors[0], //index==0?[226, 119, 40]:[226, 119, 40], // [255, 255, 255], // White
          width: 2,
        },
      };
      const pointGraphic = new Graphic({
        geometry: point,
        symbol: simpleMarkerSymbol,
        popupTemplate: {
          title: zone.timeStamp,
          content: [
            {
              type: "fields",
              fieldInfos: [
                {
                  fieldName: zone.timeStamp,
                  label: zone.timeStamp,
                  format: {
                    digitSeparator: true,
                  },
                },
                {
                  fieldName: "expression/per-asian",
                },
              ],
            },
          ],
        },
      });
      zonesGraphicsLayerZones.add(pointGraphic);
    });
    await this.addSketchToGraphicLayer(zonesGraphicsLayerZones);
  }

  //await this.raisePopup(context, zones)
  resetUserHistoryMapLayer = async () => {
    const toClearLayers = [GRAPHICS_LAYERS_ID.USERS, GRAPHICS_LAYERS_ID.USER_PATH];
    for (const layerID of toClearLayers) {
      const layer = MapUtilsV2.getLayerById(this.map, layerID);
      layer && layer.graphics.removeAll();
    }
    clearInterval(this.state.interval);
    this.mapUtils.hideAllActionsBttns(false);
  };
  async renderUserDevicesHeatMap() {
    const site = this.store.getState().globalState.site;
    var jsondata = await DevicesReducerActions.getAmbientNoiseBySite(site.id);
    if (jsondata && jsondata.length > 0) {
      const zonesGraphicsLayerHeatMap = MapUtilsV2.getLayerById(
        this.map,
        GRAPHICS_LAYERS_ID.USER_DEVICES_HEAT_MAP
      );
      for (var row = 0; row < jsondata.length; row++) {
        var rowData = jsondata[row];
        var color = [199, 200, 201];
        if (rowData.ambientNoise <= 80) {
          color = [0, 255, 0]; //green
        } else if (rowData.ambientNoise > 80 && rowData.ambientNoise <= 90) {
          color = [255, 255, 0]; //yellow
        } else if (rowData.ambientNoise > 90) {
          color = [255, 0, 0]; //Red
        }
        const point = {
          //Create a point
          type: "point",
          longitude: rowData.longitude,
          latitude: rowData.latitude,
        };
        const simpleMarkerSymbol = {
          type: simpleMarker,
          style: "square",
          size: "8px",
          color: color,
          outline: {
            color: color,
            width: 1,
          },
        };
        const sqaureGraphic = new Graphic({
          geometry: point,
          symbol: simpleMarkerSymbol,
        });

        zonesGraphicsLayerHeatMap.add(sqaureGraphic);
      }
      await this.addSketchToGraphicLayer(zonesGraphicsLayerHeatMap);
    }
  }
}
