import React from "react";
import { makeStyles, IconButton, Tooltip } from "@material-ui/core";
import { ICONS } from "../icon-manager";
import { APP_CONSTANTS } from "../../config";

const useStyles = makeStyles((theme) => ({
  weatherWrapper: {
    position: "absolute",
    bottom: "124px",
    left: "47px",
    background: theme.palette.common.black,
    display: "flex",
  },
}));

export const WeatherWidget = (props) => {
  const classes = useStyles();

  function openWeatherData() {
    window.open(APP_CONSTANTS.WEATHER_REDIRECT,'popup','width=320,height=450'); 
    return false;
  }

  return (
    <div className={classes.weatherWrapper}>
      <Tooltip title={"Go to weather information"}>
      <a
      target="_parent" 
      onClick={() => {
        openWeatherData();
      }}
       >
        <IconButton
          aria-label="Weather"
          style={{
            padding: "5px 4px",
          }}
          color="contained"
        >
          <ICONS.CloudIcon />
        </IconButton>
        </a>
      </Tooltip>
    </div>
  );
};
