import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { ZONE_REDUCER_TYPES } from "../../../reducers";
import { ICONS } from "../../../commons";

export const FilterPanelBttn = (props) => {
  const dispatch = useDispatch();
  const { show = true, count } = props;
  const showFilterPanel = useSelector((state) => state.zonesScreen.showFilterPanel);

  if (!show) {
    return null;
  }

  return (
    <Tooltip title="Filter list">
      <div>
        <span
          style={{
            color: "#FDE26A",
          }}
        >
          {count > 0 ? `Filters: (${count})` : null}
        </span>
        <IconButton
          aria-label="filter list"
          style={{
            color: "#FDE26A",
          }}
          onClick={() => {
            dispatch({
              type: ZONE_REDUCER_TYPES.SET_SHOW_FILTER_PANEL,
              payload: !showFilterPanel,
            });
          }}
        >
          {showFilterPanel ? <ICONS.Close /> : <ICONS.FilterList />}
        </IconButton>
      </div>
    </Tooltip>
  );
};
