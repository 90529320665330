import { OutlinedInput, withStyles } from "@material-ui/core";

export const CustomTextInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(1),
    },
    fontSize: "0.9rem",
    fontStyle: "italic",
    lineHeight: 1,
  },
  input: {
    borderRadius: 2,
    position: "relative",
    border: `1px solid ${theme.palette.base.inputBorderColor}`,
    padding: "10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
  },
}))(OutlinedInput);
