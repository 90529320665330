import { AxiosClient } from "./axios-client";
import { handleErrorFn } from "../custom-errors";
import { authService } from "../utils/auth-service";

const getSitelocationDevicesByQueryString = async (siteID, queryString) => {
  try {
    const headers = await authService.getAuthorizeHeader();
    const res = await AxiosClient({
      url: `/api/siteLocation/${siteID}/device?${queryString}`,
      method: "GET",
      headers,
    });

    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const getSitelocationDevicesSummary = async (siteID) => {
  try {
    const headers = await authService.getAuthorizeHeader();
    const res = await AxiosClient({
      url: `/api/SiteLocation/${siteID}/Device/Summary`,
      method: "GET",
      headers,
    });

    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const assignDevice = async (device, user) => {
  try {
    const headers = await authService.getAuthorizeHeader();
    const res = await AxiosClient({
      url: `/api/Device/${device.id}`,
      method: "PUT",
      data: {
        id: device.id,
        assignedUserId: user.id,
      },
      headers,
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const unAssignDevice = async (device) => {
  try {
    const headers = await authService.getAuthorizeHeader();
    const res = await AxiosClient({
      url: `/api/Device/${device.id}`,
      method: "PUT",
      data: {
        id: device.id,
        assignedUserId: null,
      },
      headers,
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};
const getAmbientNoiseBySite = async (siteID) => {
  try {
    //https://345e4a79ab65.ngrok.io/api/Device/%7Bid%7D/GetAmbientNoiseBySite
    const headers = await authService.getAuthorizeHeader();
    const res = await AxiosClient({
      url: `/api/Device/${siteID}/GetAmbientNoiseBySite`,
      method: "GET",
      headers,
    });

    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

export const DevicesAPI = {
  getSitelocationDevicesByQueryString,
  getSitelocationDevicesSummary,
  unAssignDevice,
  assignDevice,
  getAmbientNoiseBySite
};
