let coordsWidget;

const addSearchWidget = (Search, mapView) => {
  const search = new Search({
    view: mapView,
  });

  mapView.ui.add(search, "bottom-left");
};

const addZoomWidget = (Zoom, mapView) => {
  const zoomWidget = new Zoom({
    view: mapView,
  });

  mapView.ui.add([zoomWidget], "bottom-right");
};

const addCordsWidget = (mapView) => {
  coordsWidget = document.createElement("div");
  coordsWidget.innerHTML = `<div>Lat/Long  | Zoom </div>`;
  coordsWidget.id = "coordsWidget";
  coordsWidget.className = "esri-widget esri-component";
  coordsWidget.style.padding = "7px 15px 5px";
  coordsWidget.style.marginTop = "5px";

  mapView.ui.add(coordsWidget, "bottom-right");
};

export const addMapWidgets = (Search, Zoom, mapView) => {
  addZoomWidget(Zoom, mapView);
  addCordsWidget(mapView);
  addSearchWidget(Search, mapView);
};

export const showCoordinates = (pt, zoom) => {
  var coords = `Lat/Lon ${pt.latitude.toFixed(5)}/${pt.longitude.toFixed(5)} | Zoom ${zoom}`;
  coordsWidget.innerHTML = coords;
};
