import { AxiosClient } from "./axios-client";
import { handleErrorFn } from "../custom-errors";
import { authService } from "../utils/auth-service";

const URL = `/api/SuperAdmin`;


const createSuperUser = async (user) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    return await AxiosClient({
      url: URL,
      method: "POST",
      data: user,
      headers: headers,
    });
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const updateSuperUserById = async (data) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    const res = await AxiosClient({
      url: `${URL}/${data.id}`,
      method: "PUT",
      data: data,
      headers: headers,
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const getSuperUserById = async (userId) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    const res = await AxiosClient({
      url: `${URL}/${userId}`,
      method: "GET",
      headers: headers,
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const getAllSuperUsers = async () => {

    const headers = await authService.getAuthorizeHeader();
    try {
      const res = await Promise.resolve(AxiosClient({
        url: URL,
        method: "GET",
        headers: headers,
      }));
    
      return res.data;
    } catch (error) {
      throw handleErrorFn(error.response.data);
    }
};
  
const deleteSuperUser = async (userId) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    const res = await AxiosClient({
      url: `${URL}/${userId}`,
      method: "DELETE",
      headers: headers,
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

export const SuperUserAPI = {
  getSuperUserById,
  createSuperUser,
  getAllSuperUsers,
  updateSuperUserById,
  deleteSuperUser
};
