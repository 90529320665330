import React from "react";
import { IconButton, Tooltip } from "@material-ui/core";

export const RowActionBttn = (props) => {
  const { onClick, title, Icon, ...otherProps } = props;
  return (
    <Tooltip title={title}>
      <IconButton variant="outlined" size="small" onClick={onClick} {...otherProps}>
        <Icon />
      </IconButton>
    </Tooltip>
  );
};
