import React from "react";
import { IconButton, useTheme, makeStyles } from "@material-ui/core";
import { ICONS } from "../../commons";

const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const RIGHT_DIRECTION = "rtl";

export const TablePaginationActions = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  return (
    <div className={classes.root}>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === RIGHT_DIRECTION ? (
          <ICONS.KeyboardArrowRight />
        ) : (
          <ICONS.KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === RIGHT_DIRECTION ? (
          <ICONS.KeyboardArrowLeft />
        ) : (
          <ICONS.KeyboardArrowRight />
        )}
      </IconButton>
    </div>
  );
};
