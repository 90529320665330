import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const ShellIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.2642 36.9363H14.769L14.0746 31.3399L9.5288 28.0086C9.33081 27.0294 9.23077 26.0325 9.23077
         25.033C9.23077 16.8197 15.8473 10.1538 23.9998 10.1538C32.1523 10.1538 38.7688 16.8197 38.7688
          25.033C38.7688 26.0325 38.6692 27.0294 38.4708 28.0086L33.9254 31.3399L33.2306 36.9363H25.7358L25.3032
           37.2529C24.9243 37.5309 24.4679 37.6804 23.9998 37.6804C23.5317 37.6804 23.0753 37.5309 22.6964 37.2529L22.2642 36.9363Z"
        stroke="#FFFFFE"
        strokeWidth="1.75"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.2642 36.9363H14.769L14.0746 31.3399L9.5288 28.0086C9.33081 27.0294 9.23077 26.0325 9.23077
         25.033C9.23077 16.8197 15.8473 10.1538 23.9998 10.1538C32.1523 10.1538 38.7688 16.8197 38.7688
          25.033C38.7688 26.0325 38.6692 27.0294 38.4708 28.0086L33.9254 31.3399L33.2306 36.9363H25.7358L25.3032
           37.2529C24.9243 37.5309 24.4679 37.6804 23.9998 37.6804C23.5317 37.6804 23.0753 37.5309 22.6964 37.2529L22.2642 36.9363Z"
        fill="#F5CA25"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.4988 34.3323H17.0511L16.5004 29.8974L12.0134 26.6094C11.8821 26.0942
         11.8154 25.5646 11.8154 25.033C11.8154 24.1445 12.0013 23.266 12.3606 22.4551L20.3051
          30.5475L12.5815 20.9421C12.8858 19.6966 13.5581 18.5729 14.5106 17.7213L21.4339 29.7597L15.2996
           16.5803C16.0928 15.5162 17.1999 14.7318 18.462 14.3388L22.6947 29.2818L19.602 13.7223C20.5431 13.1877 21.6052
            12.9068 22.6851 12.9068C22.914 12.9068 23.1424 12.9194 23.3696 12.9446L23.9998 29.1248L24.6308 12.9446C24.8572 12.9194
             25.086 12.9068 25.3148 12.9068C26.3948 12.9068 27.4573 13.1877 28.3976 13.7223L25.3048 29.2818L29.538 14.3388C30.8001 14.7318
              31.9064 15.5162 32.7008 16.5803L26.5657 29.7597L33.489 17.7213C34.4414 18.5729 35.1146 19.6966 35.4184 20.9421L27.6948 30.5475L35.6389
               22.4551C35.9986 23.266 36.1845 24.1445 36.1845 25.033C36.1845 25.5646 36.1183 26.0942 35.9857 26.6094L31.4991 29.8974L30.9489
                34.3323H25.5012L24.695 34.9227C24.4933 35.0713 24.2495 35.1507 23.9998 35.1507C23.7501 35.1507 23.5071 35.0713 23.305 34.9227L22.4988
                 34.3323ZM22.2642 36.9363H14.7694L14.075 31.3399L9.52921 28.0086C9.33081 27.0294 9.23077 26.0325 9.23077 25.033C9.23077 16.8197 15.8477
                  10.1538 23.9998 10.1538C32.1523 10.1538 38.7692 16.8197 38.7692 25.033C38.7692 26.0325 38.6692 27.0294 38.4712 28.0086L33.9254 31.3399L33.231
                   36.9363H25.7362L25.3032 37.2529C24.9247 37.5309 24.4679 37.6804 23.9998 37.6804C23.5317 37.6804 23.0753 37.5309 22.6968 37.2529L22.2642 36.9363Z"
        fill="#C9242B"
      />
    </SvgIcon>
  );
};
