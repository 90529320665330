import React, { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import {
  makeStyles,
  Paper,
  Table,
  TableFooter,
  TableBody,
  TableContainer,
  TablePagination,
  useTheme,
  useMediaQuery,
  Button,
} from "@material-ui/core";
import {
  useCustomTableStyles,
  EnhancedTableHead,
  useCustomTableHook,
  UserPermissionContext,
  ICONS,
  useDebounce,
} from "../../../../../commons";
import { SITES_REDUCER_TYPES } from "../../../../../reducers";
import { Row } from "./row";
import { AppUtils, parseUtcToLocal } from "../../../../../utils";

const headCells = [
  {
    id: "categoryName",
    sortBy: "name",
    label: "Category Name",
    content: (row) => row.name,
  },
  {
    id: "numCategoryUsers",
    sortBy: "totalUsersCount",
    label: "Current Number of Users",
    content: (row) => row.totalUsersCount,
  },
  {
    id: "dateCreated",
    sortBy: "createdTimestamp",
    label: "Date Created",
    content: (row) => parseUtcToLocal(row.createdTimestamp),
  },
  {
    id: "createdBy",
    sortBy: "createdBy",
    label: "Created By",
    content: (row) => AppUtils.parseEmailToName(row.createdBy),
  },
];

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  root: {
    width: "100%",
  },
  buttonRoot: {
    border: "1px solid white",
  },
  tableFooter: {
    backgroundColor: theme.palette.base.elevation_overlay_1,
  },
}));

export const UserCategoryPermissionTable = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const tableStyles = useCustomTableStyles();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("sm"));

  const { data, dependencyMatrix, suggestionsMatrix } = props;

  const [tableData, setTableData] = useState([]);
  const [tableSearchStr, setTableSearchStr] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);

  // Permissions
  const { Permissions } = useContext(UserPermissionContext);

  const tableSearchDebounced = useDebounce((_nextVal) => {
    setPage(0);
    setTableSearchStr(_nextVal);
  }, 300);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  useEffect(() => {
    let arr = [];

    if (tableSearchStr) {
      arr = data.filter(
        (tData) => tData.name && tData.name.toLowerCase().includes(tableSearchStr.toLowerCase())
      );
    } else {
      arr = data;
    }

    setTableData(arr);
  }, [tableSearchStr, setTableData, data]);

  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    setPage,
    getComparator,
    stableSort,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useCustomTableHook({ defaultSortBy: "name", rowsPerPage: 5 });

  const checkIfRowSelected = (id) => selectedCategory && selectedCategory.id === id;

  function onTableExpandBttnCb(rowData) {
    if (selectedCategory) {
      setSelectedCategory(null);
    } else {
      setSelectedCategory(rowData);
    }
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer style={{ maxHeight: isTablet ? "24vh" : "60vh" }}>
          <Table className={classes.table} stickyHeader>
            <EnhancedTableHead
              isOverlay={true}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
              tableWithRowActions={true}
              onChangeSearch={(event) => {
                tableSearchDebounced(event.target.value);
              }}
              searchLabel={"Category Name"}
              searchTestId="search-user-category-name"
            />
            <TableBody>
              {stableSort(tableData, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((rowData, index) => {
                  const isItemSelected = checkIfRowSelected(rowData.id);
                  return (
                    <Row
                      key={rowData.id}
                      rowData={rowData}
                      headCells={headCells}
                      isItemSelected={isItemSelected}
                      onTableExpandBttnCb={onTableExpandBttnCb}
                      canManagePermissions={Permissions.Manage_User_Categories}
                      dependencyMatrix={dependencyMatrix}
                      suggestionsMatrix={suggestionsMatrix}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TableFooter
          component="div"
          className={tableStyles.tabelFooterRoot}
          classes={{
            root: classes.tableFooter,
          }}
        >
          {Permissions.Manage_User_Categories ? (
            <Button
            disabled={!Permissions.Manage_Sites}
              startIcon={<ICONS.Add />}
              color="primary"
              onClick={() => {
                dispatch({
                  type: SITES_REDUCER_TYPES.SET_CREATE_NEW_USER_CATEGORY_DIALOG,
                  payload: true,
                });
              }}
            >
              Add New Category
            </Button>
          ) : null}
          <TablePagination
            classes={{
              root: tableStyles.tablePaginationRoot,
            }}
            rowsPerPageOptions={[]}
            component="div"
            count={data?.length}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            labelRowsPerPage=""
          />
        </TableFooter>
      </Paper>
    </div>
  );
};
