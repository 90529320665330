import _ from "lodash";
import { FONTS } from "../utils";

const COLOR_PRIMARY = "#EFBF04";

export const themeConfig = {
  breakpoints: {
    keys: ["tablet", "laptop", "xs", "sm", "md", "lg", "xl"],
    values: {
      tablet: 640,
      laptop: 1024,
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    common: {
      black: "#000",
      white: "#fff",
    },
    primary: {
      main: COLOR_PRIMARY,
    },
    base: {
      elevation_0: "#1D1D2F",
      elevation_1: "#2B2B36",
      elevation_2: "#373746",
      elevation_3: "#464659",
      elevation_4: "#FFFFFF",
      elevation_5: "#373746",
      elevation_6: "#2B2B36",
      elevation_overlay_1: "#373746",
      elevation_overlay_2: "#464659",
      elevation_overlay_3: "#4F4F64",
      linkColor: "#4fb0c6",
      inputBorderColor: "rgba(255, 255, 255, 0.15)",
    },
    type: "dark",
    background: {
      default: "#1D1D25", // adds to the background color
      paper: "#373746", // style added
    },
  },
  typography: {
    fontFamily: ["Arial"].join(","),
    h4: {
      fontWeight: 600,
      fontSize: "24px",
      color: "green",
    },
    h9: {
      fontWeight: 300,
      fontSize: "16px",
      color: "red",
    },
  },
  overrides: {
    MuiMenuItem: {
      root: {
        "&$selected": {
          color: COLOR_PRIMARY,
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        borderRadius: "2px",
        paddingLeft: "24px",
        paddingRight: "24px",
        ...FONTS.Button1_Bold_16,
      },
      outlined: {
        borderRadius: "2px",
        paddingLeft: "24px",
        paddingRight: "24px",
      },
    },
    MuiCssBaseline: {
      "@global": {
        "*::-webkit-scrollbar-track": {
          background: "#2b2b36",
        },
        "*::-webkit-scrollbar-thumb": {
          background: "#7f7f7f",
        },
        body: {
          fontSize: "1.2rem",
        },
      },
    },
  },
};

export const lightThemeConfig = _.merge({}, themeConfig, {
  palette: {
    base: {
      elevation_0: "#FFFFFF",
      elevation_1: "#FFFFFF",
      elevation_2: "#F0F0F0",
      elevation_3: "#E8E8E8",
      elevation_4: "#000000",
      elevation_5: "#DDECF0",
      elevation_6: "#FBFAFA",
      elevation_overlay_1: "#F0F0F0",
      elevation_overlay_2: "#E8E8E8",
      elevation_overlay_3: "#FFFFFF",
      linkColor: "#32619F",
      inputBorderColor: "rgba(0, 0, 0, 0.15)",
    },
    type: "light",
    background: {
      default: "#FBFAFA", // adds to the background color
      paper: "#F0F0F0", // style added
    },
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(255, 255, 255, 0.7)",
      },
    },
    MuiCssBaseline: {
      "@global": {
        "*::-webkit-scrollbar-track": {
          background: "#F5F5F5",
        },
        "*::-webkit-scrollbar-thumb": {
          background: "#ffffff",
        },
      },
    },
  },
});
