import React from "react";
import { Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { GLOBAL_REDUCER_TYPES } from "../../reducers";


const plotClearContainer = ({ alert, classes, getAlertIcon,
   onActionBttnClick, dispatch, SNACKBAR_ACTIONS_LABELS,routePrefix, getAlertMessageFn, history}) => {
  return (
    <Alert
      severity="error"
      key={alert.id}
      classes={{
        root: classes.alertRoot,
        icon: classes.icon,
      }}
      icon={getAlertIcon(alert, dispatch)}
      action={
        alert.zone?.name ? (
          <Button
            color="inherit"
            size="small"
            onClick={() => {
              onActionBttnClick(alert.zone.id);
              dispatch({
                type: GLOBAL_REDUCER_TYPES.SET_SNACKBAR_SEEN_ALERTS,
                payload: alert.id,
              });
              if (!alert.muted) {
                dispatch({
                  type: GLOBAL_REDUCER_TYPES.SET_MUTED_ALERTS_IDS,
                  payload: alert.id,
                });
              }
            }}
          >
            {SNACKBAR_ACTIONS_LABELS.GO_TO_ZONE}
          </Button>
        ) : (
          <Button
            color="inherit"
            size="small"
            onClick={() => {
              history.push(`${routePrefix}/alerts`);
              dispatch({
                type: GLOBAL_REDUCER_TYPES.SET_SNACKBAR_SEEN_ALERTS,
                payload: alert.id,
              });
            }}
          >
            {SNACKBAR_ACTIONS_LABELS.GO_TO_ALERTS}
          </Button>
        )
      }
    >
      {getAlertMessageFn(alert.alertTitle, alert.zone?.name)}
    </Alert>
  );
}


export const SingleAlertSnackbar = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const jarvisScope = window?.jarvis?.user;
  const routePrefix = jarvisScope ? "/sitewise" : "";

  const {
    activeAlerts,
    classes,
    onActionBttnClick,
    SNACKBAR_ACTIONS_LABELS,
    getAlertIcon,
    getAlertMessageFn,
  } = props;

  return activeAlerts.map((alert) => {
    if (alert.alertType !== "PlotClear") {
      return(plotClearContainer({ alert, classes, getAlertIcon, onActionBttnClick,
        dispatch, SNACKBAR_ACTIONS_LABELS,routePrefix, getAlertMessageFn, history})
      )
    } else {
      return (
        <Alert
          severity="error"
          key={alert.id}
          classes={{
            root: classes.alertRoot,
            icon: classes.icon,
          }}
          action={
            alert?.zone?.name ? (
              <Button
                color="inherit"
                size="small"
                onClick={() => {
                  dispatch({
                    type: GLOBAL_REDUCER_TYPES.SET_SNACKBAR_SEEN_ALERTS,
                    payload: alert.id,
                  });
                  onActionBttnClick(alert.zone.id);
                  if (!alert.muted) {
                    dispatch({
                      type: GLOBAL_REDUCER_TYPES.SET_MUTED_ALERTS_IDS,
                      payload: alert.id,
                    });
                  }
                }}
              >
                {SNACKBAR_ACTIONS_LABELS.GO_TO_ZONE}
              </Button>
            ) : (
              <Button
                color="inherit"
                size="small"
                onClick={() => {
                  history.push(`${routePrefix}/alerts`);
                  dispatch({
                    type: GLOBAL_REDUCER_TYPES.SET_SNACKBAR_SEEN_ALERTS,
                    payload: alert.id,
                  });
                }}
              >
                {SNACKBAR_ACTIONS_LABELS.GO_TO_ALERTS}
              </Button>
            )
          }
        >
          {getAlertMessageFn(alert.alertTitle, alert.zone?.name)}
        </Alert>
      );
    }
  });
};
