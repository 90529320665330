const GasDetectedAlertColor = {
  colorCode: [255, 148, 0],
  type: "GAS_DETECTED_ALERT",
  label: "GAS DETECTED",
};

const ManDownAlertColor = {
  colorCode: [255, 255, 255],
  type: "MAN_DOWN_ALERT",
  label: "MAN DOWN",
};

const PlotClearAlertColor = {
  colorCode: [255, 69, 58],
  type: "PLOT_CLEAR_ALERT",
  label: "EVACUATION",
};

const SOSAlertColor = {
  colorCode: [255, 255, 255],
  type: "SOS_ALERT",
  label: "SOS ALERT",
};

const DefaultAlertColor = {
  colorCode: [255, 255, 255],
  type: "DEFAULT",
  label: "ALERT",
};

const AlertColor = function (alertType) {
  let colour = null;
  switch (alertType) {
    case "GasDetected":
      colour = GasDetectedAlertColor;
      break;
    case "ManDown":
      colour = ManDownAlertColor;
      break;
    case "SOS":
      colour = SOSAlertColor;
      break;
    case "PlotClear":
      colour = PlotClearAlertColor;
      break;
    default:
      colour = DefaultAlertColor;
      break;
  }
  return colour;
};

export const ALERT_COLOR_CODES = {
  AlertColor,
  GasDetectedAlertColor,
  ManDownAlertColor,
  PlotClearAlertColor,
  SOSAlertColor,
  DefaultAlertColor,
};
