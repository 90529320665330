import React from "react";
import { FormControl, TextField } from "@material-ui/core";

export const DraftMessageTextfield = (props) => {
  const { show, classes, message, helperText, onChangeCallback } = props;

  if (show) {
    return (
      <FormControl variant="filled" className={classes.formControl}>
        <TextField
          autoFocus
          multiline
          rows={3}
          rowsMax={3}
          label="Message"
          variant="filled"
          value={message}
          helperText={helperText}
          onChange={onChangeCallback}
        />
      </FormControl>
    );
  }

  return null;
};
