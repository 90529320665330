import { AxiosClient } from "./axios-client";
import { handleErrorFn } from "../custom-errors";
import { authService } from "../utils/auth-service";

const API_URL = "/api/messageDraft";

const getAllMessageDraftsBySiteId = async (siteId) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    const response = await AxiosClient({
      url: `/api/SiteLocation/${siteId}/MessageDraft`,
      method: "GET",
      headers: headers,
    });
    return response.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const getMessageDraftById = async (id) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    const response = await AxiosClient({
      url: `${API_URL}/${id}`,
      method: "GET",
      headers: headers,
    });
    return response.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const createMessageDraft = async (siteId, message) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    return await AxiosClient({
      url: API_URL,
      method: "POST",
      data: {
        siteLocationId: siteId,
        message: message,
      },
      headers,
    });
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const deleteMessageDraft = async (id) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    const res = await AxiosClient({
      url: `${API_URL}/${id}`,
      method: "DELETE",
      headers,
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

export const MessageDraftsAPI = {
  getMessageDraftById,
  createMessageDraft,
  deleteMessageDraft,
  getAllMessageDraftsBySiteId,
};
