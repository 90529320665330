export const ZonesColorCodes = [
  {
    colorCode: [238, 238, 238],
    hex: "#00B0FF",
    type: "DEFAULT",
    label: "Light Blue",
  },
  {
    colorCode: [255, 180, 204],
    hex: "#FF80AB",
    type: "ZONE_OPTION_2",
    label: "Coral Pink",
  },
  {
    colorCode: [251, 226, 254],
    hex: "#EA80FC",
    type: "ZONE_OPTION_3",
    label: "Lilac",
  },
  {
    colorCode: [202, 172, 255],
    hex: "#B388FF",
    type: "ZONE_OPTION_4",
    label: "Grape Soda",
  },
  {
    colorCode: [189, 196, 255],
    hex: "#B388FF",
    type: "ZONE_OPTION_5",
    label: "Light Purple",
  },
  {
    colorCode: [197, 221, 255],
    hex: "#B388FF",
    type: "ZONE_OPTION_6",
    label: "Light Blue",
  },
  {
    colorCode: [179, 232, 255],
    hex: "#80D8FF",
    type: "ZONE_OPTION_7",
    label: "Sky Blue",
  },
  {
    colorCode: [184, 255, 253],
    hex: "#84FFFF",
    type: "ZONE_OPTION_8",
    label: "Light Teal",
  },
  {
    colorCode: [169, 227, 216],
    hex: "#00BFA5",
    type: "ZONE_OPTION_9",
    label: "Emerald",
  },
  {
    colorCode: [173, 246, 207],
    hex: "#69F0AE",
    type: "ZONE_OPTION_10",
    label: "Light Green",
  },
  {
    colorCode: [255, 254, 189],
    hex: "#69F0AE",
    type: "ZONE_OPTION_11",
    label: "Yellow Green",
  },
  {
    colorCode: [249, 252, 199],
    hex: "#69F0AE",
    type: "ZONE_OPTION_12",
    label: "Soft Yellow",
  },
  {
    colorCode: [255, 254, 157],
    hex: "#FFEE58",
    type: "ZONE_OPTION_13",
    label: "Lime Green",
  },
  {
    colorCode: [255, 243, 215],
    type: "ZONE_OPTION_14",
    hex: "#FFE57F",
    label: "Light Mustard",
  },
  {
    colorCode: [255, 226, 184],
    type: "ZONE_OPTION_15",
    hex: "#FFAB40",
    label: "Sunset",
  },
  {
    colorCode: [255, 203, 187],
    type: "ZONE_OPTION_16",
    hex: "#FF6E40",
    label: "Burnt Orange",
  },
  {
    colorCode: [215, 204, 200],
    type: "ZONE_OPTION_17",
    hex: "#FF6E40",
    label: "Soft Wood",
  },
  {
    colorCode: [181, 195, 200],
    type: "ZONE_OPTION_18",
    hex: "#FF6E40",
    label: "Blue Gray",
  },
];

export const PlotClearZoneColor = {
  colorCode: [255, 69, 58],
  type: "PLOT_CLEAR_ZONE",
  label: "Red",
};

export const GasDetectedZoneColor = {
  colorCode: [255, 148, 0],
  type: "GAS_DETECTED_ZONE",
  label: "Orange",
};

export const MusterZoneColor = {
  colorCode: [139, 195, 74],
  type: "MUSTER_ZONE",
  label: "Green",
};
