export const isZoneCategoryActive = (cat) => {
  const nowDate = new Date();

  return !(
    cat.isAdHoc && !(nowDate.toISOString() > cat.startTime && nowDate.toISOString() < cat.endTime)
  );
};

export const parseEmailToName = (email, tooltip = true) => {
  if (email) {
    return email.split("@")[0].toString().length > 20 && !tooltip
      ? `${email.split("@")[0].toString().slice(0, 17)}...`
      : email.split("@")[0].toString();
  } else {
    return null;
  }
};

export const AppUtils = {
  isZoneCategoryActive: isZoneCategoryActive,
  parseEmailToName: parseEmailToName,
};
