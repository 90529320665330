import React from "react";
import { Select, MenuItem, InputLabel, makeStyles } from "@material-ui/core";
import { parseUtcToLocal } from "../../../../../utils";

const styles = makeStyles((theme) => ({
  select: {
    color: theme.palette.primary.main,
    "&:before": {
      borderBottom: "none !important",
    },
  },
}));

export const DraftMessegesDropdown = (props) => {
  const classes = styles();
  const { value, options, onChange, ...otherProps } = props;
  return (
    <>
      <InputLabel id="draft-messeges-dropdown-label">Message Type</InputLabel>
      <Select
        required
        className={classes.select}
        labelId="draft-messeges-dropdown-label"
        value={value}
        id="draft-messeges-dropdown"
        onChange={onChange}
        data-testid="draft-messeges-dropdown"
        {...otherProps}
      >
        <MenuItem value="NEW_MESSAGE">New Custom Message</MenuItem>
        {options.map((option) => {
          return (
            <MenuItem key={option.id} value={option}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>Saved Message: {parseUtcToLocal(option.createdTimestamp)}</div>
                <div>"{option.message}"</div>
              </div>
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};
