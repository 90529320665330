import React, { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Box, Button } from "@material-ui/core";
import {
  DocumentTitle,
  UserPermissionContext,
  useCommonStyles,
  RemoveDialog as ConfirmDeactivateDialog,
  InfoDialog,
  ICONS,
} from "../../../../commons";
import {
  SITES_REDUCER_TYPES,
  GlobalReducerActions,
  SitesReducerActions,
  AuthReducerActions,
} from "../../../../reducers";
import { SiteCard, CreateNewSiteDialog, SuperAdminSettingsDialog } from "../../components";
import { CreateNewSuperUserDialog } from "../../components/dialogs/create-new-super-user-dialog";
import { ConfirmReactivateDialog } from "./confirm-reactivate-site-dialog";
import { parseUtcToLocal } from "../../../../utils";

function getLastModified(reactivateSiteDialog) {
  return reactivateSiteDialog?.site?.lastModifiedTimestamp
    ? `<p> Last Save Date: ${parseUtcToLocal(reactivateSiteDialog.site.lastModifiedTimestamp)}</p>`
    : "";
}
function getLastModifiedBy(reactivateSiteDialog) {
  return reactivateSiteDialog?.site?.lastModifiedBy
    ? `<p> Deactivated by: ${reactivateSiteDialog.site.lastModifiedBy}</p>`
    : "";
}

const SitesScreen = () => {
  const commonStyles = useCommonStyles();
  const dispatch = useDispatch();
  const { Permissions } = useContext(UserPermissionContext);

  const showCreateNewSiteDialog = useSelector(
    (state) => state.sitesScreen.dialogs.showCreateNewSiteDialog
  );

  const showSuperAdminSettingsDialog = useSelector(
    (state) => state.sitesScreen.dialogs.showSuperAdminSettingsDialog
  );

  const showCreateNewSuperUserDialog = useSelector(
    (state) => state.sitesScreen.dialogs.showCreateNewSuperUserDialog
  );

  const deactivateSiteDialog = useSelector(
    (state) => state.sitesScreen.dialogs.deactivateSiteDialog
  );

  const reactivateSiteDialog = useSelector(
    (state) => state.sitesScreen.dialogs.reactivateSiteDialog
  );

  const sites = useSelector((state) => state.sitesScreen.sites);
  const activeSites = sites.filter((site) => site.active);

  useEffect(() => {
    dispatch(GlobalReducerActions.showPageLoader());
    dispatch(SitesReducerActions.init());
  }, [dispatch]);

  useEffect(() => {
    dispatch(AuthReducerActions.resetAuthorizedRoute());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DocumentTitle title="Sitewise | Sites" />
      <Grid id="j-sitewise-sites" className={commonStyles.page_wrapper}>
        <Box className={commonStyles.page_header}>
          <span className={commonStyles.page_title} data-testid="page-title">
            All Sites{" "}
            <span className={commonStyles.page_subtitle} data-testid="page-subtitle">
              {activeSites.length} Active, {sites.length} Total
            </span>
          </span>

          {Permissions.Manage_Sites ? (
            <div style={{ 
              display: "flex", 
              flexDirection: "row", 
              alignItems: "center",
              gap: "20px" 
            }}>
              <Button
                startIcon={<ICONS.SettingsCustomIcon />}
                data-testid="super-admin-settings-bttn"
                variant="outlined"
                onClick={() => {
                  dispatch({
                    type: SITES_REDUCER_TYPES.SET_SUPER_ADMIN_SETTINGS_DISPLAY,
                    payload: true,
                  });
                }}
              >
                Super admin settings
              </Button>

              <Button
                startIcon={<ICONS.AddCustomIcon style={{ fontSize: "16px" }} />}
                data-testid="create-site-bttn"
                variant="contained"
                disableElevation={true}
                color="primary"
                onClick={() => {
                  dispatch({
                    type: SITES_REDUCER_TYPES.SET_CREATE_NEW_SITE_DIALOG_DISPLAY,
                    payload: true,
                  });
                }}
              >
                Create new site
              </Button>
            </div>
          ) : null}
        </Box>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "flex-start",
            flexWrap: "wrap",
            overflowY: "auto",
            height: "70vh",
            gap: "85px 78px",
          }}
        >
          {sites.map((site) => {
            return <SiteCard key={site.id} site={site} />;
          })}
        </div>

        <SuperAdminSettingsDialog
          show={showSuperAdminSettingsDialog}
          onCancel={() => {
            dispatch({
              type: SITES_REDUCER_TYPES.SET_SUPER_ADMIN_SETTINGS_DISPLAY,
              payload: false,
            });
          }}
        />

        <CreateNewSiteDialog
          show={showCreateNewSiteDialog}
          onCancel={() => {
            dispatch({
              type: SITES_REDUCER_TYPES.SET_CREATE_NEW_SITE_DIALOG_DISPLAY,
              payload: false,
            });
          }}
        />

        <CreateNewSuperUserDialog
          show={showCreateNewSuperUserDialog}
          onCancel={() => {
            dispatch({
              type: SITES_REDUCER_TYPES.SET_CREATE_NEW_SUPER_ADMIN_USER_DIALOG_DISPLAY,
              payload: false,
            });
          }}
        />

        <ConfirmReactivateDialog
          title={`Are you sure you want to reactivate ${reactivateSiteDialog?.site?.name}?`}
          show={reactivateSiteDialog.showConfirmDialog}
          fullMapView={true}
          onConfirm={() => {
            dispatch(SitesReducerActions.deactivateSite(false));
          }}
          onCancel={(event) => {
            event.stopPropagation();
            dispatch({
              type: SITES_REDUCER_TYPES.SET_REACTIVATE_SITE_DIALOG,
              payload: {
                showConfirmDialog: false,
                site: null,
                showInfoDialog: false,
              },
            });
          }}
        />

        <ConfirmDeactivateDialog
          title={`Are you sure you want to deactivate ${deactivateSiteDialog?.site?.name}?`}
          show={deactivateSiteDialog.showConfirmDialog}
          fullMapView={true}
          onConfirm={() => {
            dispatch(SitesReducerActions.deactivateSite(true));
          }}
          onCancel={(event) => {
            event.stopPropagation();
            dispatch({
              type: SITES_REDUCER_TYPES.SET_DEACTIVATE_SITE_DIALOG,
              payload: {
                showConfirmDialog: false,
                site: null,
                showInfoDialog: false,
              },
            });
          }}
        />

        <InfoDialog
          infoText={`<div>
            <p>By reactivating this site, you restore all previous settings and data points. Any users who had access before will regain access immediately upon reactivation.</p>
            ${getLastModified(reactivateSiteDialog)}
            ${getLastModifiedBy(reactivateSiteDialog)}
          </div>`}
          bttnOne={{ label: "Next", Icon: ICONS.AddCircle }}
          bttnTwo={{ label: "Cancel" }}
          show={reactivateSiteDialog.showInfoDialog}
          onConfirmActionOne={() => {
            dispatch({
              type: SITES_REDUCER_TYPES.SET_REACTIVATE_SITE_DIALOG,
              payload: {
                showConfirmDialog: true,
              },
            });
          }}
          onConfirmActionTwo={() =>
            dispatch({
              type: SITES_REDUCER_TYPES.SET_REACTIVATE_SITE_DIALOG,
              payload: {
                showInfoDialog: false,
                site: null,
                showConfirmDialog: false,
              },
            })
          }
        />

        <InfoDialog
          infoText={`<div>
          <p>
            By deactivating a site, users will no longer have access and all people location and alert functionality will be unavailable.
          </p>
          <p>
            Data up till deactivation will be saved. Sites may be reactived by a Super Admin.
          </p>
          </div>`}
          bttnOne={{ label: "Next", Icon: ICONS.RemoveCircleOutline }}
          bttnTwo={{ label: "Cancel" }}
          show={deactivateSiteDialog.showInfoDialog}
          onConfirmActionOne={() => {
            dispatch({
              type: SITES_REDUCER_TYPES.SET_DEACTIVATE_SITE_DIALOG,
              payload: {
                showConfirmDialog: true,
              },
            });
          }}
          onConfirmActionTwo={() =>
            dispatch({
              type: SITES_REDUCER_TYPES.SET_DEACTIVATE_SITE_DIALOG,
              payload: {
                showInfoDialog: false,
                site: null,
                showConfirmDialog: false,
              },
            })
          }
        />
      </Grid>
    </>
  );
};

export default SitesScreen;
