import React from "react";
import { Box, Dialog, DialogTitle } from "@material-ui/core";
import { TabPanel } from "../../../../../commons/tabs";
import { DialogActionBttnBig } from "../../../../../commons/dialogs/dialog-action-bttn-big";
import { useDialogStyles } from "../../../../../commons/dialogs/use-dialog-styles";
import { ICONS } from "../../../../../commons";

export const SecondTab = (props) => {
  const classes = useDialogStyles();
  const {
    tabValue,
    handleFormSubmit,
    handleCancelBttnClick,
    name,
  } = props;

  function checkKeyDown(e) {
    if (e.code === "Enter") {
      e.preventDefault();
    }
  }

  return (
    <TabPanel value={tabValue} index={1}>
        <Dialog open="true" onClose={handleCancelBttnClick} PaperProps={{ className: classes.dialogPaper }}>
          <Box className={classes.dialogContentWrapper}>
            <DialogTitle
              className={classes.dialogTitleCentered}
              disableTypography
              id="responsive-dialog-title"
            >
              <div>Are you sure you want to add this user as a Super Admin?</div>
              
            </DialogTitle>
            <div className={classes.dialogTitleCentered}>{name}</div>
            <Box className={classes.actionsBttnWrapper}>
              <DialogActionBttnBig
                label="Yes"
                BttnIcon={ICONS.AddCircle}
                onClick={handleFormSubmit}
                onKeyDown={(e) => checkKeyDown(e)}
              />
              <DialogActionBttnBig label="Cancel" onClick={handleCancelBttnClick} />
            </Box>
          </Box>
        </Dialog>
    </TabPanel>
  );
};
