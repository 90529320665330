class SDClass {
  dashboardMessageSound() {
    try {
      document.getElementById("notification").muted = false;
      document.getElementById("notification").play();
    } catch (error) {
      console.log("error in notification sound", error);
    }
  }

  alertSound(start) {
    try {
      if (start) {
        document.getElementById("alert").muted = false;
        document.getElementById("alert").play();
      } else {
        document.getElementById("alert").pause();
      }
    } catch (error) {
      console.log("error", error);
    }
  }
}

export const SoundManager = new SDClass();
