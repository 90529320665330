import React, { useState, useEffect, useContext } from "react";
import { makeStyles, Dialog, DialogTitle, FormControl, Button, Box } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { DevicesSearch } from "../../devices-search";
import { UsersSearch } from "../../users-search";
import { DevicesReducerActions } from "../../../../../reducers";
import { useDialogStyles, UserPermissionContext } from "../../../../../commons";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    marginBottom: "32px",
  },
  formControlActions: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  assignWarning: {
    color: theme.palette.warning.main,
  },
}));

const REASSIGN_USER_WARNING = `This device is already assigned to a user. If you assign a user, it will reassign the device to a new user.`;

export const AssignNewDeviceDialog = ({ show, onCancel, onConfirm, siteID }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dialogStyles = useDialogStyles();
  const [toAssignDevice, setToAssignDevice] = useState(null);
  const [toAssignUser, setToAssignUser] = useState(null);
  const [assignBttnDisabled, setAssignBttnDisabled] = useState(true);

  const { Permissions } = useContext(UserPermissionContext);

  function onCancelBttnClick() {
    onCancel && onCancel();
  }

  function resetAssignNewDialogForm() {
    setToAssignDevice(null);
    setToAssignUser(null);

    setAssignBttnDisabled(true);
  }

  function handleFormSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    dispatch(DevicesReducerActions.assignDevice(toAssignDevice, toAssignUser));
    onConfirm && onConfirm(event);
  }

  useEffect(() => {
    resetAssignNewDialogForm();
  }, [show]);

  useEffect(() => {
    if (toAssignDevice && toAssignUser) {
      setAssignBttnDisabled(false);
    } else {
      setAssignBttnDisabled(true);
    }
  }, [toAssignDevice, toAssignUser]);

  if (!show) {
    return null;
  }

  return (
    <Dialog
      open={show}
      onClose={onCancel}
      PaperProps={{ className: dialogStyles.dialogPaper }}
      data-testid="assign-new-device-dialog"
    >
      <Box className={dialogStyles.dialogContentWrapper}>
        <DialogTitle
          className={dialogStyles.dialogTitle}
          disableTypography
          id="responsive-dialog-title"
          onClose={onCancel}
        >
          <span>Assign New Device</span>
        </DialogTitle>
        <form className={classes.form} autoComplete="off" onSubmit={handleFormSubmit}>
          <FormControl required variant="filled" className={classes.formControl}>
            <DevicesSearch
              label="Device ID"
              value={toAssignDevice}
              onAutoCompleteChange={(event, newVal) => {
                event.stopPropagation();
                setToAssignDevice(newVal);
              }}
              siteID={siteID}
              queryParams={{
                ct: 10,
              }}
            />

            {toAssignDevice && toAssignDevice.currentAssignedUser ? (
              <div>
                <span className={classes.assignWarning}>{REASSIGN_USER_WARNING}</span>
              </div>
            ) : null}
          </FormControl>

          {Permissions.View_Users && (
            <FormControl required variant="filled" className={classes.formControl}>
              <UsersSearch
                label="Search for User"
                onAutoCompleteChange={(event, newVal) => {
                  event.stopPropagation();
                  setToAssignUser(newVal);
                }}
                siteID={siteID}
                queryParams={{
                  ct: 10
                }}
              />
            </FormControl>
          )}

          <FormControl variant="filled" className={classes.formControlActions}>
            <Button
              color="primary"
              variant="outlined"
              onClick={onCancelBttnClick}
              className={dialogStyles.dialogActionBttn}
              data-testid="cancel-bttn"
            >
              Cancel
            </Button>

            <Button
              disabled={assignBttnDisabled}
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginLeft: "10px" }}
              className={dialogStyles.dialogActionBttn}
              data-testid="confirm-bttn"
            >
              Assign Device
            </Button>
          </FormControl>
        </form>
      </Box>
    </Dialog>
  );
};
