import React from "react";
import { Box, Dialog, DialogTitle } from "@material-ui/core";
import { DialogActionBttnBig, DialogCloseBttn } from "../";
import { useDialogStyles } from "../use-dialog-styles";

export const TwoActionsDialog = ({
  title,
  show,
  onCancel,
  bttnOne,
  bttnTwo,
  onConfirmActionOne,
  onConfirmActionTwo,
  showDialogCloseBttn,
}) => {
  const classes = useDialogStyles();

  if (!show) {
    return null;
  }

  return (
    <Dialog open={show} onClose={onCancel} PaperProps={{ className: classes.dialogPaper }}>
      {showDialogCloseBttn ? (
        <Box className={classes.closeIconWrapper}>
          <DialogCloseBttn onClick={onCancel} />
        </Box>
      ) : null}
      <Box className={classes.dialogContentWrapper}>
        <DialogTitle
          className={`${classes.dialogTitleCentered}`}
          disableTypography
          id="responsive-dialog-title"
          onClose={onCancel}
        >
          <span>{title}</span>
        </DialogTitle>
        <Box className={classes.actionsBttnWrapper}>
          <DialogActionBttnBig
            BttnIcon={bttnOne.Icon}
            label={bttnOne.label}
            onClick={onConfirmActionOne}
          />
          <DialogActionBttnBig
            BttnIcon={bttnTwo.Icon}
            label={bttnTwo.label}
            onClick={onConfirmActionTwo}
          />
        </Box>
      </Box>
    </Dialog>
  );
};
