import React, { useState, useEffect } from "react";
import { TextField, Typography, makeStyles } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FONTS } from "../../../../utils";
import { UserAPI } from "../../../../api";
import { useDebounce } from "../../../../commons";
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
  option: {
    minHeight: "auto",
    alignItems: "flex-start",
    padding: 8,
    '&[data-focus="true"]': {
      color: theme.palette.primary.main,
    },
  },
  menuItemName: {
    ...FONTS.B3_Reg_14,
  },
  menuItemCompany: {
    ...FONTS.B5_Reg_12,
  },
}));

const filterOptions = createFilterOptions({
   stringify: (option) => JSON.stringify(option),
 });

export const UsersSearch = (props) => {
  const classes = useStyles();

  const { label, onAutoCompleteChange, value, siteID, queryParams } = props;

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [userSearchString, setUserSearchString] = useState("");
  const [loading, setLoading] = useState(false);

  const onUserSearchTextfiledChangeDebounced = useDebounce(
    (_nextVal) => setUserSearchString(_nextVal),
    300
  );

  useEffect(() => {
    if (!open) {
      setOptions([]);
      setUserSearchString("");
    }
  }, [open]);

  useEffect(() => {
    if (userSearchString) {
      const params = new URLSearchParams({ ...queryParams, q: userSearchString });
      const queryString = params.toString();
      setLoading(true);
      UserAPI.getSitelocationUsersByQueryString(siteID, queryString).then((response) => {
        setOptions(response);
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSearchString, siteID]);

  return (
    <Autocomplete
      value={value}
      onChange={onAutoCompleteChange}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      loading={loading}
      id="user-search-component"
      data-testid="user-search-component"
      options={options}
      classes={{
        option: classes.option,
      }}
      getOptionLabel={(option) => option.email}
      filterOptions={filterOptions}
      renderInput={(params) => (
        <TextField
          label={label}
          margin="normal"
          variant="filled"
          onChange={(e) => onUserSearchTextfiledChangeDebounced(e.target.value)}
          inputProps={{
            "data-testid": "user-search-component-textfield",
          }}
          {...params}
        />
      )}
      renderOption={(option) => {
        return (
          <Typography
            style={{
              display: "flex",
              flexDirection: "column",
              paddingTop: "5px",
              paddingBottom: "5px",
            }}
          >
            <span className={classes.menuItemName}>{option.name}</span>
            <span className={classes.menuItemName}>{option.email}</span>
            <span className={classes.menuItemCompany}>{option.company}</span>
            <span className={classes.menuItemCompany}>{option.id}</span>
          </Typography>
        );
      }}
    />
  );
};
