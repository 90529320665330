import React from "react";
import { Box, Button, makeStyles } from "@material-ui/core";
import { FONTS } from "../../../../utils";

const messagesWhenAcknowledgeEnabled = {
  Declined: "You have been Opted-Out of Sitewise",
  Accepted: "You have been added to Sitewise",
};

const messagesWhenAcknowledgeNotEnabled = {
  Declined: "You have been Opted-Out of Sitewise",
  Accepted: "You have been Opted-In to Sitewise",
};

const useStyles = makeStyles((theme) => ({
  box: {
    padding: "24px 30px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    backgroundColor: theme.palette.base.elevation_2,
    margin: "30px",
    width: "calc(100% - 60px)",
  },
  button: {
    marginLeft: "32px",
  },
}));

export const PrivacyAcknowledgeForm = (props) => {
  const { userStatus, onClick, siteLocation, isAcknowledgementEnabled, isAcknowledged } = props;
  const classes = useStyles();
  const optedMessage =
    `${(isAcknowledgementEnabled
      ? messagesWhenAcknowledgeEnabled[userStatus]
      : messagesWhenAcknowledgeNotEnabled[userStatus])} - ${siteLocation}`

  return (
    <Box className={classes.box} data-testid="privacy-acknowledge-form">
      <div style={{ ...FONTS.H5_ShellBold_18 }}>{optedMessage}</div>
      {isAcknowledgementEnabled && isAcknowledged ? null : (
        <Button
          className={classes.button}
          onClick={onClick}
          variant="contained"
          color="primary"
          disabled={isAcknowledgementEnabled && isAcknowledged}
        >
          Change Status
        </Button>
      )}{" "}
    </Box>
  );
};
