import React, { useState } from "react";
import {
  makeStyles,
  Dialog,
  DialogTitle,
  FormControl,
  Button,
  Box,
  ButtonBase,
} from "@material-ui/core";
import { ICONS } from "../../../../../commons/icon-manager";
import { FONTS } from "../../../../../utils";
import { useDialogStyles } from "../../../../../commons/dialogs/use-dialog-styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    marginBottom: "32px",
  },
  formControlActions: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  uploadButton: {
    width: "100%",
    borderBottom: "1px solid",
    backgroundColor: "#464659",
  },
  uploadButtonContents: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "11px",
    ...FONTS.Label1_Reg_12,
  },
  labelText: {
    padding: "11px 11px 0 11px",
    ...FONTS.B5_Bold_12,
  },
}));

export const UploadPdfDialog = ({ show, onCancel, onConfirm }) => {
  const classes = useStyles();
  const dialogStyles = useDialogStyles();
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");

  function handleCancelBttnClick() {
    onCancel && onCancel();
  }

  function handleFormSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    onConfirm && onConfirm(file);
  }

  function handleFileChange(event) {
    if (event.target.files[0].size > 2097152) {
      setError("File exceeds 2MB");
    } else {
      setFile(event.target.files[0]);
      setError("");
    }
  }

  return (
    <Dialog open={show} onClose={onCancel} PaperProps={{ className: dialogStyles.dialogPaper }}>
      <Box className={dialogStyles.dialogContentWrapper}>
        <DialogTitle className={dialogStyles.dialogTitle} disableTypography onClose={onCancel}>
          <span>Upload PDF of Consent Form</span>
        </DialogTitle>
        <form className={classes.form} autoComplete="off" onSubmit={handleFormSubmit}>
          <FormControl required variant="filled" className={classes.formControl}>
            <input
              color="primary"
              accept="application/pdf"
              type="file"
              onChange={handleFileChange}
              id="icon-button-file"
              style={{ display: "none" }}
            />
            <label htmlFor="icon-button-file">
              <ButtonBase size="large" component="span" className={classes.uploadButton}>
                <div className={classes.uploadButtonContents}>
                  <span>{file ? file.name : "Select a file to upload"}</span>
                  <ICONS.ArrowUpwardIcon />
                </div>
              </ButtonBase>
            </label>
            {error ? <span className={classes.labelText}>{error}</span> : null}
            <label className={classes.labelText}>Maximum file size: 2MB</label>
          </FormControl>
          <FormControl required variant="filled" className={classes.formControlActions}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleCancelBttnClick}
              className={dialogStyles.dialogActionBttn}
              data-testid="cancel-bttn"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginLeft: "10px" }}
              className={dialogStyles.dialogActionBttn}
              data-testid="create-bttn"
              disabled={!file}
            >
              Create
            </Button>
          </FormControl>
        </form>
      </Box>
    </Dialog>
  );
};
