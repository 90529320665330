import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, makeStyles } from "@material-ui/core";
import { TextField, FormControl, Button } from "@material-ui/core";
import { DevicesAPI } from "../../../../api";
import { getUserLocationHistoryByIDandTimeFrame } from "../../../../reducers";
import { DeviceHistoryReportMap } from './device-history-reports-map';
import { DeviceBatteryIcon } from "../../../../commons/device-battery-icon";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { ICONS } from "../../../../commons/icon-manager";
import { IconButton, InputAdornment } from "@material-ui/core";
import { gasTypes, parseUtcToLocal } from "../../../../utils";



const useStyles = makeStyles((theme) => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));
const fieldStyle = {
    wrapper: {
        height: "600px",

    },
    formControl: {
        width: "100%",
        paddingBottom: "10px"
    },

    dateControl: {
        width: "100%"
    },
}
export const DeviceHistoryReportsScreen = (deviceDetails) => {
    const sites = useSelector((state) => state.sitesScreen.sites);
    const selectedSite = parseInt(localStorage.SITE_ID);
    const [devices, setDevices] = useState([]);
    deviceDetails = deviceDetails.deviceDetails;
    const [selectedDevice, setSelectedDevice] = useState(deviceDetails.manufacturerId);
    const [selectedFromDate, setSelectedFromDate] = useState(null);
    const [selectedToDate, setSelectedToDate] = useState(null);
    const [triggerFilter, settriggerFilter] = useState(false);
    const [filterUserData, setfilterUserData] = useState(null);
    const [applyFilter, setApplyFilter] = useState(true);
    if (applyFilter && selectedSite) {
       DeviceHistoryReportMap({ site: sites.filter(f => f.id === selectedSite)[0], filterData: filterUserData, deviceDetails: deviceDetails });
        setApplyFilter(false);
    }
    const filterData = useCallback(async () => {
        const validatedSelectedFromDate=selectedFromDate ? selectedFromDate._d.toJSON() : "";
        const validatedSelectedToDate=selectedToDate ? selectedToDate._d.toJSON() : "";
        var zones = await getUserLocationHistoryByIDandTimeFrame(selectedDevice, validatedSelectedFromDate,validatedSelectedToDate );
        setfilterUserData(zones);
        DeviceHistoryReportMap({ site: sites.filter(f => f.id === selectedSite)[0], filterData: zones, deviceDetails: deviceDetails });
        settriggerFilter(false);
    }, [selectedDevice, selectedFromDate, selectedToDate])
    useEffect(() => {
        if (selectedSite) {
            const queryString = "";
            SetSelectedSite(selectedSite, queryString, devices, setDevices);
        }
        if (triggerFilter) {
            filterData(selectedDevice, selectedFromDate, selectedToDate);
        }
    });

    return (<div>
        <Grid container spacing={5}>
            <Grid item xs={0.3}>
            </Grid>
            <Grid item xs={3}>
                <FormControl variant="filled" style={fieldStyle.formControl}>
                    <DeviceBatteryIcon manufacturerId={deviceDetails.manufacturerId} level={deviceDetails.status.battery.internalLevel} isOnline={deviceDetails.status.isOnline} />
                </FormControl>
                <FormControl variant="filled" style={fieldStyle.formControl}>
                    <TextField
                        disabled
                        label="Device ID:"
                        defaultValue={deviceDetails.manufacturerId}
                    />
                </FormControl>
                <FormControl variant="filled" style={fieldStyle.formControl}>

                    <TextField
                        disabled
                        label="Device Type:"
                        defaultValue={deviceDetails.manufacturer}
                    />
                </FormControl>
                <FormControl variant="filled" style={fieldStyle.formControl}>
                    Device Tracking:
                </FormControl>
                <FormControl variant="filled" style={fieldStyle.formControl}>
                    <TextField
                        disabled
                        label="Assigned on:"
                        defaultValue={parseUtcToLocal(deviceDetails.assignmentTimestamp)}
                    />
                </FormControl>
                {deviceDetails.currentAssignedUser != null ? <>
                    <FormControl variant="filled" style={fieldStyle.formControl}>

                        <TextField
                            disabled
                            label="Assigned on:"
                            defaultValue={deviceDetails.currentAssignedUser.company}
                        />
                    </FormControl></> : ""}
                {deviceDetails.userCategory ? <>
                    <FormControl variant="filled" style={fieldStyle.formControl}>
                        <TextField
                            disabled
                            label="User Category:"
                            defaultValue={deviceDetails.userCategory.name}
                        />
                    </FormControl></> : ""}
                {deviceDetails.role ? <>
                    <FormControl variant="filled" style={fieldStyle.formControl}>
                        <TextField
                            disabled
                            label="User Roles:"
                            defaultValue={deviceDetails.role.role}
                        />
                    </FormControl> </> : ""}
                {deviceDetails.currentAssignedUser ? <>
                    <FormControl variant="filled" style={fieldStyle.formControl}>
                        <TextField
                            disabled
                            label="User Type:"
                            defaultValue={deviceDetails.currentAssignedUser.userType}
                        />
                    </FormControl></> : ""}
                <FormControl variant="filled" style={fieldStyle.dateControl}>
                    Device History:
                </FormControl>
                <FormControl variant="filled" style={fieldStyle.dateControl}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>

                        <DateTimePicker
                            inputVariant="filled"
                            fullWidth={true}
                            margin="normal"
                            value={selectedFromDate}
                            onChange={(value) => { setSelectedFromDate(value) }}
                            label="Select From Date"
                            InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton>
                                      <ICONS.CalendarToday />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }} />
                    </MuiPickersUtilsProvider>
                    {/* <Datepicker
                        inputVariant="filled"
                        fullWidth={true}
                        margin="normal"
                        handleDateChange={(value) => { setSelectedFromDate(value) }}
                        selectedDate={selectedFromDate}
                        label="Select From Date"
                        disablePast={false}
                    /> */}
                    {/* <TextField
                        inputVariant="filled"
                        label="Select From Date"
                        type="datetime-local"
                        //defaultValue={selectedFromDate}
                        //className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    /> */}
                </FormControl>

                <FormControl variant="filled" style={fieldStyle.dateControl}>
                    {/* <Datepicker
                        inputVariant="filled"
                        fullWidth={true}
                        margin="normal"
                        handleDateChange={(value) => { setSelectedToDate(value) }}
                        selectedDate={selectedToDate}
                        label="Select To Date"
                        disablePast={false}
                    /> */}
                    <MuiPickersUtilsProvider utils={MomentUtils}>

                        <DateTimePicker
                            inputVariant="filled"
                            fullWidth={true}
                            margin="normal"
                            value={selectedToDate}
                            onChange={(value) => { setSelectedToDate(value) }}
                            label="Select To Date"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton>
                                            <ICONS.CalendarToday />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }} />
                    </MuiPickersUtilsProvider>
                </FormControl>
                <FormControl>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                            setSelectedDevice("")
                            setfilterUserData(null);
                            setSelectedFromDate(null);
                            setSelectedToDate(null);
                            setApplyFilter(true);
                        }}
                    >
                        Clear All
            </Button>
                </FormControl>
                <FormControl>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => { 
                            settriggerFilter(true);
                            setApplyFilter(true);
                             }}
                    >
                        Filter
            </Button>
                </FormControl>
            </Grid>
            <Grid item xs={8} style={fieldStyle.wrapper} >
                <div id="map-container" style={{ height: "500px" }} />
            </Grid>
        </Grid>
    </div >);

}
function SetSelectedSite(selectedSite, queryString, devices, setDevices) {
    DevicesAPI.getSitelocationDevicesByQueryString(selectedSite, queryString).then((response) => {
        if (JSON.stringify(devices) !== JSON.stringify(response)) {
            setDevices(response);
        }
    });
}

