import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Chip } from "@material-ui/core";
import { FONTS, gasTypes } from "../../../../utils";
import { ALERT_COLOR_CODES } from "../../../../config/alerts-color-codes";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
  },
  chip: {
    ...FONTS.Label1_Med_12,
  },
}));

export const AlertChip = (props) => {
  const classes = useStyles();
  const { data } = props;
  const styleObj = {};
  const colour = ALERT_COLOR_CODES.AlertColor(data.alertType);

  styleObj.backgroundColor = `rgba(${colour.colorCode[0]}, ${colour.colorCode[1]}, ${colour.colorCode[2]})`;
  styleObj.color = `rgba(0,0,0)`;

  if (data.alertType === "GasDetected") {
    const { device } = data;
    colour.label = data.status
      ? `Gas Detected: ${gasTypes(device.status.sensors, true)}`
      : "Gas Detected";
  }
  return <Chip className={classes.chip} label={colour.label} style={{ ...styleObj }} />;
};

AlertChip.propTypes = {
  data: PropTypes.object.isRequired,
};
