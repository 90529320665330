import { MAP_ICONS } from "../index";

export const renderMediaAssetsOnMap = ({
    mediaAssets,
    Point,
    Graphic,
    mediaAssetsGraphicLayer,
  }) => {

  const pictureMaker = "picture-marker";


    const pointGraphicSymbolFunc = () => {
        
        return {
            type: pictureMaker,
            url: MAP_ICONS.MEDIA_ICON,
            contentType: "image/svg",
            // width: "28px",
            // height: "28px",
        };
      };

      mediaAssets.forEach((media) => {
        const { latitude,longitude } = media;
    
        const point = new Point({
          latitude: latitude,
          longitude: longitude,
        });
    
        let pointGraphicSymbol = null;
        let pointGraphic = null;
      pointGraphicSymbol = pointGraphicSymbolFunc();

      pointGraphic = new Graphic({
        geometry: point,
        symbol: pointGraphicSymbol,
        attributes: media,
      });
      pointGraphic && mediaAssetsGraphicLayer.add(pointGraphic);
    }
    )
}
