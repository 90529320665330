import React, { useState, useEffect } from "react";
import { Button, Paper, makeStyles, FormHelperText } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { ZonesReducerActions, ZONE_REDUCER_TYPES } from "../../../reducers";
import { ColorPallete, Datepicker, useCommonStyles } from "../../../commons";
import { ZonesColorCodes } from "../../../config";
import { parseUtcToLocal } from "../../../utils";
import { ZoneCategoriesAutocomplete } from "../components";
import { FONTS } from "../../../utils";

import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  formControl: {
    width: "100%",
    marginTop: "24px",
  },
  formTitle: {
    ...FONTS.H5_Bold_22,
    marginBottom: "12px",
  },
}));

const MenuProps = {
  PaperProps: {
    style: {
      width: 250,
    },
  },
};

export const CreateZoneForm = (props) => {
  const classes = useStyles();
  const commonStyles = useCommonStyles();
  const { onCancel, onConfirm } = props;
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [entryMessage, setEntryMessage] = useState("");
  const [exitMessage, setExitMessage] = useState("");
  const [colour, setColour] = useState(null);
  const zoneCategories = useSelector((state) => state.zonesScreen.zoneCategories?.active);
  const [defaultCategory] = useState(
    zoneCategories.filter((cat) => cat.name.toLowerCase() === "common area")
  );
  const [categories, setCategories] = useState(defaultCategory);
  const [saveAsTemplate, setSaveAsTemplate] = useState(false);
  const [isTemporary, setIsTemporary] = useState(false);
  const [endTime, setEndTime] = useState(null);
  const [startTime, setStartTime] = useState(null);

  const getRenderValue = (colorObj) => {
    return (
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <ColorPallete colour={colorObj.colorCode} />
        <span style={{ marginLeft: "5px" }}> {colorObj.label}</span>
      </div>
    );
  };

  function handleFormSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    dispatch(
      ZonesReducerActions.createZone({
        name,
        entryMessage,
        exitMessage,
        colour,
        categories,
        saveAsTemplate,
        isTemporary,
        endTime,
        startTime,
      })
    );
    onConfirm && onConfirm();
  }

  useEffect(() => {
    if (isTemporary) {
      setStartTime(new Date());
    } else {
      setStartTime(null);
      setEndTime(null);
    }
  }, [isTemporary]);

  return (
    <Paper className={commonStyles.formPaper}>
      <div className={classes.formTitle}>Create New Zone</div>
      <form onSubmit={handleFormSubmit} autoComplete="off">
        <Grid container spacing={2}>
          <Grid item md={12} xs={6}>
            <FormControl className={classes.formControl}>
              <TextField
                required
                id="filled-basic"
                label="Zone Name"
                variant="filled"
                fullWidth={true}
                value={name}
                onChange={(e) => setName(e.target.value)}
                inputProps={{
                  maxLength: 30,
                }}
              />
              <FormHelperText>{`(${name.length}/30)`}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item md={12} xs={6}>
            <FormControl className={classes.formControl}>
              <ZoneCategoriesAutocomplete
                value={categories}
                options={zoneCategories}
                onChange={(event, values) => {
                  setCategories([
                    ...defaultCategory,
                    ...values.filter((option) => defaultCategory.indexOf(option) === -1),
                  ]);
                }}
              />
            </FormControl>
          </Grid>

          <Grid item md={12} xs={6}>
            <FormControl className={classes.formControl}>
              <TextField
                helperText={`(${entryMessage ? entryMessage.length : 0}/120)`}
                id="filled-basic"
                label="Entry Message"
                variant="filled"
                multiline
                rows={4}
                rowsMax={4}
                fullWidth={true}
                value={entryMessage.slice(0, 120)}
                onChange={(event) => setEntryMessage(event.target.value.slice(0, 120))}
              />
            </FormControl>
          </Grid>
          <Grid item md={12} xs={6}>
            <FormControl className={classes.formControl}>
              <TextField
                id="filled-basic"
                label="Exit Message"
                variant="filled"
                multiline
                helperText={`(${exitMessage ? exitMessage.length : 0}/120)`}
                rows={4}
                rowsMax={4}
                fullWidth={true}
                onChange={(event) => setExitMessage(event.target.value.slice(0, 120))}
                value={exitMessage.slice(0, 120)}
              />
            </FormControl>
          </Grid>
          <Grid item md={12} xs={6}>
            <FormControl className={classes.formControl}>
              <InputLabel id="zones-colour-label">Color Code</InputLabel>
              <Select
                labelId="zones-colour-label"
                id="zones-colour-select"
                MenuProps={MenuProps}
                onChange={(event) => {
                  const colorCode = event.target.value.colorCode;
                  setColour({ red: colorCode[0], green: colorCode[1], blue: colorCode[2] });
                }}
                value={colour ? { colorCode: colour, label: "" } : ""}
                renderValue={getRenderValue}
              >
                {ZonesColorCodes.map((colorObj) => {
                  return (
                    <MenuItem value={colorObj} style={{ display: "inline-block" }}>
                      <ColorPallete colour={colorObj.colorCode} title={colorObj.label} />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={12} xs={6}>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={saveAsTemplate}
                    onChange={(event) => setSaveAsTemplate(event.target.checked)}
                    name="save_as_template"
                    color="primary"
                  />
                }
                label="Save as Template"
              />
            </FormControl>
          </Grid>
          <Grid item md={12} xs={12}>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isTemporary}
                    onChange={(event) => setIsTemporary(event.target.checked)}
                    name="temp_zone_checkbox"
                    color="primary"
                  />
                }
                label="Make a Temporary Zone"
              />
            </FormControl>
          </Grid>

          {isTemporary ? (
            <>
              <Grid item md={12} xs={6}>
                <FormControl component="fieldset" style={{ width: "100%" }}>
                  <Datepicker
                    handleDateChange={(dateTime) => {
                      setStartTime(dateTime);
                    }}
                    selectedDate={startTime}
                    label="Select Start Time"
                  />

                  <Datepicker
                    handleDateChange={(dateTime) => {
                      setEndTime(dateTime);
                    }}
                    selectedDate={endTime}
                    label="Select End Time"
                    minDate={startTime}
                    minDateMessage={`Date and Time should not be less than ${parseUtcToLocal(
                      startTime,
                      "MM-DD-YY HH:mm A"
                    )}`}
                    strictCompareDates={true}
                  />
                </FormControl>
              </Grid>
            </>
          ) : null}
          <Grid item md={12} xs={12}>
            <FormControl
              className={classes.formControl}
              style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}
            >
              <Button
                color="primary"
                variant="outlined"
                style={{ ...FONTS.B4_BOLD_14 }}
                onClick={(event) => {
                  dispatch({
                    type: ZONE_REDUCER_TYPES.SET_CREATE_ZONE_FORM_DISPLAY,
                    payload: false,
                  });
                  onCancel && onCancel(event);
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                style={{ marginLeft: "10px", ...FONTS.B4_BOLD_14 }}
                variant="contained"
                color="primary"
              >
                Create
              </Button>
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};
