import React, { useState, useContext } from "react";
import {
  makeStyles,
  Dialog,
  DialogTitle,
  FormControl,
  Button,
  Box,
  IconButton,
  FormHelperText,
  Tooltip,
  OutlinedInput,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import {
  useDialogStyles,
  ICONS,
  UserPermissionContext,
  Datepicker,
} from "../../../commons";
import { ZONE_REDUCER_TYPES, ZonesReducerActions } from "../../../reducers";
import { ZoneCategoriesAutocomplete } from "../../../routes/zones/components/zone-categories-autocomplete";
import { parseUtcToLocal, AppUtils } from "../../../utils";

const useStyles = makeStyles(() => ({
  formControl: {
    width: "100%",
    marginBottom: "32px",
  },
  formControlActions: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  helperText: {
    position: "relative",
    right: "8px",
  },
  dialogCloseActionBttn:{
    position:"fixed",
    left:"938px",
    top:"60px"
  }
}));

function _updateZone(event, dispatch, onTableExpandBttnCb) {
  event.stopPropagation();

  dispatch(ZonesReducerActions.updateZone()).then((res) => {
    if (res) {
      dispatch({
        type: ZONE_REDUCER_TYPES.SET_MODIFY_ZONE_DIALOG,
        payload: true,
      })
      onTableExpandBttnCb && onTableExpandBttnCb(null);
    }
  });
}

function renderZoneMetaData({ selectedZone }) {
  return (
    <div>
      <div style={{ fontSize: "12px" }}>
        {selectedZone && (
          <Tooltip title={selectedZone.createdBy}>
            <span>{`Zone Created by ${AppUtils.parseEmailToName(selectedZone.createdBy)} `}</span>
          </Tooltip>
        )}
        {selectedZone && parseUtcToLocal(selectedZone.createdTimestamp)}
      </div>
      {selectedZone &&
      selectedZone.lastModifiedTimestamp?.slice(0, 22) !==
        selectedZone.createdTimestamp?.slice(0, 22) ? (
        <div style={{ fontSize: "12px" }}>
          <Tooltip title={selectedZone.lastModifiedBy}>
            <span>
              {`Zone Modified by ${AppUtils.parseEmailToName(selectedZone.lastModifiedBy)} `}
            </span>
          </Tooltip>
          {selectedZone && parseUtcToLocal(selectedZone.lastModifiedTimestamp)}
        </div>
      ) : null}
    </div>
  );
}

export const ModifyZonesDialog = ({ show,rowCategories, onTableExpandBttnCb, onConfirm, onCancel }) => {

  const classes = useStyles();
  const dialogStyles = useDialogStyles();
  const dispatch = useDispatch();
  const { Permissions } = useContext(UserPermissionContext);
  const selectedZone = useSelector((state) => state.zonesScreen.selectedZone);

  const zoneCategories = useSelector((state) => state.zonesScreen.zoneCategories?.active);

  const [categories, setCategories] = useState(rowCategories || []);
  const [defaultCategory] = useState(
    zoneCategories.filter((cat) => cat.name.toLowerCase() === "common area")
  );

  return (
    <Dialog
      open={show}
      onClose={onCancel}
      PaperProps={{ className: dialogStyles.dialogPaper }}
      data-testid="modify-zone-dialog"
    >
      <Box className={dialogStyles.dialogContentWrapper}>
        <DialogTitle
          className={dialogStyles.dialogTitle}
          disableTypography
          id="responsive-dialog-title"
          onClose={onCancel}
        >
          <span>Modifying - {selectedZone?.name}</span>
          <IconButton className={classes.dialogCloseActionBttn} aria-label="close" onClick={onCancel} >
            <ICONS.CloseIcon />
          </IconButton>
        </DialogTitle>
        <form className={classes.form}>
          <FormControl variant="filled" className={classes.formControl}>
            <span style={{ fontSize: "14px" }}>Zone Name </span>
            <OutlinedInput
              style={{ height: "38px" }}
              id="filled-basic"
              disabled={!Permissions.Manage_Zones}
              variant="filled"
              value={selectedZone?.name}
              inputProps={{
                style: { width: `${selectedZone?.name.length * 10}px`, padding: "20px 10px 10px" },
                maxLength: 30,
              }}
              onChange={(event) => {
                dispatch({
                  type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE_NAME,
                  payload: event.target.value,
                });
              }}
            />
            <FormHelperText
              className={classes.helperText}
            >{`(${selectedZone?.name.length}/30)`}</FormHelperText>
          </FormControl>

          <FormControl variant="filled" className={classes.formControl}>
            <ZoneCategoriesAutocomplete
              value={selectedZone?.categories || rowCategories}
              options={zoneCategories}
              onChange={(_, newValues) => {
                const newCategories = [
                  ...defaultCategory,
                  ...newValues.filter((option) => option.id !== defaultCategory[0].id),
                ];
                setCategories(newCategories);
                dispatch({
                  type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE_CATEGORIES,
                  payload: newCategories,
                });
              }}
            />
          </FormControl>

          <FormControl variant="filled" className={classes.formControl}>
            <span style={{ fontSize: "14px" }}>Entry Message </span>
            <OutlinedInput
              disabled={Permissions.Manage_Zones ? false : true}
              multiline
              rows={3}
              rowsMax={3}
              fullWidth={true}
              id="filled-basic"
              variant="filled"
              value={selectedZone && selectedZone?.entryMessage?.slice(0, 120)}
              onChange={(event) => {
                dispatch({
                  type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE_ENTRY_MESSAGE,
                  payload: event.target.value.slice(0, 120),
                });
              }}
            />
            <FormHelperText
              className={classes.helperText}
            >{`(${selectedZone?.entryMessage.length}/30)`}</FormHelperText>
          </FormControl>

          <FormControl variant="filled" className={classes.formControl}>
            <span style={{ fontSize: "14px" }}>Exit Message </span>

            <OutlinedInput
              disabled={Permissions.Manage_Zones ? false : true}
              multiline
              id="filled-basic"
              variant="filled"
              rows={3}
              rowsMax={3}
              fullWidth={true}
              value={selectedZone && selectedZone?.exitMessage?.slice(0, 120)}
              onChange={(event) => {
                dispatch({
                  type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE_EXIT_MESSAGE,
                  payload: event.target.value.slice(0, 120),
                });
              }}
            />
            <FormHelperText
              className={classes.helperText}
            >{`(${selectedZone?.exitMessage.length}/30)`}</FormHelperText>
          </FormControl>

          <FormControl variant="filled" className={classes.formControl}>
            <Datepicker
              style={{ marginTop: "0" }}
              disabled
              handleDateChange={() => {}}
              selectedDate={selectedZone && selectedZone?.startTime}
              label="Start Date/Time"
            />

            <Datepicker
              style={{ marginTop: "0" }}
              disabled={Permissions.Manage_Zones ? selectedZone && !selectedZone.isTemporary : true}
              handleDateChange={(dateTime) => {
                dispatch({
                  type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE_END_TIME,
                  payload: dateTime,
                });
              }}
              selectedDate={selectedZone && selectedZone?.endTime}
              label="End Date/Time"
            />
          </FormControl>

          <div>{renderZoneMetaData({ selectedZone })}</div>

          <FormControl variant="filled" className={classes.formControlActions}>
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: "10px" }}
              onClick={(event) => {
                onCancel();
                _updateZone(event, dispatch, onTableExpandBttnCb);
              }}
              className={dialogStyles.dialogActionBttn}
            >
              Save
            </Button>
          </FormControl>
        </form>
      </Box>
    </Dialog>
  );
};
