import React, { useState, useRef, useContext, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  MenuItem,
  Menu,
  makeStyles,
  Box,
  Hidden,
  ClickAwayListener,
  Typography,
  Popper,
  Paper,
  Grow,
  Divider,
  FormControlLabel,
} from "@material-ui/core";
import shellIcon from "../../assets/svg-icons/shell.svg";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { FONTS } from "../../utils";
import { AuthReducerActions, GlobalReducerActions } from "../../reducers";
import { ICONS, Version, ScopeContext } from "../../commons";
import { CookiesDialog } from "../../commons/dialogs/cookies-dialog";
import { APP_CONSTANTS } from "../../config";
import { YellowSwitch } from "../switch";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.base.elevation_6,
  },
  grow: {
    flex: 1,
  },
  appName: {
    color: theme.palette.base.elevation_4,
    ...FONTS.ShellBook_18_800,
  },
  jarvisName: {
    color: theme.palette.base.elevation_4,
    ...FONTS.ShellBook_14_400,
  },
  userGuide: {
    color: theme.palette.base.linkColor,
    ...FONTS.B5_Reg_12,
  },
  menuItem: {
    color: theme.palette.base.elevation_4,
    ...FONTS.ShellBook_14_400,
    "&:hover": {
      backgroundColor: theme.palette.base.elevation_5,
    },
    "&:active": {
      backgroundColor: theme.palette.primary.main,
    },
    paddingLeft: "25px",
    paddingRight: "25px",
  },
  menuItemActive: {
    borderBottom: `4px solid ${theme.palette.primary.main}`,
  },
  menuButton: {
    color: theme.palette.base.elevation_4,
  },
  user: {
    color: theme.palette.base.elevation_4,
    ...FONTS.Label2_Bold_12,
  },
  userInfoBox: {
    width: "150px",
    marginRight: "24px",
  },
  userInfoDiv: { padding: "18px 12px 12px 12px", ...FONTS.B5_Reg_12 },
  RoleinfoDiv: { padding: "18px 12px 12px 12px",color:"white", ...FONTS.B5_Reg_12 },
  logoutBtn: { padding: "12px", ...FONTS.B4_BOLD_14 },
  versionContainer: { paddingBottom: "12px", marginRight: "12px", ...FONTS.Label1_Bold_8 },
  switchLabel: {
    ...FONTS.B5_Reg_12,
  },
}));

function renderItem(menuItem, activeAlertsCount,namedRoutes) {
  if (menuItem.href === namedRoutes.alerts && activeAlertsCount !== 0) {
    return (
      <div style={{ display: "flex", alignItems: "baseline" }}>
        <div>{menuItem.label}</div>
        <div
          style={{
            background: "#D42E12",
            marginLeft: "4px",
            width: `${activeAlertsCount > 99 ? "27px" : "20px"}`,
            height: "15px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {activeAlertsCount > 99 ? "99+" : activeAlertsCount}
        </div>
      </div>
    );
  } else {
    return menuItem.label;
  }
}

export const TopNavBar = (props) => {
  const { siteID, MainMenuListJSON, setShowDrawerMenu, menuItemClick, activeAlertsCount } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const userAuth = useSelector((state) => state.auth);
  const classes = useStyles();
  const [auth] = useState(true);
  const [open, setOpen] = React.useState(false);
  const anchorRef = useRef(null);
  const {jarvisScope, namedRoutes} = useContext(ScopeContext);
  const theme = useSelector((state) => state.globalState.theme);

  const guideURL = process.env.REACT_APP_SITEWISE_USERGUIDE_URL

  useEffect(() => {
    if(window.jarvis?.currentTheme) {
      dispatch(GlobalReducerActions.setTheme(window.jarvis.currentTheme));
    }
  }, [window.jarvis]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  function handleUserLogout() {
    dispatch(AuthReducerActions.logout());
  }

  function handleUserLogin() {
    history.push(namedRoutes.root);
  };

  function toggleTheme() {
    dispatch(GlobalReducerActions.toggleTheme());
  }

  return (
    <AppBar id="j-sitewise-header" position="relative" classes={{ root: classes.root }}>
      <Toolbar>
        {!jarvisScope && 
        (<Hidden xsDown>
          <IconButton
            edge="start"
            aria-label="logo"
            style={{ marginRight: "20px" }}
            onClick={() => history.push(namedRoutes.zones)}
          >
            <img src={shellIcon} alt="shell logo" width="34px" height="34px" />
          </IconButton>
        </Hidden>)
        }

        <Hidden smUp>
          <IconButton
            edge="start"
            className={classes.menuButton}
            aria-label="menu"
            onClick={() => setShowDrawerMenu(true)}
          >
            <ICONS.Menu />
          </IconButton>
        </Hidden>

        <Hidden xsDown>
          <div style={{ 
              display: "grid", 
              marginLeft: `${(jarvisScope ? "21px" : "0")}`, 
              marginRight: "45px" 
            }}>
            <span className={classes.jarvisName}>JARVIS</span>
            <span className={classes.appName}>Sitewise</span>
          </div>
        </Hidden>

        <Hidden xsDown>
          <div style={{ display: "flex", alignSelf: "stretch" }}>
            {MainMenuListJSON.map((menuItem) => {
              const isActiveLink = location.pathname.includes(menuItem.href);
              return (
                <MenuItem
                  disabled={siteID === null && menuItem.href !== namedRoutes.sites ? true : false}
                  key={menuItem.label}
                  disableRipple
                  className={`${classes.menuItem} ${isActiveLink ? classes.menuItemActive : ""}`}
                  onClick={menuItemClick(menuItem.href)}
                >
                  {renderItem(menuItem, activeAlertsCount,namedRoutes)}
                </MenuItem>
              );
            })}
          </div>
        </Hidden>

        <div className={classes.grow}></div>
        {auth && (
          <Hidden xsDown>
        {!jarvisScope ?
            (<Box display="flex" alignItems="center">
              <div className={classes.user}>{userAuth.userInfo?.name}</div>
              <div>
                <IconButton
                  ref={anchorRef}
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleToggle}
                  data-testid="user-account-button"
                >
                  <ICONS.PersonOutline />
                </IconButton>
                {userAuth.isLoggedIn ? (
                  <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
                    {({ TransitionProps }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin: "left bottom",
                        }}
                      >
                        <Paper className={classes.userInfoBox}>
                          <ClickAwayListener onClickAway={handleToggle}>
                            <div className={classes.root}>
                              <Typography component="p" align="right">
                                <div className={classes.userInfoDiv}>
                                  <div>{userAuth.userInfo?.role}</div>
                                  {userAuth.userInfo.category !== "None" &&
                                    <div>{userAuth.userInfo?.category}</div>
                                  }
                                  {userAuth.userInfo.userType !== "None" &&
                                    <div>{userAuth.userInfo?.userType}</div>}
                                  <div><a target="_blank" rel="noreferrer" href={guideURL} className={classes.userGuide}>SitewiseUserGuide </a> </div>
                                </div>
                                {!window.jarvis?.currentTheme && (<FormControlLabel
                                  label={
                                    <span className={classes.switchLabel}>
                                      {theme === APP_CONSTANTS.THEME_DARK ? "Dark mode" : "Light mode"}
                                    </span>}
                                  control={
                                    <YellowSwitch
                                      checked={theme === APP_CONSTANTS.THEME_DARK}
                                      onChange={toggleTheme}
                                      data-testid="theme-toggle"
                                    />
                                  }
                                />)}
                                <Version
                                  title="Sitewise Version"
                                  className={classes.versionContainer}
                                />
                                <Divider />
                                {!jarvisScope && (<Button
                                  size="small"
                                  className={classes.logoutBtn}
                                  onClick={handleUserLogout}
                                  color="primary"
                                >
                                  Log Out
                                </Button>)}
                              </Typography>
                            </div>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                ) : (
                  <Menu
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    open={open}
                    onClose={handleToggle}
                  >
                    <MenuItem onClick={handleUserLogin}>Login</MenuItem>
                  </Menu>
                )}
              </div>
            </Box>)
            :
            (<Box display="flex" alignItems="center">
              {userAuth.isLoggedIn && 
                (<div>
                  <MenuItem
                    ref={anchorRef}
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleToggle}
                    data-testid="profile-button"
                    disableRipple
                    className={classes.menuItem}
                  >
                    Profile Details v 
                  </MenuItem>
                  <Popper open={open} anchorEl={anchorRef.current} role="tooltip" transition>
                    {({ TransitionProps }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin: "left bottom",
                        }}
                      >
                        <Paper className={classes.userInfoBox}>
                          <ClickAwayListener onClickAway={handleToggle}>
                            <div className={classes.root}>
                              <Typography component="p" align="right">
                                <div className={classes.userInfoDiv}>
                                {!window.jarvis &&  
                                <div>
                                  <div>{userAuth.userInfo?.role}</div>
                                  {userAuth.userInfo.category !== "None" &&
                                    <div>{userAuth.userInfo?.category}</div>
                                  }
                                  {userAuth.userInfo.userType !== "None" &&
                                    <div>{userAuth.userInfo?.userType}</div>}
                                  <Divider/>
                                    </div>
                                    }
                                  <div classes={{marginTop:"5px"}}><a target="_blank" rel="noreferrer" href={guideURL} className={classes.userGuide}>SitewiseUserGuide </a> </div>
                                </div>
                                <Version
                                  title="Sitewise Version"
                                  className={classes.versionContainer}
                                />
                              </Typography>
                            </div>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
              </div>)}
            </Box>)
            }   
          </Hidden>
        )}
      </Toolbar>
      {!window.jarvis &&
      <CookiesDialog />
      }
    </AppBar>
  );
};
