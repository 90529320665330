import { MapUtilsV2, MAP_ICONS } from "../index";

export const renderUserDevicesOnMap = ({
  userDevices,
  Point,
  Graphic,
  userDevicesGraphicLayer,
}) => {
  const pointGraphicSymbolFunc = () => {
    return {
      type: "simple-marker",
      style: "path",
      size: "28px",
      outline: {
        color: [255, 255, 255],
        width: 0,
      },
      path: MAP_ICONS.PERSON_ICON,
    };
  };

  userDevices.forEach((userDevice) => {
    const { alerts } = userDevice;
    const { location } = userDevice.status;

    const point = new Point({
      latitude: location.latitude,
      longitude: location.longitude,
    });

    let pointGraphicSymbol = null;
    let pointGraphic = null;
    const emergencyResponder = "Emergency Responder";

    if (alerts?.length) {
      const sortedAlerts = MapUtilsV2.getAlertPriorityArr(alerts);
      const highestPriorityAlert = sortedAlerts[0];
      pointGraphicSymbol = AlertIcons(highestPriorityAlert, pointGraphicSymbol);

      pointGraphic = new Graphic({
        geometry: point,
        symbol: pointGraphicSymbol,
        attributes: {
          type: "userDevice",
          UserCategory: userDevice.currentAssignedUser?.userCategory?.name,
          Role: userDevice.currentAssignedUser?.role?.role,
          UserType: userDevice.currentAssignedUser?.userType,
          ...userDevice,
        },
      });
    } else {
      pointGraphicSymbol = pointGraphicSymbolFunc();

      if (userDevice.currentAssignedUser?.userCategory) {
        var pathDef = "";
        var color;
        const device = userDevice.manufacturer.toLowerCase();
        switch (device) {
          case "apple":
            pathDef = MAP_ICONS.IPHONE_ICON;
            color = "white";
            break;
          case "mobile":
            pathDef = MAP_ICONS.ANDROID_ICON;
            color = "white";
            break;
          case "blackline":
            pathDef = MAP_ICONS.PERSON_ICON;
            color = "white";
            break;
          case "isc":
            pathDef = MAP_ICONS.PERSON_ICON;
            color = "brown";
            break;
          case "realwear inc.":
            pathDef = MAP_ICONS.REALWARE_ICON;
            color = "#A020F0";
            break;
          default:
            break;
        }
        pointGraphicSymbol = RealwearIconMarker(
          userDevice,
          emergencyResponder,
          pointGraphicSymbol,
          pathDef
        );
        pointGraphicSymbol.color =
          userDevice.currentAssignedUser.userCategory.name === emergencyResponder
            ? "#13BC86"
            : color;

        pointGraphic = new Graphic({
          geometry: point,
          symbol: pointGraphicSymbol,
          attributes: {
            type: "userDevice",
            UserCategory: userDevice.currentAssignedUser?.userCategory?.name,
            Role: userDevice.currentAssignedUser?.role?.role,
            UserType: userDevice.currentAssignedUser?.userType,
            ...userDevice,
          },
        });
      } else {
        pointGraphicSymbol.color = "white";

        pointGraphic = new Graphic({
          geometry: point,
          symbol: pointGraphicSymbol,
          attributes: {
            type: "userDevice",
            ...userDevice,
          },
        });
      }
    }

    pointGraphic && userDevicesGraphicLayer.add(pointGraphic);
  });
};
function AlertIcons(highestPriorityAlert, pointGraphicSymbol) {
  const pictureMaker = "picture-marker";

  if (highestPriorityAlert.alertType === "GasDetected") {
    pointGraphicSymbol = {
      type: pictureMaker,
      url: MAP_ICONS.GAS_DETECTED_BLINKER,
      width: "40px",
      height: "40px",
    };
  } else {
    pointGraphicSymbol = {
      type: pictureMaker,
      url: MAP_ICONS.ALERT_DETECTED_BLINKER,
      width: "40px",
      height: "40px",
    };
  }
  return pointGraphicSymbol;
}

function RealwearIconMarker(userDevice, emergencyResponder, pointGraphicSymbol, pathDef) {
  const pictureMaker = "picture-marker";
  const realwear = "realwear inc.";
  if (
    userDevice.manufacturer.toLowerCase() === realwear &&
    userDevice.currentAssignedUser.userCategory.name !== emergencyResponder
  ) {
    pointGraphicSymbol = {
      type: pictureMaker,
      url: "data:image/png;base64," + MAP_ICONS.REALWARE_ICON,
      contentType: "image/png",
      width: "32px",
      height: "32px",
    };
  } else if (
    userDevice.manufacturer.toLowerCase() === realwear &&
    userDevice.currentAssignedUser.userCategory.name === emergencyResponder
  ) {
    pointGraphicSymbol = {
      type: pictureMaker,
      url: "data:image/png;base64," + MAP_ICONS.GREEN_REALWARE_ICON,
      contentType: "image/png",
      width: "32px",
      height: "32px",
    };
  } else {
    pointGraphicSymbol = {
      type: "simple-marker",
      style: "path",
      size: "28px",
      outline: {
        color: [255, 255, 255],
        width: 0,
      },
      path: pathDef,
    };
  }
  return pointGraphicSymbol;
}
