import React from "react";
import { Select, MenuItem, InputLabel, makeStyles } from "@material-ui/core";

const styles = makeStyles((theme) => ({
  select: {
    color: theme.palette.primary.main,
    "&:before": {
      borderBottom: "none !important",
    },
  },
}));

export const ZonesDropdown = (props) => {
  const classes = styles();
  const { options, onChange } = props;
  return (
    <>
      <InputLabel id="demo-simple-select-label">Select Zone</InputLabel>
      <Select
        required
        className={classes.select}
        labelId="zones-dropdown-label"
        id="zones-dropdown"
        data-testid="zones-dropdown"
        onChange={onChange}
      >
        <MenuItem disabled value="">
          Select Zone
        </MenuItem>

        {options.map((option) => {
          return (
            <MenuItem key={option.id} value={option}>
              {option.name}
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};
