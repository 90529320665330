import React, { useState, useEffect } from "react";
import { makeStyles, Dialog } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { SitesReducerActions, SITES_REDUCER_TYPES } from "../../../../../reducers";
import { validateEmail } from "../../../../../utils";
import { FirstTab } from "./first-tab";
import { SecondTab } from "./second-tab";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    width: "448px",
    backgroundColor: theme.palette.base.elevation_1,
    padding: "20px",
  },
  closeIconWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  formControl: {
    width: "100%",
    marginTop: "24px",
  },
  dialogActionButton: {
    maxWidth: "213px",
    width: "213px",
    height: "102px",
    display: "flex",
    flexDirection: "column",
    background: theme.palette.base.elevation_overlay_1,
    "&:hover": {
      color: theme.palette.warning.main,
    },
  },
  formControlActions: {
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: "32px",
    display: "flex",
  },
}));

export const CreateNewSuperUserDialog = ({ show, onCancel, onConfirm }) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState(0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [errorText, setErrorText] = useState("");

  function handleCancelBttnClick() {
    onCancel();
  }

  function resetForm() {
    setTabValue(0);
    setName("");
    setEmail("");
    setErrorText("");
  }

  function handleFormSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    const user = {
      name: name,
      email: email,     
    };

    dispatch(SitesReducerActions.createSuperUser(user));
    dispatch({ type: SITES_REDUCER_TYPES.SET_CREATE_NEW_SUPER_ADMIN_USER_DIALOG_DISPLAY, payload: false });
    setErrorText("");
  }

  function handleNextSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    if (validateEmail(email)) {
      setErrorText("");
      setTabValue(1);
    } else {
      setErrorText("Invalid Email");
    }
  }

  useEffect(() => {
    resetForm();
  }, [show]);

  if (!show) {
    return null;
  }

  return (
    <Dialog open={show} onClose={onCancel} PaperProps={{ className: classes.dialogPaper }}>
     
      <FirstTab
        classes={classes}
        tabValue={tabValue}
        name={name}
        email={email}
        errorText={errorText}
        setName={setName}
        setEmail={setEmail}
        setErrorText={setErrorText}
        handleNextSubmit={handleNextSubmit}
        handleCancelBttnClick={handleCancelBttnClick}
      />
      <SecondTab
        tabValue={tabValue}
        classes={classes}
        handleFormSubmit={handleFormSubmit}   
        handleCancelBttnClick={handleCancelBttnClick}
        
        name={name}
        setTabValue={setTabValue}
      
      />
    </Dialog>
  );
};
