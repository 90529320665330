import { AxiosClient } from "./axios-client";
import { handleErrorFn } from "../custom-errors";
import { authService } from "../utils/auth-service";

const API_URL = "/api/zonecategory";

const createZoneCategory = async (categoryData, siteLocationId) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    return await AxiosClient({
      url: API_URL,
      method: "POST",
      data: { ...categoryData, siteLocationId: siteLocationId },
      headers,
    });
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const updateZoneCategory = async (data) => {
  const { id } = data;
  const headers = await authService.getAuthorizeHeader();
  try {
    return await AxiosClient({
      url: `${API_URL}/${id}`,
      method: "PUT",
      data: data,
      headers,
    });
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const getZoneCategoryById = async (zoneId) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    const res = await AxiosClient({
      url: `${API_URL}/${zoneId}`,
      method: "GET",
      headers,
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const deleteZoneCategory = async (zoneId) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    const res = await AxiosClient({
      url: `${API_URL}/${zoneId}`,
      method: "DELETE",
      headers,
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

export const ZoneCategoriesAPI = {
  createZoneCategory,
  updateZoneCategory,
  getZoneCategoryById,
  deleteZoneCategory,
};
