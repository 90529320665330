import React from "react";
import { renderToString } from "react-dom/server";
import { DeviceBatteryIcon } from "../../device-battery-icon";
import { MapUtilsV2 } from "../map-utils";
import { parseUtcToLocal } from "../../../utils/date-fns";

const showManfactuerId = (showName, batteryHtmlString, manufacturerId) => {
  return showName
    ? `<div class="user_id">
    #${manufacturerId} ${batteryHtmlString}
  </div>`
    : ` <div></div>`;
};

const getAlerts = (alert, viewAlert) => (alert && viewAlert ? `<div class="alerts_wrapper">${alert}</div>` : "");

export const renderMapTooltipMarkupUserHistory = (graphicsData, store, mapView, viewAlerts) => {
  let timeStamp;

  if (graphicsData.graphic.popupTemplate.title) {
    timeStamp = graphicsData.graphic.popupTemplate.title;

  }
var ts= parseUtcToLocal(timeStamp);
  if (timeStamp) {
    mapView.popup.title = `${ts}`;
  } else {
    mapView.popup.title = `Couldn't get timestamp`;
  }
  
  mapView.popup.location = graphicsData.mapPoint;
  mapView.popup.actions =null;

  const showUserInfo = (timeStamp) => {
    return timeStamp ? `
    <hr>` : "";
  }

  const toolTipHtml = (alertsStr = "") => {
    return `
    <div class="map_user_popup">
      ${showUserInfo(ts)}
    </div>`;
  };

    mapView.popup.content = toolTipHtml();
  
};
