import React from "react";
import { PropTypes } from "prop-types";
import { FontAwesome } from "../icon-manager/icon-manager";

const GREEN_COLOR = "#13BC86";
const ORANGE_COLOR = "#FF9400";
const RED_COLOR = "#FF0000";
const OFFLINE_COLOR = "#F7F7F7";

const getIconColor = (isOnline, levelColor) => {
  return isOnline ? levelColor : OFFLINE_COLOR;
};

const getBatteryIcon = (manufacturerId, level, isOnline) => {
  const styleObj = {
    fontSize: "14px",
  };

  switch (true) {
    case level === 0:
      return (
        <FontAwesome
          name="battery-empty"
          data-testid={`device-battery-icon-${manufacturerId}`}
          style={{
            color: getIconColor(isOnline, RED_COLOR),
            ...styleObj,
          }}
        />
      );
    case level <= 25:
      return (
        <FontAwesome
          name="battery-quarter"
          data-testid={`device-battery-icon-${manufacturerId}`}
          style={{
            color: getIconColor(isOnline, ORANGE_COLOR),
            ...styleObj,
          }}
        />
      );
    case level < 75:
      return (
        <FontAwesome
          name="battery-half"
          data-testid={`device-battery-icon-${manufacturerId}`}
          style={{
            color: getIconColor(isOnline, GREEN_COLOR),
            ...styleObj,
          }}
        />
      );
    case level < 100:
      return (
        <FontAwesome
          name="battery-three-quarters"
          data-testid={`device-battery-icon-${manufacturerId}`}
          style={{
            color: getIconColor(isOnline, GREEN_COLOR),
            ...styleObj,
          }}
        />
      );
    case level === 100:
      return (
        <FontAwesome
          name="battery-full"
          data-testid={`device-battery-icon-${manufacturerId}`}
          style={{
            color: getIconColor(isOnline, GREEN_COLOR),
            ...styleObj,
          }}
        />
      );
    default:
      return null;
  }
};

export const DeviceBatteryIcon = (props) => {
  const { manufacturerId, level, isOnline } = props;
  if (manufacturerId) {
    return getBatteryIcon(manufacturerId, level, isOnline);
  }
  return null;
};

DeviceBatteryIcon.propTypes = {
  manufacturerId: PropTypes.string.isRequired,
  level: PropTypes.number,
  isOnline: PropTypes.bool,
};
