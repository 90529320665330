import React from "react";
import { Chip, makeStyles, useTheme } from "@material-ui/core";
import { FONTS } from "../../utils";
import { getIconByKey, ICONS } from "../../commons";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
  },
  chip: {
    ...FONTS.Label1_Med_12,
  },
}));

export const CategoryChip = (props) => {
  const theme = useTheme();
  const classes = useStyles();
  const { data, isSelected, handleClick, isFilterChip, ...otherProps } = props;
  const { isAdHoc, colour, icon } = data;

  const styleObj = {};

  const chipProps = {
    ...(handleClick && { onClick: () => handleClick(data.id) }),
    ...otherProps,
  };

  const ChipIcon = getIconByKey(icon);

  if (isAdHoc) {
    styleObj.border = "1px solid #D9D9D9";
    styleObj.backgroundColor = "#373746";
  } else {
    styleObj.backgroundColor = `rgba(255, 255, 255, 0.12)`;
  }

  if (colour) {
    styleObj.backgroundColor = `rgba(${colour["red"]}, ${colour["green"]}, ${colour["blue"]})`;
  }

  if (ChipIcon) {
    chipProps.icon = <ChipIcon />;
  }

  if (isFilterChip && colour) {
    styleObj.backgroundColor = `rgba(${colour["red"]}, ${colour["green"]}, ${colour["blue"]}, .2)`;
  }

  if (isSelected) {
    styleObj.color = theme.palette.primary.main;
    chipProps.deleteIcon = <ICONS.Done />;
    chipProps.onDelete = () => {
      handleClick && handleClick(data.id);
    };
    styleObj.backgroundColor = `rgba(79, 176, 198, 0.12)`;
  }

  return <Chip className={classes.chip} label={data.name} {...chipProps} style={{ ...styleObj }} />;
};
