import { AxiosClient } from "./axios-client";
import { handleErrorFn } from "../custom-errors";
import { authService } from "../utils/auth-service";
const API_URL = "/api/siteLocation";

const getAllSites = async () => {
  const headers = await authService.getAuthorizeHeader();
  try {
    const res = await AxiosClient({
      url: `${API_URL}`,
      method: "GET",
      headers: headers,
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const getSiteById = async (id) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    const response = await AxiosClient({
      url: `${API_URL}/${id}`,
      method: "GET",
      headers: headers,
    });
    return response.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const createSiteByName = async (name) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    return await AxiosClient({
      url: API_URL,
      method: "POST",
      data: {
        name,
      },
      headers,
    });
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const updateSiteById = async (data) => {
  const headers = await authService.getAuthorizeHeader();
  const { id } = data;
  try {
    return await AxiosClient({
      url: `${API_URL}/${id}`,
      method: "PUT",
      data: data,
      headers,
    });
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const updateUserAttributesBySiteId = async (data, siteID) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    const res = await AxiosClient({
      url: `${API_URL}/${siteID}/UserAttributes`,
      method: "PUT",
      headers: headers,
      data: data,
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const updateAlertConfigurationsBySiteId = async (data, siteID) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    const res = await AxiosClient({
      url: `${API_URL}/${siteID}/AlertConfigurations`,
      method: "PUT",
      headers: headers,
      data: data,
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const updateEmergencyContactsBySiteId = async (data, siteID) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    const res = await AxiosClient({
      url: `${API_URL}/${siteID}/EmergencyContactConfiguration`,
      method: "PUT",
      headers: headers,
      data: data,
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const updateEvacuationMessageBySiteId = async (data, siteID) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    const res = await AxiosClient({
      url: `${API_URL}/${siteID}/EvacuationMessage`,
      method: "PUT",
      headers: headers,
      data: data,
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const getAllZonesBySitelocation = async (siteId) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    const res = await AxiosClient({
      url: `${API_URL}/${siteId}/Zone`,
      method: "GET",
      headers,
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const getAllZoneCategoriesBySiteLocation = async (siteId) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    const res = await AxiosClient({
      url: `${API_URL}/${siteId}/ZoneCategories`,
      method: "GET",
      headers,
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const getAllUserRolesBySiteLocation = async (siteId) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    const res = await AxiosClient({
      url: `${API_URL}/${siteId}/UserRole`,
      method: "GET",
      headers: headers,
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const getAllUsersBySiteLocation = async (siteId) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    const res = await AxiosClient({
      url: `${API_URL}/${siteId}/User`,
      method: "GET",
      headers: headers,
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const syncAllUsersFromJarvis = async (data) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    const res = await AxiosClient({
      url: `${API_URL}/SyncOneJarvisUser`,
      method: "POST",
      headers: headers,
      data:data
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const updateSitePrivacyById = async (data) => {
  let headers = await authService.getAuthorizeHeader();
  headers = { ...headers, "Content-Type": "multipart/form-data" };
  const {
    id,
    tableShowName,
    tableShowEmail,
    printConsent,
    privacyPdfFile,
    mapShowNameAlways,
    isAcknowledgementEnabled,
  } = data;

  const formData = new FormData();
  const fields = { tableShowName, tableShowEmail, printConsent, privacyPdfFile, mapShowNameAlways, isAcknowledgementEnabled };
  Object.keys(fields).forEach((key) => formData.append(key, fields[key]));
  try {
    return await AxiosClient({
      url: `${API_URL}/${id}/Privacy`,
      method: "PUT",
      data: formData,
      headers,
    });
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const patchSitePrivacyById = async (siteID, data) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    return await AxiosClient({
      url: `${API_URL}/${siteID}/privacy`,
      method: "PATCH",
      data: data,
      headers,
    });
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const deleteSitePrivacyById = async (id) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    return await AxiosClient({
      url: `${API_URL}/${id}/clearprivacy`,
      method: "PUT",
      headers,
    });
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const updateSiteDisclaimerTickerMessagesById= async (data,id) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    return await AxiosClient({
      url: `${API_URL}/${id}/DisclaimerMessage`,
      method: "PUT",
      headers,
      data,
    });
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};


const updateDeviceConfigById = async (data, id) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    return await AxiosClient({
      url: `${API_URL}/${id}/deviceconfiguration`,
      method: "PUT",
      headers,
      data,
    });
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const getAllDevicesBySiteID = async (siteID) => {
  try {
    const headers = await authService.getAuthorizeHeader();
    const res = await AxiosClient({
      url: `${API_URL}/${siteID}/device`,
      method: "GET",
      headers,
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const bulkUploadUsersBySiteID = async (formData, siteID) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    const res = await AxiosClient({
      url: `${API_URL}/${siteID}/User/bulk`,
      method: "POST",
      headers: { ...headers, "Content-Type": "multipart/form-data" },
      data: formData,
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const getBulkUploadUserTemplate = async (siteID) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    const res = await AxiosClient({
      url: `${API_URL}/${siteID}/User/bulk/template`,
      method: "GET",
      headers: { ...headers, Accept: "text/csv" },
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};
const getUserLocationHistoryByIDandTimeFrame=async(id,fromDate,toDate)=> {
  const headers = await authService.getAuthorizeHeader();
  try {
    const res = await AxiosClient({
      url: `api/Device/${id}/GetUserLocationHistoryReport?fromDate=${fromDate}&toDate=${toDate}`,
      method: "GET",
      headers,
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};
const getUserLocationHistoryByMultiIDandTimeFrame=async(id,fromDate,toDate)=> {
  const headers = await authService.getAuthorizeHeader();
  try {
    const res = await AxiosClient({
      url: `api/Device/GetUserLocationHistoryReport`,
      method: "POST",
      headers,
      data: {
        id:id,
        fromDate:fromDate,
        toDate:toDate
      },
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};
export const SiteLocationsAPI = {
  getAllSites,
  getSiteById,
  getAllZonesBySitelocation,
  getAllZoneCategoriesBySiteLocation,
  getAllUserRolesBySiteLocation,
  getAllUsersBySiteLocation,
  createSiteByName,
  updateSiteById,
  updateSitePrivacyById,
  updateUserAttributesBySiteId,
  updateAlertConfigurationsBySiteId,
  patchSitePrivacyById,
  deleteSitePrivacyById,
  updateSiteDisclaimerTickerMessagesById,
  updateDeviceConfigById,
  getAllDevicesBySiteID,
  bulkUploadUsersBySiteID,
  getBulkUploadUserTemplate,
  getUserLocationHistoryByIDandTimeFrame,
  getUserLocationHistoryByMultiIDandTimeFrame,
  updateEvacuationMessageBySiteId,
  updateEmergencyContactsBySiteId,
  syncAllUsersFromJarvis
};
