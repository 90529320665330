import { useState, useEffect } from "react";
import _get from "lodash.get";
import _partition from "lodash.partition";

export const useCustomTableHook = (props) => {
  const { selectedZone, defaultSortBy, defaultSortOrder, data, rowsPerPage, isZoneRoute } = props;
  const [order, setOrder] = useState(defaultSortOrder || "asc");
  const [orderBy, setOrderBy] = useState(defaultSortBy || "name");
  const [page, setPage] = useState(0);

  function descendingComparator(a, b, orderBy) {
    const getOrderBy = (x, orderBy) => {
      return typeof _get(x, orderBy) === "string"
        ? _get(x, orderBy).toLowerCase()
        : _get(x, orderBy);
    };
    const aValue = _get(a, orderBy) ? getOrderBy(a, orderBy) : "";
    const bValue = _get(b, orderBy) ? getOrderBy(b, orderBy) : "";
    if (bValue < aValue) {
      return -1;
    }
    if (bValue > aValue) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function sortHelper(a, b, comparator) {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  }

  function stableSort(array, comparator) {
    var stabilizedThis = [];
      if(array && array.length){
        stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => sortHelper(a, b, comparator));
        stabilizedThis = stabilizedThis.map((el) => el[0]);
      }else{
        stabilizedThis = [];
      }
    return stabilizedThis
  }

  function partitionHelper(el) {
    return el.alerts && el.alerts.length;
  }

  function alertArrayHelper(a, b, order) {
    if (order === "desc") {
      return b.alerts[0].createdTimestamp > a.alerts[0].createdTimestamp ? 1 : -1;
    } else {
      return a.alerts[0].createdTimestamp > b.alerts[0].createdTimestamp ? 1 : -1;
    }
  }
  function stableSortZone(array, comparator) {
    // break the array into 2 parts: one with alerts, one without alerts
    // sort independently and join again before sending back

    const [alertsArray, noAlertsArray] = _partition(array, partitionHelper);

    let stabilizedAlertsArray = [];
    if (orderBy === "createdTimestamp") {
      stabilizedAlertsArray = alertsArray.sort((a, b) => alertArrayHelper(a, b, order));
    } else {
      stabilizedAlertsArray = alertsArray.map((el, index) => [el, index]);
      stabilizedAlertsArray.sort((a, b) => sortHelper(a, b, comparator));
    }

    const stabilizedNoAlertsArray = noAlertsArray.map((el, index) => [el, index]);
    stabilizedNoAlertsArray.sort((a, b) => sortHelper(a, b, comparator));
    return [
      ...(orderBy === "createdTimestamp"
        ? stabilizedAlertsArray
        : stabilizedAlertsArray.map((el) => el[0])),
      ...stabilizedNoAlertsArray.map((el) => el[0]),
    ];
  }

  const handleRequestSort = (event, property, overrideOrder = null) => {
    const isAsc = orderBy === property && order === "asc";

    if (overrideOrder) {
      setOrder(overrideOrder);
    } else {
      setOrder(isAsc ? "desc" : "asc");
    }

    setOrderBy(property);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function navigateToTablePage(_selectedZone) {
    const sortingFunction = isZoneRoute ? stableSortZone : stableSort;
    const sortedData = sortingFunction(data, getComparator(order, orderBy));
    const i = sortedData.findIndex((elm) => elm.id === _selectedZone.id);
    const goToPage = Math.floor(i / rowsPerPage);
    if (goToPage === -1) {
      setPage(0);
    } else {
      setPage(goToPage);
    }
  }

  useEffect(() => {
    if (selectedZone) {
      navigateToTablePage(selectedZone);
    }
  }, [navigateToTablePage, selectedZone]);

  return {
    order,
    orderBy,
    page,
    rowsPerPage,
    getComparator,
    stableSort,
    stableSortZone,
    descendingComparator,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    setPage,
  };
};
