import React, { useState, useEffect } from "react";
import { TextField, makeStyles } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FONTS } from "../../../../utils";
import { DevicesAPI } from "../../../../api";
import { useDebounce } from "../../../../commons";

const useStyles = makeStyles((theme) => ({
  option: {
    minHeight: "auto",
    alignItems: "flex-start",
    padding: 8,
    ...FONTS.B3_Reg_14,
    '&[data-focus="true"]': {
      color: theme.palette.primary.main,
    },
  },
}));

export const DevicesSearch = (props) => {
  const classes = useStyles();
  const { label, onAutoCompleteChange, value, siteID, queryParams } = props;
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [loading, setLoading] = useState(false);

  const debouncedOnChangeDeviceIdSearch = useDebounce((_nextVal) => setSearchString(_nextVal), 300);

  useEffect(() => {
    if (!open) {
      setOptions([]);
      setSearchString("");
    }
  }, [open]);

  useEffect(() => {
    if (searchString) {
      const params = new URLSearchParams({ ...queryParams, q: searchString });
      const queryString = params.toString();
      setLoading(true);
      DevicesAPI.getSitelocationDevicesByQueryString(siteID, queryString).then((response) => {
        setOptions(response);
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString, siteID]);

  return (
    <Autocomplete
      classes={{
        option: classes.option,
      }}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      loading={loading}
      value={value}
      onChange={onAutoCompleteChange}
      data-testid="devices-search-component"
      id={"devices-search-component"}
      options={options}
      getOptionLabel={(option) => option.manufacturerId}
      renderInput={(params) => (
        <TextField
          label={label}
          margin="normal"
          variant="filled"
          onChange={(e) => {
            debouncedOnChangeDeviceIdSearch(e.target.value);
          }}
          {...params}
        />
      )}
    />
  );
};
