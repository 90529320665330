import React, { useState, useContext } from "react";
import {
  TableCell,
  TableRow,
  MenuItem,
  TextField,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Tooltip,
  Box,
  Select,
  FormHelperText,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  USERS_REDUCER_TYPES,
  UsersReducerActions,
  GLOBAL_REDUCER_TYPES,
} from "../../../../reducers";
import {
  useCustomTableStyles,
  RowActionBttn,
  UserTypeDropdown,
  UserPermissionContext,
  ICONS,
} from "../../../../commons";
import { copyTextToClipBoard } from "../../../../utils";

function copyText({ event, cell, rowData, dispatch }) {
  event.stopPropagation();
  if (cell.copyCellTextFn) {
    const content = typeof cell.copyCellTextFn === "function" && cell.copyCellTextFn(rowData);
    if (content) {
      copyTextToClipBoard(content);
      if (cell.id === "assignedDevice.manufacturerId") {
        dispatch({
          type: GLOBAL_REDUCER_TYPES.SET_INFO_TOAST,
          payload: {
            show: true,
            message: "Device ID has been copied.",
          },
        });
      } else if (cell.id === "id") {
        dispatch({
          type: GLOBAL_REDUCER_TYPES.SET_INFO_TOAST,
          payload: {
            show: true,
            message: "User ID has been copied.",
          },
        });
      }
    }
  }
}

function getUserNameWidth(selectedUser) {
  return `${selectedUser?.name ? selectedUser.name.length * 10 : 10}px`;
}

function getCompanyWidth(selectedUser) {
  return `${selectedUser?.company ? selectedUser.company.length * 10 : 10}px`;
}

function renderNameUserDeviceIDContractor(props){
  const {headCells,rowData,tableStyles,selectedUser,setSelectedUser,setSaveBttnDisabled,getUserNameWidth,userAttributes} = props
  return (<> {headCells.filter((cell) => cell.id === "name").length > 0 && (
    <TableCell className={tableStyles.cell} key={`${rowData.id}-user-name`}>
      <TextField
        id="filled-basic"
        variant="filled"
        value={selectedUser.name}
        onChange={(event) => {
          setSelectedUser({ ...selectedUser, name: event.target.value });
          setSaveBttnDisabled(false);
        }}
        inputProps={{
          style: {
            width: getUserNameWidth(selectedUser),
          },
          maxLength: 30,
        }}
      />
      <FormHelperText>{`${selectedUser.name.length}/30`}</FormHelperText>
    </TableCell>
  )}

  <TableCell className={tableStyles.cell} key={`${rowData.id}-user-id`}>
    <Typography gutterBottom noWrap={true}>
      <span>{rowData.id}</span>
    </Typography>
  </TableCell>
  <TableCell className={tableStyles.cell} key={`${rowData.id}-device-id`}>
    <span>{rowData.deviceID}</span>
  </TableCell>

  {userAttributes.contractorOrEmployeeId &&
    <TableCell className={tableStyles.cell} key={`${rowData.id}-contracter-pass-number`}>
      <span>{rowData.contractorPassNumber}</span>
    </TableCell>
  }

  {headCells.filter((cell) => cell.id === "email").length ? (
    <TableCell className={tableStyles.cell} key={`${rowData.id}-email`}>
      <span>{rowData.email}</span>
    </TableCell>
  ) : null}</>)
}

function renderCompanyCell(props){
  const {headCells,rowData,selectedUser,tableStyles,setSelectedUser,setSaveBttnDisabled,getCompanyWidth} = props;
  if(headCells.filter((cell) => cell.id === "company").length > 0 && selectedUser.isContractor){
   return (
      <TableCell className={tableStyles.cell} key={`${rowData.id}-company`}>
        <TextField
          id="filled-basic"
          variant="filled"
          value={selectedUser.company}
          onChange={(event) => {
            setSelectedUser({ ...selectedUser, company: event.target.value });
            setSaveBttnDisabled(false);
          }}
          inputProps={{
            style: {
              width: getCompanyWidth(selectedUser),
            },
            maxLength: 30,
          }}
        />
        <FormHelperText>{`${selectedUser?.company.length}/30`}</FormHelperText>
      </TableCell>
    )
  }else{
    return(
      <TableCell className={tableStyles.cell} key={`${rowData.id}-company`}></TableCell>
    )
  }
}

function userCategoryRoleType(props){
  const {rowData,selectedUser,setSaveBttnDisabled,setSelectedUser,userCategories,userRoles} = props;
 return (<>
 <TableCell key={`${rowData.id}-user-category`}>
  <FormControl variant="filled">
    <InputLabel id="demo-simple-select-label">User Category</InputLabel>
    <Select
      id="simple-select-filled"
      value={selectedUser.categoryId}
      onChange={(event) => {
        setSaveBttnDisabled(false);
        setSelectedUser({
          ...selectedUser,
          categoryId: event.target.value,
        });
      }}
    >
      <MenuItem disabled value="">
        Select User Category
      </MenuItem>
      {userCategories.map((userCat) => (
        <MenuItem key={userCat.id} value={userCat.id}>
          {userCat.name}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
</TableCell>
<TableCell key={`${rowData.id}-user-roles`}>
  <FormControl variant="filled">
    <InputLabel id="demo-simple-select-label">User Role</InputLabel>
    <Select
      required
      labelId="simple-select-filled-label"
      value={selectedUser.roleId}
      id="simple-select-filled"
      onChange={(event) => {
        setSaveBttnDisabled(false);
        setSelectedUser({
          ...selectedUser,
          roleId: event.target.value,
        });
      }}
    >
      <MenuItem disabled value="">
        Select User Role
      </MenuItem>

      {userRoles.map((userRole) => {
        return (
          <MenuItem key={userRole.id} value={userRole.id}>
            {userRole.role}
          </MenuItem>
        );
      })}
    </Select>
  </FormControl>
</TableCell>
<TableCell key={`${rowData.id}-user-types`}>
  <FormControl variant="filled">
    <InputLabel id="demo-simple-select-label">User Type</InputLabel>
    <UserTypeDropdown
      showInputLabel={false}
      value={selectedUser.userType}
      onChange={(event) => {
        setSaveBttnDisabled(false);
        setSelectedUser({
          ...selectedUser,
          userType: event.target.value,
        });
      }}
    />
  </FormControl>
</TableCell>
<TableCell key={`${rowData.id}-optIn-status-created-time`}>
  {rowData.userOptInStatus === "Pending" ? (
    <span style={{ color: "#EB8705" }}>{rowData.userOptInStatus}</span>
  ) : (
    rowData.userOptInStatus
  )}
</TableCell></>)
}

function renderDepartmentRadioChannelLocations(props){
  const {userAttributes,selectedUser,setSelectedUser,setSaveBttnDisabled,getUserNameWidth} = props
 return(<> {userAttributes.department &&
    <TableCell>
      <TextField
        id="filled-basic"
        variant="filled"
        value={selectedUser.department}
        onChange={(event) => {
          setSelectedUser({ ...selectedUser, department: event.target.value });
          setSaveBttnDisabled(false);
        }}
        inputProps={{
          style: {
            width: getUserNameWidth(selectedUser),
          },
          maxLength: 30,
        }}
      />
    </TableCell>
  }
  {userAttributes.radioChannel &&
    <TableCell>
      <TextField
        id="filled-basic"
        variant="filled"
        value={selectedUser.radioChannel}
        onChange={(event) => {
          setSelectedUser({ ...selectedUser, radioChannel: event.target.value });
          setSaveBttnDisabled(false);
        }}
        inputProps={{
          style: {
            width: getUserNameWidth(selectedUser),
          },
          maxLength: 30,
        }}
      />
    </TableCell>
  }
  {userAttributes.locations &&
    <TableCell>
      <TextField
        id="filled-basic"
        variant="filled"
        value={selectedUser.locations}
        onChange={(event) => {
          setSelectedUser({ ...selectedUser, locations: event.target.value });
          setSaveBttnDisabled(false);
        }}
        inputProps={{
          style: {
            width: getUserNameWidth(selectedUser),
          },
          maxLength: 30,
        }}
      />
    </TableCell>
  }</>)
}

function renderActionBttns(props) {
  const {
    tableStyles,
    isEditRowActive,
    saveBttnDisabled,
    rowData,
    setisEditRowActive,
    dispatch,
    selectedUser,
    resetRowEditData,
    Permissions,
  } = props;
  return (
    <Box className={tableStyles.rowActionBttnsWrapper}>
      {isEditRowActive ? (
        <>
          <Button
            className={tableStyles.rowActionBttn}
            disabled={saveBttnDisabled}
            variant="contained"
            color="primary"
            data-id={rowData.id}
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              setisEditRowActive(false);
              dispatch(UsersReducerActions.updateUser(selectedUser));
            }}
          >
            SAVE
          </Button>

          <RowActionBttn
            title={"Cancel"}
            onClick={(event) => {
              event.stopPropagation();
              setisEditRowActive(false);
              resetRowEditData();
            }}
            Icon={ICONS.Cancel}
          />
        </>
      ) : (
        <>
          {Permissions.Manage_Users ? (
            <RowActionBttn
              color="primary"
              title={"Edit"}
              onClick={(event) => {
                event.stopPropagation();
                setisEditRowActive(true);
              }}
              Icon={ICONS.Edit}
              disabled={
                rowData.userOptInStatus !== "Accepted"
              }
            />
          ) : null}

          {Permissions.Delete_Users ? (
            <RowActionBttn
              color="primary"
              title={"Remove"}
              onClick={(event) => {
                event.stopPropagation();

                dispatch({
                  type: USERS_REDUCER_TYPES.SET_SELECTED_USER,
                  payload: rowData,
                });

                dispatch({
                  type: USERS_REDUCER_TYPES.SET_REMOVE_USER_DIALOG_DISPLAY,
                  payload: true,
                });
              }}
              Icon={ICONS.Delete}
            />
          ) : null}
        </>
      )}
    </Box>
  );
}

export const Row = (props) => {
  const dispatch = useDispatch();
  const tableStyles = useCustomTableStyles();
  const { rowData, headCells, isItemSelected } = props;
  const { Permissions } = useContext(UserPermissionContext);

  const userCategories = useSelector((state) => state.usersScreen.userCategories);
  const userRoles = useSelector((state) => state.usersScreen.userRoles);
  const userAttributes = useSelector((state) => state.globalState.site?.userAttributes);

  function setSelectedUserInitialState(rowData) {
    const selectedUser = {
      id: rowData.id,
      name: rowData.name,
      email: rowData.email,
      categoryId: rowData.userCategory.id,
      isContractor: rowData.isContractor,
      contractorPassNumber: rowData.contractorPassNumber,
      contractCompanyName: rowData.contractCompanyName,
      userType: rowData.userType,
      siteLocationIds: rowData.siteLocationIds,
      company: rowData.company,
      department: rowData.department,
      radioChannel: rowData.radioChannel,
      locations: rowData.locations
    };

    if (rowData.role) {
      selectedUser.roleId = rowData.role.id;
    };

    return selectedUser;
  }

  const [selectedUser, setSelectedUser] = useState(setSelectedUserInitialState(rowData));

  const [isEditRowActive, setisEditRowActive] = useState(false);
  const [saveBttnDisabled, setSaveBttnDisabled] = useState(true);

  function resetRowEditData() {
    setSelectedUser({
      id: rowData.id,
      name: rowData.name,
      email: rowData.email,
      categoryId: rowData.userCategory.id,
      isContractor: rowData.isContractor,
      contractorPassNumber: rowData.contractorPassNumber,
      contractCompanyName: rowData.contractCompanyName,
      userType: rowData.userType,
      roleId: rowData.role.id,
      siteLocationIds: rowData.siteLocationIds,
      department: rowData.department,
      radioChannel: rowData.radioChannel,
      locations: rowData.locations
    });
    setisEditRowActive(false);
  }

  return (
    <TableRow hover role="checkbox" tabIndex={-1} selected={isItemSelected}>
      {isEditRowActive ? (
        <>
          {renderNameUserDeviceIDContractor({headCells,rowData,tableStyles,selectedUser,setSelectedUser,setSaveBttnDisabled,getUserNameWidth,userAttributes})}
          {renderCompanyCell({headCells,rowData,selectedUser,tableStyles,setSelectedUser,setSaveBttnDisabled,getCompanyWidth})}
          {userCategoryRoleType({rowData,selectedUser,setSaveBttnDisabled,setSelectedUser,userCategories,userRoles})}
          <TableCell key={`${rowData.id}-optIn-status-created-time`}>
            {rowData.userOptInStatus === "Pending" ? (
              <span style={{ color: "#EB8705" }}>{rowData.userOptInStatus}</span>
            ) : (
              rowData.userOptInStatus
            )}
          </TableCell>
          {renderDepartmentRadioChannelLocations({userAttributes,selectedUser,setSelectedUser,setSaveBttnDisabled,getUserNameWidth})}
          <TableCell key={`${rowData.id}-account-created-time`}>
            {rowData.createdTimestamp}
          </TableCell>
          <TableCell key={`${rowData.id}-last-active`}>{rowData.lastActive}</TableCell>
        </>
      ) : (
        headCells.map((cell, index) => {
          const showEllipses = cell.showEllipses;
          const content = cell.content(rowData);
          const toolTipContent = cell.toolTipContent ? cell.toolTipContent(rowData) : null;
          const tableCellCmp = (
            <TableCell
              key={`${cell.id}-${index}`}
              className={tableStyles.cell}
              onClick={(event) => {
                copyText({ event, cell, rowData, dispatch });
              }}
            >
              <span>{content}</span>
            </TableCell>
          );

          if (showEllipses && toolTipContent) {
            return (
              <Tooltip
                classes={{
                  tooltip: tableStyles.tooltip,
                }}
                title={
                  <>
                    <div className={tableStyles.tooltipContentWrapper}>
                      <span className={tableStyles.tooltipContent}>
                        {toolTipContent ?? content}
                      </span>
                    </div>
                  </>
                }
                arrow
                placement="top-start"
                key={`${cell.id}-tooltip`}
              >
                {tableCellCmp}
              </Tooltip>
            );
          }

          return tableCellCmp;
        })
      )}

      <TableCell className={tableStyles.cell}>
        {renderActionBttns({
          tableStyles,
          isEditRowActive,
          saveBttnDisabled,
          rowData,
          setisEditRowActive,
          dispatch,
          selectedUser,
          resetRowEditData,
          Permissions,
        })}
      </TableCell>
    </TableRow>
  );
};
