const filterGases = (gas) => {
  return (["O2","LEL-MPS","LEL","H2S","CO","PID"].map((item)=>item.toLowerCase() === gas.gasType.toLowerCase()) );
};

export const removeDuplicates = (arr, att = "gasType") => {
  // For now this will remove duplicates base on gasType name
  // It can be modified to remove duplicate by adding && t.value===v.value
  return arr.filter((v, i, a) => a.findIndex((t) => t[att] === v[att]) === i);
};

const gasPercentage = (curr) =>{
  return curr.units.toLowerCase() === "ppm" ? `(${curr.reading} ${curr.units})` : `(${curr.reading}%${curr.units})`;
}

const gasMapper = (sensors) => {
  return sensors.map((gas) => {
    switch (gas.gasType.toLowerCase()) {
      case "o2":
      case "lel":
      case "lel-mps":
        return {
          ...gas,
          showLevel: true,
        };
      case "h2s":
      case "co":
      case "pid":
      default:
        return {
          ...gas,
        };
    }
  });
};

const toolTipStringHelper = (curr) => {
  return curr.readingDescription ? curr.readingDescription : gasPercentage(curr);
};

const toolTipStringFormater = (toolTip, acc, curr) => {
  return toolTip
    ? `${acc} ${curr.gasType} ${toolTipStringHelper(curr)},`
    : `${acc} ${curr.gasType}: ${toolTipStringHelper(curr)},`;
};

const showLevelFormatter = (toolTip, acc, curr, showLevel, last = false) => {
  if (last) {
    return showLevel ? toolTipStringFormater(toolTip, acc, curr) : `${acc} ${curr.gasType},`;
  } else {
    return showLevel ? toolTipStringFormater(toolTip, acc, curr) : `${acc} ${curr.gasType}`;
  }
};

export const gasTypes = (sensors, showLevel = false, toolTip = false) => {
  const gasString = "";
  if (sensors) {
    const sensorArr = gasMapper(sensors);

    return removeDuplicates(sensorArr)
      ?.filter(filterGases)
      .reduce((acc, curr, idx, arr) => {
        return arr.length - 1 !== idx
          ? showLevelFormatter(toolTip, acc, curr, showLevel)
          : showLevelFormatter(toolTip, acc, curr, showLevel, true);
      }, "");
  }

  return gasString;
};

export const setGasTypeEnabled = (deviceConfiguration) => {
  let enableGasType = true;

  if (deviceConfiguration?.Mobile && !deviceConfiguration?.Blackline && !deviceConfiguration?.ISC) {
      enableGasType = false;
  }

  return enableGasType;
}
