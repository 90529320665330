import React from "react";
import { makeStyles, Box, IconButton } from "@material-ui/core";
import { ICONS } from "../icon-manager";

const useStyles = makeStyles((theme) => ({
  closeIconWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export const DialogCloseBttn = ({ onClick }) => {
  const classes = useStyles();
  return (
    <Box className={classes.closeIconWrapper}>
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClick}>
        <ICONS.Close />
      </IconButton>
    </Box>
  );
};
