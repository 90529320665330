export const APP_CONSTANTS = {
  MUSTER_ZONE_ENTER_MSG: "you are now entering a muster zone",
  MUSTER_ZONE_EXIT_MSG: "you are now exiting a muster zone",
  WEATHER_REDIRECT: "https://my.safersystem.com/saferone.html#default",
  THEME_LIGHT: "light",
  THEME_DARK: "dark",
  GLOBAL_TENANT_ID: "__ALL__",
  Blackline:"https://live.blacklinesafety.com/sign-in",
  ISC:"https://www.indsci.com/en/"
};
