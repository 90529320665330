import React from "react";
import { Select, MenuItem, InputLabel } from "@material-ui/core";

const userTypes = ["Vehicle", "Pedestrian"];

export const UserTypeDropdown = (props) => {
  const { showInputLabel = true, value, onChange } = props;
  return (
    <>
      {showInputLabel ? <InputLabel id="user-type-dropdown-label">User Type</InputLabel> : null}
      <Select
        labelId="user-type-dropdown-label"
        id="user-type-dropdown"
        onChange={onChange}
        value={value}
      >
        <MenuItem value="" disabled>
          Select User Type
        </MenuItem>

        {userTypes.map((userType, index) => (
          <MenuItem key={index} value={userType}>
            {userType}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};
