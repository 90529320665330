import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  makeStyles,
  Dialog,
  DialogTitle,
  Button,
  TextField,
  Box,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import { Datepicker } from "../../../commons";
import { ZonesReducerActions } from "../../../reducers";
import { useDialogStyles } from "../../../commons/dialogs/use-dialog-styles";
import { parseUtcToLocal } from "../../../utils";

const useStyles = makeStyles(() => ({
  formControl: {
    width: "100%",
    marginBottom: "32px",
  },
  formControlActions: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
}));

export const CategoryNewDialog = ({ show, onCancel, onConfirm }) => {
  const classes = useStyles();
  const dialogStyles = useDialogStyles();
  const dispatch = useDispatch();
  const [categoryName, setCategoryName] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [isAdHoc, setIsAdHoc] = useState("false");

  const [createBttnDisabled, setCreateBttnDisabled] = useState(true);

  function handleDialogClose(event) {
    onCancel(event);
    resetForm();
  }

  function resetForm() {
    setCategoryName("");
    setIsAdHoc("false");
    setStartTime(null);
    setEndTime(null);
    setCreateBttnDisabled(true);
  }

  const handleEndDateChange = (date) => {
    setEndTime(date);
  };

  const handleStartDateChange = (date) => {
    setStartTime(date);
  };

  function handleFormSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    dispatch(
      ZonesReducerActions.createNewZoneCategory({
        name: categoryName,
        startTime: startTime,
        endTime: endTime,
        isAdHoc: isAdHoc === "true" ? true : false,
      })
    );
    onConfirm && onConfirm();
  }

  const handleRadioGroupChange = (event) => {
    setIsAdHoc(event.target.value);
  };

  useEffect(() => {
    if (isAdHoc === "true") {
      setStartTime(new Date());
    } else {
      setStartTime(null);
      setEndTime(null);
    }
  }, [isAdHoc]);

  useEffect(() => {
    if (isAdHoc === "true" && startTime && endTime) {
      setCreateBttnDisabled(false);
    } else if (isAdHoc === "false" && categoryName) {
      setCreateBttnDisabled(false);
    } else {
      setCreateBttnDisabled(true);
    }
  }, [isAdHoc, categoryName, startTime, endTime]);

  useEffect(() => {
    if (!show) {
      resetForm();
    }
  }, [show]);

  return (
    <Dialog
      open={show}
      onClose={handleDialogClose}
      PaperProps={{ className: dialogStyles.dialogPaper }}
    >
      <Box className={dialogStyles.dialogContentWrapper}>
        <DialogTitle
          className={dialogStyles.dialogTitle}
          disableTypography
          id="new-dialog-category"
        >
          <span>Create New Category</span>
        </DialogTitle>
        <form className={classes.form} onSubmit={handleFormSubmit} autocomplete="off">
          <FormControl variant="filled" className={classes.formControl}>
            <TextField
              required
              id="filled-basic"
              label="Category Name"
              variant="filled"
              fullWidth={true}
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
            />
          </FormControl>

          <FormControl component="fieldset" className={classes.formControl}>
            <RadioGroup
              value={isAdHoc}
              onChange={handleRadioGroupChange}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <FormControlLabel
                value="false"
                control={<Radio color="primary" />}
                label="Permanent Category"
              />
              <FormControlLabel
                value="true"
                control={<Radio color="primary" />}
                label="Temporary Category"
              />
            </RadioGroup>
          </FormControl>

          {isAdHoc === "true" ? (
            <>
              <FormControl component="fieldset" className={classes.formControl}>
                <Datepicker
                  handleDateChange={handleStartDateChange}
                  selectedDate={startTime}
                  label="Select start date"
                />
              </FormControl>

              <FormControl component="fieldset" className={classes.formControl}>
                <Datepicker
                  required
                  handleDateChange={handleEndDateChange}
                  selectedDate={endTime}
                  label="Select end date"
                  minDate={startTime}
                  minDateMessage={`Date and Time should not be less than ${parseUtcToLocal(
                    startTime,
                    "MM-DD-YY HH:mm A"
                  )}`}
                  strictCompareDates={true}
                />
              </FormControl>
            </>
          ) : null}

          <FormControl required variant="filled" className={classes.formControlActions}>
            <Button
              onClick={handleDialogClose}
              variant="outlined"
              color="primary"
              className={dialogStyles.dialogActionBttn}
            >
              Cancel
            </Button>
            <Button
              className={dialogStyles.dialogActionBttn}
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginLeft: "10px" }}
              disabled={createBttnDisabled}
            >
              Create
            </Button>
          </FormControl>
        </form>
      </Box>
    </Dialog>
  );
};
