import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableFooter,
  TableContainer,
  TablePagination,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { DeviceIdWithBatteryIcon } from "../../../../../commons/deviceId-with-battery-icon";
import { DeviceIdWithBatteryIconTooltip } from "../../../../../commons/deviceId-with-battery-icon-tooltip";
import { Row } from "./row";
import {
  useCustomTableHook,
  useCustomTableStyles,
  EnhancedTableHead,
  UserPermissionContext,
  ICONS,
  useDebounce,
} from "../../../../../commons";
import { DEVICES_REDUCER_TYPES } from "../../../../../reducers";
import { parseUtcToLocal } from "../../../../../utils/date-fns";
import { ViewZoneHistoryDialog } from '../../../components/device-history/view-zone-history-dialog';

const headCellMap = {
  manufacturerId: "manufacturerId",
  lastAssignedUser: "lastAssignedUser.id",
  assignmentTimestamp: "assignmentTimestamp",
  width30: "30%",
  deviceIdLabel: "Device ID",
  componentDiv: "div",
};

const headCells = [
  {
    id: headCellMap.manufacturerId,
    sortBy: headCellMap.manufacturerId,
    label: headCellMap.deviceIdLabel,
    width: "18%",
    showEllipses: true,
    copyCellTextFn: (row) => row.manufacturerId,
    toolTipContent: (row) =>
      row.manufacturerId ? (
        <DeviceIdWithBatteryIconTooltip
          manufacturerId={row.manufacturerId}
          level={row.status.battery.internalLevel}
          isOnline={row.status.isOnline}
        />
      ) : null,
    content: (row) => (
      <DeviceIdWithBatteryIcon
        manufacturerId={row.manufacturerId}
        level={row.status.battery.internalLevel}
        isOnline={row.status.isOnline}
      />
    ),
  },
  {
    id: headCellMap.lastAssignedUser,
    sortBy: headCellMap.lastAssignedUser,
    label: "Last Assigned to (User ID)",
    content: (row) => row.lastAssignedUser?.id,
    showEllipses: true,
    width: headCellMap.width30,
  },
  {
    id: headCellMap.assignmentTimestamp,
    sortBy: headCellMap.assignmentTimestamp,
    label: "Last Assigned on (Date)",
    content: (row) => parseUtcToLocal(row.assignmentTimestamp),
    width: headCellMap.width30,
  },
];

export const UnassignedDevicesTable = (props) => {
  const tableClasses = useCustomTableStyles();
  const dispatch = useDispatch();
  const [tableSearchStr, setTableSearchStr] = useState("");
  const [tableData, setTableData] = useState([]);
  const [showViewZoneDeviceHistory, setshowViewZoneDeviceHistory] = useState(false);
  const [selectedDevice, setselectedDevice] = useState(null);
 
  const { Permissions } = useContext(UserPermissionContext);

  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    setPage,
    getComparator,
    stableSort,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useCustomTableHook({ defaultSortBy: headCells[0].sortBy, rowsPerPage: 10 });

  const {
    data,
    onTableRowSelectedCb,
    handleRemoveZoneDialogDisplay,
    rowActionBttn,
    ...otherProps
  } = props;

  const unAssignDeviceTableSearchDebounced = useDebounce((_nextVal) => {
    setPage(0);
    setTableSearchStr(_nextVal);
  }, 300);
  const triggerViewHistoryAction = (deviceData) => {
    setshowViewZoneDeviceHistory(true);
    setselectedDevice(deviceData);
  }
  const triggerClosePopup=()=>{
    setshowViewZoneDeviceHistory(false);
  }
 
  useEffect(() => {
    setTableData(data);
  }, [data]);

  useEffect(() => {
    let arr = [];

    if (tableSearchStr) {
      arr = data.filter(
        (tData) =>
          tData.manufacturerId &&
          tData.manufacturerId.toLowerCase().includes(tableSearchStr.toLowerCase())
      );
    } else {
      arr = data;
    }

    setTableData(arr);
  }, [tableSearchStr, setTableData, data]);

  return (
    <div>
      <Paper>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            aria-label="unassigned devices table"
            classes={{
              root: tableClasses.tableRoot,
            }}
            data-testid="unassigned-devices-table"
            stickyHeader
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
              toShow={true}
              tableWithRowActions={true}
              onChangeSearch={(event) => {
                unAssignDeviceTableSearchDebounced(event.target.value);
              }}
              searchLabel={headCellMap.deviceIdLabel}
            />
            <TableBody data-testid="unassigned-devices-table-body">
              {stableSort(tableData, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((rowData) => {
                  return (
                    <Row
                      key={rowData.id}
                      fullMapView={true}
                      rowData={rowData}
                      onTableRowSelectedCb={onTableRowSelectedCb}
                      headCells={headCells}
                      handleRemoveZoneDialogDisplay={handleRemoveZoneDialogDisplay}
                      rowActionBttn={rowActionBttn}
                      triggerViewHistoryAction={triggerViewHistoryAction}
                      {...otherProps}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TableFooter className={tableClasses.tabelFooterRoot} component={headCellMap.componentDiv}>
          {Permissions.Manage_Devices ? (
            <Button
              startIcon={<ICONS.Add />}
              color="primary"
              onClick={() =>
                dispatch({
                  type: DEVICES_REDUCER_TYPES.SET_ASSIGN_NEW_DEVICE_DIALOG_DISPLAY,
                  payload: true,
                })
              }
            >
              Assign New Device to User
            </Button>
          ) : (
            <div></div>
          )}

          <TablePagination
            classes={{
              root: tableClasses.tablePaginationRoot,
            }}
            component={headCellMap.componentDiv}
            rowsPerPageOptions={[]}
            labelRowsPerPage=""
            count={tableData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableFooter>
        <ViewZoneHistoryDialog show={showViewZoneDeviceHistory} deviceDetails={selectedDevice} triggerClosePopup={triggerClosePopup} >

</ViewZoneHistoryDialog>
      </Paper>
    </div>
  );
};
