import React, { useEffect } from "react";
import { Grid, Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { DevicesManagement } from "./devices-management";
import { DocumentTitle, useCommonStyles } from "../../commons";
import { DevicesReducerActions, GlobalReducerActions } from "../../reducers";

const DevicesScreen = () => {
  const dispatch = useDispatch();
  const commonStyles = useCommonStyles();
  const siteID = useSelector((state) => state.globalState.siteID);

  useEffect(() => {
    if (siteID) {
      dispatch(DevicesReducerActions.getAllDevices());
      dispatch(GlobalReducerActions.getAllAlerts(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, siteID]);

  return (
    <>
      <DocumentTitle title="Sitewise | Devices" />
      <Grid id="j-sitewise-devices" className={commonStyles.page_wrapper}>
        <Box className={commonStyles.page_header}>
          <span className={commonStyles.page_title} data-testid="page-title">
            Devices
          </span>
        </Box>
        {siteID ? <DevicesManagement siteID={siteID} /> : null}
      </Grid>
    </>
  );
};

export default DevicesScreen;
