import axios from "axios";
import qs from "qs";
import { useEffect, useState, useRef, useCallback } from "react";

const STATIC_MAP_URL =
  "https://utility.arcgisonline.com/arcgis/rest/services/Utilities/PrintingTools/GPServer/Export Web Map Task/execute";

const requestData = (mapOptions) => {
  const map = JSON.stringify({
    mapOptions: {
      extent: mapOptions
        ? mapOptions
        : {
            xmin: -16999060.35,
            ymin: -6111446.32,
            xmax: 17362135.59,
            ymax: 17370008.77,
            spatialReference: { wkId: 1000 },
          },
    },
    operationalLayers: [],
    baseMap: {
      title: "Topographic Basemap",
      baseMapLayers: [
        {
          url: "https://services.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer",
        },
      ],
    },
    exportOptions: {
      outputSize: [600, 400],
    },
  });

  return qs.stringify({
    f: "json",
    Format: "PNG32",
    Layout_Template: "MAP_ONLY",
    Web_Map_as_JSON: map,
  });
};

export const useStaticMapApi = (mapOptions, deps) => {
  const [url, setUrl] = useState(null);
  const cancelToken = axios.CancelToken.source();
  const cancel = useRef({ cancel: false });

  const getStaticMap = useCallback(async () => {
    try {
      const data = requestData(mapOptions);
      const res = await axios({
        url: STATIC_MAP_URL,
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
        cancelToken: cancelToken.token,
      });
      return res.data;
    } catch (error) {
      if (!(error instanceof axios.Cancel)) {
        console.error(error);
      }
      return null;
    }
  }, [cancelToken.token, mapOptions]);

  useEffect(() => {
    const cancelRef = cancel;
    if (!url) {
      if (mapOptions) {
        getStaticMap(mapOptions)
          .then((res) => setUrl(res.results[0].value.url))
          .catch((err) => {
            if (!cancelRef.current.cancel) {
              console.error(err);
            }
          });
      } else {
        getStaticMap(null)
          .then((res) => setUrl(res.results[0].value.url))
          .catch((err) => {
            if (!cancelRef.current.cancel) {
              console.error("Error: ", err);
            }
          });
      }
    }
    return () => {
      cancelRef.current.cancel = true;
      cancelToken.cancel();
    };
  }, [cancelToken, mapOptions, url, getStaticMap, deps]);

  return [url];
};
