import * as signalR from "@microsoft/signalr";
import _debounce from "lodash.debounce";
import {
  UsersReducerActions,
  AlertsReducerActions,
  MessagesReducerActions,
  GlobalReducerActions,
  DevicesReducerActions,
  ZonesReducerActions,
  SitesReducerActions,
} from "../reducers";


const DEFAULT_DEBOUNCE_WAIT_TIME = 3000;
const ALERT_MSG_DEBOUNCE_WAIT_TIME = 1000;

export class SignalRConn {
  constructor(dispatch, routesJSON) {
    this.dispatch = dispatch;
    this.routesJSON = routesJSON;
    this.routePrefix = window.jarvis?"/sitewise/":"/";
    this.currentTabRef = { current: this.routePrefix };
    this.SignalR = new signalR.HubConnectionBuilder()
      .withUrl(process.env.REACT_APP_SITEWISE_SIGNALR_ENDPOINT)
      .withAutomaticReconnect([0, 0, 10000])
      .build();
  }

  //Key processing functions
  processAlertsKeyCb = _debounce((currentRoute) => {
    if (currentRoute.href === `${this.routePrefix}alerts`) {
      const params = new URLSearchParams({
        o: true,
        a: true,
      });
      const queryString = params.toString();
      this.dispatch(AlertsReducerActions.getUserDevices(queryString, false));
    }

    if (currentRoute.href === `${this.routePrefix}zones`) {
      // Fetch devices in case of alerts event
      this.dispatch(GlobalReducerActions.getUserDevicesWithAppliedFilters(null, false));
    }

    this.dispatch(GlobalReducerActions.getAllAlerts(false));
  }, ALERT_MSG_DEBOUNCE_WAIT_TIME);

  processBulkdevicemessagesKeyCb = _debounce(() => {
    this.dispatch(MessagesReducerActions.getAllBulkDeviceMessages(false));
  }, ALERT_MSG_DEBOUNCE_WAIT_TIME);

  processDashboardmessagesKeyCb = _debounce((currentRoute) => {
    if (currentRoute.href === `${this.routePrefix}zones`) {
      this.dispatch(ZonesReducerActions.getAllDashboardMessages(false));
      this.dispatch(GlobalReducerActions.showSoftAlert());
    }

    if (currentRoute.href !== `${this.routePrefix}zones`) {
      this.dispatch(MessagesReducerActions.getAllDashboardMessages(false));
    }
  }, ALERT_MSG_DEBOUNCE_WAIT_TIME);

  processDevicesKeyCb = _debounce((currentRoute) => {
    if (currentRoute.href === `${this.routePrefix}devices`) {
      this.dispatch(DevicesReducerActions.getAllDevices(false));
    }

    if (currentRoute.href === `${this.routePrefix}zones`) {
      this.dispatch(GlobalReducerActions.getUserDevicesWithAppliedFilters(null, false));
    }
    //Since people tab also shows the devices, we need to refresh users data
    if (currentRoute.href === `${this.routePrefix}users`) {
      this.dispatch(UsersReducerActions.getAllUsers(false));
    }
  }, DEFAULT_DEBOUNCE_WAIT_TIME);

  processRolesKeyCb = _debounce(() => {
    this.dispatch(UsersReducerActions.getUserRoles(false));
  }, DEFAULT_DEBOUNCE_WAIT_TIME);

  processUsersKeyCb = _debounce(() => {
    this.dispatch(UsersReducerActions.getAllUsers(false));
  }, DEFAULT_DEBOUNCE_WAIT_TIME);

  processZonesKeyCb = _debounce((currentRoute) => {
    if (currentRoute.href === `${this.routePrefix}zones`) {
      this.dispatch(ZonesReducerActions.getAllZones(false));
    }
    if (currentRoute.href === `${this.routePrefix}alerts`) {
      this.dispatch(AlertsReducerActions.getAllZones(false));
    }
  }, DEFAULT_DEBOUNCE_WAIT_TIME);

  processSitelocationsKeyCb = _debounce((currentRoute) => {
    if (currentRoute.href === `${this.routePrefix}sites`) {
      this.dispatch(SitesReducerActions.getAllSites(false));
    }
  }, DEFAULT_DEBOUNCE_WAIT_TIME);

  processZonecategoriesKeyCb = _debounce(() => {
    this.dispatch(ZonesReducerActions.getAllZoneCategories(false));
  }, DEFAULT_DEBOUNCE_WAIT_TIME);

  UpdateSignalRConnStatus = function (status) {
    this.dispatch(GlobalReducerActions.updateSignalRConnStatus(status));
  };
  handleSignalRKeyProcessing = function (msg) {
    console.log(
      `Received UpdateDashboardEvents: ${JSON.stringify(msg)}. Current Route: ${this.currentTabRef.current
      }`
    );

    if (msg.Key) {
      const currentRoute = this.routesJSON.find(
        (route) => route.href === this.currentTabRef.current
      );
      if (currentRoute && currentRoute.supportedSignalREventKeys.includes(msg.Key)) {
        console.log("We need to process the key on this route");
        switch (msg.Key) {
          case "alerts":
            this.processAlertsKeyCb(currentRoute);
            break;
          case "bulkdevicemessages":
            this.processBulkdevicemessagesKeyCb();
            break;
          case "dashboardmessages":
            this.processDashboardmessagesKeyCb(currentRoute);
            break;
          case "devices":
            this.processDevicesKeyCb(currentRoute);
            break;
          case "roles":
            this.processRolesKeyCb();
            break;
          case "users":
            this.processUsersKeyCb();
            break;
          case "zones":
            this.processZonesKeyCb(currentRoute);
            break;
          case "zonecategories":
            this.processZonecategoriesKeyCb();
            break;
          case "sitelocations":
            this.processSitelocationsKeyCb(currentRoute);
            break;
          default:
            console.log("Key is not supported");
            break;
        }
      } else {
        console.log("***** We don't need to process the key on this route ****");
      }
    } else {
      console.log("Key is missing: ", msg.Key);
    }
  };

  stopConnection = async function () {
    await this.SignalR.stop()
      .then(() => {
        console.log("SignalR Connection closed...");
        this.UpdateSignalRConnStatus(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  startConnection = async function (currentTabRef) {
      this.currentTabRef = currentTabRef;
      await this.SignalR.start()
        .then(() => {
          console.log("SignalR Connection is up...");
          this.UpdateSignalRConnStatus(true);
          this.SignalR.on("UpdateDashboardEvents", (msg) => {
            const currentSiteId = parseInt(localStorage.SITE_ID);
            if (currentSiteId === msg.SiteLocationId) {
              this.handleSignalRKeyProcessing(msg, this.currentTabRef, this.routesJSON)
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
  };

  updateCurrentTabRef = function (currentTabRef) {
    this.currentTabRef = currentTabRef;
  };
}
