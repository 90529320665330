import React, { useContext } from "react";
import { TableCell, TableRow, Tooltip, Box, makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { DEVICES_REDUCER_TYPES, GLOBAL_REDUCER_TYPES } from "../../../../../reducers";
import { copyTextToClipBoard } from "../../../../../utils";
import {
  useCustomTableStyles,
  RowActionBttn,
  UserPermissionContext,
  ICONS,
} from "../../../../../commons";

const useStyles = makeStyles((theme) => ({
  actionBttnsWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));

function copyText(event, cell, rowData, dispatch) {
  event.stopPropagation();
  if (cell.copyCellTextFn) {
    const content = typeof cell.copyCellTextFn === "function" && cell.copyCellTextFn(rowData);
    if (content) {
      copyTextToClipBoard(content);
      if (cell.id === "id") {
        dispatch({
          type: GLOBAL_REDUCER_TYPES.SET_INFO_TOAST,
          payload: {
            show: true,
            message: "User ID has been copied.",
          },
        });
      } else if (cell.id === "manufacturerId") {
        dispatch({
          type: GLOBAL_REDUCER_TYPES.SET_INFO_TOAST,
          payload: {
            show: true,
            message: "Device ID has been copied.",
          },
        });
      }
    }
  }
}

const CONSTANTS = {
  gas: "gasType",
};

export const Row = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const tableStyles = useCustomTableStyles();

  const { rowData, headCells,triggerViewHistoryAction } = props;
  const { Permissions } = useContext(UserPermissionContext);

  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={rowData.id}>
      {headCells.map((cell, index) => {
        const { showEllipses } = cell;
        const content = cell.content(rowData);
        const toolTipContent = cell.toolTipContent ? cell.toolTipContent(rowData) : null;

        const tableCellCmp = (
          <TableCell
            onClick={(event) => {
              copyText(event, cell, rowData, dispatch);
            }}
            key={`${rowData.id}-${cell.id}-${index}`}
            className={
              cell.id === CONSTANTS.gas
                ? `${tableStyles.cell} ${tableStyles.alertText}`
                : tableStyles.cell
            }
          >
            <div style={{ width: "170px" }}>
              {cell.id === CONSTANTS.gas
                ? content.split(",").map((gas, i) => <div key={i}>{gas}</div>)
                : content}
            </div>
          </TableCell>
        );

        if (showEllipses && content) {
          return (
            <Tooltip
              classes={{
                tooltip: tableStyles.tooltip,
              }}
              title={
                <div className={tableStyles.tooltipContentWrapper}>
                  <span className={tableStyles.tooltipContent}>{toolTipContent ?? content}</span>
                </div>
              }
              arrow
              placement="top-start"
              key={`${rowData.id}-${cell.id}-${index}-tooltip`}
            >
              {tableCellCmp}
            </Tooltip>
          );
        }

        return tableCellCmp;
      })}

      <TableCell className={tableStyles.cell} key={`${rowData.id}-cell-actions`}>
        <span style={{display: "flex"}}>
      <Box className={classes.actionBttnsWrapper}>
            <RowActionBttn
              color="primary"
              title={"View History"}
              onClick={(event) => {
                event.stopPropagation();
                triggerViewHistoryAction(rowData);
              }}
              Icon={ICONS.HistoryIcon}
            />
          </Box>
        {Permissions.Manage_Devices ? (
          <Box className={classes.actionBttnsWrapper}>
            <RowActionBttn
              color="primary"
              title={"Unassign"}
              onClick={(event) => {
                event.stopPropagation();
                dispatch({
                  type: DEVICES_REDUCER_TYPES.SET_SELECTED_DEVICE,
                  payload: rowData,
                });

                dispatch({
                  type: DEVICES_REDUCER_TYPES.SET_UNASSIGN_DEVICE_DIALOG_DISPLAY,
                  payload: true,
                });
              }}
              Icon={ICONS.UnAssign}
            />
          </Box>
        ) : null}
        </span>
      </TableCell>
    </TableRow>
  );
};
