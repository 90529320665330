import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, IconButton, Button, InputBase, makeStyles } from "@material-ui/core";
import { ICONS, useCommonStyles, UserPermissionContext } from "../../../../../../commons";
import { SitesReducerActions, SITES_REDUCER_TYPES } from "../../../../../../reducers";
import { FONTS } from "../../../../../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.base.elevation_0,
    border: `1px solid ${theme.palette.base.inputBorderColor}`,
    borderRadius: "2px",
    height: "40px",
    padding: "0px 0px 0px 10px",
    ...FONTS.H5_ShellBold_20,
    minWidth: "30%",
    maxWidth: "50%",
  },
}));

export const EditSiteName = (props) => {
  const { currentSite } = props;
  const commonStyles = useCommonStyles();
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();
  const { Permissions } = useContext(UserPermissionContext);
  const classes = useStyles();

  return (
    <Box
      className={commonStyles.page_breadcrumps_header}
      style={{ justifyContent: "flex-start", alignItems: "center" }}
    >
      {edit ? (
        <>
          <InputBase
            classes={{
              root: classes.root,
            }}
            id="site-name-input"
            value={currentSite?.name}
            onChange={(event) =>
              dispatch({
                type: SITES_REDUCER_TYPES.SET_CURRENT_SITE,
                payload: {
                  ...currentSite,
                  name: event.target.value,
                },
              })
            }
          />
          <Button
            variant="contained"
            color="primary"
            disableElevation={true}
            onClick={() =>
              dispatch(SitesReducerActions.updateSiteConfig()).then(() => {
                setEdit(false);
              })
            }
            style={{ marginLeft: "10px" }}
            data-testid="save-button"
          >
            Save
          </Button>
        </>
      ) : (
        <>
          <span className={commonStyles.page_title} data-testid="page-title">
            {currentSite?.name}
          </span>
          {Permissions.Manage_Sites && (
            <IconButton
              aria-label="edit"
              color="primary"
              size="medium"
              onClick={() => setEdit(true)}
              style={{ marginLeft: "10px" }}
              title="Edit"
            >
              <ICONS.EditCustomIcon style={{ fontSize: "1.2rem" }} />
            </IconButton>
          )}
        </>
      )}
    </Box>
  );
};
