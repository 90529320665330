import React from "react";
import { makeStyles, FormGroup, FormControlLabel } from "@material-ui/core";
import { YellowCheckbox } from "../../../../../commons";
import { FONTS } from "../../../../../utils";


const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    marginTop: "24px",
  },
  formLabel2: {
    ...FONTS.B5_Bold_12,
  },
  formGroup: {
    marginRight: "40px",
  },
  formLabel1: {
    ...FONTS.B5_Bold_12,
  },
  recommendations: {
    opacity: "0.6"
  },
  checkboxColor: {
    "&:hover": {
      color: theme.palette.primary.main
    }
  }
}));

export const CheckboxesForm = (props) => {
  const {
    list,
    isRowDataNotEditable,
    handleChange,
    boxes
  } = props;
  const classes = useStyles();

  return (
    <FormGroup className={classes.formGroup}>
      <label className={classes.formLabel2}>{boxes.label}</label>
      <div>
        {boxes.keys.map((keyObj) => (
          keyObj.label === "View" ?
            <FormControlLabel
              control={
                <YellowCheckbox
                  style={keyObj.style}
                  className={classes.checkboxColor}
                  checked={true}
                  name={keyObj.key}
                  disabled
                />
              }
              label={<span className={classes.formLabel1} style={keyObj.style}>{keyObj.label}</span>}
            /> :
            <FormControlLabel
              control={
                <YellowCheckbox
                  style={keyObj.style}
                  className={classes.checkboxColor}
                  checked={list.includes(keyObj.key)}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name={keyObj.key}
                  disabled={isRowDataNotEditable}
                />
              }
              label={<span className={classes.formLabel1} >{keyObj.label}</span>}
            />
        ))}
      </div>
    </FormGroup>
  );
};
