import React from "react";
import { Box, Dialog } from "@material-ui/core";
import { DialogActionBttnBig, useDialogStyles } from "../../";

export const InfoDialog = (props) => {
  const classes = useDialogStyles();
  const {
    show,
    onCancel,
    onConfirmActionOne,
    infoText,
    bttnOne,
    onConfirmActionTwo,
    bttnTwo,
  } = props;

  if (!show) {
    return null;
  }

  return (
    <Dialog open={show} onClose={onCancel} PaperProps={{ className: classes.dialogPaper }}>
      <Box style={{ padding: "24px 24px 24px 24px" }}>
        <div style={{ textAlign: "center" }} dangerouslySetInnerHTML={{ __html: infoText }}></div>
        <Box className={classes.actionsBttnWrapper} style={{ marginTop: "40px" }}>
          <DialogActionBttnBig
            BttnIcon={bttnOne.Icon}
            label={bttnOne.label}
            onClick={onConfirmActionOne}
          />
          <DialogActionBttnBig
            label={bttnTwo.label}
            onClick={onConfirmActionTwo}
            BttnIcon={bttnTwo.Icon}
          />
        </Box>
      </Box>
    </Dialog>
  );
};
