import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

/**
 *
 * @param {file} file Its value can be an URL, a file (imported using import ... from ... or from file input form element),
 * or an object with parameters (url - URL; data - data, preferably Uint8Array;
 * range - PDFDataRangeTransport; httpHeaders - custom request headers, e.g. for authorization),
 *  withCredentials - a boolean to indicate whether or not to include cookies in the request (defaults to false).
 * @param {width} width Page width. If neither height nor width are defined, page will be rendered at the size defined in PDF.
 * @param {height} height Page height. If neither height nor width are defined, page will be rendered at the size defined in PDF.
 * @param {scale} scale Page scale.
 * @param {loading} loading What the component should display while loading. Can be string, function or JSX
 *  https://www.npmjs.com/package/react-pdf
 *
 */
export const PdfViewer = ({ file, width, height, scale, loading, setLoadedDocument }) => {
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setTimeout(() => {
      setLoadedDocument()
    }, 500)
  };

  return (
    <Document
      file={file}
      onLoadSuccess={onDocumentLoadSuccess}
      loading={loading ? loading : "Loading..."}
    >
      {Array.from(Array(numPages), (el, index) => (
        <Page
          key={`page_${index + 1}`}
          pageNumber={index + 1}
          width={width ? width : null}
          height={height ? height : null}
          scale={scale ? scale : null}
        />
      ))}
    </Document>
  );
};

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
