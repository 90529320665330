import { GlobalReducerActions } from "./global-reducer";
import {
  DashboardMessagesAPI,
  MessageDraftsAPI,
  SiteLocationsAPI,
  UserAPI,
  BulkDeviceMessageAPI,
} from "../api";
import { AppUtils } from "../utils";

export const MESSAGES_REDUCER_TYPES = {
  SET_DASHBOARD_MESSAGES: "SET_DASHBOARD_MESSAGES",
  SET_BULK_DEVICE_MESSAGES: "SET_BULK_DEVICE_MESSAGES",
  SET_DRAFT_MESSAGES: "SET_DRAFT_MESSAGES",
  SET_USER_ROLES: "SET_USER_ROLES",
  SET_USER_CATEGORIES: "SET_USER_CATEGORIES",
  SET_ZONES: "SET_ZONES",
  SET_ZONE_CATEGORIES: "SET_ZONE_CATEGORIES",
  //dialogs
  SET_SEND_MESSAGE_DIALOG_DISPLAY: "SET_SEND_MESSAGE_DIALOG_DISPLAY",
  SET_SEND_MESSAGE_ZONES_ALERTS_DIALOG_DISPLAY:"SET_SEND_MESSAGE_ZONES_ALERTS_DIALOG_DISPLAY"
};

export const MESSAGES_INITIAL_STATE = {
  dashboardMessages: [],
  dashBoardMessageSoftAlert: [],
  bulkDeviceMessages: [],
  draftMessages: [],
  userRoles: [],
  userCategories: [],
  zones: [],
  zoneCategories: [],
  dialogs: {
    showSendMessageDialog: false,
    showSendMessageZonesAlertsDialog:false,
  },
};

export const messagesReducer = (state = MESSAGES_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case MESSAGES_REDUCER_TYPES.SET_DASHBOARD_MESSAGES:
      return {
        ...state,
        dashboardMessages: payload,
      };

    case MESSAGES_REDUCER_TYPES.SET_BULK_DEVICE_MESSAGES:
      const arr = payload.map((deviceMsg) => {
        deviceMsg.displayZone = deviceMsg.zones[0] ? deviceMsg.zones[0].name : "ALL ZONES";

        if (deviceMsg.userRoles && deviceMsg.userRoles.length) {
          deviceMsg.recipientType = `User Role: ${deviceMsg.userRoles[0].role}`;
        } else if (deviceMsg.userCategories && deviceMsg.userCategories.length) {
          deviceMsg.recipientType = `User Category: ${deviceMsg.userCategories[0].name}`;
        } else if (
          deviceMsg.userTypes &&
          deviceMsg.userTypes.length &&
          deviceMsg.userTypes[0] !== "None"
        ) {
          deviceMsg.recipientType = `User Type: ${deviceMsg.userTypes[0]}`;
        }
        else if(deviceMsg?.devices.length){
          deviceMsg.recipientType = `User DeviceID: ${deviceMsg.devices[0].manufacturerId}`;
        }
        else {
          deviceMsg.recipientType = `All Workers`;
        }

        return deviceMsg;
      });
      return {
        ...state,
        bulkDeviceMessages: arr,
      };

    case MESSAGES_REDUCER_TYPES.SET_DRAFT_MESSAGES:
      return {
        ...state,
        draftMessages: payload,
      };

    case MESSAGES_REDUCER_TYPES.SET_USER_ROLES:
      return {
        ...state,
        userRoles: payload,
      };

    case MESSAGES_REDUCER_TYPES.SET_USER_CATEGORIES:
      return {
        ...state,
        userCategories: payload,
      };

    case MESSAGES_REDUCER_TYPES.SET_ZONES:
      return {
        ...state,
        zones: payload,
      };

    case MESSAGES_REDUCER_TYPES.SET_SEND_MESSAGE_DIALOG_DISPLAY:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          showSendMessageDialog: payload,
        },
      };

      case MESSAGES_REDUCER_TYPES.SET_SEND_MESSAGE_ZONES_ALERTS_DIALOG_DISPLAY:
        return {
          ...state,
          dialogs: {
            ...state.dialogs,
            showSendMessageZonesAlertsDialog: payload,
          },
        };

    case MESSAGES_REDUCER_TYPES.SET_ZONE_CATEGORIES:
      return {
        ...state,
        zoneCategories: payload.filter(AppUtils.isZoneCategoryActive),
      };

    default:
      return state;
  }
};

const getAllDashboardMessages = (showPageLoader = true) => {
  return async (dispatch, getState) => {
    try {
      showPageLoader && dispatch(GlobalReducerActions.showPageLoader());
      const siteId = getState().globalState.siteID;
      const response = await DashboardMessagesAPI.getAllDashboardMessegesBySiteId(siteId);
      dispatch({ type: MESSAGES_REDUCER_TYPES.SET_DASHBOARD_MESSAGES, payload: response });
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const getAllBulkDeviceMessages = (showPageLoader = true) => {
  return async (dispatch, getState) => {
    try {
      showPageLoader && dispatch(GlobalReducerActions.showPageLoader());
      const siteId = getState().globalState.siteID;
      const response = await BulkDeviceMessageAPI.getAllBulkDeviceMessegesBySiteId(siteId);
      dispatch({ type: MESSAGES_REDUCER_TYPES.SET_BULK_DEVICE_MESSAGES, payload: response });
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const getAllMessageDrafts = (showPageLoader = true) => {
  return async (dispatch, getState) => {
    try {
      showPageLoader && dispatch(GlobalReducerActions.showPageLoader());
      const siteId = getState().globalState.siteID;
      const response = await MessageDraftsAPI.getAllMessageDraftsBySiteId(siteId);
      dispatch({ type: MESSAGES_REDUCER_TYPES.SET_DRAFT_MESSAGES, payload: response });
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const createDraftMessage = (message, showPageLoader = true) => {
  return async (dispatch, getState) => {
    try {
      showPageLoader && dispatch(GlobalReducerActions.showPageLoader());
      const siteId = getState().globalState.siteID;
      await MessageDraftsAPI.createMessageDraft(siteId, message);
      dispatch(MessagesReducerActions.getAllMessageDrafts());
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const getAllUserRoles = (showPageLoader = true) => {
  return async (dispatch, getState) => {
    try {
      showPageLoader && dispatch(GlobalReducerActions.showPageLoader());
      const siteLocationId = getState().globalState.siteID;
      const response = await SiteLocationsAPI.getAllUserRolesBySiteLocation(siteLocationId);
      dispatch({ type: MESSAGES_REDUCER_TYPES.SET_USER_ROLES, payload: response });
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const getAllUserCategories = (showPageLoader = true) => {
  return async (dispatch, getState) => {
    try {
      showPageLoader && dispatch(GlobalReducerActions.showPageLoader());
      const siteID = getState().globalState.siteID;
      const response = await UserAPI.getUserCategoriesSummary(siteID);
      dispatch({ type: MESSAGES_REDUCER_TYPES.SET_USER_CATEGORIES, payload: response });
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const sendBulkDeviceMessages = (
  { message, userTypes, userRoleIds, userCategoryIds, zoneIds, zoneCategoryIds, deivceIds, draftId },
  showPageLoader = false
) => {
  return async (dispatch, getState) => {
    try {
      showPageLoader && dispatch(GlobalReducerActions.showPageLoader());
      const siteLocationId = getState().globalState.siteID;
      await BulkDeviceMessageAPI.createBulkDeviceMessage({
        siteLocationId: siteLocationId,
        userTypes,
        userRoleIds,
        userCategoryIds,
        zoneIds,
        message,
        zoneCategoryIds,
        deivceIds,
        draftId,
      });
      dispatch(MessagesReducerActions.getAllBulkDeviceMessages(false));
      dispatch({ type: MESSAGES_REDUCER_TYPES.SET_SEND_MESSAGE_DIALOG_DISPLAY, payload: false });
      dispatch({ type: MESSAGES_REDUCER_TYPES.SET_SEND_MESSAGE_ZONES_ALERTS_DIALOG_DISPLAY, payload: false });
      dispatch(MessagesReducerActions.getAllMessageDrafts(false));
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const getAllZones = (showPageLoader = true) => {
  return async (dispatch, getState) => {
    try {
      showPageLoader && dispatch(GlobalReducerActions.showPageLoader());
      const siteID = getState().globalState.siteID;
      const response = await SiteLocationsAPI.getAllZonesBySitelocation(siteID);
      dispatch({ type: MESSAGES_REDUCER_TYPES.SET_ZONES, payload: response });
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const getAllZoneCategories = (showPageLoader = true) => {
  return async (dispatch, getState) => {
    try {
      showPageLoader && dispatch(GlobalReducerActions.showPageLoader());
      const siteID = getState().globalState.siteID;
      const response = await SiteLocationsAPI.getAllZoneCategoriesBySiteLocation(siteID);
      dispatch({ type: MESSAGES_REDUCER_TYPES.SET_ZONE_CATEGORIES, payload: response });
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const init = () => {
  return async (dispatch) => {
    try {
      dispatch(MessagesReducerActions.getAllMessageDrafts(false));
      dispatch(MessagesReducerActions.getAllUserRoles(false));
      dispatch(MessagesReducerActions.getAllUserCategories(false));
      dispatch(MessagesReducerActions.getAllZones(false));
      dispatch(MessagesReducerActions.getAllZoneCategories(false));
      dispatch(GlobalReducerActions.getAllAlerts(false));
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

// ACTIONS //
export const MessagesReducerActions = {
  getAllDashboardMessages,
  getAllBulkDeviceMessages,
  getAllMessageDrafts,
  createDraftMessage,
  sendBulkDeviceMessages,
  getAllUserRoles,
  getAllUserCategories,
  getAllZones,
  getAllZoneCategories,
  init,
};
