import React, { useState, useEffect } from "react";
import { makeStyles, Dialog, DialogTitle, FormControl, Button, Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { UserTypeDropdown, useDialogStyles } from "../../../../../commons";
import { MessagesReducerActions } from "../../../../../reducers";
import {
  UserRolesDropdown,
  UserCategoryDropdown,
  DraftMessegesDropdown,
  ZonesDropdown,
  ZoneCategoryDropdown,
  MessageRecepientTypeDropdown,
} from "../../dropdowns";

import { DraftMessageTextfield } from "./draft-message-textfield";
import { OnlineDevicesSearch } from "../../../../zones/components/map-filter/online-devices-search/online-devices-search";


const useStyles = makeStyles(() => ({
  formControl: {
    width: "100%",
    marginBottom: "32px",
  },
  formControlActions: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
}));

function getHelperText(message) {
  return `(${message ? message.length : 0}/120)`;
}

function renderDraftMessagesCmp(props) {
  const {
    showMessageCMP,
    classes,
    selectedDraftMessage,
    dialogState,
    draftMessages,
    setSelectedDraftMessage,
  } = props;

  if (!showMessageCMP) {
    return (
      <FormControl variant="filled" className={classes.formControl}>
        <DraftMessegesDropdown
          value={selectedDraftMessage}
          disabled={dialogState.msgTypeDropdownDisabled}
          options={draftMessages}
          onChange={(event) => {
            setSelectedDraftMessage(event.target.value);
          }}
        />
      </FormControl>
    );
  } else {
    return null;
  }
}

export const SendMessageDialog = ({ show, onCancel }) => {
  const classes = useStyles();
  const dialogStyles = useDialogStyles();
  const dispatch = useDispatch();
  const [message, setMessage] = useState([""]);

  const [dialogState, setDialogState] = useState({
    msgTypeDropdownDisabled: true,
    userTypes: [],
    userRoleIds: [],
    userCategoryIds: [],
    zoneIds: [],
    zoneCategoryIds: [],
    deivceIds: [],
    sent: false,
    draftMessageAlreadySaved: false,
  });

  const [selectedRecepientType, setSelectedRecepientType] = useState("");
  const [showMessageCMP, setShowMessageCMP] = useState(false);
  const [selectedDraftMessage, setSelectedDraftMessage] = useState(null);

  const userRoles = useSelector((state) => state.messagesScreen.userRoles);
  const userCategories = useSelector((state) => state.messagesScreen.userCategories);
  const draftMessages = useSelector((state) => state.messagesScreen.draftMessages);
  const zones = useSelector((state) => state.messagesScreen.zones);
  const zoneCategories = useSelector((state) => state.messagesScreen.zoneCategories);
  const siteID = useSelector((state) => state.globalState.siteID);

  function handleCancelBttnClick() {
    onCancel();
  }

  function onSendMessageBttnClick() {
    setDialogState({ ...dialogState, sent: true });
    dispatch(
      MessagesReducerActions.sendBulkDeviceMessages({
        message: message,
        userTypes: dialogState.userTypes,
        userRoleIds: dialogState.userRoleIds,
        userCategoryIds: dialogState.userCategoryIds,
        zoneIds: dialogState.zoneIds,
        zoneCategoryIds: dialogState.zoneCategoryIds,
        deivceIds:dialogState.deivceIds,
        draftId: selectedDraftMessage?.id,
      })
    ).then(() => setDialogState({ ...dialogState, sent: false }));
  }

  function renderUserComp() {
    if (selectedRecepientType === "USER_TYPE") {
      return (
        <UserTypeDropdown
          onChange={(event) => {
            setDialogState({
              ...dialogState,
              msgTypeDropdownDisabled: false,
              userRoleIds: [],
              userTypes: [event.target.value],
              userCategoryIds: [],
              zoneIds: [],
              zoneCategoryIds: [],
              deivceIds: []
            });
            setSelectedDraftMessage(null);
          }}
        />
      );
    } else if (selectedRecepientType === "USER_ROLE") {
      return (
        <UserRolesDropdown
          options={userRoles}
          onChange={(event) => {
            setDialogState({
              ...dialogState,
              msgTypeDropdownDisabled: false,
              userRoleIds: [event.target.value.id],
              userCategoryIds: [],
              zoneIds: [],
              zoneCategoryIds: [],
              deivceIds: []
            });
            setSelectedDraftMessage(null);
          }}
        />
      );
    } else if (selectedRecepientType === "USER_CATEGORY") {
      return (
        <UserCategoryDropdown
          options={userCategories}
          onChange={(event) => {
            setDialogState({
              ...dialogState,
              msgTypeDropdownDisabled: false,
              userRoleIds: [],
              userCategoryIds: [event.target.value.id],
              zoneIds: [],
              zoneCategoryIds: [],
              deivceIds: []
            });

            setSelectedDraftMessage(null);
          }}
        />
      );
    } else if (selectedRecepientType === "USER_ZONE") {
      return (
        <ZonesDropdown
          options={zones}
          onChange={(event) => {
            setDialogState({
              ...dialogState,
              msgTypeDropdownDisabled: false,
              userRoleIds: [],
              userCategoryIds: [],
              zoneIds: [event.target.value.id],
              zoneCategoryIds: [],
              deivceIds:[]
            });

            setSelectedDraftMessage(null);
          }}
        />
      );
    } else if (selectedRecepientType === "ZONE_CATEGORIES") {
      return (
        <ZoneCategoryDropdown
          options={zoneCategories}
          onChange={(event) => {
            setDialogState({
              ...dialogState,
              msgTypeDropdownDisabled: false,
              userRoleIds: [],
              userCategoryIds: [],
              zoneIds: [],
              zoneCategoryIds: [event.target.value.id],
              deivceIds:[]
            });

            setSelectedDraftMessage(null);
          }}
        />
      );
    } else if (selectedRecepientType === "BY_USER_DEVICE_ID") {
      return (
        <OnlineDevicesSearch
        siteID={siteID}
        value={dialogState.deivceIds}
        onAutoCompleteChange={(value) => {
          setDialogState({
            ...dialogState,
            msgTypeDropdownDisabled: false,
            userRoleIds: [],
            userCategoryIds: [],
            zoneIds: [],
            zoneCategoryIds: [],
            deivceIds: [value.id]
          });
          setSelectedDraftMessage(null);
        }}
      />
      );
    } else {
      return null;
    }
  }

  useEffect(() => {
    if (selectedRecepientType === "ALL_USERS") {
      setDialogState({
        msgTypeDropdownDisabled: false,
        userRoleIds: [],
        userCategoryIds: [],
        zoneIds: [],
        zoneCategoryIds: [],
      });
      setMessage("");
    }
  }, [selectedRecepientType]);

  useEffect(() => {
    setDialogState({
      msgTypeDropdownDisabled: true,
      userTypes: [],
      userRoleIds: [],
      userCategoryIds: [],
      zoneIds: [],
    });
    setMessage("");
    setSelectedRecepientType("");
    setSelectedDraftMessage(null);
  }, [show]);

  useEffect(() => {
    if (selectedDraftMessage === "NEW_MESSAGE") {
      setShowMessageCMP(true);
      setMessage("");
    } else {
      setMessage(selectedDraftMessage?.message);
      setShowMessageCMP(false);
    }
  }, [selectedDraftMessage]);

  return (
    <Dialog
      open={show}
      onClose={onCancel}
      PaperProps={{ className: dialogStyles.dialogPaper }}
      data-testid="send-message-dialog"
    >
      <Box className={dialogStyles.dialogContentWrapper}>
        <DialogTitle
          className={dialogStyles.dialogTitle}
          disableTypography
          id="responsive-dialog-title"
          onClose={onCancel}
        >
          <span>Send Message</span>
        </DialogTitle>
        <form className={classes.form} autoComplete="off">
          <FormControl variant="filled" className={classes.formControl}>
            <MessageRecepientTypeDropdown
              value={selectedRecepientType}
              onChange={(event) => {
                setSelectedRecepientType(event.target.value.key);
                setMessage("");
                setDialogState({
                  ...dialogState,
                  msgTypeDropdownDisabled: true,
                });
                setSelectedDraftMessage(null);
              }}
            />
          </FormControl>

          {selectedRecepientType ? (
            <FormControl variant="filled" className={classes.formControl}>
              {renderUserComp()}
            </FormControl>
          ) : null}

          {renderDraftMessagesCmp({
            showMessageCMP,
            classes,
            selectedDraftMessage,
            dialogState,
            draftMessages,
            setSelectedDraftMessage,
            setDialogState,
          })}

          <DraftMessageTextfield
            show={showMessageCMP}
            classes={classes}
            message={message}
            helperText={getHelperText(message)}
            onChangeCallback={(event) => {
              setMessage(event.target.value.slice(0, 120));
              setDialogState({ ...dialogState, draftMessageAlreadySaved: false });
            }}
          />

          <FormControl variant="filled" className={classes.formControlActions}>
            <Button
              color="primary"
              variant="outlined"
              onClick={handleCancelBttnClick}
              className={dialogStyles.dialogActionBttn}
              disabled={dialogState.sent}
            >
              Cancel
            </Button>

            <Button
              disabled={
                message?.length === 0 ||
                dialogState.sent ||
                dialogState.draftMessageAlreadySaved ||
                selectedDraftMessage?.id
              }
              variant="outlined"
              color="primary"
              style={{ marginLeft: "10px" }}
              className={dialogStyles.dialogActionBttn}
              onClick={() => {
                dispatch(MessagesReducerActions.createDraftMessage(message)).then(() => {
                  setDialogState({ ...dialogState, draftMessageAlreadySaved: true });
                });
              }}
            >
              Save Draft
            </Button>

            <Button
              disabled={!message || dialogState.sent}
              variant="contained"
              color="primary"
              style={{ marginLeft: "10px" }}
              onClick={onSendMessageBttnClick}
              className={dialogStyles.dialogActionBttn}
            >
              Send
            </Button>
          </FormControl>
        </form>
      </Box>
    </Dialog>
  );
};
