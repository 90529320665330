import React from "react";
import { Chip, TextField } from "@material-ui/core";
import { useChipStyles } from "../useChipStyles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ICONS } from "../../../../../commons";

export const UserTypeAutoselectWithChips = (props) => {
  const chipStyles = useChipStyles();
  const { onChange, value, options, onChipDelete, showCount, userDevices } = props;

  function handleChipDelete(data) {
    const arr = [...value];
    const i = arr.indexOf(data);
    arr.splice(i, 1);
    onChipDelete(arr);
  }

  function renderChips() {
    return value.map((userType, index) => {
      let label = "";
      if (showCount) {
        let count = 0;
        userDevices.forEach((device) => {
          const { currentAssignedUser } = device;
          if (currentAssignedUser?.userType === userType) {
            count += 1;
          }
        });
        label = `${userType} (${count})`;
      } else {
        label = userType;
      }

      return (
        <Chip
          className={chipStyles.chip}
          key={index}
          label={label}
          onDelete={() => {
            handleChipDelete(userType);
          }}
          onClick={() => handleChipDelete(userType)}
        />
      );
    });
  }

  return (
    <>
      <Autocomplete
        multiple
        filterSelectedOptions
        id="zone-categories-autocomplete"
        value={value}
        options={options}
        getOptionLabel={(option) => option}
        onChange={onChange}
        renderInput={(params) => (
          <TextField {...params} label={"User Type"} margin="dense" variant="filled" />
        )}
        renderTags={() => null}
        popupIcon={<ICONS.AddCircle />}
      />
      <div className={chipStyles.chipsWrapper}>{renderChips()}</div>
    </>
  );
};
