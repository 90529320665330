import React from "react";
import { Box, Dialog, DialogTitle } from "@material-ui/core";
import { ICONS, useDialogStyles, DialogActionBttnBig } from "../../../../commons";

export const ConfirmReactivateDialog = ({ title, show, onCancel, onConfirm }) => {
  const classes = useDialogStyles();

  if (!show) {
    return null;
  }

  return (
    <Dialog open={show} onClose={onCancel} PaperProps={{ className: classes.dialogPaper }}>
      <Box className={classes.dialogContentWrapper}>
        <DialogTitle
          className={classes.dialogTitleCentered}
          disableTypography
          id="responsive-dialog-title"
        >
          <span>{title}</span>
        </DialogTitle>
        <Box className={classes.actionsBttnWrapper}>
          <DialogActionBttnBig label="yes" BttnIcon={ICONS.AddCircle} onClick={onConfirm} />
          <DialogActionBttnBig label="cancel" onClick={onCancel} />
        </Box>
      </Box>
    </Dialog>
  );
};
