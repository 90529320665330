import React from "react";
import { Select, MenuItem, InputLabel, makeStyles } from "@material-ui/core";

const styles = makeStyles((theme) => ({
  select: {
    color: theme.palette.primary.main,
    "&:before": {
      borderBottom: "none !important",
    },
  },
}));

export const UserRolesDropdown = (props) => {
  const classes = styles();
  const { options, onChange } = props;
  return (
    <>
      <InputLabel id="user-role-dropdown-label">User Role</InputLabel>
      <Select
        required
        className={classes.select}
        labelId="user-role-dropdown-label"
        id="user-role-dropdown"
        data-testid="user-role-dropdown"
        onChange={onChange}
      >
        <MenuItem disabled value="">
          Select User Role
        </MenuItem>

        {options.map((userRole) => {
          return (
            <MenuItem key={userRole.id} value={userRole}>
              {userRole.role}
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};
