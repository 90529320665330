import React from "react";
import { makeStyles, Box, ButtonBase } from "@material-ui/core";
import { FONTS } from "../../utils";
import { ICONS } from "../../commons"

const useStyles = makeStyles((theme) => ({
  dialogActionButton: {
    width: "188px",
    height: "88px",
    display: "flex",
    flexDirection: "column",
    border: "",
    color: "",
    background: theme.palette.base.elevation_overlay_1,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  dialogActionButtonLabel: {
    ...FONTS.H6_Bold_18,
  },
}));

export const DialogActionBttnBig = ({ label, BttnIcon, onClick, clearAlert = false }) => {
  const classes = useStyles("");

  function setRemoveButtonStyle() {
    const removeStyle = {
      color: "",
      border: ""
    };

    if (BttnIcon) {
      if (clearAlert) {
        removeStyle.border = "1px solid #339D69";
        removeStyle.color = "#339D69";
      } else if (BttnIcon === ICONS.RemoveCircleOutline) {
        removeStyle.border = "1px solid #EB8705";
        removeStyle.color = "#EB8705";
      } else if (BttnIcon === ICONS.AddCircle) {
        removeStyle.border = "1px solid #FDE26A";
        removeStyle.color = "#FDE26A";
      }
    }
    return removeStyle;
  }

  return (
    <ButtonBase style={setRemoveButtonStyle()} className={classes.dialogActionButton} onClick={onClick}>
      {BttnIcon ? (
        <Box mb={2}>
          <BttnIcon />
        </Box>
      ) : null}

      <Box>
        <span className={classes.dialogActionButtonLabel}>{label}</span>
      </Box>
    </ButtonBase>
  );
};
