const GRAPHIC_LABEL_ACYION_BTTN_ID = "graphic-label-action-bttn";
const DISPLAY = "none";
const DIV_ELEMENT = "div";

const getSketchHelperWidget = (_id = "sketch-tool-helper-text") => {
  let sketchHelperDiv = document.getElementById(_id);

  if (!sketchHelperDiv) {
    sketchHelperDiv = document.createElement(DIV_ELEMENT);
  }

  sketchHelperDiv.innerHTML = `<span class="sketch-tool-helper-label"></span>`;
  sketchHelperDiv.id = _id;
  sketchHelperDiv.className = _id;
  sketchHelperDiv.style.display = DISPLAY;
  return sketchHelperDiv;
};

const getGraphicLabelActionBttnToMapView = (callback, _id = GRAPHIC_LABEL_ACYION_BTTN_ID) => {
  const graphicLabel = document.createElement(DIV_ELEMENT);
  const grapahicLabelClass = "graphic-label-action";
  graphicLabel.innerHTML = `<span class="${grapahicLabelClass}-text"></span>
  <span class="${grapahicLabelClass}"><i class="fa fa-times map-cross-icon" aria-hidden="true"></i></span>`;
  graphicLabel.id = _id;
  graphicLabel.className = _id;
  graphicLabel.style.top = "15%";
  graphicLabel.style.left = "50%";
  graphicLabel.style.display = DISPLAY;
  graphicLabel.querySelector(`.${grapahicLabelClass}`).onclick = callback;
  return graphicLabel;
};

const updateGraphicLabelActionBttn = (graphic, show) => {
  const zoneDoneBttn = document.getElementById(GRAPHIC_LABEL_ACYION_BTTN_ID);
  if (zoneDoneBttn) {
    if (show) {
      zoneDoneBttn.style.display = "flex";
      const colour = graphic.attributes.displayColour;
      let zoneColour;

      if (colour) {
        zoneColour = [colour["red"], colour["green"], colour["blue"], 0.3];
      } else {
        zoneColour = [255, 0, 0];
      }
      zoneDoneBttn.style.backgroundColor = `rgba(${zoneColour[0]}, ${zoneColour[1]}, ${zoneColour[2]})`;
      zoneDoneBttn.firstChild.innerText = graphic.attributes.name;
    } else {
      zoneDoneBttn.style.display = DISPLAY;
      zoneDoneBttn.style.backgroundColor = "";
    }
  }
};

export const MapWidgetTmpl = {
  getSketchHelperWidget,
  getGraphicLabelActionBttnToMapView,
  updateGraphicLabelActionBttn,
};
