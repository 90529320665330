import React, { useState, useEffect, useContext, useCallback } from "react";
import { TableCell, TableRow, Select, MenuItem, Button, Box, TextField } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { ZONE_REDUCER_TYPES, ZonesReducerActions } from "../../../../../reducers";
import {
  RowActionBttn,
  useCustomTableStyles,
  Datepicker,
  UserPermissionContext,
  ICONS,
} from "../../../../../commons";
import { CategoryPallete } from "../../../../../commons/category-chip/category-pallete";
import { parseUtcToLocal } from "../../../../../utils";

const zoneCategoriesStatus = [
  {
    name: "Permament",
    isAdHoc: false,
  },
  { name: "temporary", isAdHoc: true },
];

export const Row = (props) => {
  const tableStyles = useCustomTableStyles();
  const dispatch = useDispatch();
  const { rowData, headCells, isItemSelected } = props;
  const { Permissions } = useContext(UserPermissionContext);

  const [selectedCategory, setSelectedCategory] = useState({
    name: rowData.name,
    id: rowData.id,
    startTime: rowData.startTime,
    endTime: rowData.endTime,
    isAdHoc: rowData.isAdHoc,
  });

  const [isEditRowActive, setisEditRowActive] = useState(false);
  const [saveBttnDisabled, setSaveBttnDisabled] = useState(true);
  const [disableActionBttn] = useState(rowData.notEditable);

  const handleTableRowClicked = (event) => {
    event.stopPropagation();
  };

  const handleEndDateTimeChange = (date) => {
    setSelectedCategory({
      ...selectedCategory,
      endTime: date,
    });
    setSaveBttnDisabled(false);
  };

  function onCategorySelectChange(event) {
    if (event.target.value === false) {
      setSelectedCategory({
        ...selectedCategory,
        endTime: null,
        startTime: null,
        isAdHoc: event.target.value,
      });
    } else {
      setSelectedCategory({ ...selectedCategory, isAdHoc: event.target.value });
    }

    setSaveBttnDisabled(false);
  }

  const resetRowEditData = useCallback(() => {
    setSelectedCategory({
      name: rowData.name,
      id: rowData.id,
      startTime: rowData.startTime,
      endTime: rowData.endTime,
      isAdHoc: rowData.isAdHoc,
    });
  }, [rowData]);

  useEffect(() => {
    resetRowEditData();
    setSaveBttnDisabled(true);
  }, [isEditRowActive, resetRowEditData]);

  const isAdHocCategory = (selectedCategory) => {
    return selectedCategory && selectedCategory.isAdHoc ? (
      <Datepicker
        handleDateChange={handleEndDateTimeChange}
        selectedDate={selectedCategory.endTime}
        label="Select End Date"
      />
    ) : (
      selectedCategory.endTime
    );
  };

  const isEditContent = (isEditRowActive) => {
    return isEditRowActive ? (
      <>
        <Button
          className={tableStyles.rowActionBttn}
          disabled={saveBttnDisabled}
          variant="contained"
          color="primary"
          data-id={rowData.id}
          size="small"
          onClick={(event) => {
            event.stopPropagation();
            setisEditRowActive(false);
            dispatch(ZonesReducerActions.updateZoneCategory(selectedCategory));
          }}
        >
          SAVE
        </Button>

        <RowActionBttn
          disabled={disableActionBttn}
          title={"Cancel"}
          onClick={(event) => {
            event.stopPropagation();
            setisEditRowActive(false);
          }}
          Icon={ICONS.Cancel}
        />
      </>
    ) : (
      <>
        <RowActionBttn
          color="primary"
          disabled={disableActionBttn}
          title={"Edit"}
          onClick={(event) => {
            event.stopPropagation();
            setisEditRowActive(true);
          }}
          Icon={ICONS.Edit}
        />

        <RowActionBttn
          disabled={disableActionBttn}
          color="primary"
          title={"Remove"}
          onClick={(event) => {
            event.stopPropagation();
            dispatch({
              type: ZONE_REDUCER_TYPES.SET_REMOVE_ZONE_CATEGORY_DIALOG,
              payload: { id: rowData.id, show: true },
            });
          }}
          Icon={ICONS.Delete}
        />
      </>
    );
  };
  return (
    <TableRow
      hover
      onClick={(event) => handleTableRowClicked(event)}
      key={rowData.id}
      selected={isItemSelected}
    >
      {isEditRowActive ? (
        <>
          <TableCell className={tableStyles.cell}>
            <Box display="flex" alignItems="center">
              <Box pl={1.5} pr={3}>
                <CategoryPallete data={rowData} />
              </Box>
              <TextField
                id="filled-basic"
                variant="filled"
                value={selectedCategory.name}
                onChange={(event) => {
                  setSelectedCategory({ ...selectedCategory, name: event.target.value });
                  setSaveBttnDisabled(false);
                }}
              />
            </Box>
          </TableCell>
          <TableCell className={tableStyles.cell}>
            <Select
              required
              labelId="simple-select-filled-label"
              id="simple-select-filled"
              onChange={onCategorySelectChange}
              value={selectedCategory.isAdHoc}
            >
              {zoneCategoriesStatus.map((catStatus, index) => (
                <MenuItem key={index} value={catStatus.isAdHoc}>
                  {catStatus.name}
                </MenuItem>
              ))}
            </Select>
          </TableCell>

          <TableCell className={tableStyles.cell}>
            <span>{parseUtcToLocal(rowData.createdTimestamp)}</span>
          </TableCell>

          <TableCell className={tableStyles.cell}>{isAdHocCategory(selectedCategory)}</TableCell>

          <TableCell className={tableStyles.cell}>{rowData.activeZones}</TableCell>
        </>
      ) : (
        headCells.map((cell, index) => {
          if (index === 0) {
            return (
              <TableCell className={tableStyles.cell} key={`${cell.id}-${index}`}>
                <Box display="flex" alignItems="center">
                  <Box pl={1.5} pr={3}>
                    <CategoryPallete data={rowData} />
                  </Box>
                  <span>{cell.content(rowData)}</span>
                </Box>
              </TableCell>
            );
          }
          return (
            <TableCell key={`${cell.id}-${index}`} className={tableStyles.cell}>
              <span>{cell.content(rowData)}</span>
            </TableCell>
          );
        })
      )}

      <TableCell className={tableStyles.cell}>
        {Permissions.Manage_Zones ? (
          <Box className={tableStyles.rowActionBttnsWrapper}>{isEditContent(isEditRowActive)}</Box>
        ) : null}
      </TableCell>
    </TableRow>
  );
};
