import React from "react";
import { Dialog, DialogTitle, Button, Box, Grid } from "@material-ui/core";
import { useDialogStyles } from "../../../../../commons/dialogs/use-dialog-styles";
import { useDispatch } from "react-redux";
import { SITES_REDUCER_TYPES, SitesReducerActions } from "../../../../../reducers";
import { useSubSiteDialogStyles } from "../use-subsite-dialog-styles";
import { CustomTextInput, CustomInputLabel } from "../../custom-form-components";

export const EditSubSiteDialog = ({ show, currentSubSite, rootContainer }) => {
  const dispatch = useDispatch();
  const dialogStyles = useDialogStyles();
  const classes = useSubSiteDialogStyles();

  const updateSite = (event) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(SitesReducerActions.updateSiteConfig(currentSubSite));
  };

  const closeDialog = () => {
    dispatch({
      type: SITES_REDUCER_TYPES.SET_SHOW_MAP_INFO_WIDGET,
      payload: false,
    });

    dispatch({
      type: SITES_REDUCER_TYPES.RESET_CURRENT_SUBSITE,
      payload: null,
    });

    dispatch({
      type: SITES_REDUCER_TYPES.NEW_SITE_FORM_VISIBLE,
      payload: false,
    });

    dispatch({
      type: SITES_REDUCER_TYPES.SET_SHOW_ADD_NEW_SITE_BTTN,
      payload: true,
    });

    dispatch(SitesReducerActions.showEditSubSiteDialog(false));
  };

  let isSaveBttnDisabled = true;

  if (
    currentSubSite &&
    currentSubSite.name &&
    currentSubSite.latitude &&
    currentSubSite.longitude &&
    currentSubSite.zoom > 2
  ) {
    isSaveBttnDisabled = false;
  }

  return (
    <Dialog
      open={show}
      PaperProps={{
        className: `${dialogStyles.dialogPaper} ${classes.dialog}`,
        style: {
          width: "100%",
          maxWidth: "fit-content",
        },
      }}
      container={rootContainer}
      data-testid="edit-sub-site-dialog"
    >
      <Box className={dialogStyles.dialogContentWrapper}>
        <DialogTitle className={dialogStyles.dialogTitle} disableTypography onClose={closeDialog}>
          <span>Edit site marker</span>
        </DialogTitle>
        <form autoComplete="off" onSubmit={updateSite}>
          <Grid container>
            <Grid item xs={12}>
              <CustomInputLabel>Name of site area</CustomInputLabel>
              <CustomTextInput
                value={currentSubSite?.name || ""}
                onChange={(event) => {
                  dispatch({
                    type: SITES_REDUCER_TYPES.SET_CURRENT_SUBSITE,
                    payload: {
                      ...currentSubSite,
                      name: event.target.value,
                    },
                  });
                }}
                fullWidth
                data-testid="name-input"
              />
            </Grid>
          </Grid>
          <hr className={classes.hr} />
          <Grid container spacing={4}>
            <Grid item sm={12} className={classes.actionDescription}>
              Please double click on the map to find the location
            </Grid>
            <Grid item sm={12} md={6}>
              <CustomInputLabel>Latitude</CustomInputLabel>
              <CustomTextInput
                onChange={(event) => {
                  dispatch({
                    type: SITES_REDUCER_TYPES.SET_CURRENT_SUBSITE,
                    payload: {
                      ...currentSubSite,
                      latitude: parseFloat(event.target.value, 10),
                    },
                  });
                }}
                type="number"
                fullWidth
                value={currentSubSite?.latitude || ""}
                data-testid="latitude-input"
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <CustomInputLabel>Longitude</CustomInputLabel>
              <CustomTextInput
                onChange={(event) => {
                  dispatch({
                    type: SITES_REDUCER_TYPES.SET_CURRENT_SUBSITE,
                    payload: {
                      ...currentSubSite,
                      longitude: parseFloat(event.target.value, 10),
                    },
                  });
                }}
                type="number"
                fullWidth
                value={currentSubSite?.longitude || ""}
                data-testid="longitude-input"
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <CustomInputLabel>Zoom</CustomInputLabel>
              <CustomTextInput
                onChange={(event) => {
                  dispatch({
                    type: SITES_REDUCER_TYPES.SET_CURRENT_SUBSITE,
                    payload: {
                      ...currentSubSite,
                      zoom: parseInt(event.target.value, 10),
                    },
                  });
                }}
                type="number"
                fullWidth
                value={currentSubSite?.zoom || ""}
                data-testid="zoom-input"
              />
            </Grid>
          </Grid>

          <Grid container className={classes.inputMargin}>
            <Grid item xs={12} className={dialogStyles.actionsBttnWrapper}>
              <Button variant="outlined" onClick={closeDialog} data-testid="cancel-bttn">
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={isSaveBttnDisabled}
                variant="contained"
                color="primary"
                disableElevation={true}
                data-testid="save-bttn"
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Dialog>
  );
};
