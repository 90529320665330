import { useMediaQuery, useTheme } from "@material-ui/core";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useContext } from "react";
import { GlobalReducerActions, ZonesReducerActions, ZONE_REDUCER_TYPES } from "../../../reducers";
import { ZoneMap, GRAPHICS_ACTIONS } from "../components/zone-map";
import { UserPermissionContext } from "../../../commons";
 
export const useZoneScreen = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const mapView = useSelector((state) => state.zonesScreen.mapView);
  const selectedZone = useSelector((state) => state.zonesScreen.selectedZone);
  const zones = useSelector((state) => state.zonesScreen.zones);
  const userDevices = useSelector((state) => state.globalState.mapUserDevices.list);
  const mediaAssets = useSelector((state) => state.zonesScreen.uploadedMediaData);
 
  const showEditZoneFromTplForm = useSelector(
    (state) => state.zonesScreen.forms.showEditZoneFromTplForm
  );
 
  const siteID = useSelector((state) => state.globalState.siteID);
 
  const [map, setMap] = useState("null");
  const showCreateZoneForm = useSelector((state) => state.zonesScreen.dialogs.showCreateZoneForm);
  const alerts = useSelector((state) => state.globalState.alerts);
 
  const selectZoneAndEdit = (zoneData) => {
    dispatch({ type: ZONE_REDUCER_TYPES.SET_MAP_EDIT_SHAPE_IN_PROGRESS, payload: true });
    zoneData && map.editGraphic(zoneData, GRAPHICS_ACTIONS.UPDATE);
  };
 
  const selectZoneAndEditFromTpl = () => {
    map.editGraphic(selectedZone, GRAPHICS_ACTIONS.CREATE_FROM_TEMPLATE);
  };
 
  const { Permissions } = useContext(UserPermissionContext);
 
  function selectZoneAndZoom(zoneData) {
    map.selectZoneAndZoom(zoneData, true);
  }
 
  function onCurrentZonesTableExpandBttnClick(zoneData) {
    if (zoneData) {
      map.selectZoneAndZoom(zoneData, false);
    } else {
      map.unselectZone();
    }
  }
 
  function handleCreateZoneBttnClick() {
    map.setShowSketchTools(true);
  }
 
  function onCreateZoneFromListTpl() {
    map.cancelCreateGraphic();
    dispatch({ type: ZONE_REDUCER_TYPES.SET_SHOW_ZONES_TPL_TABLE, payload: true });
  }
 
  function onFilterShowHideCb(value) {
    map.showHideAllUserDevices(value);
  }
 
  function onLocateSiteCb(site) {
    map.goToSiteLocation(site);
  }
 
  function onActionBttnClick(zoneId) {
    map.selectZoneAndZoom({ id: zoneId });
  }
 
  function showHidePlotPlan(graphicID) {
    map.showHidePlotPlan(graphicID);
  }
 
  function showHideMapImageLayer(graphicID) {
    map.showHideMapImageLayer(graphicID);
  }
 
  function cancelEditTpl() {
    map.cancelCreateGraphic();
    map.setFormOpen(false);
    dispatch({ type: ZONE_REDUCER_TYPES.SET_ZONES_VIEW_PAGE_FILTER, payload: "active" });
  }
 
  function confirmEditTpl() {
    map.setFormOpen(false);
    dispatch({ type: ZONE_REDUCER_TYPES.SET_ZONES_VIEW_PAGE_FILTER, payload: "active" });
  }
  useEffect(() => {
    if(map && map.renderMap && zones) {
      map.renderZones(zones);
    }
  }, [map, zones]);
 
  useEffect(() => {
    if(map && map.renderMap && userDevices) {
      map.renderUserDevices(userDevices);
    }
  }, [map, userDevices]);
 
  useEffect(() => {
    if(map && map.renderMap && mediaAssets.length > 0) {
      map.renderMediaAssets(mediaAssets);
    }  
  }, [map, mediaAssets]);
 
  const onMapLoadCb = (renderMapFn, _zones, _devices, _media) => {
    renderMapFn(_zones, _devices, _media);
  };
 
  useEffect(() => {
    if (siteID) {
      dispatch(ZonesReducerActions.init(siteID)).then(() => {
        setMap(
          new ZoneMap({
            onMapLoadCb: onMapLoadCb,
            viewDevicesEnabled: Permissions.View_Devices,
            viewAlertsEnabled: Permissions.View_Alerts,
          })
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, siteID]);
 
  useEffect(() => {
    dispatch({
      type: ZONE_REDUCER_TYPES.SET_MAP_VIEW,
      payload: isTablet ? "SMALL_MAP" : "LARGE_MAP",
    });
  }, [isTablet, dispatch]);
 
  useEffect(() => {
    if (selectedZone?.id && selectedZone?.name !== "{Not in any zone}") {
      dispatch(GlobalReducerActions.getUserDevicesWithinZoneId(selectedZone.id, false));
    }
  }, [selectedZone, dispatch]);
 
  useEffect(() => {
    const thisIntervalId = setInterval(async () => {
      dispatch(ZonesReducerActions.getAllZones(false));
      dispatch(GlobalReducerActions.getUserDevicesWithAppliedFilters(null, false));
    }, 5 * 60 * 1000);
    return () => {
      dispatch({
        type: ZONE_REDUCER_TYPES.SET_ZONES_VIEW_PAGE_FILTER,
        payload: "active",
      });
      if (thisIntervalId) {
        clearInterval(thisIntervalId);
      }
    };
  }, [dispatch]);
 
  useEffect(() => {
    if (map && map.renderMap) {
      map.goToRecentAlertDevice();
    }
  }, [alerts]);
 
  return {
    mapView,
    showEditZoneFromTplForm,
    showCreateZoneForm,
    selectZoneAndEdit,
    selectZoneAndEditFromTpl,
    selectZoneAndZoom,
    onCurrentZonesTableExpandBttnClick,
    handleCreateZoneBttnClick,
    onCreateZoneFromListTpl,
    onFilterShowHideCb,
    onLocateSiteCb,
    onActionBttnClick,
    showHidePlotPlan,
    cancelEditTpl,
    confirmEditTpl,
    showHideMapImageLayer,
    map,
  };
};