import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  ButtonBase,
  Tooltip,
  Paper,
  Tabs,
  Tab,
  useMediaQuery,
  useTheme,
  makeStyles,
} from "@material-ui/core";
import { FONTS } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { ZONE_REDUCER_TYPES, ZonesReducerActions } from "../../reducers";

import {
  CategoriesTable,
  InactiveZonesTable,
  CurrentZonesTable,
  ZonesTemplateTable,
  ActiveZonesSmallTable,
} from "./components";

import { CategoryNewDialog } from "./dialogs/category-new-dialog";
import { ZoneReactivateDialog } from "./dialogs/zone-reactivate-dialog";
import { FilterPanel, FilterPanelBttn } from "./filter-panel";
import { ToggleViewLink } from "./toggle-view-link";

import {
  BackBttn,
  RemoveDialog,
  TwoActionsDialog,
  UserPermissionContext,
  TabPanel,
  a11yProps,
  ICONS,
  useCommonStyles,
} from "../../commons";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "4px",
    border: `1px solid ${theme.palette.primary.main}`,
  },
  siteLabel: {
    ...FONTS.H5_ShellBold_18,
  },
  fade: {
    opacity: 0.5,
  },
  formControl: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  select: {
    ...FONTS.Label2_Bold_12,
    color: theme.palette.primary.main,
    "&:before": {
      borderBottom: "none !important",
    },
  },
  menuItemRoot: {
    ...FONTS.Label2_Bold_12,
  },
}));

function _getSiteName({ isLargeMapView, site }) {
  return isLargeMapView ? site?.name : `Zone Management |  ${site?.name}`;
}

function _handleTabClick({ event, newValue, dispatch, setTabValue }) {
  event.stopPropagation();
  if (newValue === 0) {
    dispatch({ type: ZONE_REDUCER_TYPES.SET_ZONES_SEARCH_VALUE, payload: "" });
    dispatch({ type: ZONE_REDUCER_TYPES.SET_ZONES_VIEW_PAGE_FILTER, payload: "active" });
  } else if (newValue === 1) {
    dispatch({ type: ZONE_REDUCER_TYPES.SET_ZONES_SEARCH_VALUE, payload: "" });
    dispatch({ type: ZONE_REDUCER_TYPES.SET_ZONES_VIEW_PAGE_FILTER, payload: "inActive" });
  } else if (newValue === 2) {
    dispatch({ type: ZONE_REDUCER_TYPES.SET_ZONES_VIEW_PAGE_FILTER, payload: "active" });
  }
  setTabValue(newValue);
}

function renderContent({
  mapView,
  isLargeMapView,
  showZonesTplTable,
  zones,
  selectZoneAndZoom,
  selectZoneAndEditFromTpl,
  commonStyles,
  showFilterPanel,
  tabValue,
  dispatch,
  setTabValue,
  onCurrentZonesTableExpandBttnClick,
  activeZones,
  zoneCategories,
  inactiveZones,
  selectZoneAndEdit,
  showZoneReactivateDialog,
  editingForm,
  editing,
  classes,
  handleRemoveZoneDialogDisplay,
  showRemoveZoneCategoryDialog,
  showCreateNewCategoryDialog,
  nonUserZone,
  map,
  filterPanel,
  onDeleteZoneCb,
}) {
  if (isLargeMapView) {
    if (showZonesTplTable) {
      return (
        <ZonesTemplateTable
          data={zones}
          isLargeMapView={mapView === "LARGE_MAP"}
          onTableRowSelectedCb={selectZoneAndZoom}
          selectZoneAndEditFromTpl={selectZoneAndEditFromTpl}
        />
      );
    } else {
      return (
        <Paper>
          <ActiveZonesSmallTable
            data={[...zones, nonUserZone]}
            isLargeMapView={mapView === "LARGE_MAP"}
            onTableRowClickCb={selectZoneAndZoom}
            onTableExpandBttnCb={onCurrentZonesTableExpandBttnClick}
            showFilterPanel={showFilterPanel}
            selectZoneAndEdit={selectZoneAndEdit}
            onDeleteZoneCb={onDeleteZoneCb}
          />
        </Paper>
      );
    }
  } else {
    const count = filterPanel.selectedZoneCategories.length + filterPanel.selectedZoneTypes.length;
    return (
      <Paper>
        <Box className={`${commonStyles.tabs_wrapper} ${showFilterPanel ? classes.fade : ""} `}>
          <Tabs
            value={tabValue}
            onChange={(event, newValue) =>
              _handleTabClick({ event, newValue, dispatch, setTabValue })
            }
            aria-label="tabs"
            style={{ display: "flex", justifyContent: "space-between" }}
            classes={{
              root: commonStyles.tabs_root,
              indicator: commonStyles.tabs_indicator,
            }}
          >
            <Tab
              label={`Active Zones (${activeZones.length})`}
              {...a11yProps(0)}
              classes={{
                root: commonStyles.tab_root,
                selected: commonStyles.tab_selected,
              }}
            />
            <Tab
              label={`Inactive Zones (${inactiveZones.length})`}
              {...a11yProps(1)}
              classes={{
                root: commonStyles.tab_root,
                selected: commonStyles.tab_selected,
              }}
              disabled={editing || editingForm}
            />
            <Tab
              label={`Categories (${zoneCategories.length})`}
              {...a11yProps(2)}
              classes={{
                root: commonStyles.tab_root,
                selected: commonStyles.tab_selected,
              }}
              disabled={editing || editingForm}
            />
          </Tabs>
          <FilterPanelBttn show={tabValue === 0 || count > 0} count={count} />
        </Box>

        <TabPanel value={tabValue} index={0}>
          <CurrentZonesTable
            data={[...zones, nonUserZone]}
            isLargeMapView={isLargeMapView}
            onTableExpandBttnCb={onCurrentZonesTableExpandBttnClick}
            onTableRowSelectedCb={selectZoneAndZoom}
            selectZoneAndEdit={selectZoneAndEdit}
            map={map}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <InactiveZonesTable
            isLargeMapView={isLargeMapView}
            data={inactiveZones}
            onRowActionBttnOneClick={selectZoneAndZoom}
            onTableRowSelectedCb={selectZoneAndZoom}
          />
          <ZoneReactivateDialog
            dialogTitle="Reactivate"
            show={showZoneReactivateDialog}
            onCancel={(event) => {
              event.stopPropagation();
              dispatch({
                type: ZONE_REDUCER_TYPES.SET_ZONE_REACTIVATE_DIALOG_DISPLAY,
                payload: false,
              });
            }}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <CategoriesTable data={zoneCategories} isLargeMapView={isLargeMapView}></CategoriesTable>
          <RemoveDialog
            title="Are you sure you want to remove category?"
            show={showRemoveZoneCategoryDialog.show}
            onConfirm={() => {
              dispatch(
                ZonesReducerActions.deleteZoneCategory(showRemoveZoneCategoryDialog.id)
              ).then(() => {
                dispatch({
                  type: ZONE_REDUCER_TYPES.SET_REMOVE_ZONE_CATEGORY_DIALOG,
                  payload: { id: null, show: false },
                });
              });
            }}
            onCancel={(event) => {
              event.stopPropagation();
              dispatch({
                type: ZONE_REDUCER_TYPES.SET_REMOVE_ZONE_CATEGORY_DIALOG,
                payload: { id: null, show: false },
              });
            }}
          />
          <CategoryNewDialog
            show={showCreateNewCategoryDialog}
            onCancel={(event) => {
              event.stopPropagation();
              dispatch({
                type: ZONE_REDUCER_TYPES.SET_CREATE_NEW_CATEGORY_DIALOG_DISPLAY,
                payload: false,
              });
            }}
          />
        </TabPanel>
      </Paper>
    );
  }
}

export const ZoneManagement = (props) => {
  const classes = useStyles();
  const commonStyles = useCommonStyles();
  const dispatch = useDispatch();
  const { Permissions } = useContext(UserPermissionContext);
  const [tabValue, setTabValue] = useState(0);
  const [showZoneOperation, setshowZoneOperation] = useState(true);

  const showAddZoneDialog = useSelector((state) => state.zonesScreen.dialogs.showAddZoneDialog);
  const editing = useSelector((state) => state.zonesScreen.mapEditShapeInProgress);
  const editingForm = useSelector((state) => state.zonesScreen.editingForm);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("sm"));

  const showRemoveZoneDialog = useSelector(
    (state) => state.zonesScreen.dialogs.showRemoveZoneDialog
  );
  const showRemoveZoneCategoryDialog = useSelector(
    (state) => state.zonesScreen.dialogs.showRemoveZoneCategoryDialog
  );
  const showCreateNewCategoryDialog = useSelector(
    (state) => state.zonesScreen.dialogs.showCreateNewCategoryDialog
  );
  const showZoneReactivateDialog = useSelector(
    (state) => state.zonesScreen.dialogs.showZoneReactivateDialog
  );

  const showZonesTplTable = useSelector((state) => state.zonesScreen.showZonesTplTable);
  const site = useSelector((state) => state.globalState.site);
  const zones = useSelector((state) => state.zonesScreen.zones);
  const inactiveZones = useSelector((state) => state.zonesScreen.allZonesData.inactiveZones);
  const activeZones = useSelector((state) => state.zonesScreen.allZonesData.activeZones);
  const showFilterPanel = useSelector((state) => state.zonesScreen.showFilterPanel);
  const siteDevicesSummary = useSelector((state) => state.zonesScreen.siteDevicesSummary);
  const filterPanel = useSelector((state) => state.zonesScreen.filterPanel);
  const selectedZonesOperations = useSelector(
    (state) => state.zonesScreen.selectedZonesToPerformOperation
  );
  const nonUserZone = {
    alerts: [],
    categories: [],
    circleCenter: null,
    colour: { red: 255, green: 255, blue: 255 },
    createdTimestamp: new Date().toISOString(),
    description: null,
    displayColour: { red: 255, green: 255, blue: 255 },
    endTime: null,
    entryMessage: "",
    exitMessage: "",
    headcount: siteDevicesSummary.onlineAssignedNotInZoneCount,
    id: "000000000000000000000000",
    isActive: true,
    isTemporary: true,
    name: "{Not in any zone}",
    polygonPoints: [],
    radius: 0,
    radiusUnit: "Miles",
    saveAsTemplate: false,
    shape: "Polygon",
    siteLocationId: null,
    startTime: null,
    warningSupervisor: null,
    workPermitRequired: false,
    isFiltered: false,
    isFrontEndOnly: true,
  };

  const zoneCategories = useSelector((state) => state.zonesScreen.zoneCategories?.all);
  const selectedZoneId = useSelector(
    (state) => state.zonesScreen.selectedZone && state.zonesScreen.selectedZone.id
  );

  const {
    mapView,
    isLargeMapView,
    onCurrentZonesTableExpandBttnClick,
    handleRemoveZoneDialogDisplay,
    selectZoneAndZoom,
    selectZoneAndEdit,
    selectZoneAndEditFromTpl,
    onDeleteZoneCb,
    map,
  } = props;

  // on Tab Click

  useEffect(() => {
    dispatch({ type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE, payload: null });
    dispatch({ type: ZONE_REDUCER_TYPES.SET_SELECTED_MAP_GRAPHIC, payload: null });
  }, [tabValue, dispatch]);

  useEffect(() => {
    // send user to active zone on click of add zone
    if (showAddZoneDialog) {
      setTabValue(0);
    }
  }, [showAddZoneDialog]);

  useEffect(() => {
    dispatch({ type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONES_TO_PERFORM_OPERATION, payload: [] });
  }, []);

  const showEvacuatebutton = () => {
    const isCheckedItems = (value) => {
      if (value.checked) return value;
    };
    if (selectedZonesOperations?.length && selectedZonesOperations.filter(isCheckedItems).length) {
      setshowZoneOperation(false);
    } else {
    setshowZoneOperation(true);
    }
  };

  useEffect(() => {
    showEvacuatebutton()
  }, [JSON.stringify(selectedZonesOperations)]);



  const siteLabelText = _getSiteName({ isLargeMapView, site });
  return (
    <>
      <BackBttn
        show={showZonesTplTable}
        onClick={() => {
          dispatch({
            type: ZONE_REDUCER_TYPES.SET_SHOW_ZONES_TPL_TABLE,
            payload: false,
          });

          dispatch({
            type: ZONE_REDUCER_TYPES.SET_ZONES_VIEW_PAGE_FILTER,
            payload: "active",
          });
        }}
      />

      {isTablet ? null : (
        <ToggleViewLink {...props} isLargeMapView={isLargeMapView} show={!showZonesTplTable} />
      )}

      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3} mt={2}>
        <span className={classes.siteLabel}>
          {siteLabelText}{" "}
          {siteDevicesSummary?.onlineAssignedCount > 0
            ? `( ${siteDevicesSummary.onlineAssignedCount} )`
            : ""}
        </span>

        {Permissions.Manage_Zones && !showZonesTplTable ? (
          <Box>
            {mapView === "LARGE_MAP" ? (
              <>
                <Button
                  disabled={showZoneOperation}
                  onClick={() => {
                    dispatch(ZonesReducerActions.evacuateMultipleZones());
                  }}
                  size="small"
                  variant="outlined"
                  color="red"
                  style={{ right: "10px" }}
                >
                  Evacuate Zones
                </Button>
                <Tooltip title="Add Zone">
                  <ButtonBase
                    color="primary"
                    className={classes.button}
                    onClick={() => {
                      dispatch({
                        type: ZONE_REDUCER_TYPES.SET_ADD_ZONE_DIALOG_DISPLAY,
                        payload: true,
                      });
                      dispatch({ type: ZONE_REDUCER_TYPES.SET_MAP_VIEW, payload: "LARGE_MAP" });
                    }}
                    disabled={editing || editingForm}
                  >
                    <ICONS.Add color="primary" />
                  </ButtonBase>
                </Tooltip>
              </>
            ) : (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  dispatch({ type: ZONE_REDUCER_TYPES.SET_ADD_ZONE_DIALOG_DISPLAY, payload: true });
                  dispatch({ type: ZONE_REDUCER_TYPES.SET_MAP_VIEW, payload: "LARGE_MAP" });
                }}
                disabled={editing || editingForm}
              >
                ADD ZONE
              </Button>
            )}
          </Box>
        ) : null}
      </Box>

      <div style={{ position: "relative" }}>
        {renderContent({
          mapView,
          isLargeMapView,
          showZonesTplTable,
          zones,
          selectZoneAndZoom,
          selectZoneAndEditFromTpl,
          commonStyles,
          showFilterPanel,
          tabValue,
          dispatch,
          setTabValue,
          onCurrentZonesTableExpandBttnClick,
          activeZones,
          zoneCategories,
          inactiveZones,
          selectZoneAndEdit,
          showZoneReactivateDialog,
          editingForm,
          editing,
          classes,
          handleRemoveZoneDialogDisplay,
          showRemoveZoneCategoryDialog,
          showCreateNewCategoryDialog,
          nonUserZone,
          map,
          filterPanel,
          onDeleteZoneCb,
        })}
        <FilterPanel />
      </div>

      <TwoActionsDialog
        title="Add New Zone To Map"
        show={showAddZoneDialog}
        showDialogCloseBttn={true}
        bttnOne={{
          label: "Draw New Shape",
          Icon: ICONS.Create,
        }}
        bttnTwo={{
          label: "Choose From List",
          Icon: ICONS.FormatListBulleted,
        }}
        onConfirmActionOne={() => {
          dispatch({ type: ZONE_REDUCER_TYPES.SET_MAP_VIEW, payload: "LARGE_MAP" });
          props.onCreateZoneBttnClick();
          dispatch({
            type: ZONE_REDUCER_TYPES.SET_ADD_ZONE_DIALOG_DISPLAY,
            payload: false,
          });
        }}
        onConfirmActionTwo={() => {
          dispatch({ type: ZONE_REDUCER_TYPES.SET_MAP_VIEW, payload: "LARGE_MAP" });
          dispatch({ type: ZONE_REDUCER_TYPES.SET_SHOW_ZONES_TPL_TABLE, payload: true });

          dispatch({
            type: ZONE_REDUCER_TYPES.SET_ZONES_VIEW_PAGE_FILTER,
            payload: "saveAsTemplate",
          });

          dispatch({
            type: ZONE_REDUCER_TYPES.SET_ADD_ZONE_DIALOG_DISPLAY,
            payload: false,
          });
        }}
        onCancel={() => {
          dispatch({
            type: ZONE_REDUCER_TYPES.SET_ADD_ZONE_DIALOG_DISPLAY,
            payload: false,
          });
        }}
      />

      <RemoveDialog
        title="Are you sure you want to remove zone?"
        show={showRemoveZoneDialog}
        onConfirm={() => {
          dispatch(ZonesReducerActions.deleteZone(selectedZoneId, onDeleteZoneCb));
        }}
        onCancel={() =>
          dispatch({
            type: ZONE_REDUCER_TYPES.SET_REMOVE_ZONE_DIALOG,
            payload: false,
          })
        }
      />
    </>
  );
};
