import React, { useState, useEffect, useContext } from "react";
import clsx from "clsx";
import {
  Drawer,
  FormControl,
  Button,
  IconButton,
  makeStyles,
  Tooltip,
  Divider,
  Box,
} from "@material-ui/core";
import { ICONS, UserPermissionContext } from "../../../../commons";
import { FONTS } from "../../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { GlobalReducerActions, GLOBAL_REDUCER_TYPES } from "../../../../reducers";
import { SiteLocationsAPI, UserAPI } from "../../../../api";
import { UserTypeAutoselectWithChips } from "./user-type-autoselect-with-chips";
import { UserCategoryAutoselectWithChips } from "./user-category-autoselect-with-chips";
import { UserRoleAutoselectWithChips } from "./user-role-autoselect-with-chips";
import { ZoneAutoselect } from "./zone-autoselect";
import { OnlineDevicesSearch } from "./online-devices-search";
import { MapUtilsV2 } from "../../../../commons/map/map-utils";

const useStyles = makeStyles((theme) => ({
  mapFilterWrapper: {
    position: "absolute",
    bottom: "190px",
    right: 0,
    background: theme.palette.common.black,
  },
  paper: {
    background: theme.palette.background.default,
  },
  filterCounter: {
    ...FONTS.Label1_Med_12,
    color: theme.palette.primary.main,
  },
  list: {
    width: 250,
    padding: "22px",
  },
  fullList: {
    width: "auto",
  },
  formControl: {
    width: "100%",
  },
  mapFilterTitle: {
    ...FONTS.H6_Bold_18,
  },
  filterTitle: {},
  filter: {
    ...FONTS.Label1_Med_12,
    marginBottom: "20px",
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
  },
  formControlActions: {
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: "32px",
    display: "flex",
  },
  closeBttn: {
    display: "flex",
    justifyContent: "flex-end",
  },
  drawerContainer: {
    width: "0px",
  },
  dividerRoot: {
    height: "2px",
    background: "#FFFFFF",
  },
}));

export const MapFilter = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { onFilterShowHideCb, open, setOpen, filters, map } = props;
  const siteID = useSelector((state) => state.globalState.siteID);
  const site = useSelector((state) => state.globalState.site);
  const userDevices = useSelector((state) => state.globalState.mapUserDevices.list);
  const mapUserCount = useSelector((state) => state.globalState.mapUserCount);
  const { Permissions } = useContext(UserPermissionContext);

  const [mapFilterState, setMapFilterState] = useState({
    selectedUserRoles: [],
    selectedUserType: [],
    selectedUserCategories: [],
    selectedUserDevice: null,
    selectedZone: null,
    filtersApplied: 0,
    outsidezone: false,
  });

  const [rolesOptions, setRolesOptions] = useState([]);
  const [userCategoriesOptions, setUserCategoriesOptions] = useState([]);
  const [userTypeOptions] = useState(["Vehicle", "Pedestrian"]);
  const [zonesOptions, setZonesOptions] = useState([]);
  const [filterApplyBttnDisabled, setFilterApplyBttnDisabled] = useState(true);
  const [zonesHeatMap, setZonesHeatMap] = useState(false);

  useEffect(() => {
    setMapFilterState(filters);
  }, [filters]);

  useEffect(() => {
    if (open && site) {
      fetchUserCategories();
      fetchRoles(site);
      fetchZones(site);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, site]);

  async function fetchRoles(siteData) {
    const response = await SiteLocationsAPI.getAllUserRolesBySiteLocation(siteData.id);
    setRolesOptions(response);
  }

  async function fetchUserCategories() {
    const response = await UserAPI.getUserCategoriesSummary(siteID);
    setUserCategoriesOptions(response);
  }

  async function fetchZones(siteData) {
    const response = await SiteLocationsAPI.getAllZonesBySitelocation(siteData.id);
    setZonesOptions(response.filter((zone) => zone.isActive));
  }

  const toggleDrawer = (open) => {
    setOpen(open);
    onFilterShowHideCb(open);
  };

  function handleFilterApply() {
    dispatch({
      type: GLOBAL_REDUCER_TYPES.SET_MAP_USER_FILTERS,
      payload: mapFilterState,
    });
    dispatch(GlobalReducerActions.getUserDevicesWithAppliedFilters(mapFilterState));
    setFilterApplyBttnDisabled(true);
  }

  function onClearFilters() {
    setFilterApplyBttnDisabled(true);
    dispatch({
      type: GLOBAL_REDUCER_TYPES.SET_MAP_USER_FILTERS,
      payload: {
        selectedUserRoles: [],
        selectedUserType: [],
        selectedUserCategories: [],
        selectedUserDevice: null,
        selectedZone: null,
        filtersApplied: false,
        outsidezone: false,
      },
    });

    dispatch(GlobalReducerActions.getUserDevicesWithAppliedFilters(null));
    onFilterShowHideCb(false);
  }
  function ShowOrHideLayer() {
    ToggleAmbientNoise(map, setZonesHeatMap, zonesHeatMap, sqaureDiv);
  }

  function sqaureDiv(div,color,text)
  {
    var content=document.createElement("div");
    content.style.paddingLeft="5px";
    content.style.paddingRight="5px";
    content.innerHTML=text;
    div.appendChild(content);
    var square=document.createElement("div");
    square.style.height="15px";
    square.style.width="15px";
    square.style.backgroundColor=color;
    div.appendChild(square);
    return div;
  }
  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    >
      <div className={classes.closeBttn}>
        <IconButton
          onClick={() => {
            toggleDrawer(false);
          }}
        >
          <ICONS.Close color="primary" />
        </IconButton>
      </div>

      <div className={classes.mapFilterTitle}>Filter Users on Map</div>

      <div className={classes.filter}>
        <div className={classes.filterTitle}>
          <span>Search for User: </span>
        </div>
        <OnlineDevicesSearch
          siteID={siteID}
          value={mapFilterState.selectedUserDevice}
          onAutoCompleteChange={(value) => {
            setMapFilterState({
              ...mapFilterState,
              selectedUserDevice: value,
            });
            setFilterApplyBttnDisabled(false);
          }}
        />
      </div>

      <Divider
        classes={{
          root: classes.dividerRoot,
        }}
      />

      <div className={classes.filter}>
        <div style={{ height: "59vh", overflowY: "auto" }}>
          {Permissions.View_Users ? (
            <>
              <div className={classes.filterTitle}>
                <span>Filter Users by:</span>
              </div>

              <FormControl variant="filled" className={classes.formControl}>
                <UserCategoryAutoselectWithChips
                  value={mapFilterState.selectedUserCategories}
                  userDevices={userDevices}
                  options={userCategoriesOptions}
                  onChange={(event, newValue) => {
                    setMapFilterState({
                      ...mapFilterState,
                      selectedUserCategories: newValue,
                    });
                    setFilterApplyBttnDisabled(false);
                  }}
                  onChipDelete={(data) => {
                    setMapFilterState({
                      ...mapFilterState,
                      selectedUserCategories: data,
                    });
                    setFilterApplyBttnDisabled(false);
                  }}
                  showCount={mapFilterState.filtersApplied}
                />
              </FormControl>
              <FormControl variant="filled" className={classes.formControl}>
                <UserRoleAutoselectWithChips
                  value={mapFilterState.selectedUserRoles}
                  userDevices={userDevices}
                  options={rolesOptions}
                  onChange={(event, newValue) => {
                    setMapFilterState({
                      ...mapFilterState,
                      selectedUserRoles: newValue,
                    });
                    setFilterApplyBttnDisabled(false);
                  }}
                  onChipDelete={(data) => {
                    setMapFilterState({
                      ...mapFilterState,
                      selectedUserRoles: data,
                    });
                    setFilterApplyBttnDisabled(false);
                  }}
                  showCount={mapFilterState.filtersApplied}
                />
              </FormControl>

              <FormControl variant="filled" className={classes.formControl}>
                <UserTypeAutoselectWithChips
                  value={mapFilterState.selectedUserType}
                  userDevices={userDevices}
                  options={userTypeOptions}
                  onChange={(event, newValue) => {
                    setMapFilterState({
                      ...mapFilterState,
                      selectedUserType: newValue,
                    });
                    setFilterApplyBttnDisabled(false);
                  }}
                  onChipDelete={(data) => {
                    setMapFilterState({
                      ...mapFilterState,
                      selectedUserType: data,
                    });
                    setFilterApplyBttnDisabled(false);
                  }}
                  showCount={mapFilterState.filtersApplied}
                />
              </FormControl>
            </>
          ) : null}

          <FormControl variant="filled" className={classes.formControl}>
            <div className={classes.filterTitle}>
              <span>Search for User: </span>
            </div>

            <ZoneAutoselect
              value={mapFilterState.selectedZone}
              options={zonesOptions}
              onAutoCompleteChange={(event, newVal) => {
                if (!newVal) {
                  setMapFilterState({
                    ...mapFilterState,
                    outsidezone: false,
                    selectedZone: null,
                  });
                } else if (typeof newVal.id === "boolean") {
                  setMapFilterState({
                    ...mapFilterState,
                    selectedZone: newVal,
                    outsidezone: true,
                  });
                } else {
                  setMapFilterState({
                    ...mapFilterState,
                    selectedZone: newVal,
                    outsidezone: false,
                  });
                }
                setFilterApplyBttnDisabled(false);
              }}
            />
          </FormControl>
        </div>

        <Box>
          <Box pt={1}>
            <span style={{ ...FONTS.Label_Reg_10 }}>
              *Users with Active Alerts will always be visible.
            </span>
          </Box>
          <FormControl className={classes.formControlActions}>
            <Button
              color="primary"
              variant="outlined"
              onClick={onClearFilters}
              disabled={!mapFilterState.filtersApplied}
              style={{ ...FONTS.B4_BOLD_14 }}
            >
              Clear All
            </Button>

            <Button
              onClick={handleFilterApply}
              variant="contained"
              color="primary"
              style={{ marginLeft: "10px", ...FONTS.B4_BOLD_14 }}
              disabled={filterApplyBttnDisabled}
            >
              Apply
            </Button>
          </FormControl>
        </Box>
      </div>
    </div>
  );
  return (
    <div className={classes.mapFilterWrapper}>
      <Tooltip title={"Filter users on map"}>
        <IconButton
          onClick={() => {
            toggleDrawer(true);
          }}
        >
          {mapFilterState.filtersApplied ? (
            <ICONS.PersonPinCircle color="primary" />
          ) : (
            <ICONS.PersonPinCircle />
          )}
          {mapFilterState.filtersApplied ? (
            <span className={classes.filterCounter}>({mapUserCount})</span>
          ) : null}
        </IconButton>

      </Tooltip>
      <br />
      <Tooltip title={"Ambient Noise"}>
        <IconButton
          onClick={() => { ShowOrHideLayer(); }}    >
          {zonesHeatMap === true ?
            <ICONS.VibrationIcon color="primary" />
            : <ICONS.VibrationIcon />}
        </IconButton>
      </Tooltip>
      <Drawer
        anchor={"right"}
        open={open}
        classes={{ paper: classes.paper, root: classes.drawerContainer }}
        variant="temporary"
        BackdropProps={{ style: { width: "0px" } }}
      >
        {list("right")}
      </Drawer>
    </div>
  );
};
function ToggleAmbientNoise(map, setZonesHeatMap, zonesHeatMap, sqaureDiv) {
  if (map && map.map.allLayers != null) {
    map.renderUserDevicesHeatMap();
    var layer = MapUtilsV2.getLayerById(map.map, "USER_DEVICES_HEAT_MAP");
    layer.visible = !layer.visible;
    setZonesHeatMap(!zonesHeatMap);
    var div = document.createElement("div");
    var node = document.createTextNode("Ambient noise :");
    div.appendChild(node);
    div.style.display = "flex";
    div = sqaureDiv(div, "red", ">80");
    div = sqaureDiv(div, "yellow", "60-80");
    div = sqaureDiv(div, "green", "<60");
    div.id = "imgAmbientNoise";
    if (layer.visible === true) {
      map.mapView.ui.add(div, "top-right");
    }
    else {
      map.mapView.ui.remove("imgAmbientNoise");
    }
  }
}

