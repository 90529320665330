import React, { useContext } from "react";
import { Box, makeStyles, TableCell, TableRow } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { ZONE_REDUCER_TYPES } from "../../../../../reducers";
import {
  useCustomTableStyles,
  RowActionBttn,
  ColorPallete,
  UserPermissionContext,
  ICONS,
} from "../../../../../commons";

const useStyles = makeStyles((theme) => ({
  actionBttnsWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: theme.palette.base.elevation_3,
    },
  },
  tableRowPointer: {
    cursor: "pointer",
  },
  selected: {},
}));

export const Row = (props) => {
  const classes = useStyles();
  const tableStyles = useCustomTableStyles();
  const { Permissions } = useContext(UserPermissionContext);
  const {
    rowData,
    headCells,
    isItemSelected,
    onRowActionBttnOneClick,
    onTableRowSelectedCb,
  } = props;
  const dispatch = useDispatch();

  const handleTableRowClicked = (event, zoneData) => {
    event.stopPropagation();
    onTableRowSelectedCb && onTableRowSelectedCb(zoneData);
  };

  return (
    <TableRow
      className={`${classes.tableRow} ${onTableRowSelectedCb ? classes.tableRowPointer : ""}`}
      hover
      onClick={(event) => handleTableRowClicked(event, rowData)}
      key={rowData.id}
      selected={isItemSelected}
      classes={{ selected: classes.selected }}
    >
      {headCells.map((cell, index) => {
        if (index === 0) {
          return (
            <TableCell align="left" key={`${rowData.id}-${index}`} className={tableStyles.cell}>
              <Box display="flex" alignItems="center">
                <Box pl={1.5} pr={3}>
                  <ColorPallete data={rowData} />
                </Box>
                <span>{cell.content(rowData)}</span>
              </Box>
            </TableCell>
          );
        }
        return (
          <TableCell key={`${rowData.id}-${index}`} className={tableStyles.cell}>
            <span>{cell.content(rowData)}</span>
          </TableCell>
        );
      })}

      <TableCell key={`${rowData.id}-row-actions-bttns`} className={tableStyles.cell}>
        {Permissions.Manage_Zones ? (
          <Box className={classes.actionBttnsWrapper}>
            <RowActionBttn
              color="primary"
              title={"Reactivate"}
              onClick={(event) => {
                event.stopPropagation();

                dispatch({
                  type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE,
                  payload: rowData,
                });

                dispatch({
                  type: ZONE_REDUCER_TYPES.SET_ZONE_REACTIVATE_DIALOG_DISPLAY,
                  payload: true,
                });

                onRowActionBttnOneClick && onRowActionBttnOneClick(rowData);
              }}
              Icon={ICONS.Restore}
            />
          </Box>
        ) : null}
      </TableCell>
    </TableRow>
  );
};
