import React from "react";
import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";
import { GLOBAL_REDUCER_TYPES } from "../../reducers";
import { ICONS } from "../../commons";
import { CombineAlertsSnackbar } from "./combine-alerts-snackbar";
import { SingleAlertSnackbar } from "./single-alert-snackbar";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: "1",
    top: "5px",
    width: "100%",
    position: "absolute",
    left: "1%",
    display: "flex",
    flexDirection: "column",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  alertRoot: {
    background: "#FFFFFF",
    color: "#DB4052",
    justifyContent: "center",
    alignItems: "center",
    width: "98%",
  },
  icon: {
    cursor: "pointer",
  },
}));

const SNACKBAR_ACTIONS_LABELS = {
  GO_TO_ZONE: "GO TO ZONE",
  GO_TO_ALERTS: "GO TO ALERTS",
};

function getAlertMessageFn(title, zoneName) {
  let str = `There is ${title} `;

  if (zoneName) {
    str += `in the ${zoneName} zone.`;
  } else {
    str += `outside of zones.`;
  }

  return str;
}

function setMuteAlert({ dispatch, alertID }) {
  dispatch({
    type: GLOBAL_REDUCER_TYPES.SET_MUTED_ALERTS_IDS,
    payload: alertID,
  });
}

function getAlertIcon(alert, dispatch) {
  return alert.muted ? (
    <ICONS.VolumeOff
      fontSize="inherit"
      onClick={() => {
        setMuteAlert({ dispatch, alertID: alert.id });
      }}
    />
  ) : (
    <ICONS.VolumeUp
      fontSize="inherit"
      onClick={() => {
        setMuteAlert({ dispatch, alertID: alert.id });
      }}
    />
  );
}

export const AlertSnackbar = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("sm"));
  const { activeAlerts, onActionBttnClick } = props;
  const maxCount = isTablet ? 1 : 3;

  function renderCMP(list) {
    if (list.length > maxCount) {
      return (
        <CombineAlertsSnackbar
          activeAlerts={activeAlerts}
          classes={classes}
          SNACKBAR_ACTIONS_LABELS={SNACKBAR_ACTIONS_LABELS}
        />
      );
    } else {
      return (
        <SingleAlertSnackbar
          activeAlerts={activeAlerts.slice(0, maxCount)}
          classes={classes}
          onActionBttnClick={onActionBttnClick}
          SNACKBAR_ACTIONS_LABELS={SNACKBAR_ACTIONS_LABELS}
          getAlertMessageFn={getAlertMessageFn}
          getAlertIcon={getAlertIcon}
        />
      );
    }
  }

  return <div className={classes.root}>{renderCMP(activeAlerts)}</div>;
};
