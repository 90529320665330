import React from "react";
import { Link } from "@material-ui/core";
import { ICONS, useCommonStyles } from "../../commons";

export const BackBttn = (props) => {
  const commonStyles = useCommonStyles();
  const { show, onClick, label = "BACK" } = props;

  if (show) {
    return (
      <Link color="inherit" component="button" variant="body2" onClick={onClick}>
        <span className={commonStyles.page_breadcrumps}>
          <ICONS.ChevronLeftCustomIcon />
          {label}
        </span>
      </Link>
    );
  }
  return null;
};
