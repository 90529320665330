import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Grid, makeStyles, Paper } from "@material-ui/core";
import { useCommonStyles } from "../../../../commons";
import { EditSiteName, SiteCordsForm } from "./components";
import { SitesReducerActions, SITES_REDUCER_TYPES } from "../../../../reducers";
import SiteCordsMapContainer from "./components/site-cords-map";
import { EditSubSiteDialog, CreateSubSiteDialog } from "../../components";

const useStyles = makeStyles((theme) => ({
  mapGridWrapper: {
    height: "100%",
  },
  paper: {
    minWidth: "684px",
    height: "431px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "25px",
    backgroundColor: theme.palette.base.elevation_1,
  },
  message: {
    fontSize: "18px",
    fontFamily: "ShellBold",
  },
}));

const SiteCoordFormPage = ({ backToSites }) => {
  const commonStyles = useCommonStyles();

  const { siteId } = useParams();
  const dispatch = useDispatch();
  const currentSite = useSelector((state) => state.sitesScreen.currentSite);
  const showDefaultSiteForm = useSelector((state) => state.sitesScreen.showDefaultSiteForm);
  const currentSubSite = useSelector((state) => state.sitesScreen.currentSubSite);
  const fullMapView = useSelector((state) => state.sitesScreen.fullMapView);
  const pageIndex = useSelector((state) => state.sitesScreen.pageIndex);
  const newSiteFormVisible = useSelector((state) => state.sitesScreen.newSiteFormVisible);
  const showMapInfoWidget = useSelector((state) => state.sitesScreen.mapState.showMapInfoWidget);
  const mapSelectedSubSite = useSelector((state) => state.sitesScreen.mapState.selectedSubSite);
  const isPageLoading = useSelector((state) => state.globalState.isPageLoading);
  const showshowEditSudSiteDialog = useSelector(
    (state) => state.sitesScreen.dialogs.showEditSubSite
  );
  const classes = useStyles();

  const rootRef = React.useRef();

  function goToSubSite(subSite) {
    dispatch({ type: SITES_REDUCER_TYPES.SET_CURRENT_SUBSITE, payload: subSite });
  }

  useEffect(() => {
    if (siteId && pageIndex === 0) {
      dispatch(SitesReducerActions.getSiteBySiteId(siteId));
    }
  }, [siteId, dispatch, pageIndex]);

  return (
    <>
      {!currentSite ? (
        <Grid id="j-sitewise-siteCords" className={commonStyles.page_wrapper}>
          {backToSites()}
          <Paper className={classes.paper}>
            <div className={classes.message}>
              {!isPageLoading && !currentSite ? "YOU DO NOT HAVE PERMISSION TO VIEW THIS PAGE" : ""}
            </div>
          </Paper>
        </Grid>
      ) : (
        <>
          <Grid container className={classes.mapGridWrapper}>
            <Grid
              item
              xs={fullMapView ? 5 : 7}
              className={commonStyles.page_wrapper}
              ref={rootRef}
              style={{
                transform: "translateZ(0)",
              }}
            >
              {backToSites()}
              <EditSiteName currentSite={currentSite} />
              <SiteCordsForm
                currentSite={currentSite}
                showDefaultSiteForm={showDefaultSiteForm}
                currentSubSite={currentSubSite}
                newSiteFormVisible={newSiteFormVisible}
                goToSubSite={goToSubSite}
                mapSelectedSubSite={mapSelectedSubSite}
                showMapInfoWidget={showMapInfoWidget}
                editDialogRootContainerRef={rootRef}
              />
            </Grid>
            <Grid item xs={fullMapView ? 7 : 5}>
              <SiteCordsMapContainer
                currentSite={currentSite}
                currentSubSite={currentSubSite}
                showMapInfoWidget={showMapInfoWidget}
                mapSelectedSubSite={mapSelectedSubSite}
              />
            </Grid>
          </Grid>

          <EditSubSiteDialog
            show={showshowEditSudSiteDialog}
            rootContainer={() => rootRef.current}
            currentSubSite={currentSubSite}
          />

          <CreateSubSiteDialog
            show={showDefaultSiteForm || (newSiteFormVisible && !!currentSite.subSites.length)}
            rootContainer={() => rootRef.current}
            currentSubSite={currentSubSite}
            currentSite={currentSite}
            id={currentSite.subSites.length}
            isDefaultSiteForm={showDefaultSiteForm}
          />
        </>
      )}
    </>
  );
};

export default SiteCoordFormPage;
