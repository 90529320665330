import React, { useContext } from "react";
import { TableCell, TableRow, Tooltip, Box, makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";

import { DEVICES_REDUCER_TYPES, GLOBAL_REDUCER_TYPES } from "../../../../../reducers";
import { copyTextToClipBoard } from "../../../../../utils";
import clsx from "clsx";
import {
  useCustomTableStyles,
  RowActionBttn,
  UserPermissionContext,
  ICONS,
} from "../../../../../commons";

const useStyles = makeStyles((theme) => ({
  actionBttnsWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));

function copyText(event, cell, rowData, dispatch) {
  event.stopPropagation();
  if (cell.copyCellTextFn) {
    const content = cell.copyCellTextFn(rowData);
    if (content) {
      copyTextToClipBoard(cell.copyCellTextFn(rowData));
      if (cell.id === "manufacturerId") {
        dispatch({
          type: GLOBAL_REDUCER_TYPES.SET_INFO_TOAST,
          payload: {
            show: true,
            message: "Device ID has been copied.",
          },
        });
      }
    }
  }
}

export const Row = (props) => {
  const classes = useStyles();
  const { rowData, headCells, isItemSelected,triggerViewHistoryAction } = props;
  const { Permissions } = useContext(UserPermissionContext);
  const dispatch = useDispatch();
  const tableStyles = useCustomTableStyles();

  const handleTableRowClicked = (event) => {
    event.stopPropagation();
  };

  return (
    <TableRow
      hover
      onClick={(event) => handleTableRowClicked(event)}
      role="checkbox"
      tabIndex={-1}
      key={rowData.id}
      selected={isItemSelected}
    >
      {headCells.map((cell, index) => {
        const showEllipses = cell.showEllipses;
        const content = cell.content(rowData);
        const toolTipContent = cell.toolTipContent ? cell.toolTipContent(rowData) : null;
        const tableCellCmp = (
          <TableCell
            key={`${cell.id}-${index}`}
            className={tableStyles.cell}
            onClick={(event) => {
              copyText(event, cell, rowData, dispatch);
            }}
          >
            <div className={clsx({ [tableStyles.cellEllipses]: showEllipses })}>{content}</div>
          </TableCell>
        );

        if (showEllipses && content) {
          return (
            <Tooltip
              classes={{
                tooltip: tableStyles.tooltip,
              }}
              title={
                <>
                  <div className={tableStyles.tooltipContentWrapper}>
                    <span className={tableStyles.tooltipContent}>{toolTipContent ?? content}</span>
                  </div>
                </>
              }
              arrow
              placement="top-start"
              key={`${rowData.id}-${cell.id}-${index}`}
            >
              {tableCellCmp}
            </Tooltip>
          );
        }

        return tableCellCmp;
      })}

      <TableCell className={tableStyles.cell}>
      <span style={{display: "flex"}}>

      <Box className={classes.actionBttnsWrapper}>
            <RowActionBttn
              color="primary"
              title={"View History"}
              onClick={(event) => {
                event.stopPropagation();
                triggerViewHistoryAction(rowData);
              }}
              Icon={ICONS.HistoryIcon}
            />
          </Box>
        {Permissions.Manage_Devices ? (
          <Box className={classes.actionBttnsWrapper}>
            <RowActionBttn
              color="primary"
              title={"Assign"}
              onClick={(event) => {
                event.stopPropagation();
                dispatch({
                  type: DEVICES_REDUCER_TYPES.SET_SELECTED_DEVICE,
                  payload: rowData,
                });

                dispatch({
                  type: DEVICES_REDUCER_TYPES.SET_UNASSIGNED_ASSIGN_DEVICE_DIALOG_DISPLAY,
                  payload: true,
                });
              }}
              Icon={ICONS.Assign}
            />
          </Box>
        ) : null}
        </span>
      </TableCell>
    </TableRow>
  );
};
