import { BadRequest, ServerError } from ".";

export const handleErrorFn = (errorData) => {
  let result;
  if (errorData.status === 400) {
    if (errorData.errors) {
      result = new BadRequest(errorData.title, errorData.errors);
    } else {
      result = new BadRequest(errorData.title, errorData.detail);
    }
  } else if (errorData.status === 500) {
    result = new ServerError();
  }

  return result;
};
