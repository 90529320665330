import { AxiosClient } from "./axios-client";
import { handleErrorFn } from "../custom-errors";
import { authService } from "../utils/auth-service";

const getMediaById = async (siteId) => {
  try {
    const headers = await authService.getAuthorizeHeader();
    const res = await AxiosClient({
      url: `/api/SiteLocation/${siteId}/Assets`,
      method: "GET",
      headers,
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const deleteMedia = async (name) => {
  try {
    const headers = await authService.getAuthorizeHeader();
    const res = await AxiosClient({
      url: `/api/SiteLocation/Assets/${name}`,
      method: "DELETE",
      headers,
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const renameMedia = async (data) => {
  try {
    const headers = await authService.getAuthorizeHeader();
    const res = await AxiosClient({
      url: `/api/SiteLocation/RenameMediaFile`,
      method: "POST",
      data: data,
      headers,
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

export const MediaAPI = {
  getMediaById,
  deleteMedia,
  renameMedia,
};
