import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField, Typography } from "@material-ui/core";
import { CategoryChip, CategoryPallete, ICONS } from "../../../../commons";

function getOptionsDisabledFn(cat, _values) {
  let isDisabled = false;

  if (cat.name.toLowerCase() === "common area") {
    isDisabled = true;
  }

  _values.forEach((val) => {
    if (val.id === cat.id) {
      isDisabled = true;
    }
  });

  return isDisabled;
}

export const ZoneCategoriesAutocomplete = (props) => {
  const { value, options, onChange } = props;
  return (
    <Autocomplete
      id="zone-categories-autocomplete"
      value={value}
      options={options}
      multiple
      filterSelectedOptions
      getOptionLabel={(option) => option.name}
      getOptionDisabled={(cat) => getOptionsDisabledFn(cat, value)}
      onChange={onChange}
      renderInput={(params) => {
        return <TextField {...params} label="Categories" />;
      }}
      renderOption={(option) => {
        return (
          <Typography style={{ display: "flex", flexDirection: "row" }} component={"div"}>
            <CategoryPallete data={option} />
            <span style={{ marginLeft: "10px" }}>{option.name}</span>
          </Typography>
        );
      }}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => (
          <CategoryChip
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.12)",
            }}
            data={option}
            {...getTagProps({ index })}
            disabled={option.name.toLowerCase() === "common area"}
          />
        ));
      }}
      popupIcon={<ICONS.AddCircle fontSize="inherit" />}
      getOptionSelected={(option, value) => option.name === value.name}
    />
  );
};
