import React from "react";
import { TableFooter, TablePagination } from "@material-ui/core";

export const CommonFooter = (props) => {
  const {
    componentType,
    rowsPerPage,
    page,
    tableClasses,
    tableData,
    handleChangePage,
    handleChangeRowsPerPage,
  } = props;
  return (
    <TableFooter data-testid="TableFooter" className={tableClasses.tabelFooterRoot} component={componentType}>
      <div></div>
      <TablePagination
        data-testid="TablePagination"
        classes={{
          root: tableClasses.tablePaginationRoot,
        }}
        component={componentType}
        rowsPerPageOptions={[]}
        labelRowsPerPage=""
        count={tableData && tableData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </TableFooter>
  );
};
