import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SendMessageZonesAlertsDialog } from "../messages/components";
import { MESSAGES_REDUCER_TYPES } from "../../reducers";
import { Grid, makeStyles } from "@material-ui/core";
import { DocumentTitle } from "../../commons";
import { AlertsContainer } from "./alerts-container";
import { ALERTS_REDUCER_TYPES } from "../../reducers";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    flex: 1,
    display: "flex",
    paddingTop: "32px",
    paddingLeft: "24px",
    paddingRight: "24px",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "24px",
    },
  },
}));

const AlertsScreen = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const showSendMessageZonesAlertsDialog = useSelector(
    (state) => state.messagesScreen?.dialogs?.showSendMessageZonesAlertsDialog
  );

  useEffect(() => {
    return () => {
      dispatch({ type: ALERTS_REDUCER_TYPES.RESET_ALERTS_SCREEN_STATE });
    };
  }, [dispatch]);

  return (
    <>
      <DocumentTitle title="Sitewise | Alerts" />
      <Grid id="j-sitewise-alerts" className={classes.wrapper}>
      <SendMessageZonesAlertsDialog
        show={showSendMessageZonesAlertsDialog}
        onCancel={() => {
          dispatch({
            type: MESSAGES_REDUCER_TYPES.SET_SEND_MESSAGE_ZONES_ALERTS_DIALOG_DISPLAY,
            payload: false,
          });
        }}
      />
        <AlertsContainer />
      </Grid>
    </>
  );
};

export default AlertsScreen;
