import React, { useState, useEffect } from "react";
import { Box, Dialog, DialogTitle } from "@material-ui/core";
import { DialogActionBttnBig } from "../dialog-action-bttn-big";
import { useDialogStyles } from "../use-dialog-styles";
import { ICONS } from "../../icon-manager";

export const RemoveDialog = ({ title, show, onCancel, onConfirm }) => {
  const classes = useDialogStyles();
  const [clearAlert, setClearAlert] = useState(false);

  useEffect(() => {
    if (title === "Are you sure you want to clear this alert?") {
      setClearAlert(true);
    }
  }, [title])

  if (!show) {
    return null;
  }

  return (
    <Dialog open={show} onClose={onCancel} PaperProps={{ className: classes.dialogPaper }}>
      <Box className={classes.dialogContentWrapper}>
        <DialogTitle
          className={classes.dialogTitleCentered}
          disableTypography
          id="responsive-dialog-title"
        >
          <span>{title}</span>
        </DialogTitle>
        <Box className={classes.actionsBttnWrapper}>
          <DialogActionBttnBig
            label="yes"
            BttnIcon={ICONS.RemoveCircleOutline}
            onClick={onConfirm}
            clearAlert={clearAlert}
          />
          <DialogActionBttnBig label="cancel" onClick={onCancel} />
        </Box>
      </Box>
    </Dialog>
  );
};
