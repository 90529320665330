import React, { useState, useEffect } from "react";
import { Paper } from "@material-ui/core";
import { Table, TableBody, TableFooter, TableContainer, TablePagination } from "@material-ui/core";
import {
  EnhancedTableHead,
  useCustomTableHook,
  useCustomTableStyles,
  useDebounce,
} from "../../../../../commons";
import { Row } from "../row";
import { parseUtcToLocal } from "../../../../../utils";

const headCellMap = {
  message: "message",
  createdTimestamp: "createdTimestamp",
  componentDiv: "div",
};
const headCells = [
  {
    id: headCellMap.message,
    sortBy: headCellMap.message,
    label: "Message",
    width: "30%",
    content: (row) => row.message,
  },
  {
    id: "zone",
    sortBy: "eventZone.name",
    label: "Zone",
    width: "20%",
    content: (row) => row.eventZone?.name,
  },
  {
    id: headCellMap.createdTimestamp,
    sortBy: headCellMap.createdTimestamp,
    label: "Date Received",
    content: (row) => parseUtcToLocal(row.createdTimestamp),
  },
];

export const NotificationsTable = (props) => {
  const tableClasses = useCustomTableStyles();
  const [tableSearchStr, setTableSearchStr] = useState("");

  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    setPage,
    getComparator,
    stableSort,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useCustomTableHook({
    defaultSortBy: headCells[2].sortBy,
    defaultSortOrder: "desc",
    rowsPerPage: 10,
  });

  const { data } = props;
  const [tableData, setTableData] = useState([]);

  const notificationTableSearchDebounced = useDebounce((_nextVal) => {
    setPage(0);
    setTableSearchStr(_nextVal);
  }, 300);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  useEffect(() => {
    let arr = [];

    if (tableSearchStr) {
      arr = data.filter(
        (tData) =>
          tData.message && tData.message.toLowerCase().includes(tableSearchStr.toLowerCase())
      );
    } else {
      arr = data;
    }
    setTableData(arr);
  }, [tableSearchStr, setTableData, data]);

  if (tableData === null) {
    return null;
  }

  return (
    <Paper>
      <TableContainer className={tableClasses.tableContainer}>
        <Table
          aria-labelledby="tableTitle"
          aria-label="notifications table"
          stickyHeader
          data-testid="notifications-table"
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={headCells}
            toShow={true}
            tableWithRowActions={true}
            onChangeSearch={(event) => {
              notificationTableSearchDebounced(event.target.value);
            }}
            searchLabel={"Search Message"}
          />
          <TableBody data-testid="notifications-table-body">
            {stableSort(tableData, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((rowData) => {
                return <Row key={rowData.id} rowData={rowData} headCells={headCells} />;
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TableFooter className={tableClasses.tabelFooterRoot} component={headCellMap.componentDiv}>
        <div></div>
        <TablePagination
          classes={{
            root: tableClasses.tablePaginationRoot,
          }}
          component={headCellMap.componentDiv}
          rowsPerPageOptions={[]}
          labelRowsPerPage=""
          count={tableData && tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableFooter>
    </Paper>
  );
};
