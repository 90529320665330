import { loadModules } from "esri-loader";
import { MapWidgetTmpl } from "./map-widget-tmpl";
import { MapUtilsV2 } from "../../../../commons/map";

let webMercatorUtils, geometryEngine, Circle;
const DEFAULT_SKETCH_HELPER_TXT = "Select a shape or draw a new shape with the pen tool.";

export class MapUtils {
  constructor(map, mapView) {
    this.map = map;
    this.mapView = mapView;
  }

  static convertXYtoLongLat = async (x, y) => {
    if (!webMercatorUtils) {
      [webMercatorUtils] = await loadModules(["esri/geometry/support/webMercatorUtils"], {
        css: true,
      });
    }

    return webMercatorUtils.xyToLngLat(x, y);
  };

  static getPolygonPoints = async (rings) => {
    const ringsWithPolygonPts = await rings.map(async (ring) => {
      const [longitude, latitude] = await MapUtils.convertXYtoLongLat(ring[0], ring[1]);
      return {
        longitude,
        latitude,
      };
    });

    return Promise.all(ringsWithPolygonPts);
  };

  static calculateCircleRadius = async (geometry, unit) => {
    if (!geometryEngine) {
      [geometryEngine] = await loadModules(["esri/geometry/geometryEngine"], {
        css: true,
      });
    }

    return geometryEngine.distance(geometry.centroid, geometry.getPoint(0, 0), unit);
  };

  static getCircle = async (geometry) => {
    if (!Circle) {
      [Circle] = await loadModules(["esri/geometry/Circle"], {
        css: true,
      });
    }

    return new Circle({
      rings: geometry.rings,
      centroid: geometry.centroid,
      radius: geometry.extent.width / 2,
    });
  };

  addCustomWidgets(graphicLabelClickCb, cancelBttnClickCb) {
    const sketchHelperWidget = MapUtilsV2.MapWidgetTmpl.getSketchHelperWidget();
    const graphicLabel = MapWidgetTmpl.getGraphicLabelActionBttnToMapView(graphicLabelClickCb);
    const cancelBttn = MapWidgetTmpl.getGraphicCancelBttn(cancelBttnClickCb);
    this.mapView.ui.add([sketchHelperWidget], "top-left");
    this.mapView.ui.add([cancelBttn], "manual");
    this.mapView.ui.add(graphicLabel, "manual");
  }

  updateSketchHelperTxt = (show, text = DEFAULT_SKETCH_HELPER_TXT) => {
    const divElement = document.getElementById("sketch-tool-helper-text");
    if (divElement) {
      if (show) {
        divElement.firstChild.innerText = text;
        divElement.style.display = "block";
      } else {
        divElement.style.display = "none";
      }
    }
  };

  updateCancelBttnWidget = (show) => {
    const cancelBttn = document.getElementById("graphic-cancel-bttn");
    if (cancelBttn) {
      if (show) {
        cancelBttn.style.display = "block";
      } else {
        cancelBttn.style.display = "none";
      }
    }
  };

  hideAllActionsBttns = () => {
    const zoneUpdateBttn = document.getElementById("graphic-update-bttn");
    const zoneSaveBttn = document.getElementById("graphic-save-bttn");
    const zoneCancelBttn = document.getElementById("graphic-cancel-bttn");

    if (zoneUpdateBttn) {
      zoneUpdateBttn.style.display = "none";
    }

    if (zoneSaveBttn) {
      zoneSaveBttn.style.display = "none";
    }

    if (zoneCancelBttn) {
      zoneCancelBttn.style.display = "none";
    }
  };

  setShowSketchInfoPanel = (toShow) => {
    const classPath = ".esri-sketch__panel.esri-sketch__info-panel";
    const elm = document.querySelector(classPath);
    if (elm && elm.style) {
      elm.style.display = toShow ? "block" : "none";
    }
  };

  disableSketchOptions = (val) => {
    const ediBttnsWrapper = document.querySelectorAll(
      ".esri-sketch__panel .esri-sketch__section.esri-sketch__tool-section"
    )[1];

    if (ediBttnsWrapper) {
      const { childNodes } = ediBttnsWrapper;

      if (childNodes && childNodes.length) {
        childNodes.forEach((bttn) => {
          bttn.disabled = val;
        });
      }
    }
  };
}
