import React, { useEffect, useState } from "react";
import { Button, Paper, TextField, Box, makeStyles } from "@material-ui/core";
import { FONTS } from "../../utils";
import { ShellIcon } from "../../commons/icon-manager/icons/shell";
import { UserAPI } from "../../api";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "684px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "25px",
    backgroundColor: theme.palette.base.elevation_1,
  },
  iconWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "100px",
    paddingTop: "30px",
  },
  dialogHeader: {
    paddingBottom: "40px",
    alignSelf: "flex-start",
  },
  dialogTitle: {
    ...FONTS.H5_ShellBold_18,
    paddingBottom: "10px",
  },
  bttnContainer: {
    paddingTop: "20px",
    alignSelf: "flex-end",
  },
  feedback: {
    color: theme.palette.primary.main,
    paddingLeft: "10px",
    ...FONTS.B5_Bold_12,
  },
  error: {
    color: theme.palette.warning.main,
    paddingLeft: "10px",
    ...FONTS.B5_Bold_12,
  },
}));

const PrivacyScreen = () => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [isError, setIsError] = useState(null);
  const [isBttnDisabled, setIsBttnDisabled] = useState(true);

  function renderMsg() {
    if (isError !== null) {
      return isError ? (
        <span className={classes.error}>Please verify the email.</span>
      ) : (
        <span className={classes.feedback}>An email has been sent, please check your inbox.</span>
      );
    }
    return null;
  }

  async function handleFormSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    if (email) {
      try {
        await UserAPI.sendPrivacyLink(email);
        setIsBttnDisabled(true);
        setIsError(false);
      } catch (error) {
        setIsError(true);
        setIsBttnDisabled(false);
      }
    }
  }

  useEffect(() => {
    if (email) {
      setIsBttnDisabled(false);
    } else {
      setIsBttnDisabled(true);
    }
  }, [email]);

  return (
    <form className={classes.form} autoComplete="off" onSubmit={handleFormSubmit}>
      <Box style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Box className={classes.iconWrapper}>
          <ShellIcon
            style={{ width: "66px", height: "66px" }}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          />
        </Box>
        <Paper className={classes.paper}>
          <Box className={classes.dialogHeader}>
            <Box>
              <span className={classes.dialogTitle}>Jarvis Privacy Agreement</span>
            </Box>
            <Box style={{ ...FONTS.B5_Reg_12, paddingTop: "10px" }}>
              Please enter your email address. A unique link will be sent to you inbox to access
              Jarvis Privacy Agreements.
            </Box>
          </Box>
          <Box
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              marginBottom: "45px",
            }}
          >
            <TextField
              label="Email Address"
              variant="filled"
              value={email}
              fullWidth
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              inputProps={{
                "data-testid": "email-input",
              }}
            />

            <Box>{renderMsg()}</Box>
          </Box>

          <div className={classes.bttnContainer}>
            <Button
              disabled={isBttnDisabled}
              color="primary"
              variant="contained"
              style={{
                fontWeight: "bold",
                border: "2px solid #FDE26A",
              }}
              type="submit"
              data-testid="send-email"
            >
              Send Link to Email
            </Button>
          </div>
        </Paper>
      </Box>
    </form>
  );
};

export default PrivacyScreen;
