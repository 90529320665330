import React from "react";
import { makeStyles, Dialog, DialogTitle, FormControl, Button, Box } from "@material-ui/core";
import { FONTS } from "../../../../utils";
import { useDialogStyles } from "../../../../commons/dialogs/use-dialog-styles";

const useStyles = makeStyles((theme) => ({
  closeIconWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  dialogTitle: {
    textAlign: "center",
    paddingTop: "45px",
    paddingBottom: "81px",
    ...FONTS.H3_DB_28,
  },
  formControl: {
    width: "100%",
    marginTop: "24px",
  },
  formControlActions: {
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: "32px",
    display: "flex",
  },
}));

export const ConfirmationBatchUploadDialog = ({ show, onCancel, data }) => {
  const dialogStyles = useDialogStyles();
  const classes = useStyles();

  function handleCancelBttnClick() {
    onCancel && onCancel();
  }

  if (!show) {
    return null;
  }

  return (
    <Dialog open={show} onClose={onCancel} PaperProps={{ className: dialogStyles.dialogPaper }}>
      <Box className={dialogStyles.dialogContentWrapper}>
        <DialogTitle
          className={dialogStyles.dialogTitle}
          disableTypography
          id="responsive-dialog-title"
          onClose={onCancel}
        >
          <span>{`Upload Successful`}</span>
        </DialogTitle>
        <form>
          <FormControl required variant="filled" className={classes.formControl}>
            <div>
              <span>{` ${data.addedUsers.length} Users Added, ${data.updatedUsers.length} Users Updated.`}</span>
            </div>
          </FormControl>

          <FormControl className={classes.formControlActions}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleCancelBttnClick}
              data-testid={"cancel-bttn"}
            >
              Close
            </Button>
          </FormControl>
        </form>
      </Box>
    </Dialog>
  );
};
