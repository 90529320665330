import { makeStyles, fade } from "@material-ui/core";
import { FONTS } from "../../../../utils";

export const useChipStyles = makeStyles((theme) => ({
  chipsWrapper: {
    marginTop: "10px",
  },
  chip: {
    ...FONTS.Label1_Med_12,
    background: fade(theme.palette.primary.main, 0.3),
    marginRight: "5px",
    marginBottom: "5px",
    cursor: "pointer",
  },
}));
