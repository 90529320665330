import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Dialog,
  DialogTitle,
  FormControl,
  TextField,
  Button,
  Box,
} from "@material-ui/core";
import { useDialogStyles } from "../../../../../commons/dialogs/use-dialog-styles";
import { useDispatch } from "react-redux";
import { SitesReducerActions } from "../../../../../reducers";

const cssProperties = {
  filledVariant: "filled",
  primaryColor: "primary",
};
const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    marginBottom: "32px",
  },
  formControlActions: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
}));

export const EditSuperAdminDialog = ({ show, onCancel, superAdminId, superAdminName }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dialogStyles = useDialogStyles();
  const [adminId, setAdminId] = useState(superAdminId);
  const [adminName, setAdminName] = useState(superAdminName);

  function handleCancelBttnClick() {
    onCancel && onCancel();
  }

  function handleFormSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    
    const user = {
      id: adminId,
      name: adminName,     
    };

    dispatch(SitesReducerActions.updateSuperUSer(user));
  }

  useEffect(() => {
    setAdminName(superAdminName);
    setAdminId(superAdminId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  if (!show) {
    return null;
  }

  return (
    <Dialog open={show} onClose={onCancel} PaperProps={{ className: dialogStyles.dialogPaper }}>
      <Box className={dialogStyles.dialogContentWrapper}>
        <DialogTitle className={dialogStyles.dialogTitle} disableTypography onClose={onCancel}>
          <span>Edit Super Admin Name</span>
        </DialogTitle>
        <form className={classes.form} autoComplete="off" onSubmit={handleFormSubmit}>
          <FormControl
            required
            variant={cssProperties.filledVariant}
            className={classes.formControl}
          >
            <TextField
              required
              label="Name"
              variant={cssProperties.filledVariant}
              fullWidth={true}
              value={adminName}
              onChange={(event) => setAdminName(event.target.value)}
              inputProps={{
                "data-testid": "super-admin-name-input",
              }}
            />
          </FormControl>
          <FormControl
            required
            variant={cssProperties.filledVariant}
            className={classes.formControlActions}
          >
            <Button
              variant="outlined"
              color={cssProperties.primaryColor}
              onClick={handleCancelBttnClick}
              className={dialogStyles.dialogActionBttn}
              data-testid="cancel-bttn"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color={cssProperties.primaryColor}
              style={{ marginLeft: "10px" }}
              className={dialogStyles.dialogActionBttn}
              data-testid="save-bttn"
            >
              Save
            </Button>
          </FormControl>
        </form>
      </Box>
    </Dialog>
  );
};
