import { useEffect } from "react";
import PropTypes from "prop-types";

export const DocumentTitle = (props) => {
  const { title } = props;
  useEffect(() => {
    document.title = title || "";
  });
  return null;
};

DocumentTitle.propTypes = {
  title: PropTypes.string,
};
