import React from "react";
import { Snackbar, IconButton, makeStyles, SnackbarContent } from "@material-ui/core";
import { GLOBAL_REDUCER_TYPES } from "../../reducers";
import { useSelector, useDispatch } from "react-redux";
import { ICONS } from "../../commons";

const useStyles = makeStyles((theme) => ({
  snackbarContentRoot: {
    color: theme.palette.common.white,
    background: theme.palette.base.elevation_1,
  },
}));

export const InfoToast = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const infoToast = useSelector((state) => state.globalState.infoToast);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch({
      type: GLOBAL_REDUCER_TYPES.SET_INFO_TOAST,
      payload: {
        show: false,
        message: "",
      },
    });
  };

  return (
    <Snackbar
      open={infoToast.show}
      autoHideDuration={2000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <SnackbarContent
        classes={{
          root: classes.snackbarContentRoot,
        }}
        message={infoToast ? infoToast.message : undefined}
        action={
          <React.Fragment>
            <IconButton aria-label="close" color="inherit" onClick={handleClose}>
              <ICONS.Close />
            </IconButton>
          </React.Fragment>
        }
      />
    </Snackbar>
  );
};
