import React from "react";
import {
  makeStyles,
  Dialog,
  DialogTitle,
  Button,
  TextField,
  Box,
  FormControl,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Datepicker, useDialogStyles } from "../../../commons";
import { ZonesReducerActions, ZONE_REDUCER_TYPES } from "../../../reducers";

const useStyles = makeStyles(() => ({
  formControl: {
    width: "100%",
    marginBottom: "32px",
  },
  formControlActions: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
}));

export const ZoneReactivateDialog = ({ show, onCancel, onConfirm }) => {
  const classes = useStyles();
  const dialogStyles = useDialogStyles();
  const dispatch = useDispatch();
  const selectedZone = useSelector((state) => state.zonesScreen.selectedZone);

  const handleEndDateTime = (endDateTime) => {
    dispatch({
      type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE_REACTIVATE_START_END_TIME,
      payload: {
        startTime: new Date(),
        endTime: endDateTime,
      },
    });
  };

  const onCreateButtonClick = () => {
    dispatch(ZonesReducerActions.reactivateZone());
    onConfirm && onConfirm();
  };

  function handleCancelBttnClick(event) {
    onCancel && onCancel(event);
  }

  if (!show) {
    return null;
  }

  return (
    <Dialog open={show} onClose={onCancel} PaperProps={{ className: dialogStyles.dialogPaper }}>
      <Box className={dialogStyles.dialogContentWrapper}>
        <DialogTitle
          className={dialogStyles.dialogTitle}
          disableTypography
          id="responsive-dialog-title"
        >
          <span>Reactivate Zone</span>
        </DialogTitle>
        <form className={classes.form} autoComplete="off">
          <FormControl required variant="filled" className={classes.formControl}>
            <TextField
              disabled
              required
              id="filled-basic"
              label="Zone Name"
              variant="filled"
              fullWidth={true}
              value={selectedZone.name}
            />
          </FormControl>
          <FormControl component="fieldset" className={classes.formControl}>
            <Datepicker
              disabled
              handleDateChange={() => {}}
              selectedDate={new Date()}
              label="Select start date"
            />
          </FormControl>
          <FormControl component="fieldset" className={classes.formControl}>
            <Datepicker
              handleDateChange={handleEndDateTime}
              selectedDate={selectedZone.endTime}
              label="Select end date"
            />
          </FormControl>
          <FormControl required variant="filled" className={classes.formControlActions}>
            <Button
              onClick={handleCancelBttnClick}
              variant="outlined"
              color="primary"
              className={dialogStyles.dialogActionBttn}
            >
              Cancel
            </Button>
            <Button
              className={dialogStyles.dialogActionBttn}
              variant="contained"
              color="primary"
              style={{ marginLeft: "10px" }}
              onClick={onCreateButtonClick}
            >
              Create
            </Button>
          </FormControl>
        </form>
      </Box>
    </Dialog>
  );
};
