import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TableCell, TableRow, Box, Collapse, Button, Grid } from "@material-ui/core";
import { CheckboxesForm } from "../checkboxes-form";
import { SitesReducerActions, SITES_REDUCER_TYPES } from "../../../../../reducers";
import { RemoveDialog as CategoryRemoveDialog } from "../../../../../commons";

export const CollapsedRow = (props) => {
  const dispatch = useDispatch();
  const {
    classes,
    isItemSelected,
    rowData,
    canManagePermissions,
    dependencyMatrix,
    suggestionsMatrix,
  } = props;
  const [editMode, setEditMode] = useState(false);
  const [list, setList] = useState(rowData.permissions);

  const showRemoveCategoryDialog = useSelector(
    (state) => state.sitesScreen.dialogs.showRemoveUserCategoryDialog
  );

  function handleSave(event) {
    dispatch(
      SitesReducerActions.updateUserCategory({
        ...rowData,
        permissions: list,
      })
    );
    setEditMode(false);
  }

  function handleChange(e) {
    const name = e.target.name;
    let listAfterChange;
    if (list.includes(name)) {
      list.splice(list.indexOf(name), 1);
      listAfterChange = [...list];
    } else {
      listAfterChange = [...list, name];
    }
    setList(listAfterChange);
  }

  function renderButtons() {
    let buttons;
    if (editMode) {
      buttons = <div>
        <Button
          className={classes.button}
          disabled={false}
          variant="outlined"
          data-id={rowData.id}
          size="small"
          onClick={(event) => {
            setEditMode(false);
          }}
        >
          Cancel
        </Button>
        <Button
          className={classes.primaryButton}
          disabled={false}
          variant="contained"
          color="primary"
          data-id={rowData.id}
          size="small"
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
    } else {
      buttons = <div>
        <Button
          className={classes.button}
          disabled={false}
          variant="outlined"
          color="primary"
          data-id={rowData.id}
          size="small"
          onClick={() => {
            dispatch({
              type: SITES_REDUCER_TYPES.SET_REMOVE_USER_CATEGORY_DIALOG,
              payload: true,
            });
          }}
        >
          Delete
        </Button>
        <CategoryRemoveDialog
          title="Are you sure you want to remove this User Category?"
          show={showRemoveCategoryDialog}
          onConfirm={() => {
            dispatch(SitesReducerActions.deleteUserCategory(rowData.id));
          }}
          onCancel={() => {
            dispatch({
              type: SITES_REDUCER_TYPES.SET_REMOVE_USER_CATEGORY_DIALOG,
              payload: false,
            });
          }}
        />
        <Button
          className={classes.button}
          disabled={false}
          variant="outlined"
          color="primary"
          data-id={rowData.id}
          size="small"
          onClick={(event) => {
            setEditMode(true);
          }}
        >
          Edit
        </Button>
      </div>
    }
    return buttons;
  }

  return (
    <TableRow
      className={classes.tableRow}
      classes={{
        selected: classes.selected,
      }}
      hover
      selected={isItemSelected}
      key={`${rowData.id}-collapsed-row`}
    >
      <TableCell
        key={`${rowData.id}-collapse-without-alert`}
        style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }}
        colSpan={6}
      >
        <Collapse style={{ paddingLeft: "24px" }} in={isItemSelected} timeout="auto" unmountOnExit>
          <Box display="flex" flexDirection="row" pt={2} pb={2}>
            <Grid container spacing={3}>
              <Grid className={classes.gridSection} container spacing={1} xl={4} lg={5} md={7}>
                <Grid item xs={12}>
                  <div className={classes.formLabel1}>USER MANAGEMENT</div>
                </Grid>
                <Grid item xl={6} lg={7} md={7}>
                  <CheckboxesForm
                    list={list}
                    isRowDataNotEditable={rowData.notEditable}
                    disabled={!editMode}
                    handleChange={handleChange}
                    boxes={{
                      label: "Users",
                      keys: [
                        {
                          key: "sitewise.Permissions.View.Users",
                          label: "View",
                        },
                        {
                          key: "sitewise.Permissions.Manage.Users",
                          label: "Edit",
                        },
                        {
                          key: "sitewise.Permissions.Delete.Users",
                          label: "Delete",
                        },
                      ],
                    }}
                    dependentPermissions={dependencyMatrix}
                    recommendedPermissions={suggestionsMatrix}
                  />
                </Grid>

                <Grid item xl={6} lg={5} md={5}>
                  <CheckboxesForm
                    list={list}
                    isRowDataNotEditable={rowData.notEditable}
                    disabled={!editMode}
                    handleChange={handleChange}
                    boxes={{
                      label: "Roles",
                      keys: [
                        {
                          key: "sitewise.Permissions.View.Roles",
                          label: "View",
                        },
                        {
                          key: "sitewise.Permissions.Manage.Roles",
                          label: "Edit",
                        },
                      ],
                    }}
                    dependentPermissions={dependencyMatrix}
                    recommendedPermissions={suggestionsMatrix}
                  />
                </Grid>
              </Grid>

              <Grid className={classes.gridSection} container spacing={1} xl={5} lg={6} md={10}>
                <Grid item xs={12}>
                  <div className={classes.formLabel1}>ZONE MANAGEMENT</div>
                </Grid>
                <Grid item xs={4}>
                  <CheckboxesForm
                    list={list}
                    isRowDataNotEditable={rowData.notEditable}
                    disabled={!editMode}
                    handleChange={handleChange}
                    boxes={{
                      label: "Devices",
                      keys: [
                        {
                          key: "sitewise.Permissions.View.Devices",
                          label: "View",
                        },
                        {
                          key: "sitewise.Permissions.Manage.Devices",
                          label: "Edit",
                        },
                      ],
                    }}
                    dependentPermissions={dependencyMatrix}
                    recommendedPermissions={suggestionsMatrix}
                  />
                </Grid>

                <Grid item xs={4}>
                  <CheckboxesForm
                    list={list}
                    isRowDataNotEditable={rowData.notEditable}
                    disabled={!editMode}
                    handleChange={handleChange}
                    boxes={{
                      label: "Zones",
                      keys: [
                        {
                          key: "sitewise.Permissions.View.Zones",
                          label: "View",
                        },
                        {
                          key: "sitewise.Permissions.Manage.Zones",
                          label: "Edit",
                        },
                      ],
                    }}
                    dependentPermissions={dependencyMatrix}
                    recommendedPermissions={suggestionsMatrix}
                    
                  />
                </Grid>

                <Grid item xs={4}>
                  <CheckboxesForm
                    list={list}
                    isRowDataNotEditable={rowData.notEditable}
                    disabled={!editMode}
                    handleChange={handleChange}
                    boxes={{
                      label: "Alerts",
                      keys: [
                        {
                          key: "sitewise.Permissions.View.Alerts",
                          label: "View",
                        },
                        {
                          key: "sitewise.Permissions.Manage.Alerts",
                          label: "Edit",
                        },
                      ],
                    }}
                    dependentPermissions={dependencyMatrix}
                    recommendedPermissions={suggestionsMatrix}
                  />
                </Grid>
              </Grid>

              <Grid className={classes.gridSection} container spacing={1} xl={4} lg={6} md={9}>
                <Grid item xs={12}>
                  <div className={classes.formLabel1}>MESSAGES</div>
                </Grid>
                <Grid item xs={4}>
                  <CheckboxesForm
                    list={list}
                    isRowDataNotEditable={rowData.notEditable}
                    disabled={!editMode}
                    handleChange={handleChange}
                    boxes={{
                      label: "Bulk Messages",
                      keys: [
                        {
                          key: "sitewise.Permissions.View.BulkMessages",
                          label: "View",
                        },
                        {
                          key: "sitewise.Permissions.Manage.BulkMessages",
                          label: "Edit",
                        },
                      ],
                    }}
                    dependentPermissions={dependencyMatrix}
                    recommendedPermissions={suggestionsMatrix}
                  />
                </Grid>

                <Grid item xs={4}>
                  <CheckboxesForm
                    list={list}
                    isRowDataNotEditable={rowData.notEditable}
                    disabled={!editMode}
                    handleChange={handleChange}
                    boxes={{
                      label: "Dashboard Messages",
                      keys: [
                        {
                          key: "sitewise.Permissions.View.DashboardMessages",
                          label: "View",
                        },
                      ],
                    }}
                    
                  />
                </Grid>
              </Grid>
              {canManagePermissions && !rowData.notEditable ? (
                <Grid className={classes.gridSection} item xs={12}>
                  <div className={classes.buttonContainer}>
                    {renderButtons()}
                  </div>
                </Grid>
              ) : null}
            </Grid>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};
