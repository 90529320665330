import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GLOBAL_REDUCER_TYPES, ZONE_REDUCER_TYPES } from "../../../../reducers";
import { Snackbar, SnackbarContent, makeStyles } from "@material-ui/core";
import { ICONS, ScopeContext } from "../../../../commons";
import { SoundManager } from "../../../../utils/sound-manager";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  snackbar: {
    marginTop: theme.spacing(2),
    marginLeft: -theme.spacing(1),
    position: "absolute",
  },
  snackbarContent: {
    backgroundColor: "#2B2B36",
    width: "100%",
    color: "white",
    height: "32px",
    fontWeight: "700",
    fontSize: "12px",
    cursor: "pointer",

    "& > .MuiSnackbarContent-message": {
      padding: 0,
    },

    "& > .MuiSnackbarContent-action": {
      color: theme.palette.primary.main,
    },
  },
}));

export const SoftAlertSnackbar = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  
  const dashboardMessageSoftAlert = useSelector(
    (state) => state.zonesScreen.dashboardMessageSoftAlert
  );
  const dashboardMessage = dashboardMessageSoftAlert ? dashboardMessageSoftAlert[0] : null;
  const siteId = useSelector((state) => state.globalState.siteID);

  const messageVisibleForCurrentSite = dashboardMessage?.siteLocationId === siteId;

  const isSoftAlertVisible = useSelector((state) => state.globalState.isSoftAlertVisible);
  const {namedRoutes} = useContext(ScopeContext);

  const handleGotoLog = () => {
    dispatch({
      type: GLOBAL_REDUCER_TYPES.SET_SOFT_ALERT,
      payload: false,
    });
    dispatch({
      type: ZONE_REDUCER_TYPES.SET_DASHBOARD_MESSAGE_SOFT_ALERT,
      payload: null,
    });
    history.push(namedRoutes.messages);
  };

  useEffect(() => {
    if (dashboardMessage) {
      SoundManager.dashboardMessageSound();
    }
  }, [dashboardMessage]);


  return isSoftAlertVisible && dashboardMessage && dashboardMessage.message && messageVisibleForCurrentSite ? (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "start",
        }}
        className={classes.snackbar}
        open={isSoftAlertVisible}
        autoHideDuration={null}
      >
        <SnackbarContent
          message={dashboardMessage.message}
          className={classes.snackbarContent}
          onClick={handleGotoLog}
          action={
            <React.Fragment>
              Go to Log <ICONS.ChevronRight />
            </React.Fragment>
          }
        ></SnackbarContent>
      </Snackbar>
    </div>
  ) : null;
};
