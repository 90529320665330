import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Dialog,
  DialogTitle,
  FormControl,
  Button,
  Box,
  TextField,
} from "@material-ui/core";
import { useDialogStyles } from "../../../commons/dialogs/use-dialog-styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    marginBottom: "32px",
  },
  formControlActions: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
}));

export const AlertNoteDialog = ({ show, onCancel, onActionOneClick, onActionTwoClick }) => {
  const classes = useStyles();
  const dialogStyles = useDialogStyles();

  const [note, setNote] = useState("");

  useEffect(() => {
    setNote("");
  }, [show]);

  return (
    <Dialog open={show} onClose={onCancel} PaperProps={{ className: dialogStyles.dialogPaper }}>
      <Box className={dialogStyles.dialogContentWrapper}>
        <DialogTitle
          className={dialogStyles.dialogTitle}
          disableTypography
          id="responsive-dialog-title"
          onClose={onCancel}
        >
          <span>Add Note to Alert (Optional)</span>
        </DialogTitle>
        <form className={classes.form} autoComplete="off">
          <FormControl className={classes.formControl}>
            <TextField
              variant="filled"
              id="standard-multiline-static"
              label="Enter Note"
              multiline
              rows={2}
              value={note}
              onChange={(event) => setNote(event.target.value)}
            />
          </FormControl>

          <FormControl variant="filled" className={classes.formControlActions}>
            <Button color="primary" variant="outlined" onClick={onActionOneClick}>
              Clear without Note
            </Button>

            <Button
              disabled={!note.length}
              variant="contained"
              color="primary"
              style={{ marginLeft: "10px" }}
              onClick={() => {
                onActionTwoClick(note);
              }}
            >
              Clear with Note
            </Button>
          </FormControl>
        </form>
      </Box>
    </Dialog>
  );
};
