import { SvgIcon } from "@material-ui/core";

export const EyeCustomRegularIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 28 18">
      <path
        d="M7.77778 9.11C7.77778 6.31789 10.5632 4.05499 14 4.05499C17.4368 4.05499 20.2222 6.31789 20.2222 9.11C20.2222 11.9021 17.4368 14.165 14 14.165C10.5632 14.165 7.77778 11.9021 7.77778 9.11ZM14 12.2694C16.1486 12.2694 17.8889 10.8556 17.8889 9.11C17.8889 7.36444 16.1486 5.95062 14 5.95062C13.966 5.95062 13.9368 5.95062 13.859 5.95062C13.966 6.15203 14 6.36528 14 6.58249C14 7.97657 12.6049 9.11 10.8889 9.11C10.6215 9.11 10.359 9.08235 10.1111 8.99547C10.1111 9.05866 10.1111 9.08235 10.1111 9.07445C10.1111 10.8556 11.8514 12.2694 14 12.2694ZM4.63848 3.44681C6.92709 1.71863 10.0722 0.263733 14 0.263733C17.9278 0.263733 21.0729 1.71863 23.3625 3.44681C25.6375 5.16077 27.159 7.17882 27.8785 8.62424C28.0389 8.93623 28.0389 9.28376 27.8785 9.59575C27.159 11.0056 25.6375 13.0237 23.3625 14.7732C21.0729 16.5029 17.9278 17.9563 14 17.9563C10.0722 17.9563 6.92709 16.5029 4.63848 14.7732C2.36348 13.0237 0.842923 11.0056 0.119638 9.59575C-0.0398793 9.28376 -0.0398793 8.93623 0.119638 8.62424C0.842923 7.17882 2.36348 5.16077 4.63848 3.44681ZM14 2.15936C10.8306 2.15936 8.22501 3.32833 6.22709 4.83299C4.35556 6.24681 3.06348 7.88968 2.40334 9.11C3.06348 10.2948 4.35556 11.9732 6.22709 13.387C8.22501 14.8917 10.8306 16.0606 14 16.0606C17.1695 16.0606 19.775 14.8917 21.7729 13.387C23.6445 11.9732 24.8938 10.2948 25.5986 9.11C24.8938 7.88968 23.6445 6.24681 21.7729 4.83299C19.775 3.32833 17.1695 2.15936 14 2.15936Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
