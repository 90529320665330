import QRCode from 'react-qr-code';
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    qrCode: {
        padding: "26px",
        backgroundColor: "#FFF",
        minWidth: "205px"
    }
}));

export const QR = ({ url, size }) => {
    const classes = useStyles();
    return (
        <div className={classes.qrCode}>
            <QRCode value={url} size={size} />
        </div>
    )
};