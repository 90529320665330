import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Paper } from "@material-ui/core";
import { FONTS } from "../../../../utils";
import { PlotClearCard } from "./cards/plot-clear-card";
import { DevicePersonalAlertCard } from "./cards/device-personal-alert-card";
import { ALERTS_REDUCER_TYPES } from "../../../../reducers";

const useStyles = makeStyles((theme) => ({
  title: {
    ...FONTS.H5_ShellBold_18,
    marginBottom: "15px",
    display: "block",
  },
  currentAlertsWrapper: {
    width: "100%",
    height: "100%",
  },
  paper: {
    width: "100%",
    height: "72vh",
    overflow: "auto",
    background: theme.palette.base.elevation_1,
    [theme.breakpoints.down("sm")]: {
      // tablets
      paddingTop: "24px",
      height: "40vh",
    },
  },
}));

export const CurrentAlerts = ({ onLocateSiteCb }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const activeAlerts = useSelector((state) => state.globalState.alerts.activeAlerts);

  function clearAlert() {
    dispatch({ type: ALERTS_REDUCER_TYPES.SET_CLEAR_ALERT_DIALOG_DISPLAY, payload: true });
  }

  return (
    <div className={classes.currentAlertsWrapper}>
      <span className={classes.title}>Active Alerts ({activeAlerts.length})</span>
      <Paper className={classes.paper}>
        {activeAlerts.length ? (
          activeAlerts.map((alert) => {
            if (alert.alertType === "PlotClear") {
              return <PlotClearCard alert={alert} clearAlert={clearAlert} key={alert.id} />;
            } else {
              return (
                <DevicePersonalAlertCard
                  alert={alert}
                  clearAlert={clearAlert}
                  key={alert.id}
                  onLocateSiteCb={onLocateSiteCb}
                />
              );
            }
          })
        ) : (
          <div
            style={{
              display: "flex",
              flex: 1,
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            There are no current active alerts.
          </div>
        )}
      </Paper>
    </div>
  );
};
