import { GlobalReducerActions } from "./global-reducer";
import { AuthAPI } from "../api";
import { authService } from "../utils/auth-service";

const AUTH_INITIAL_STATE = {
  sitewiseUser: authService.getSitewiseUser(),
  isLoggedIn: authService.checkIfUserLoggedIn(),
  accessToken: authService.getTokenByKey("sitewise_access_token"),
  refreshToken: authService.getTokenByKey("sitewise_refresh_token"),
  userInfo: authService.getUserInfo(),
  accessDenied: false,
  authorizedRoute: "",
  goToNewRoute: false,
  navigation: 0,
};

export const AUTH_REDUCER_TYPES = {
  SET_USERINFO_DETAILS: "SET_USERINFO_DETAILS",
  SET_IS_LOGGED_IN: "SET_IS_LOGGED_IN",
  SET_USER_TOKENS: "SET_USER_TOKENS",
  SET_ACCESS_DENIED: "SET_ACCESS_DENIED",
  SET_AUTHORIZED_ROUTE: "SET_AUTHORIZED_ROUTE",
  SET_GO_TO_NEW_ROUTE: "SET_GO_TO_NEW_ROUTE",
  SET_NAVIGATION: "SET_NAVIGATION",
  LOG_OUT: "LOG_OUT",
};

export const authReducer = (_state, action) => {
  const state = _state || AUTH_INITIAL_STATE;
  const { payload, type } = action;
  switch (type) {
    case AUTH_REDUCER_TYPES.SET_IS_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: payload,
      };

    case AUTH_REDUCER_TYPES.SET_USER_TOKENS:
      authService.setTokens(payload);
      return {
        ...state,
        isLoggedIn: true,
        accessToken: payload.accessToken,
        refreshToken: payload.refreshToken,
        sitewiseUser: authService.getSitewiseUser(),
        userInfo: authService.getUserInfo(),
      };

    case AUTH_REDUCER_TYPES.LOG_OUT:
      return {
        ...state,
        isLoggedIn: false,
      };

    case AUTH_REDUCER_TYPES.SET_ACCESS_DENIED:
      return {
        ...state,
        accessDenied: true,
      };

    case AUTH_REDUCER_TYPES.SET_USERINFO_DETAILS:
      authService.setUserInfo(payload);
      return {
        ...state,
        userInfo: {
          ...payload,
        },
      };

    case AUTH_REDUCER_TYPES.SET_AUTHORIZED_ROUTE:
      return {
        ...state,
        authorizedRoute: payload,
      };

    case AUTH_REDUCER_TYPES.SET_GO_TO_NEW_ROUTE:
      return {
        ...state,
        goToNewRoute: payload,
      };

    case AUTH_REDUCER_TYPES.SET_NAVIGATION:
      return {
        ...state,
        navigation: payload,
      };

    default:
      return state;
  }
};

const getAccessToken = (idToken) => {
  return async (dispatch) => {
    try {
      const response = await AuthAPI.getAccessToken(idToken);
      dispatch({ type: AUTH_REDUCER_TYPES.SET_USER_TOKENS, payload: response });
      dispatch(AuthReducerActions.getUserInfo(response));
    } catch (error) {
      dispatch({ type: AUTH_REDUCER_TYPES.SET_ACCESS_DENIED, payload: true });
      dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const getJarvisAccessToken = (jarvisAccessToken, siteLocationId, userId, tenantId) => {
  return async (dispatch) => {
    try {
      const response = await AuthAPI.getJarvisAccessToken(jarvisAccessToken, siteLocationId, userId, tenantId);
      dispatch({ type: AUTH_REDUCER_TYPES.SET_USER_TOKENS, payload: response });
      dispatch(AuthReducerActions.getUserInfo(response));
    } catch (error) {
      dispatch({ type: AUTH_REDUCER_TYPES.SET_ACCESS_DENIED, payload: true });
      dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const getUserInfo = (response) => {
  return async (dispatch) => {
    try {
      const userResponse = await AuthAPI.getUserProfile(response.accessToken);
      dispatch({ type: AUTH_REDUCER_TYPES.SET_USERINFO_DETAILS, payload: userResponse });
    } catch (error) {
      dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const signInRedirectCb = () => {
  return async (dispatch) => {
    try {
      if (window.jarvis) {
        const jarvisAccessToken = window.jarvis.accessToken;
        const siteLocationId = window.jarvis.tenant?.sitewise?.siteId;
        const userId = window.jarvis.user?.uniqueId;
        const tenantId = window.jarvis.tenant?.id;
        dispatch(AuthReducerActions.getJarvisAccessToken(jarvisAccessToken, siteLocationId, userId, tenantId));
      } else {
        authService.oidcManager
          .signinRedirectCallback()
          .then((user) => {
            dispatch(AuthReducerActions.getAccessToken(user.id_token));
          })
          .catch((error) => {
            console.log("err", error);
          });
      }
    } catch (error) {
      dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const updateSiteUserInfo = (showPageLoader = true) => {
  return async (dispatch, getState) => {
    try {
      showPageLoader && dispatch(GlobalReducerActions.showPageLoader());
      const siteId = localStorage.SITE_ID;
      const accessToken = await authService.getValidAccessTokenForSite(siteId);
      const refreshToken = localStorage.sitewise_refresh_token;
      const payload = {
        accessToken,
        refreshToken,
      };
      const userResponse = await AuthAPI.getUserProfile(accessToken);
      dispatch({ type: AUTH_REDUCER_TYPES.SET_USERINFO_DETAILS, payload: userResponse });
      dispatch({ type: AUTH_REDUCER_TYPES.SET_GO_TO_NEW_ROUTE, payload: true });
      await dispatch({ type: AUTH_REDUCER_TYPES.SET_USER_TOKENS, payload });
      const nav = getState().auth.navigation + 1;
      dispatch({ type: AUTH_REDUCER_TYPES.SET_NAVIGATION, payload: nav });
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    } catch (error) {
      dispatch(GlobalReducerActions.setApiRequestError(error));
      showPageLoader && dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const signInRedirect = () => {
  return async (dispatch) => {
    try {
      if (window.jarvis) {
        const jarvisAccessToken = window.jarvis.accessToken;
        const siteLocationId = window.jarvis.tenant?.sitewise?.siteId;
        const userId = window.jarvis.user?.uniqueId;
        const tenantId = window.jarvis.tenant?.id;
        dispatch(AuthReducerActions.getJarvisAccessToken(jarvisAccessToken, siteLocationId, userId, tenantId));
      } else {
        authService.oidcManager.signinRedirect();
      }
    } catch (error) {
      dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const setAuthorizedRoute = (route) => {
  return async (dispatch) => {
    try {
      dispatch({ type: AUTH_REDUCER_TYPES.SET_AUTHORIZED_ROUTE, payload: route });
    } catch (error) {
      dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const resetAuthorizedRoute = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: AUTH_REDUCER_TYPES.SET_AUTHORIZED_ROUTE, payload: "" });
      dispatch({ type: AUTH_REDUCER_TYPES.SET_GO_TO_NEW_ROUTE, payload: false });
    } catch (error) {
      dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

const logout = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: AUTH_REDUCER_TYPES.LOG_OUT, payload: false });
      authService.logout();
    } catch (error) {
      dispatch(GlobalReducerActions.hidePageLoader());
    }
  };
};

export const AuthReducerActions = {
  signInRedirect,
  signInRedirectCb,
  getAccessToken,
  getJarvisAccessToken,
  getUserInfo,
  updateSiteUserInfo,
  setAuthorizedRoute,
  resetAuthorizedRoute,
  logout,
};
