import React from "react";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { IconButton, InputAdornment } from "@material-ui/core";
import { ICONS } from "../icon-manager";

export const Datepicker = (props) => {
  const { selectedDate, handleDateChange, ...otherProps } = props;

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DateTimePicker
        inputVariant="filled"
        fullWidth={true}
        margin="normal"
        id="date-picker"
        value={selectedDate}
        onChange={handleDateChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <ICONS.CalendarToday />
              </IconButton>
            </InputAdornment>
          ),
        }}
        disablePast={true}
        {...otherProps}
      />
    </MuiPickersUtilsProvider>
  );
};
