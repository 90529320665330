import React, { useContext } from "react";
import { Box, makeStyles, Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ICONS, UserPermissionContext, ScopeContext } from "../../../../../../commons";
import { SiteDetail } from "./site-detail";
import { SITES_REDUCER_TYPES } from "../../../../../../reducers";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    flexBasis: "75%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  description: {
    marginBottom: "64px",
  },
  sitesContainer: {
    height: "35vh",
    overflowY: "auto",
  },
  sitesGrid: {
    fontSize: "1rem",
    display: "grid",
    gridTemplateColumns: "1fr 1fr max-content",
    gridColumnGap: "10px",
    alignItems: "center",
  },
  buttonsWrapper: {
    display: "flex",
    justifyContent: "space-between",
    gap: "20px",
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
    },
  },
  rigthButtonsWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "20px",
  },
}));

export const SiteCordsForm = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { Permissions } = useContext(UserPermissionContext);
  const history = useHistory();
  const { currentSite, goToSubSite, newSiteFormVisible, mapSelectedSubSite, showMapInfoWidget } =
    props;

  const showAddSubSiteBttn = useSelector((state) => state.sitesScreen.showAddSubSiteBttn);

  const iconDisabled = () => {
    return Permissions.Manage_Sites && (newSiteFormVisible || currentSite.subSites.length === 0)
      ? true
      : false;
  };
  const { namedRoutes } = useContext(ScopeContext);

  return (
    <>
      <div className={classes.wrapper}>
        <Box display="flex" flexDirection="column">
          <Box className={classes.description}>
            <span>
              Sitewise allows multiple site areas to be shown with shortcuts that zoom to each area.
              To set these areas, add Site Areas below. Double click on the map to the right to drop
              a pin and designate that area on the map.
            </span>
          </Box>

          <div className={classes.sitesContainer}>
            <div className={classes.sitesGrid}>
              {currentSite.subSites.map((subSite, index) => {
                return (
                  <SiteDetail
                    mapSelectedSubSite={mapSelectedSubSite}
                    subSite={subSite}
                    key={`${subSite.name}-${index}`}
                    id={index}
                    goToSubSite={goToSubSite}
                    showMapInfoWidget={showMapInfoWidget}
                  />
                );
              })}
            </div>
          </div>
        </Box>

        <div className={classes.buttonsWrapper}>
          <div>
            {showAddSubSiteBttn ? (
              <Button
                startIcon={<ICONS.AddCustomIcon style={{ fontSize: "16px" }} />}
                disabled={iconDisabled() || !Permissions.Manage_Sites}
                variant="outlined"
                onClick={() => {
                  dispatch({ type: SITES_REDUCER_TYPES.NEW_SITE_FORM_VISIBLE, payload: true });
                  dispatch({
                    type: SITES_REDUCER_TYPES.SET_MAP_ENABLE_DOUBLE_CLICK,
                    payload: true,
                  });
                  dispatch({
                    type: SITES_REDUCER_TYPES.SET_SHOW_ADD_NEW_SITE_BTTN,
                    payload: false,
                  });
                }}
              >
                Add additional site markers
              </Button>
            ) : null}
          </div>

          <div className={classes.rigthButtonsWrapper}>
            <Button
              disabled={currentSite.subSites.length === 0 ? true : false}
              variant="outlined"
              onClick={() => {
                history.push(namedRoutes.sites);
              }}
            >
              Exit
            </Button>

            <Button
              onClick={() => {
                dispatch({ type: SITES_REDUCER_TYPES.SET_PAGE_INDEX, payload: 1 });
              }}
              disabled={currentSite.subSites.length === 0 ? true : false}
              variant="contained"
              color="primary"
              disableElevation={true}
            >
              Next Site Settings
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
