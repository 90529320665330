import { AxiosClient } from "./axios-client";
import { handleErrorFn } from "../custom-errors";
import { authService } from "../utils/auth-service";

const API_URL = "/api/BulkDeviceMessage";

const getAllBulkDeviceMessegesBySiteId = async (siteId) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    const response = await AxiosClient({
      url: `/api/SiteLocation/${siteId}/BulkDeviceMessage`,
      method: "GET",
      headers: headers,
    });
    return response.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const createBulkDeviceMessage = async (data) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    const response = await AxiosClient({
      url: `${API_URL}`,
      method: "POST",
      data: data,
      headers: headers,
    });
    return response.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

export const BulkDeviceMessageAPI = {
  createBulkDeviceMessage,
  getAllBulkDeviceMessegesBySiteId,
};
