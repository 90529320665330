import React from "react";
import {
  TableCell,
  TableRow,
  Button,
  Box,
  Collapse,
  Grid,
  TextField,
  Tooltip,
} from "@material-ui/core";

import { RowActionBttn, Datepicker, ICONS } from "../../../../../commons";

import { ZONE_REDUCER_TYPES, ZonesReducerActions } from "../../../../../reducers";
import { parseUtcToLocal, AppUtils } from "../../../../../utils";

const SPACE_BETWEEN = "space-between";

function _updateZone(event, dispatch, setSaveBttnDisable, onTableExpandBttnCb) {
  event.stopPropagation();

  dispatch(ZonesReducerActions.updateZone()).then((res) => {
    if (res) {
      dispatch({
        type: ZONE_REDUCER_TYPES.SET_EDIT_FORM,
        payload: false,
      });
      setSaveBttnDisable(true);
      onTableExpandBttnCb && onTableExpandBttnCb(null);
    }
  });
}

function _getHelperText(selectedZone) {
  return `(${selectedZone ? selectedZone.entryMessage.length : 0}/120)`;
}

function renderZoneMetaData({ selectedZone }) {
  return (
    <div>
      <div>
        {selectedZone && (
          <Tooltip title={selectedZone.createdBy}>
            <span>{`Zone Created by ${AppUtils.parseEmailToName(selectedZone.createdBy)} `}</span>
          </Tooltip>
        )}
        {selectedZone && parseUtcToLocal(selectedZone.createdTimestamp)}
      </div>
      {selectedZone &&
      selectedZone.lastModifiedTimestamp?.slice(0, 22) !==
        selectedZone.createdTimestamp?.slice(0, 22) ? (
        <div>
          <Tooltip title={selectedZone.lastModifiedBy}>
            <span>
              {`Zone Modified by ${AppUtils.parseEmailToName(selectedZone.lastModifiedBy)} `}
            </span>
          </Tooltip>
          {selectedZone && parseUtcToLocal(selectedZone.lastModifiedTimestamp)}
        </div>
      ) : null}
    </div>
  );
}

export const CollapsedRowView = (props) => {
  const {
    rowData,
    classes,
    fullMapRef,
    isItemSelected,
    selectedZone,
    dispatch,
    setSaveBttnDisable,
    selectZoneAndEdit,
    Permissions,
    mapEditShapeInProgress,
    onTableExpandBttnCb,
    tableStyles,
    saveBttnDisable,
    editForm,
  } = props;

  if (!rowData.isFrontEndOnly) {
    return (
      <TableRow
        className={classes.tableRow}
        classes={{
          selected: classes.selected,
        }}
        hover
        selected={isItemSelected}
        key={`${rowData.id}-collapsed-row`}
        ref={fullMapRef}
      >
        <TableCell
          key={`${rowData.id}-collapse-without-alert`}
          style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: "45px" }}
          colSpan={6}
        >
          <Collapse in={isItemSelected} timeout="auto" unmountOnExit>
            <Box display="flex" flexDirection="column" pt={1} pb={1}>
              <Grid container spacing={2}>
                <Grid item lg={3} md={6} xs={6}>
                  <Box className={classes.formControl}>
                    <TextField
                      disabled={Permissions.Manage_Zones ? false : true}
                      helperText={_getHelperText(selectedZone)}
                      multiline
                      rows={3}
                      rowsMax={3}
                      fullWidth={true}
                      id="filled-basic"
                      label="Entry Message"
                      variant="filled"
                      value={selectedZone && selectedZone.entryMessage.slice(0, 120)}
                      onChange={(event) => {
                        dispatch({
                          type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE_ENTRY_MESSAGE,
                          payload: event.target.value.slice(0, 120),
                        });
                        dispatch({
                          type: ZONE_REDUCER_TYPES.SET_EDIT_FORM,
                          payload: true,
                        });
                        setSaveBttnDisable(false);
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item lg={3} md={6} xs={6}>
                  <Box className={classes.formControl}>
                    <TextField
                      disabled={Permissions.Manage_Zones ? false : true}
                      helperText={_getHelperText(selectedZone)}
                      multiline
                      id="filled-basic"
                      label="Exit Message"
                      variant="filled"
                      rows={3}
                      rowsMax={3}
                      fullWidth={true}
                      value={selectedZone && selectedZone.exitMessage.slice(0, 120)}
                      onChange={(event) => {
                        dispatch({
                          type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE_EXIT_MESSAGE,
                          payload: event.target.value.slice(0, 120),
                        });
                        dispatch({
                          type: ZONE_REDUCER_TYPES.SET_EDIT_FORM,
                          payload: true,
                        });
                        setSaveBttnDisable(false);
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item lg={3} md={6} xs={6}>
                  <Box className={classes.formControl}>
                    <Datepicker
                      style={{ marginTop: "0" }}
                      disabled
                      handleDateChange={() => {}}
                      selectedDate={selectedZone && selectedZone.startTime}
                      label="Start Date/Time"
                    />
                  </Box>
                </Grid>
                <Grid item lg={3} md={6} xs={6}>
                  <Box className={classes.formControl}>
                    <Datepicker
                      style={{ marginTop: "0" }}
                      disabled={
                        Permissions.Manage_Zones ? selectedZone && !selectedZone.isTemporary : true
                      }
                      handleDateChange={(dateTime) => {
                        dispatch({
                          type: ZONE_REDUCER_TYPES.SET_SELECTED_ZONE_END_TIME,
                          payload: dateTime,
                        });
                        dispatch({
                          type: ZONE_REDUCER_TYPES.SET_EDIT_FORM,
                          payload: true,
                        });
                        setSaveBttnDisable(false);
                      }}
                      selectedDate={selectedZone && selectedZone.endTime}
                      label="End Date/Time"
                    />
                  </Box>
                </Grid>
              </Grid>
              <Box display="flex" justifyContent={SPACE_BETWEEN} alignItems="flex-end">
                {renderZoneMetaData({ selectedZone })}
                <Box display="flex" className={classes.actionBttnsWrapper}>
                  {Permissions.Manage_Zones ? (
                    <>
                      <RowActionBttn
                        title={"Edit Zone"}
                        onClick={() => {
                          selectZoneAndEdit(selectedZone);
                        }}
                        color="primary"
                        Icon={ICONS.RoundedCornerOutlined}
                        disabled={mapEditShapeInProgress}
                      />
                      <RowActionBttn
                        color="primary"
                        title={"Delete Zone"}
                        onClick={() => {
                          dispatch({
                            type: ZONE_REDUCER_TYPES.SET_REMOVE_ZONE_DIALOG,
                            payload: true,
                          });
                          dispatch({
                            type: ZONE_REDUCER_TYPES.SET_EDIT_FORM,
                            payload: false,
                          });
                        }}
                        Icon={ICONS.Delete}
                        disabled={mapEditShapeInProgress}
                      />
                      <Button
                        className={tableStyles.rowActionBttn}
                        disabled={mapEditShapeInProgress || saveBttnDisable || !editForm}
                        variant="contained"
                        color="primary"
                        data-id={rowData.id}
                        size="small"
                        onClick={(event) => {
                          _updateZone(event, dispatch, setSaveBttnDisable, onTableExpandBttnCb);
                        }}
                      >
                        Save
                      </Button>
                    </>
                  ) : null}
                </Box>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    );
  }
  return null;
};
