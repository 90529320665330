import React from "react";
import { makeStyles } from "@material-ui/styles";
import { FONTS } from "../../utils";
import { useDispatch } from "react-redux";
import { DEVICES_REDUCER_TYPES } from "../../reducers";

const useStyles = makeStyles(() => ({
  usersCountLabel: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "8px",
  },
  count: {
    ...FONTS.H4_Bold_24,
  },
}));

export const UsersCount = (props) => {
  const { count, label, countColor = "#FFFFFF", showDialog, alertData } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const styles = {
    cursor : showDialog?'pointer':'',
    textDecoration:showDialog?'underline':''
  }
  return (
    <div
    style={{cursor:styles.cursor}}
     onClick={() => {
      dispatch({type: DEVICES_REDUCER_TYPES.DEVICES_DIALOG_DISPLAY, payload: {allDevicesList:showDialog?alertData:[],show:showDialog?true:false},});
    }}
      >
      <div>
        <span className={classes.usersCountLabel}>{label}</span>
      </div>
      <div className={classes.count} style={{ color: countColor, textDecoration:styles.textDecoration}}>
        {count}
      </div>
    </div>
  );
};
