import moment from "moment";
const formatStr = "YYYY-MM-DD hh:mmA";

export const parseUtcToLocal = (val, format = formatStr) => {
  if (val) {
    return moment(val).local().format(format);
  }

  return "";
};

export const parseDateToUtc = (val) => {
  return moment.utc(val);
};

export const setDateAndTime = (val) => {
  let formatedDate = null;
  if (val) {
    const date = val.split("-");
    const year = date[0];
    const month = date[1];
    const day = date[2].substring(0, 2);
    const time = date[2].substring(3, 8);
    formatedDate = `${day}/${month}/${year} ${time}`
  };
  return formatedDate;
}
