import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, makeStyles } from "@material-ui/core";
import { FONTS } from "../../utils";
import {
  RemoveDialog as RemoveAlertDialog,
  AlertNoteDialog,
  UserPermissionContext,
} from "../../commons";
import { ALERTS_REDUCER_TYPES, AlertsReducerActions, DevicesReducerActions } from "../../reducers";
import { AlertsLogTable } from "./components/tables/alerts-log-table";
import { CurrentAlerts } from "./components/current-alerts";
import { AlertsMap, AlertsMapContainer } from "./components/alerts-map";
import { PrivacyDisclaimerDialog } from "../../commons/dialogs/privacy-disclaimer-dialog/index.js";

const useStyles = makeStyles((theme) => ({
  tableTitle: {
    ...FONTS.H5_ShellBold_18,
    marginBottom: "15px",
    display: "block",
  },
  alertsContainer: {
    height: "100%",
  },
  alertsContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: "76vh",
    [theme.breakpoints.down("sm")]: {
      // tablets
      flexDirection: "column",
    },
  },
  mapContainer: {
    flexBasis: "35%",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      // tablets
      height: "38vh",
    },
  },
  alertsAndLogsContainer: {
    flexBasis: "63%",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      marginTop: "15px",
    },
  },
  currentAlertsContainer: {
    flexBasis: "43%",
  },
  alertsLogContainer: {
    flexBasis: "53%",
  },
}));


export const onMapLoadCb = (renderMapFn, _zones, _devices) => {
  renderMapFn(_zones, _devices);
};

export const renderZones = (isMapRendered,zones,alertsMap) =>{
  if (isMapRendered && zones) {
    alertsMap.renderZones(zones);
  }
}

export const renderUserDevices = (isMapRendered,userDevices,alertsMap) =>{
  if (isMapRendered && userDevices) {
    alertsMap.renderUserDevices(userDevices);
  }
}

export const AlertsContainer = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { siteID, alerts } = useSelector((state) => state.globalState);
  const { zones, userDevices, clearedAlerts, mapView } = useSelector((state) => state.alertsScreen);
  const currentAlert = useSelector((state) => state.alertsScreen.selected.currentAlert);
  const allDevices = useSelector((state) => state.devicesScreen?.devices);

  const [alertsMap, setAlertsMap] = useState(null);

  const showClearAlertDialog = useSelector(
    (state) => state.alertsScreen.dialogs.showClearAlertDialog
  );

  const showAlertNoteDialog = useSelector(
    (state) => state.alertsScreen.dialogs.showAlertNoteDialog
  );

  const { Permissions } = useContext(UserPermissionContext);
  const isMapRendered = alertsMap?.renderMap;

  const onLocateSiteCb = (site, isSpecificDeviceLocation = false, isAlertLogsLocation = false) => {
    alertsMap.goToSiteLocation(site, isSpecificDeviceLocation, isAlertLogsLocation);
  }

  const showHidePlotPlan = (graphicID) => {
    alertsMap.showHidePlotPlan(graphicID);
  }

  const showHideMapImageLayer = (graphicID) => {
    alertsMap.showHideMapImageLayer(graphicID);
  }


  useEffect(() => {
    renderZones(isMapRendered,zones,alertsMap);
  }, [alertsMap, zones]);

  useEffect(() => {
    renderUserDevices(isMapRendered,userDevices,alertsMap);
  }, [alertsMap, userDevices]);

  useEffect(() => {
    if (siteID) {
      dispatch(AlertsReducerActions.init(siteID)).then(() => {
        setAlertsMap(
          new AlertsMap({
            onMapLoadCb: onMapLoadCb,
            viewDevicesEnabled: Permissions.View_Devices,
            viewZonesEnabled: Permissions.View_Zones,
          })
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, siteID]);

  useEffect(() => {
    const thisIntervalId = setInterval(async () => {
      dispatch(AlertsReducerActions.getAllZones(false));
    }, 5 * 60 * 1000);
    return () => {
      if (thisIntervalId) {
        clearInterval(thisIntervalId);
      }
      dispatch({ type: ALERTS_REDUCER_TYPES.SET_SELECTED_ALERT_LOG, payload: null });
    };
  }, [dispatch]);

  useEffect(() => {
    if (!allDevices?.length) {
      dispatch(DevicesReducerActions.getAllDevices());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allDevices]);

  useEffect(() => {
    if (isMapRendered) {
      alertsMap.goToRecentAlertDevice();
    }
  }, [alerts]);

  return (
    <Box className={classes.alertsContainer}>
      {mapView === "LARGE_MAP" ? (
        <Box className={classes.alertsContent}>
          <Box className={classes.mapContainer}>
        <PrivacyDisclaimerDialog/>
            <AlertsMapContainer
              onLocateSiteCb={onLocateSiteCb}
              showHidePlotPlan={showHidePlotPlan}
              showHideMapImageLayer={showHideMapImageLayer}
            />
          </Box>

          <Box className={classes.alertsAndLogsContainer}>
            <Box className={classes.currentAlertsContainer}>
              <CurrentAlerts onLocateSiteCb={onLocateSiteCb} />
            </Box>
            <Box className={classes.alertsLogContainer}>
              <span className={classes.tableTitle}>Alerts Log History</span>
              <AlertsLogTable data={clearedAlerts} onLocateSiteCb={onLocateSiteCb} />
            </Box>
          </Box>
        </Box>
      ) : (
        <Box className={classes.alertsContent}>
          <Box className={classes.mapContainer} style={{ flexBasis: "100%" }}>
        <PrivacyDisclaimerDialog/>
            <AlertsMapContainer
              onLocateSiteCb={onLocateSiteCb}
              showHidePlotPlan={showHidePlotPlan}
              showHideMapImageLayer={showHideMapImageLayer}
            />
          </Box>
        </Box>
      )}

      <RemoveAlertDialog
        title="Are you sure you want to clear this alert?"
        onCancel={() => {
          dispatch({ type: ALERTS_REDUCER_TYPES.SET_SELECTED_CURRENT_ALERT, payload: null });
          dispatch({ type: ALERTS_REDUCER_TYPES.SET_CLEAR_ALERT_DIALOG_DISPLAY, payload: false });
        }}
        onConfirm={() => {
          dispatch({ type: ALERTS_REDUCER_TYPES.SET_ALERT_NOTE_DIALOG_DISPLAY, payload: true });
          dispatch({ type: ALERTS_REDUCER_TYPES.SET_CLEAR_ALERT_DIALOG_DISPLAY, payload: false });
        }}
        show={showClearAlertDialog}
      />
      <AlertNoteDialog
        show={showAlertNoteDialog}
        onCancel={() => {
          dispatch({ type: ALERTS_REDUCER_TYPES.SET_ALERT_NOTE_DIALOG_DISPLAY, payload: false });
        }}
        onActionOneClick={() => {
          dispatch(
            AlertsReducerActions.updateAlert({
              id: currentAlert.id,
              isCleared: true,
              note: null,
            })
          );
        }}
        onActionTwoClick={(note) => {
          dispatch(
            AlertsReducerActions.updateAlert({
              id: currentAlert.id,
              isCleared: true,
              note: note,
            })
          );
        }}
      />
    </Box>
  );
};
