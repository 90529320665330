import { combineReducers } from "redux";
import { authReducer } from "./auth-reducer";
import { devicesReduer } from "./devices-reducer";
import { globalReducer } from "./global-reducer";
import { zonesReducer } from "./zones-reducer";
import { usersReducer } from "./users-reducer";
import { messagesReducer } from "./messages-reducer";
import { alertsReducer } from "./alerts-reducer";
import { sitesReducer } from "./sites-reducer";

export const rootReducer = combineReducers({
  globalState: globalReducer,
  auth: authReducer,
  devicesScreen: devicesReduer,
  zonesScreen: zonesReducer,
  usersScreen: usersReducer,
  messagesScreen: messagesReducer,
  alertsScreen: alertsReducer,
  sitesScreen: sitesReducer,
});
