import { AxiosClient } from "./axios-client";
import { handleErrorFn } from "../custom-errors";
import { authService } from "../utils/auth-service";

const URL = `/api/usercategory`;

const getSiteUserCategories = async (siteId) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    const res = await AxiosClient({
      url: `/api/siteLocation/${siteId}/UserCategory`,
      method: "GET",
      headers: headers,
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const createUserCategory = async (userCategory) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    return await AxiosClient({
      url: URL,
      method: "POST",
      data: userCategory,
      headers: headers,
    });
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const updateUserCategory = async (userCategoryData) => {
  const headers = await authService.getAuthorizeHeader();
  const { id } = userCategoryData;
  try {
    return await AxiosClient({
      url: `${URL}/${id}`,
      method: "PUT",
      data: userCategoryData,
      headers: headers,
    });
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const getUserCategoryById = async (userCategoryId) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    const res = await AxiosClient({
      url: `${URL}/${userCategoryId}`,
      method: "GET",
      headers: headers,
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

const deleteUserCategory = async (userCategoryId) => {
  const headers = await authService.getAuthorizeHeader();
  try {
    const res = await AxiosClient({
      url: `${URL}/${userCategoryId}`,
      method: "DELETE",
      headers: headers,
    });
    return res.data;
  } catch (error) {
    throw handleErrorFn(error.response.data);
  }
};

export const UserCategoryAPI = {
  getSiteUserCategories,
  createUserCategory,
  updateUserCategory,
  getUserCategoryById,
  deleteUserCategory,
};
