import React, { useState, useEffect, useContext } from "react";
import { Button, Paper } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { Table, TableBody, TableFooter, TableContainer, TablePagination } from "@material-ui/core";
import {
  EnhancedTableHead,
  useCustomTableHook,
  useCustomTableStyles,
  UserPermissionContext,
  ICONS,
  useDebounce,
} from "../../../../commons";
import { DeviceIdWithBatteryIcon } from "../../../../commons/deviceId-with-battery-icon";
import { DeviceIdWithBatteryIconTooltip } from "../../../../commons/deviceId-with-battery-icon-tooltip";
import { Row } from "./row";
import { parseUtcToLocal } from "../../../../utils";
import { USERS_REDUCER_TYPES } from "../../../../reducers";

function getHeadCells({
  showTableNameCell,
  showTableEmailCell,
  showTableDepartmentCell,
  showTableRadioChannelCell,
  showTableLocationsCell,
  showTableContractorOrEmployeeIdCell,
  showTableRoles,
}) {
  return [
    ...(showTableNameCell
      ? [
          {
            id: "name",
            sortBy: "name",
            label: "User Name",
            width: "7%",
            content: (row) => row.name,
          },
        ]
      : []),
    {
      id: "id",
      sortBy: "id",
      label: "User ID",
      width: "7%",
      content: (row) => row.id,
      copyCellTextFn: (row) => row.id,
    },
    {
      id: "assignedDevice.manufacturerId",
      sortBy: "assignedDevice.manufacturerId",
      label: "Device ID",
      width: "11%",
      content: (row) => (
        <DeviceIdWithBatteryIcon
          manufacturerId={row.assignedDevice?.manufacturerId}
          level={row.assignedDevice?.batteryLevel}
          isOnline={row.assignedDevice?.isOnline}
        />
      ),
      toolTipContent: (row) =>
        row.assignedDevice ? (
          <DeviceIdWithBatteryIconTooltip
            manufacturerId={row.assignedDevice?.manufacturerId}
            level={row.assignedDevice?.batteryLevel}
            isOnline={row.assignedDevice?.isOnline}
          />
        ) : null,
      showEllipses: true,
      copyCellTextFn: (row) => row.assignedDevice?.manufacturerId,
    },
    ...(showTableContractorOrEmployeeIdCell
      ? [
          {
            id: "contractorOrEmployeeId",
            sortBy: "contractorOrEmployeeId",
            label: "Contractor ID",
            width: "7%",
            content: (row) => row.contractorOrEmployeeId,
          },
        ]
      : []),
    ...(showTableEmailCell
      ? [
          {
            id: "email",
            sortBy: "email",
            label: "Email",
            width: "7%",
            content: (row) => row.email,
          },
        ]
      : []),
    {
      id: "company",
      sortBy: "company",
      label: "Company",
      width: "7%",
      content: (row) => row.company,
    },
    {
      id: "userCategory.name",
      sortBy: "userCategory.name",
      label: "User Category",
      width: "7%",
      content: (row) => row.userCategory?.name,
    },
    ...(showTableRoles
      ? [
          {
            id: "role.role",
            sortBy: "role.role",
            label: "User Roles",
            width: "7%",
            content: (row) => row.role?.role,
          },
        ]
      : []),
    {
      id: "userType",
      sortBy: "userType",
      label: "User Type",
      width: "7%",
      content: (row) => row.userType,
    },
    {
      id: "status",
      sortBy: "userOptInStatus",
      label: "Status",
      width: "7%",
      content: (row) => {
        if (row.userOptInStatus === "Pending") {
          return <span style={{ color: "#EB8705" }}>{row.userOptInStatus}</span>;
        } else {
          return row.userOptInStatus;
        }
      },
    },
    ...(showTableDepartmentCell
      ? [
          {
            id: "department",
            sortBy: "department",
            label: "Department",
            width: "7%",
            content: (row) => row.department,
          },
        ]
      : []),

    ...(showTableRadioChannelCell
      ? [
          {
            id: "radioChannel",
            sortBy: "radioChannel",
            label: "Radio Channel",
            width: "7%",
            content: (row) => row.radioChannel,
          },
        ]
      : []),

    ...(showTableLocationsCell
      ? [
          {
            id: "locations",
            sortBy: "locations",
            label: "Locations",
            width: "7%",
            content: (row) => row.locations,
          },
        ]
      : []),

    {
      id: "createdTimestamp",
      sortBy: "createdTimestamp",
      label: "User created on",
      width: "7%",
      content: (row) => parseUtcToLocal(row.createdTimestamp),
    },
    {
      id: "lastActive",
      sortBy: "lastActive",
      label: "Last Active",
      width: "7%",
      content: (row) => parseUtcToLocal(row.lastActive),
    },
  ];
}

export const UsersTable = (props) => {
  const dispatch = useDispatch();
  const tableClasses = useCustomTableStyles();
  const { data, site } = props;
  const { Permissions } = useContext(UserPermissionContext);
  const showTableNameCell = site.privacySettings?.tableShowName;
  const showTableEmailCell = site.privacySettings?.tableShowEmail;
  const showTableDepartmentCell = site.userAttributes?.department;
  const showTableRadioChannelCell = site.userAttributes?.radioChannel;
  const showTableLocationsCell = site.userAttributes?.locations;
  const showTableContractorOrEmployeeIdCell = site.userAttributes?.contractorOrEmployeeId;
  const showTableRoles = Permissions.View_Roles;
  const headCells = getHeadCells({
    showTableNameCell,
    showTableEmailCell,
    showTableDepartmentCell,
    showTableRadioChannelCell,
    showTableLocationsCell,
    showTableContractorOrEmployeeIdCell,
    showTableRoles,
  });

  const [tableSearchStr, setTableSearchStr] = useState("");

  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    setPage,
    getComparator,
    stableSort,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useCustomTableHook({ defaultSortBy: headCells[0].sortBy, rowsPerPage: 10 });

  const [tableData, setTableData] = useState([]);

  const tableSearchDebounced = useDebounce((_nextVal) => {
    setPage(0);
    setTableSearchStr(_nextVal);
  }, 300);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  useEffect(() => {
    let arr = [];

    if (tableSearchStr) {
      if (showTableNameCell) {
        arr = data.filter(
          (tData) =>{
            return(
              tData.name.toLowerCase().includes(tableSearchStr.toLowerCase()) ||
              tData?.assignedDevice?.manufacturerId.toLowerCase().includes(tableSearchStr.toLowerCase())
             ) 
          }
        );
      } else {
        arr = data.filter(
          (tData) =>{
            return(
              tData.id.includes(tableSearchStr.toLowerCase()) ||
              tData?.assignedDevice?.manufacturerId.toLowerCase().includes(tableSearchStr.toLowerCase())
             ) 
          }
        );
      }
    } else {
      arr = data;
    }
    setTableData(arr);
  }, [tableSearchStr, data, showTableNameCell, setTableData]);

  if (tableData === null) {
    return null;
  }

  return (
    <Paper>
      <TableContainer className={tableClasses.tableContainer}>
        <Table
          aria-labelledby="users-table"
          aria-label="users table"
          stickyHeader
          data-testid="users-table"
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={headCells}
            toShow={true}
            tableWithRowActions={true}
            onChangeSearch={(event) => {
              tableSearchDebounced(event.target.value);
            }}
            searchLabel={showTableNameCell ? "User Name or Device ID" : "User ID or Device ID"}
            isminWidth={true}
          />
          <TableBody data-testid="users-table-body">
            {stableSort(tableData, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((rowData) => {
                return <Row key={rowData.id} rowData={rowData} headCells={headCells} />;
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TableFooter className={tableClasses.tabelFooterRoot} component="div">
        {Permissions.Manage_Users ? (
          <Button
            startIcon={<ICONS.Add />}
            color="primary"
            onClick={() =>
              dispatch({
                type: USERS_REDUCER_TYPES.SET_NEW_USER_DIALOG_DISPLAY,
                payload: true,
              })
            }
          >
            Add new user
          </Button>
        ) : (
          <div></div>
        )}

        <TablePagination
          classes={{
            root: tableClasses.tablePaginationRoot,
          }}
          component="div"
          rowsPerPageOptions={[]}
          labelRowsPerPage=""
          count={tableData && tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableFooter>
    </Paper>
  );
};
