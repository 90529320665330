import React from "react";
import { Chip, TextField } from "@material-ui/core";
import { useChipStyles } from "../useChipStyles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ICONS } from "../../../../../commons";

export const UserRoleAutoselectWithChips = (props) => {
  const chipStyles = useChipStyles();
  const { onChange, value, options, onChipDelete, userDevices, showCount } = props;

  function handleChipDelete(data) {
    const arr = [...value];
    const i = arr.indexOf(data);
    arr.splice(i, 1);
    onChipDelete(arr);
  }

  function renderChips() {
    return value.map((role) => {
      let label = "";
      if (showCount) {
        let count = 0;
        userDevices.forEach((device) => {
          const { currentAssignedUser } = device;
          if (currentAssignedUser?.role?.role === role.role) {
            count += 1;
          }
        });
        label = `${role.role} (${count})`;
      } else {
        label = role.role;
      }
      return (
        <Chip
          className={chipStyles.chip}
          key={role.id}
          label={label}
          onDelete={() => {
            handleChipDelete(role);
          }}
          onClick={() => handleChipDelete(role)}
        />
      );
    });
  }

  return (
    <>
      <Autocomplete
        multiple
        filterSelectedOptions
        id="zone-categories-autocomplete"
        value={value}
        options={options}
        getOptionLabel={(option) => option.role}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label={"User Role"}
            margin="dense"
            variant="filled"
            onChange={() => {}}
          />
        )}
        renderTags={() => null}
        popupIcon={<ICONS.AddCircle />}
      />
      <div className={chipStyles.chipsWrapper}>{renderChips()}</div>
    </>
  );
};
