import { makeStyles } from "@material-ui/core";
import { FONTS } from "../../utils";

export const useCustomTableStyles = makeStyles((theme) => ({
  tablePaper: {
    width: "100%",
  },
  tableContainer: {
    maxHeight: "60vh",
  },
  table: {
    minWidth: 750,
    position: "relative",
  },
  tableRoot: {
    tableLayout: "fixed",
  },
  tablePaginationRoot: {
    border: "none",
  },
  rowActionBttnsWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  rowActionBttn: {
    color: theme.palette.base.elevation_4,
    ...FONTS.Button1_Bold_16,
  },
  cell: {
    padding: "16px 10px",
    flex: "none",
    order: 0,
    alignSelf: "center",
    margin: "0px 0px",
    overflowWrap: "anywhere",
  },
  zoneRowCell:{
    padding: "13px 1px",
    flex: "none",
    order: 0,
    alignSelf: "center",
    margin: "0px 0px",
    overflowWrap: "anywhere",
  },
  cellEllipses: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    display: "block",
    overflow: "hidden",
  },
  tooltip: {
    backgroundColor: "#4F4F64",
    maxWidth: 220,
    color: "white",
  },
  tooltipContentWrapper: {
    padding: "8px",
  },
  tooltipContent: {
    ...FONTS.Label1_Med_12,
  },
  alertText: {
    color: "#FADEB1",
    letterSpacing: "1px",
    ...FONTS.B5_Bold_12,
  },
  tabelFooterRoot: {
    background: theme.palette.base.elevation_1,
    display: "flex !important",
    flexDirection: "row",
    justifyContent: "space-between",
  },
}));
