import React from "react";
import { FormControl, Button, Select, MenuItem, InputLabel } from "@material-ui/core";
import { TabPanel } from "../../../../commons/tabs";
import { UserTypeDropdown } from "../../../../commons";

export const SecondTabPanel = (props) => {
  const {
    tabValue,
    classes,
    handleFormSubmit,
    userCategory,
    setRoleId,
    setUserCategory,
    userCategories,
    userRoles,
    userType,
    setUserType,
    handleCancelBttnClick,
    roleId,
    name,
    setTabValue,
    assignDevice,
    deviceToAssign,
  } = props;

  function checkKeyDown(e) {
    if (e.code === "Enter") {
      e.preventDefault();
    }
  }

  return (
    <TabPanel value={tabValue} index={1}>
      <form
        className={classes.form}
        autoComplete="off"
        onSubmit={handleFormSubmit}
        onKeyDown={(e) => checkKeyDown(e)}
      >
        <div>{name}</div>
        <FormControl variant="filled" required className={classes.formControl}>
          <InputLabel id="select-user-category-label">User Category</InputLabel>
          <Select
            labelId="select-user-category-label"
            id="simple-user-category"
            value={userCategory}
            onChange={(event) => {
              setUserCategory(event.target.value);
              setRoleId("");
            }}
          >
            <MenuItem value="" disabled>
              Select User Category
            </MenuItem>
            {userCategories.map((userCat) => (
              <MenuItem className={classes.menuItemRoot} key={userCat.id} value={userCat}>
                {userCat.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl variant="filled" required className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">User Role</InputLabel>
          <Select
            required
            labelId="simple-select-filled-label"
            id="simple-select-filled"
            renderValue={(value) => {
              let renderRoleValue = "";

              if (userRoles) {
                const filter = userRoles.filter((uRole) => uRole.id === value);
                renderRoleValue = filter ? filter[0].role : "";
              }

              return <span>{renderRoleValue}</span>;
            }}
            onChange={(event) => {
              setRoleId(event.target.value);
            }}
            value={roleId}
          >
            <MenuItem value="" disabled>
              Select User Role
            </MenuItem>

            {userRoles.map((userRole) => {
              return (
                <MenuItem className={classes.menuItemRoot} key={userType.id} value={userRole.id}>
                  {userRole.role}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl required variant="filled" className={classes.formControl}>
          <UserTypeDropdown
            onChange={(event) => {
              setUserType(event.target.value);
            }}
            value={userType}
          />
        </FormControl>

        <FormControl className={classes.formControlActions}>
          <Button color="primary" variant="outlined" onClick={handleCancelBttnClick}>
            Cancel
          </Button>

          <Button
            onClick={() => setTabValue(0)}
            variant="outlined"
            color="primary"
            style={{ marginLeft: "10px" }}
          >
            Back
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ marginLeft: "10px" }}
            disabled={assignDevice && !deviceToAssign}
          >
            Create User
          </Button>
        </FormControl>
      </form>
    </TabPanel>
  );
};
